import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import Category from "./Category";
import ShopMode from "./ShopMode";

export default class Menu extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.categories)
      props.categories = Immutable(props.categories.map((cat) => Immutable(new Category(this.getImmutableAsObj(cat)), { prototype: Category.prototype })));
    if (props.shopModes)
      props.shopModes = Immutable(props.shopModes.map((mode) => Immutable(new ShopMode(this.getImmutableAsObj(mode)), { prototype: ShopMode.prototype })));

    props && Object.assign(this, Immutable(props));
  }

  getName() {
    return this.name;
  }
}
