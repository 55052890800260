import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { pathOr } from "ramda";
import Franchise from "../Logic/Entities/Franchise";
import City from "../Logic/Entities/City";
import functions from "../Shared/functions";
import { dateFormatSyncInStore, serverTimeZone } from "../Shared/Constants";
import i18n from "i18n-js";
import Device from "../Logic/Entities/Device";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getFranchiseRequest: ["franchiseId"],
  getFranchiseSuccess: ["franchiseData", "isForSyncShops", "shopTimeZone"],
  getFranchiseFailure: ["franchiseError", "franchiseErrors"],
  getCitiesRequest: null,
  getCitiesSuccess: ["citiesData"],
  getCitiesFailure: ["error", "errors"],
  setSinceShops: ["shopTimeZone"],

  setSelectedLocaleRequest: ["langCode", "isLanguageSelectedManually"],

  getDevicesRequest: ["tempSelectedShop", "context"],
  getDevicesSuccess: ["devices", "shopId"],
  getDevicesFailure: ["devicesError", "devicesErrors"],
  setSelectedDevicesInfoRequest: ["selectedDevicesInfo", "printer", "shopId"],

  resetFranchiseError: null,
});

export const FranchiseTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  franchiseFetching: false,
  franchiseError: null,
  franchiseErrors: null,
  franchiseData: null,

  citiesFetching: false,
  citiesError: null,
  citiesErrors: null,
  citiesData: null,
  sinceShops: false,

  devicesData: Immutable({}),
  devicesFetching: false,
  devicesError: null,
  devicesErrors: null,
  selectedDevicesInfoData: Immutable({}),

  isLanguageSelectedManually: false,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

/* ------------- Selectors ------------- */
export const FranchiseSelectors = {
  getFranchise: (state) => getImmutableAsEntity(state.franchise.franchiseData ?? null, Franchise),
  // getColors: state => state.franchise.franchiseData,
  getCustomColors: (state) => pathOr(false, ["franchiseData", "colors"], state.franchise),
  getCustomTemplate: (state) => functions?.getTemplate(state.franchise?.franchiseData),
  getCities: (state) => {
    const citiesAsEntity = state.franchise?.citiesData?.map((city) => Immutable(getImmutableAsEntity(city, City), { prototype: City.prototype }));

    let finalCitiesAsEntity = citiesAsEntity ? Immutable.asMutable(citiesAsEntity) : [];
    // const firstCity =  new City({id: '', name: ''});
    // finalCitiesAsEntity.unshift(Immutable(firstCity, {prototype: City.prototype}));

    return Immutable(finalCitiesAsEntity);
  },
  getSinceShops: (state) => state?.franchise?.sinceShops ?? false,
  franchiseHaseFidQoodos: (state) => !!state.franchise.franchiseData?.qoodosId,
  getLocales: (state) => state.franchise.franchiseData?.locales ?? [],
  getSelectedLocale: (state) => state.franchise.selectedLocale ?? state.franchise.franchiseData?.parameter?.locale?.code ?? "fr_FR",
  getDevices: (state, shopId) =>
    state.franchise?.devicesData?.[shopId]
      ?.filter((device) => device.name !== "C&C")
      ?.map?.((device) => Immutable(getImmutableAsEntity(device, Device), { prototype: Device.prototype })),
  getSelectedDevicesInfo: (state, shopId) => {
    return state.franchise?.selectedDevicesInfoData?.[shopId] ?? Immutable({});
  },
};

/* ------------- Reducers ------------- */

export const getFranchiseRequest = (state) => state.merge({ franchiseFetching: true });

export const getFranchiseSuccess = (state, { franchiseData, isForSyncShops, shopTimeZone }) => {
  // const firstShopTimeZone = franchiseData?.shops.length > 0 && !shopTimeZone ?
  //                           franchiseData?.shops.find((shop)=> shop.timeZone)?.timeZone??"Africa/Accra" : "Africa/Accra";

  // const timeZone = shopTimeZone ? shopTimeZone : firstShopTimeZone;
  const sinceShops = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);

  const shops = franchiseData?.shops.map((shop) => {
    const shopModes = shop.shopModes.filter((shopMode) => {
      if (this?.licence === "light") return shopMode.mode?.code === "on-site" && shopMode.enabled && shopMode.visible;
      return shopMode.enabled && shopMode.visible;
    });
    const normalizedShop = functions?.normalizeShop(shop);
    const normalizedShopModes = shopModes?.map((shopMode) => functions.normalizeShopMode(shopMode, shop?.timeZone));
    const isPickupMode = shop?.shopSettings?.pickupMode;
    const shopModesWithfilteredOrderingHours =
      !isPickupMode && normalizedShop?.openingHours?.length
        ? functions.filterOrderingHoursInShopModeByOpeningHours(normalizedShopModes, normalizedShop?.openingHours)
        : normalizedShopModes;
    return { ...normalizedShop, shopModes: shopModesWithfilteredOrderingHours };
  });

  const selectedTemplate = functions?.getTemplate(franchiseData);
  const isTemplateBorne = selectedTemplate === "borne";

  const franchiseDataFinal = { ...franchiseData, shops, isTemplateBorne };
  return state.merge({
    franchiseData: franchiseDataFinal,
    franchiseFetching: false,
    franchiseError: null,
    franchiseErrors: null,
    sinceShops,
  });
};

export const getFranchiseFailure = (state, { franchiseError, franchiseErrors }) =>
  state.merge({
    franchiseData: null, // ca permet de reloader la page et rénitialiser les configs de la marque blanche.
    franchiseFetching: false,
    franchiseError,
    franchiseErrors,
  });

export const setSinceShops = (state, { shopTimeZone }) => {
  // const firstShopTimeZone = state?.franchise?.shops.length > 0 && !shopTimeZone ?
  //                           state?.franchise?.shops.find((shop)=> shop.timeZone)?.timeZone??"Africa/Accra" : "Africa/Accra";

  // const timeZone = shopTimeZone ? shopTimeZone : firstShopTimeZone;
  const sinceShops = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({ sinceShops });
};

export const getCitiesRequest = (state) => state.merge({ citiesFetching: true });

export const getCitiesSuccess = (state, { citiesData }) => {
  return state.merge({
    citiesData,
    citiesFetching: false,
    citiesError: null,
    citiesErrors: null,
  });
};

export const getCitiesFailure = (state, { citiesError, citiesErrors }) =>
  state.merge({
    citiesFetching: false,
    citiesError,
    citiesErrors,
  });

export const setSelectedLocaleRequest = (state, { langCode, isLanguageSelectedManually }) => {
  const locales = state.franchiseData?.locales ?? [];
  if (!locales?.map((locale) => locale.code)?.includes(langCode)) {
    return state;
  }
  if (!isLanguageSelectedManually && state.isLanguageSelectedManually) {
    i18n.locale = (langCode ?? state.selectedLocale)?.replace?.("_", "-");
    // le changement de la langue ne prendre pas effet que après un render, du coup on le force par changement de state (nouvel valeur pourrandomValueToForceUpdate)
    return state.merge({ randomValueToForceUpdate: Math.random(), selectedLocale: langCode, isLanguageSelectedManually });
  }
  const localeCode = langCode?.replace?.("_", "-");
  if (["fr-FR", "en-US", "de-DE", "es-ES"].includes(localeCode)) {
    //méme es on a pas encore sur les traductions fixes du site
    i18n.locale = localeCode;
  }
  return state.merge({ selectedLocale: langCode, isLanguageSelectedManually });
};

export const resetFranchiseError = (state) =>
  state.merge({
    franchiseError: null,
    franchiseErrors: null,
  });

export const getDevicesRequest = (state) => {
  return state.merge({ devicesFetching: true });
};

export const getDevicesSuccess = (state, { devices, shopId }) => {
  const devicesData = state.devicesData?.setIn([shopId], devices);
  return state.merge({
    devicesData,
    devicesFetching: false,
    devicesError: null,
    devicesErrors: null,
  });
};

export const getDevicesFailure = (state, { devicesError, devicesErrors }) =>
  state.merge({
    devicesFetching: false,
    devicesError,
    devicesErrors,
  });

export const setSelectedDevicesInfoRequest = (state, { selectedDevicesInfo, printer, shopId }) => {
  if (!selectedDevicesInfo && !printer && !shopId) {
    return state.merge({
      selectedDevicesInfoData: Immutable({}),
    });
  } else {
    const { productId, productName, serialNumber, vendorId } = printer ?? {};
    const selectedDevicesInfoData = state.selectedDevicesInfoData?.setIn([shopId], {
      borne: selectedDevicesInfo,
      printer: { productId, productName, serialNumber, vendorId },
    });
    return state.merge({
      selectedDevicesInfoData,
    });
  }
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FRANCHISE_REQUEST]: getFranchiseRequest,
  [Types.GET_FRANCHISE_SUCCESS]: getFranchiseSuccess,
  [Types.GET_FRANCHISE_FAILURE]: getFranchiseFailure,

  [Types.GET_CITIES_REQUEST]: getCitiesRequest,
  [Types.GET_CITIES_SUCCESS]: getCitiesSuccess,
  [Types.GET_CITIES_FAILURE]: getCitiesFailure,

  [Types.SET_SINCE_SHOPS]: setSinceShops,

  [Types.SET_SELECTED_LOCALE_REQUEST]: setSelectedLocaleRequest,

  [Types.GET_DEVICES_REQUEST]: getDevicesRequest,
  [Types.GET_DEVICES_SUCCESS]: getDevicesSuccess,
  [Types.GET_DEVICES_FAILURE]: getDevicesFailure,
  [Types.SET_SELECTED_DEVICES_INFO_REQUEST]: setSelectedDevicesInfoRequest,

  [Types.RESET_FRANCHISE_ERROR]: resetFranchiseError,
});
