import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { pathOr } from "ramda";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
  changeStartupState: ["firstTestIncDecr", "data"],
  loadFontRequest: ["os"],
  loadFontSuccess: null,
  setDeviceType: ["deviceType"],
  setStorageConsentApproved: ["isStorageConsentApproved"],

  generateTokenRequest: ["env"],
  generateTokenSuccess: null,
  generateTokenFailure: ["generateTokenError", "generateTokenErrors"],

  getGeneratedTokenRequest: ["env"],
  getGeneratedTokenSuccess: ["generatedToken"],
  getGeneratedTokenFailure: ["getGeneratedTokenError", "getGeneratedTokenErrors"],

  resetCacheRequest: ["resetType"],

  setErrorScreenMessageRequest: ["message"],

  setIsFromWebViewRequest: ["isFromWebView"],
});

export const StartupTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  loadingFont: false,
  isFontLoaded: false,
  firstTestIncDecr: 0,
  deviceType: "desktop", //other possible values : mobile and tablet
  storageConsentApproved: false,
  generatedToken: false,
  generateTokenFetching: false,
  getGeneratedTokenFetching: false,
  errorScreenMessage: null,
  isFromWebView: false,
});

/* ------------- Selectors ------------- */
export const StartupSelectors = {
  // getAllState: state => pathOr({}, ['deviceInfo', 'screen'], state.startup),
  getAllStartupState: (state) => state.startup,
  getFirstTestIncDecr: (state) => pathOr(0, ["firstTestIncDecr"], state.startup),
  getDeviceType: (state) => state.startup?.deviceType,
  isMobileDevice: (state) => ["mobile", "tablet"].includes(state.startup?.deviceType),
  isTabletDevice: (state) => "tablet" === state.startup?.deviceType,
  isPhoneDevice: (state) => "mobile" === state.startup?.deviceType,
  isStorageConsentApproved: (state) => state.startup?.storageConsentApproved,
  getGeneratedToken: (state) => state.startup?.generatedToken?.data,
  isGeneratingOrGetingTokenFetching: (state) => state.startup?.generateTokenFetching || state.startup?.getGeneratedTokenFetching,
  getErrorScreenMessageRequest: (state) => state.startup?.errorScreenMessage,
  isFromWebView: (state) => state.startup?.isFromWebView,
};

/* ------------- Reducers ------------- */

export const changeStartupState = (state, { firstTestIncDecr, data }) => {
  return state.merge({ firstTestIncDecr });
};

/* ------------- Reducers ------------- */
export const loadFont = (state) => state.merge({ loadingFont: true });
// fonts were loaded successfully
export const loadFontSuccess = (state) => state.merge({ isFontLoaded: true, loadingFont: false });
export const setDeviceType = (state, { deviceType }) => state.merge({ deviceType });

export const setStorageConsentApproved = (state, { isStorageConsentApproved }) => state.merge({ storageConsentApproved: !!isStorageConsentApproved });

/*********** generate api token  ****************/
export const generateTokenRequest = (state) => state.merge({ generateTokenFetching: true });

export const generateTokenSuccess = (state, {}) => {
  return state.merge({
    generateTokenError: null,
    generateTokenErrors: null,
    generateTokenFetching: false,
  });
};

export const generateTokenFailure = (state, { generateTokenError, generateTokenErrors }) =>
  state.merge({
    generateTokenError,
    generateTokenErrors,
    generateTokenFetching: false,
  });

export const getGeneratedTokenRequest = (state) => state.merge({ getGeneratedTokenFetching: true, generateTokenFetching: false });

export const getGeneratedTokenSuccess = (state, { generatedToken }) => {
  return state.merge({
    getGeneratedTokenFetching: false,
    generatedToken,
    getGeneratedTokenError: null,
    getGeneratedTokenErrors: null,
  });
};

export const getGeneratedTokenFailure = (state, { getGeneratedTokenError, getGeneratedTokenErrors }) =>
  state.merge({
    getGeneratedTokenFetching: false,
    getGeneratedTokenError,
    getGeneratedTokenErrors,
  });

export const setErrorScreenMessageRequest = (state, { message }) => state.merge({ errorScreenMessage: message });

export const setIsFromWebViewRequest = (state, { isFromWebView }) => state.merge({ isFromWebView });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_STARTUP_STATE]: changeStartupState,
  [Types.LOAD_FONT_REQUEST]: loadFont,
  [Types.LOAD_FONT_SUCCESS]: loadFontSuccess,
  [Types.SET_DEVICE_TYPE]: setDeviceType,

  [Types.SET_STORAGE_CONSENT_APPROVED]: setStorageConsentApproved,

  [Types.GENERATE_TOKEN_REQUEST]: generateTokenRequest,
  [Types.GENERATE_TOKEN_SUCCESS]: generateTokenSuccess,
  [Types.GENERATE_TOKEN_FAILURE]: generateTokenFailure,

  [Types.GET_GENERATED_TOKEN_REQUEST]: getGeneratedTokenRequest,
  [Types.GET_GENERATED_TOKEN_SUCCESS]: getGeneratedTokenSuccess,
  [Types.GET_GENERATED_TOKEN_FAILURE]: getGeneratedTokenFailure,

  [Types.SET_ERROR_SCREEN_MESSAGE_REQUEST]: setErrorScreenMessageRequest,

  [Types.SET_IS_FROM_WEB_VIEW_REQUEST]: setIsFromWebViewRequest,
});
