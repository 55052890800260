import Immutable from "seamless-immutable";
import configEnv from "../../../config.env";
import BaseEntity from "./BaseEntity";
import Shop from "./Shop";
import userMale from "../../Assets/AssetsT1/images/avatars/male.jpg";
import userFemale from "../../Assets/AssetsT1/images/avatars/female.jpg";

export default class User extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.shop) props.shop = Immutable(new Shop(this.getImmutableAsObj(props.shop)), { prototype: Shop.prototype });

    props && Object.assign(this, Immutable(props));
  }

  getName() {
    const firstName = this.firstName ? `${this.firstName?.charAt(0).toUpperCase()}${this.firstName?.slice(1)}` : "";
    return `${firstName} ${this.lastName?.toUpperCase() ?? ""}`;
  }

  getAddress() {
    // return `${this.address?.street} - ${this.address?.postcode}, ${this.address?.city}`;
    return `${this.get("defaultAddress.street")} - ${this.get("defaultAddress.postcode")}, ${this.get("defaultAddress.city")}`;
  }

  getFirstAddress(defaultValue) {
    // return `${this.address?.street} - ${this.address?.postcode}, ${this.address?.city}`;
    if (!defaultValue) return this.get("deliveryAddresses")[0];
    return this.get("deliveryAddresses")[0] ? this.get("deliveryAddresses")[0][defaultValue] : "";
  }

  getBalance() {
    return this.balance ?? 0;
  }

  getCashBack() {
    return (this.cashback ?? 0) / 100; //La valeur est en cent depui api
  }

  addArr(data) {
    return Immutable.updateIn(this, ["arr"], () => this.arr.concat(data)); //this.arr.concat(data);
  }

  // add (x, y) { return x + y }

  updateData(data) {
    return Immutable.updateIn(this, ["obj", "data3", "sousData1"], () => data);
  }

  getImageProfil() {
    if (!this.imageUrl || this.imageUrl.includes("assets/media/users/male.png") || this.imageUrl.includes("assets/media/users/female.png")) {
      return this.gender === "female" ? userFemale : userMale;
    }

    return this.get("imageUrl");
  }

  get = (property, defaultValue = "") => {
    const propertyPath = property?.split(".");
    if (property === "imageUrl") return `${configEnv.BASE_API_URL}${Immutable.getIn(this, propertyPath, defaultValue)}`;
    return Immutable.getIn(this, propertyPath, defaultValue);
    // return this[property]??defaultValue;
  };
}

// utilisation
// const user = new User({id: 1, lastName: "lastName", firstName: "firstName", obj: {data1: 1, data2: 2, data3: {sousData1: 3}}, arr: ["a", "b", "c"]});
// const userUpdated = user.addArr("d");
// const userUpdated2 = user.updateData(55);
// console.log("getName", user.getName(), user.id, user.obj, user.arr, userUpdated, userUpdated2);

/*{
      _id: undefined, // data_type_created ( new Date().toISOString() ) // voir si ajout du code barre
      _rev: undefined,
      _deleted: undefined,
    
      id: undefined,
      code: undefined, //  code de base passée par le serveur ( eventuellement )
      schema: 'v1.0',
      type: 'user',
      deleted: undefined, // default response
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
      originalId: undefined,
    
      active: true, // true / false
      group: 'user',
    
      nom: undefined,
      prenom: undefined,
      email: undefined,
      tel: undefined,
      commentaire: undefined,
      handlerCode: undefined,
      address: Immutable.Map({
        numero: undefined,
        rue: undefined,
        cp: undefined,
        ville: undefined,
      }),
    
      lastConnection: Immutable.Map({
        date: new Date().toISOString(),
        nowLoggedIn: false,
        device: Immutable.Map({
          _id: undefined,
          os: undefined,
          ip: undefined,
          designation: undefined
        }),
      }),
    
      passwd: Immutable.Map({
        codeNumeric: undefined, // généré ou code bar ?
        codebar: undefined,
        dallasCode: undefined,
        pattPassword: undefined, // teleco
      }),
      commPassword: undefined, // téléco,
      dallas: undefined, // téléco,
      siteOrigin: undefined
    }*/
