import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import City from "./City";

export default class Address extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.city) props.city = Immutable(new City(this.getImmutableAsObj(props.city)), { prototype: City.prototype });

    props && Object.assign(this, Immutable(props));
  }

  getAddress() {
    return `${this.name ? `${this.name} : ` : ""}${this.street || ""}${this.street2 ? `, ${this.street2}` : ""}${this.postalCode?.code ? `- ${this.postalCode?.code}` : ""}${this.postalCode?.city?.name ? `- ${this.postalCode.city.name}` : ""}${this.postalCode?.city?.country?.name ? `, ${this.postalCode.city.country.name}` : ""}`;
  }
}
