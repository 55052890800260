import React from "react";
import { Row, Col, Button, Modal, Alert } from "react-bootstrap";
import L from "../../I18n/tr";
import PropTypes from "prop-types";
import Loading from "../Components/Loading";
import { byQoodos } from "../../Shared/Constants";

function ModalShared(props) {
  // function renderSimpleHeader (){
  //   if(!props.selectedProduct) return null;
  //   return props.selectedProduct.title;
  // }

  // function renderCmdHeader (){
  //   if(!props.commandeData) return null;
  //   return <Row>
  //             <Col sm="6" lg="6">{L('YourCmd').toUpperCase()}</Col>
  //             <Col sm="6" lg="6" ><div style={{ textAlign: 'right' }}>{`${L('TotalAmount')} : ${props.commandeData?.totalToPaid??0}`}</div></Col>
  //           </Row>
  // }

  function renderTitleHeader() {
    if (!props.title) return null;
    return props.title;
  }

  function renderHeader() {
    return renderTitleHeader();
    // if(!props.commandeData && !props.selectedProduct) return renderTitleHeader();
    // return props.selectedProduct ? renderSimpleHeader() : renderCmdHeader();
  }

  function sizeMdl() {
    // if(props.selectedProduct) return "md";
    if (props.xlarge) return "xl";
    if (props.large) return "lg";
    if (props.medium) return "md";
    else return "lg";
  }

  function renderByQoodos() {
    if (!props.isByQoodos) return null;
    return <div className="position-absolute bottom-5 end-5 text-end">{byQoodos("var(--bs-primary)")}</div>;
  }

  function renderHeaderComponnent() {
    if (props.hideHeader) return null;

    // bgHeaderColor ==> bg-primary bg-secondary bg-success bg-info bg-warning bg-soft-danger
    if (props.headerContent) {
      return (
        <Modal.Header closeButton={props.closeButton} className={props.bgHeaderColor ? `${props.bgHeaderColor}` : ""}>
          {props.headerContent}
        </Modal.Header>
      );
    }
    return (
      <Modal.Header closeButton={props.closeButton} className={props.bgHeaderColor ? `${props.bgHeaderColor} position-relative` : " position-relative"}>
        {props.btnLeftAction ? props.btnLeftAction() : null}
        <Col>
          {props.logo && (
            <Row style={{ height: 100, width: "100%" }} className="mx-0">
              <div style={{ height: 100, width: "auto", margin: "0 auto" }} className="px-0">
                <img style={{ height: "100%", width: "auto" }} src={props.logo} alt="#"></img>
              </div>
            </Row>
          )}
          <Row>
            <Modal.Title
              style={{ width: "100%" }}
              className={props.bgHeaderColor ? `${props.textHeaderColor} ` : "" + props.classHeaderTitle ? props.classHeaderTitle : ""}
            >
              {renderHeader()}
            </Modal.Title>
          </Row>
          {renderErrors()}
          {rendeAlert()}
          {renderByQoodos()}
        </Col>
      </Modal.Header>
    );
  }

  function renderFooterComponnent() {
    if (props.hideFooter) return null;
    if (props.footerComponnent) return <Modal.Footer style={{ width: "100%", ...props.footerStyle }}>{props.footerComponnent()}</Modal.Footer>;
    if (props.hideFooter || (!props.negatifBtnText && !props.positifBtnText)) return null;
    return (
      <Modal.Footer style={{ ...props.footerStyle }}>
        {props.positifBtnText && (
          <Button
            variant="primary"
            onClick={props.handleValid}
            disabled={props.positifBtnDisabled}
            dangerouslySetInnerHTML={{ __html: props.positifBtnText }}
          ></Button>
        )}
        {props.negatifBtnText && (
          <Button variant="outline-primary" onClick={props.handleClose} disabled={props.negatifBtnDisabled}>
            {props.negatifBtnText}
          </Button>
        )}
      </Modal.Footer>
    );
  }

  function renderErrors() {
    if (!props.errorsMessage) {
      return null;
    }
    return (
      <Row className={"mx-1"} style={{ position: "relative" }}>
        <Alert variant="danger" onClose={() => props.errorsHandleClose()} dismissible style={{ marginBottom: 0 }}>
          {props.errorsMessage}
        </Alert>
      </Row>
    );
  }

  function rendeAlert() {
    if (!props.alertMessage?.msg) {
      return null;
    }
    return (
      <Row className={"mx-1"} style={{ position: "relative" }}>
        <Alert variant={props.alertMessage.variant ?? "info"} style={{ marginBottom: 0 }}>
          {props.alertMessage.msg}
        </Alert>
      </Row>
    );
  }

  return (
    <Modal
      centered
      scrollable={true}
      show={props.show}
      onShow={props.onShow}
      onHide={props.handleClose}
      size={sizeMdl()}
      className={props.classNameData ?? ""}
      backdrop={props.noBackdrop ? "static" : true}
      fullscreen={props.fullscreen ?? false}
      {...(props.backdropClassName ? { backdropClassName: props.backdropClassName } : {})}
    >
      {renderHeaderComponnent()}
      <Modal.Body style={props.bodyStyles} onScroll={props.onScroll ? props.onScroll : null} className={props.bodyClassNames}>
        {props.children}
      </Modal.Body>
      {renderFooterComponnent()}
      {props.isLoading ? <Loading isLoading={true} /> : null}
    </Modal>
  );
}

ModalShared.propTypes = {
  positifBtnDisabled: PropTypes.bool,
  negatifBtnDisabled: PropTypes.bool,
  closeButton: PropTypes.bool,
  errorsMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  errorsHandleClose: PropTypes.func,
};

ModalShared.defaultProps = {
  positifBtnDisabled: false,
  negatifBtnDisabled: false,
  closeButton: false,
  errorsMessage: null,
  errorsHandleClose: () => null,
};

export default ModalShared;
