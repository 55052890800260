import { Row, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

function Loading(props) {
  if (!props.isLoading) {
    return null;
  }
  return (
    <div className={`wait-loading${props.className ? " " + props.className : ""}`}>
      <Row className="spinner-wrapper w-100 h-100">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <Spinner animation="grow" variant="primary" />
      </Row>
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  ContainerStyle: PropTypes.object,
};

Loading.defaultProps = {
  isLoading: false,
  ContainerStyle: {},
};

export default Loading;
