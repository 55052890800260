import * as Localization from "expo-localization";
import i18n from "i18n-js";

// Enable fallbacks if you want `en-US` and `en-GB` to fallback to `en`
i18n.fallbacks = true;
i18n.defaultLocale = "fr";

// English language is the main language for fall back:
i18n.translations = {
  en: require("../Assets/i18n/en.json"),
  fr: require("../Assets/i18n/fr.json"),
  de: require("../Assets/i18n/de.json"),
  es: require("../Assets/i18n/es.json"),
};

i18n.locale = Localization.locale;
