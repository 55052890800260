import { put, call, select, race } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import PaymentActions from "../Redux/PaymentRedux";
import CartActions, { CartSelectors } from "../Redux/CartRedux";
import { UserSelectors } from "../Redux/UserRedux";
import OrderActions, { OrderSelectors } from "../Redux/OrderRedux";
import { ShopSelectors } from "../Redux/ShopRedux";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import Secrets from "../../config.env";
import { CLOSE_PAYMENT_TIME, extendedStatus, globalStatus } from "../Shared/Constants";
import delay from "@redux-saga/delay-p";
import { FranchiseSelectors } from "../Redux/FranchiseRedux";
import { DoErrorPrint, DoPrintCBTicket, DoPrintFoxTicket } from "../TemplateBorne/PrinterService/DoPrint";
import { modal } from "../TemplateBorne/Shared/SagaModalComponent";
import L from "../I18n/tr";
import functions from "../Shared/functions";
import * as Sentry from "@sentry/react";
import { StartupSelectors } from "../Redux/StartupRedux";

export function* createPayment(api, { paymentMethodId, orderId, subPaymentMethod, amountToPay }) {
  const selectedShop = yield select(ShopSelectors.getShop);
  const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
  const posNumber = selectedDevicesInfo?.borne?.retailCenterPosNumber ?? false;
  const apiCall = call(api.createPayment, { paymentMethodId, orderId, subPaymentMethod, amountToPay, posNumber });
  const response = yield call(callApi, apiCall);

  if (!Secrets.IS_DEV_OR_STAGING) {
    const franchise = yield select(FranchiseSelectors.getFranchise);
    const lastSucceededOrderFormData = yield select(OrderSelectors.getLastSucceededOrderFormData);
    if (franchise?.isTemplateBorneFn() && lastSucceededOrderFormData.paymentMethodType === "online") {
      Sentry.withScope(function (scope) {
        const textPayement = response.data?.transactionNumber ? `TransactionNumber ${response.data?.transactionNumber}` : "failed";
        scope.setTransactionName(`Paiement Nepting : ${textPayement}`);
        // const responseToSave = response;
        // if (responseToSave?.data?.merchantId) {
        //   responseToSave.data.merchantId = "********";
        // }
        scope.setExtras({ response: response });
        scope.setTag("orderId", orderId);
        Sentry.captureMessage(`Info creation paiement Nepting pour order : ${orderId}`, "info");
      });
    }
  }

  if (response.ok) {
    yield put(PaymentActions.createPaymentSuccess(response?.data));
  } else {
    yield put(PaymentActions.createPaymentFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* paymentWithStripe(api, { amount, elements, stripe, clientSecret, emailAdress, name }) {
  const result = yield stripe.confirmPayment({
    elements,
    redirect: "if_required",
    confirmParams: {
      // return_url: 'http://localhost:19006/addToCart?path=takeAway',
      receipt_email: emailAdress ?? "",
      payment_method_data: {
        billing_details: {
          name: name ?? "",
          email: emailAdress ?? "",
        },
      },
    },
  });

  if (result?.error) {
    if (result?.error?.type === "card_error" || result?.error?.type === "validation_error") {
      // setMessage(result?.error?.message);
      yield put(PaymentActions.paymentWithStripeFailure(result?.error?.message));
    } else {
      yield put(PaymentActions.paymentWithStripeFailure(result?.error?.message));
    }
  } else {
    const { paymentIntent } = yield stripe.retrievePaymentIntent(clientSecret);
    yield put(PaymentActions.paymentWithStripeSuccess(paymentIntent.status));
  }
}

export function* paymentSucceeded(api) {
  const selectedShop = yield select(ShopSelectors.getShop);
  const orderInitData = yield select(CartSelectors.getOrderInitData);
  const locale = yield select(FranchiseSelectors.getSelectedLocale) ?? "fr_FR";
  const promotionsApplied = yield select(CartSelectors.getPromotionsApplied);
  const receivedOrderData = yield select(OrderSelectors.getReceivedOrderData);
  const franchise = yield select(FranchiseSelectors.getFranchise);
  if (franchise?.isTemplateBorneFn()) {
    const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
    const printer = selectedDevicesInfo?.printer;
    const borne = selectedDevicesInfo?.borne;
    const isFromWebView = yield select(StartupSelectors.isFromWebView);
    yield call(DoPrintFoxTicket, { selectedShop, orderInitData, locale, promotionsApplied, receivedOrderData, franchise, printer, borne, isFromWebView });
    yield put(CartActions.removeCartSuccess(selectedShop));
    // yield put(OrderActions.initOrderState());
  }
  yield put(CartActions.initCartState());
  yield put(OrderActions.initOrderStateExceptSendedOrderAndReceivedOrderData());
}

export function* paymentWithPaygreen(api, { amount, selectedAddress, yourName }) {
  const orderInitData = yield select(CartSelectors.getOrderInitData);
  const connectedUser = yield select(UserSelectors.getConnectedUser);

  const buyer = {
    id: connectedUser ? connectedUser.id : "",
    lastName: connectedUser ? connectedUser.lastName : yourName,
    firstName: connectedUser ? connectedUser.firstName : yourName,
    email: connectedUser ? connectedUser.email : "",
    country: "FR",
    // "companyName": "PayGreen"
  };

  const shippingAddress = {
    lastName: connectedUser ? connectedUser.lastName : yourName,
    firstName: connectedUser ? connectedUser.firstName : yourName,
    address: selectedAddress.getAddress ? selectedAddress?.getAddress() : "",
    zipCode: selectedAddress.getAddress ? selectedAddress.zipCode : "",
    city: selectedAddress.getAddress ? selectedAddress.city : "",
    country: selectedAddress.getAddress ? selectedAddress.country : "",
  };

  const selectedShop = yield select(ShopSelectors.getShop);
  const shopCurrency = selectedShop?.currency?.code;
  const paymentData = {
    orderId: "1234567",
    amount: 100,
    currency: shopCurrency ? shopCurrency : "EUR",
    paymentType: "CB",
    returned_url: "http://localhost:19006/addToCart?path=takeAway", // Adresse sur laquelle il faut rediriger le client après que l'action a été effectuée.
    notified_url: "http://localhost:19006/retour-server", // Adresse sur laquelle PayGreen peut faire des appels pour mettre à jour le statut.
    // "idFingerprint": 0,
    metadata: {
      orderId: "test-1234",
      display: "0",
    },
    ttl: "PT10M",
  };

  const payment = { ...paymentData, buyer, shippingAddress };

  // window.open("https://sandbox.paygreen.fr/payment/execute/trf0587c90fc1513a02d5792c092d825d3", '_self', 'noopener,noreferrer');

  const apiCall = call(api.paymentWithPaygreen, { payment });
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    yield put(PaymentActions.paymentWithPaygreenSuccess(response?.data?.data?.data?.url));

    // window.open(response?.data?.data?.data?.url, '_self', 'noopener,noreferrer');
  } else {
    yield put(PaymentActions.paymentWithPaygreenFailure(response, response));
  }
}

export function* paymentWithNeptingRetail(
  api,
  { merchantid, login, password, amount, posNumber, merchantReference, currencyCode, transactionType, merchantPrivateData }
) {
  const payment = {
    env: Secrets.IS_DEV_OR_STAGING ? "staging" : "prod",
    merchantid,
    login,
    password,
    amount,
    posNumber,
    merchantReference,
    currencyCode,
    transactionType,
    merchantPrivateData,
  };

  const apiCall = call(api.sendNeptingRetail, { payment });
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    yield put(PaymentActions.paymentWithNeptingRetailSuccess(response?.data));
  } else {
    yield put(PaymentActions.paymentWithNeptingRetailFailure(response, response));
  }
}

export function* getResultNeptingRetail(api, { merchantId, transactionNumber }) {
  // avant que le user inscrit ça carte et fait le payement je demande le résultat chaque 2 seconds
  // dés que le client fait le paiement je commence à demander le résultat de nepting comme suite;
  // après 2 secondes
  // après 4 secondes
  // après 6 secondes
  // après 8 secondes
  // après 10 secondes
  // qui donne 30 secondes après c’est time out refus de paiement
  let result;
  let i = 0;
  let isNotEnded = true;
  const receivedOrderData = yield select(OrderSelectors.getReceivedOrderData);
  // let j = 1;
  while (i <= CLOSE_PAYMENT_TIME && result?.data?.transactionStatus !== "ENDED") {
    result = yield call(getResultNeptingApi, api, { merchantId, transactionNumber });
    // console.log("getResultNeptingRetail iiiiii ", i, result?.data?.transactionStatus, new Date());
    if (result?.data?.transactionStatus === "ENDED") {
      isNotEnded = false;
      // ce while a supprimé si on est sure qu'il y aura pas une latence entre payement refu accepté et la réponse retail
      // while (j <= 5 && result?.result === "KO") {
      //   yield delay(j * 2000);
      //   result = yield call(getResultNeptingApi, api, { merchantId, transactionNumber });
      //   // console.log("getResultNeptingRetail jjjjjj ", j, result?.result, new Date());
      //   if (result?.result === "OK") {
      //     yield put(PaymentActions.getResultNeptingRetailSuccess(result?.data));
      //     break;
      //   }
      //   j++;
      // }

      if (result?.result === "OK") {
        // const selectedShop = yield select(ShopSelectors.getShop);
        // const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
        // const printer = selectedDevicesInfo?.printer;

        yield put(PaymentActions.getResultNeptingRetailSuccess(result?.data));

        const apiCall = call(api.sendPaymentResult, { transactionNumber, result: "Success" });
        const res = yield call(callApi, apiCall);

        if (!Secrets.IS_DEV_OR_STAGING) {
          Sentry.withScope(function (scope) {
            const textPayementResultRecieved = res.ok ? `Result recieved` : "Result not recieved";
            scope.setTransactionName(`Paiement Nepting send to api : ${textPayementResultRecieved}`);
            scope.setExtras({
              result: result,
              responseFroSendPaymentResult: res,
            });
            scope.setTag("transactionNumber", transactionNumber);
            Sentry.captureMessage(`Info paiement Nepting Success ==> id commande : ${receivedOrderData?.id}, transactionNumber : ${transactionNumber}`, "info");
          });
        }

        // const confirm = yield call(confirmModal, api, {
        //   title: L("Print"),
        //   text: L("PrintTicketCB"),
        //   positifBtnText: L("yes"),
        //   negatifBtnText: L("no"),
        // });

        // if (confirm) {
        //   yield call(DoPrintCBTicket, { ticket: result?.data?.ticket, printer });
        // }
        break;
      } else if (result?.result === "KO") {
        // yield call(DoPrintCBTicket, { ticket: result?.data?.ticket }); // pour test
        yield put(PaymentActions.getResultNeptingRetailFailure(null, { type: "neptingError", errors: result?.data?.extendedResult }));
        break;
      }
    }
    yield delay(2000);
    i++;
  }

  if (result?.result === "KO") {
    yield put(PaymentActions.getResultNeptingRetailFailure(null, { type: "neptingError", errors: result?.data?.extendedResult }));

    const selectedShop = yield select(ShopSelectors.getShop);
    const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
    const printer = selectedDevicesInfo?.printer;
    const isFromWebView = yield select(StartupSelectors.isFromWebView);
    // const errorsMsg = getErrors(result?.data?.extendedResult);
    if (result?.data?.ticket && result?.data?.ticket !== "") {
      yield call(DoErrorPrint, { errorsMsg: result?.data?.ticket, printer, isFromWebView });
    }

    const apiCall = call(api.sendPaymentResult, { transactionNumber, result: "Refused" });
    const res = yield call(callApi, apiCall);

    if (!Secrets.IS_DEV_OR_STAGING) {
      Sentry.withScope(function (scope) {
        const textPayementResultRecieved = res.ok ? `Result recieved` : "Result not recieved";
        scope.setTransactionName(`Paiement Nepting send to api : ${textPayementResultRecieved}`);
        scope.setExtras({
          result: result,
          responseFroSendPaymentResult: res,
        });
        scope.setTag("transactionNumber", transactionNumber);
        Sentry.captureMessage(`Info paiement Nepting Refused ==> id commande : ${receivedOrderData?.id}, transactionNumber : ${transactionNumber}`, "info");
      });
    }
  } else if (result?.data?.transactionStatus === "Error" || isNotEnded) {
    // le statut Error exist mais n'est pas dans la doc
    const selectedShop = yield select(ShopSelectors.getShop);
    const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
    const printer = selectedDevicesInfo?.printer;
    const isFromWebView = yield select(StartupSelectors.isFromWebView);

    if (result?.data?.ticket && result?.data?.ticket !== "") {
      yield call(DoErrorPrint, { errorsMsg: result?.data?.ticket, printer, isFromWebView });
    }

    const apiCall = call(api.sendPaymentResult, { transactionNumber, result: "Error" });
    const res = yield call(callApi, apiCall);

    if (!Secrets.IS_DEV_OR_STAGING) {
      Sentry.withScope(function (scope) {
        const textPayementResultRecieved = res.ok ? `Result recieved` : "Result not recieved";
        scope.setTransactionName(`Paiement Nepting send to api : ${textPayementResultRecieved}`);
        scope.setExtras({
          result: result,
          responseFroSendPaymentResult: res,
        });
        scope.setTag("transactionNumber", transactionNumber);
        Sentry.captureMessage(`Info paiement Nepting Error ==> id commande : ${receivedOrderData?.id}, transactionNumber : ${transactionNumber}`, "info");
      });
    }
  }
}

function getErrors(extendedResult) {
  const errs = extendedResult.split("|");
  let finalErrs = [];
  errs.forEach((err) => {
    finalErrs.push(L(`nepting.code_extended_${atob(err)}`));
  });

  return finalErrs;
}

export function* getResultNeptingApi(api, { merchantId, transactionNumber }) {
  const payment = {
    env: Secrets.IS_DEV_OR_STAGING ? "staging" : "prod",
    merchantId,
    transactionNumber,
  };

  const apiCall = call(api.getResultNeptingRetail, { ...payment });
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    const transactionNeptingResult =
      response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0]?.["GetTransactionStatusResponse"]?.[0]?.transactionResult?.[0]?.["$"];
    // console.log(
    //   "transactionNeptingResult",
    //   response?.data?.data?.["S:Envelope"],
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"],
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0],
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0]?.["GetTransactionStatusResponse"],
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0]?.["GetTransactionStatusResponse"]?.[0],
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0]?.["GetTransactionStatusResponse"]?.[0]?.transactionResult,
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0]?.["GetTransactionStatusResponse"]?.[0]?.transactionResult?.[0],
    //   response?.data?.data?.["S:Envelope"]?.["S:Body"]?.[0]?.["GetTransactionStatusResponse"]?.[0]?.transactionResult?.[0]?.["$"]
    // );
    // "amount": "100",
    // "applicationName": "FRV621 SUNMI CT",
    // "currencyCode": "978",
    // "entryMode": "9",
    // "extendedResult": "Mw==",
    // "maskedAccountIdentifier": "507100......2285",
    // "merchantPrivateData": "ldp",
    // "merchantReference": "NDU4NzM=",
    // "paymentScheme": "Q0I=",
    // "result": "UmVmdXNlZA==",
    // "systemDateTime": "2024-04-11 10:02:41.435",
    // "ticket": "CiAgQ0FSVEUgQkFOQ0FJUkUKVFJBTlNBQ1RJT04gREUgVEVTVCBDQgpBMDAwMDAwMDQyMTAxMApDQgpMZSAxMS8wNC8yMDI0IGEgMTI6MDA6NDkKVEVTVAo0NCBMQSBNT05UQUdORQpBQkFORE9OIERFQklUCjE5OTkyNzIgCjI2NTUwCjAwMDAwMDAxOTk5MjcyIAojIyMjIyMjIyMjIyMyMjg1CjAwMSAwMDEgMDAwMDkwIEMgQCAKTm8gQVVUTyA6Ck1PTlRBTlQgOgogICAgICAgICAxLDAwIEVVUgpUSUNLRVQgQ0xJRU5UCkEgQ09OU0VSVkVSCkNFTlRSRSBERSBURVNUIENCCg==",
    // "transactionStatus": "ENDED"

    if (
      transactionNeptingResult &&
      transactionNeptingResult?.transactionStatus === "ENDED" &&
      transactionNeptingResult?.result &&
      transactionNeptingResult?.extendedResult &&
      (atob(transactionNeptingResult?.result) === globalStatus.accepted ||
        atob(transactionNeptingResult?.extendedResult) === extendedStatus.Sys_Accepted ||
        atob(transactionNeptingResult?.extendedResult) === extendedStatus.AcceptedOffline ||
        atob(transactionNeptingResult?.extendedResult) === extendedStatus.AcceptedOnline)
    ) {
      return { result: "OK", data: transactionNeptingResult };
      // yield put(PaymentActions.getResultNeptingRetailSuccess(transactionNeptingResult));
    } else {
      // yield put(PaymentActions.getResultNeptingRetailFailure(null, { type: "neptingError", errors: transactionNeptingResult?.extendedResult }));
      return { result: "KO", data: transactionNeptingResult };
    }
  } else {
    // yield put(PaymentActions.getResultNeptingRetailFailure(null, { type: "neptingError", errors: transactionNeptingResult?.extendedResult }));
    return { result: "KO", data: { type: "neptingError", errors: "" } };
  }
}

export const confirmModal = function* (api, initProps) {
  yield modal.show(initProps);

  const [submit] = yield race([modal.takeSubmit(), modal.takeHide()]);

  yield modal.hide();

  return !!submit;
};

export function* retrievePaymentPayPlugResult(api, { payplugPaymentId }) {
  // const selectedShop = yield select(ShopSelectors.getShop);
  const apiCall = call(api.getPayementPayPlugResult, { payplugPaymentId });
  let result;
  let i = 0;
  let response;
  while ((!result || functions.paymentIsPending(result)) && i <= 5) {
    response = yield call(callApi, apiCall);
    result = response?.data?.state;
    if (response.ok) {
      yield put(PaymentActions.retrievePaymentPayPlugResultSuccess(response?.data));
    } else {
      yield put(PaymentActions.retrievePaymentPayPlugResultFailure(getApiResponseError(response), getApiResponseErrors(response)));
    }
    yield delay(2000);
    i++;
  }

  if (i > 5) {
    yield put(PaymentActions.retrievePaymentPayPlugResultFailure(response?.data, response?.data));
  }
}
