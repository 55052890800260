import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";

export default class Device extends BaseEntity {
  constructor(props) {
    super(props);
    // if (props.shops) props.shops = Immutable(props.shops.map((shop) => Immutable(new Shop(this.getImmutableAsObj(shop)), { prototype: Shop.prototype })));
    // if (props.socials)
    //   props.socials = Immutable(props.socials.map((social) => Immutable(new Social(this.getImmutableAsObj(social)), { prototype: Social.prototype })));
    // if (props.colors)
    //   props.colors = Immutable(props.colors.map((color) => Immutable(new Color(this.getImmutableAsObj(color)), { prototype: Color.prototype })));

    props && Object.assign(this, Immutable(props));
  }
}
