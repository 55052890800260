import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { pathOr } from "ramda";
import Order from "../Logic/Entities/Order";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  addOrderRequest: ["orderData"],
  addOrderSuccess: ["orderData", "lastSucceededOrderData"],
  addOrderFailure: ["orderError", "orderErrors"],

  getOrdersRequest: ["userId", "params"],
  getOrdersSuccess: ["userId", "franchiseId", "userOrdersData"],
  getOrdersFailure: ["error", "errors"],

  getOrdersByUserAndSlotRequest: ["slot"],
  getOrdersByUserAndSlotSuccess: ["ordersBySlotData"],
  getOrdersByUserAndSlotFailure: ["ordersBySlotError", "ordersBySlotErrors"],

  resetAllOrderErrors: null,
  setCurrentOrderData: ["orderData"],
  setCurrentReOrderingOrder: ["orderData"],

  orderCancelRequest: ["orderId"],
  orderCancelSuccess: ["orderData"],
  orderCancelFailure: ["orderCancelError", "orderCancelErrors"],

  resetOrderCancelErrors: null,

  initOrderState: null,
  initOrderStateExceptSendedOrderAndReceivedOrderData: null,

  setReOrderErrorsModalData: ["reOrderErrorsModalData"],

  setNumTable: ["numTable"],
});

export const OrderTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  ordersFetching: false,
  orders: Immutable({}),
  ordersPaginationData: Immutable({}),
  ordersError: null,
  ordersErrors: null,
  orderFetching: false,
  createOrderSucceeded: false,
  orderError: null,
  orderErrors: null,
  ordersData: null,
  currentOrderData: null,
  currentReOrderingOrder: null,
  ordersByUserAndSlotFetching: false,
  ordersBySlotData: null,
  ordersBySlotError: false,
  ordersBySlotErrors: false,
  orderCanceling: false,
  receivedOrderData: null,
  lastSucceededOrderData: null,
  reOrderErrorsModalData: false,
  numTable: null,
  orderCancelError: null,
  orderCancelErrors: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

/* ------------- Selectors ------------- */
export const OrderSelectors = {
  // getOrders: state => state.order?.ordersData?.map((order)=> getImmutableAsEntity(order, Order)),
  getOrders: (state) => {
    const currentUserId = state.user.currentUser?.id;
    const currentFranchiseId = state.franchise.franchiseData?.id;
    if (!currentUserId || !currentFranchiseId) {
      return [];
    }
    return state.order?.orders?.[currentFranchiseId]?.[currentUserId]?.map((order) => getImmutableAsEntity(order, Order));
  },
  getCurrentOrderData: (state) => state.order?.currentOrderData ?? null,
  getCurrentReOrderingOrderData: (state) => state.order?.currentReOrderingOrder ?? null,
  getOrdersPagination: (state) => {
    const currentUserId = state.user.currentUser?.id;
    const currentFranchiseId = state.franchise.franchiseData?.id;
    if (!currentUserId || !currentFranchiseId) {
      return {};
    }
    return state.order?.ordersPaginationData?.getIn?.([currentFranchiseId, currentUserId]);
  },
  getReceivedOrderData: (state) => state.order?.receivedOrderData ?? null,
  getLastSucceededOrderData: (state) => state.order?.lastSucceededOrderData ?? null,
  getLastSucceededOrderFormData: (state) => state.order?.lastSucceededOrderData?.formData ?? null,
  getReOrderErrorsModalData: (state) => state.order?.reOrderErrorsModalData ?? false,
  getNumTable: (state) => state.order?.numTable,
};

/* ------------- Reducers ------------- */

export const addOrderRequest = (state) => state.merge({ orderFetching: true, createOrderSucceeded: false, currentOrderData: null });

export const addOrderSuccess = (state, { orderData, lastSucceededOrderData }) => {
  const allOrders = state.ordersData ?? Immutable({});

  return state.merge({
    ...(!!orderData ? { ordersData: allOrders.set(orderData?.id, orderData) } : {}),
    ...(!!orderData ? { currentOrderData: orderData } : {}),
    ...(!!orderData ? { receivedOrderData: orderData } : {}),
    ...(!!lastSucceededOrderData ? { lastSucceededOrderData } : {}),
    orderFetching: false,
    createOrderSucceeded: true,
    orderError: null,
    orderErrors: null,
  });
};

export const addOrderFailure = (state, { orderError, orderErrors }) =>
  state.merge({
    orderFetching: false,
    createOrderSucceeded: false,
    orderError,
    orderErrors,
  });

export const getOrdersRequest = (state) => state.merge({ ordersFetching: true });

export const getOrdersSuccess = (state, { userId, franchiseId, userOrdersData }) => {
  const ordersPaginationDataByFranshiseAndUser = userOrdersData["hydra:view"]
    ? {
        first: userOrdersData["hydra:view"]["hydra:first"],
        last: userOrdersData["hydra:view"]["hydra:last"],
        next: userOrdersData["hydra:view"]["hydra:next"],
        current: userOrdersData["hydra:view"]["@id"],
        totalItems: userOrdersData["hydra:totalItems"],
      }
    : {
        first: 0,
        last: 0,
        next: 0,
        current: 0,
        totalItems: 0,
      };
  const ordersPaginationData = state.ordersPaginationData.setIn([franchiseId, userId], ordersPaginationDataByFranshiseAndUser);
  const formatedOrdersByFranshiseAndUser = [...(state.orders?.getIn([franchiseId, userId]) ?? []), ...userOrdersData["hydra:member"]];
  const allUserOrdersData = state.orders?.setIn([franchiseId, userId], formatedOrdersByFranshiseAndUser);

  return state.merge({
    orders: allUserOrdersData,
    ordersPaginationData,
    ordersTotalPages: userOrdersData.totalPages ?? 1, // a revoir le 1 est temporaire
    ordersFetching: false,
    ordersError: null,
    ordersErrors: null,
  });
};

export const getOrdersFailure = (state, { ordersError, ordersErrors }) =>
  state.merge({
    ordersFetching: false,
    ordersError,
    ordersErrors,
  });

export const getOrdersByUserAndSlotRequest = (state) => state.merge({ ordersByUserAndSlotFetching: true });

export const getOrdersByUserAndSlotSuccess = (state, { ordersBySlotData }) => {
  return state.merge({
    ordersBySlotData,
    ordersByUserAndSlotFetching: false,
    ordersBySlotError: null,
    ordersBySlotErrors: null,
  });
};

export const getOrdersByUserAndSlotFailure = (state, { ordersBySlotError, ordersBySlotErrors }) =>
  state.merge({
    ordersByUserAndSlotFetching: false,
    ordersBySlotError,
    ordersBySlotErrors,
  });

export const resetAllOrderErrors = (state) => state.merge({ orderError: null, orderErrors: null });

export const setCurrentReOrderingOrder = (state, { orderData }) => state.merge({ currentReOrderingOrder: orderData });

export const setCurrentOrderData = (state, { orderData }) => state.merge({ currentOrderData: orderData });

export const orderCancelRequest = (state) => state.merge({ orderCanceling: true });
export const orderCancelSuccess = (state, { orderData }) => {
  const allOrders = state.ordersData ?? Immutable({});
  return state.merge({
    ordersData: allOrders.set(orderData?.id, orderData),
    orderCanceling: false,
    orderCancelError: null,
    orderCancelErrors: null,
  });
};
export const orderCancelFailure = (state, { orderCancelError, orderCancelErrors }) =>
  state.merge({
    orderCanceling: false,
    orderCancelError,
    orderCancelErrors,
  });

export const resetOrderCancelErrors = (state) => state.merge({ orderCancelError: null, orderCancelErrors: null });

export const initOrderState = (state) => INITIAL_STATE;

export const initOrderStateExceptSendedOrderAndReceivedOrderData = (state) => {
  return INITIAL_STATE.merge({
    receivedOrderData: state?.receivedOrderData,
    lastSucceededOrderData: state?.lastSucceededOrderData,
  });
};

export const setReOrderErrorsModalData = (state, { reOrderErrorsModalData }) => state.merge({ reOrderErrorsModalData });

export const setNumTable = (state, { numTable }) => state.merge({ numTable });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_ORDER_REQUEST]: addOrderRequest,
  [Types.ADD_ORDER_SUCCESS]: addOrderSuccess,
  [Types.ADD_ORDER_FAILURE]: addOrderFailure,

  [Types.GET_ORDERS_REQUEST]: getOrdersRequest,
  [Types.GET_ORDERS_SUCCESS]: getOrdersSuccess,
  [Types.GET_ORDERS_FAILURE]: getOrdersFailure,

  [Types.GET_ORDERS_BY_USER_AND_SLOT_REQUEST]: getOrdersByUserAndSlotRequest,
  [Types.GET_ORDERS_BY_USER_AND_SLOT_SUCCESS]: getOrdersByUserAndSlotSuccess,
  [Types.GET_ORDERS_BY_USER_AND_SLOT_FAILURE]: getOrdersByUserAndSlotFailure,

  [Types.RESET_ALL_ORDER_ERRORS]: resetAllOrderErrors,

  [Types.SET_CURRENT_RE_ORDERING_ORDER]: setCurrentReOrderingOrder,
  [Types.SET_CURRENT_ORDER_DATA]: setCurrentOrderData,

  [Types.ORDER_CANCEL_REQUEST]: orderCancelRequest,
  [Types.ORDER_CANCEL_SUCCESS]: orderCancelSuccess,
  [Types.ORDER_CANCEL_FAILURE]: orderCancelFailure,

  [Types.RESET_ORDER_CANCEL_ERRORS]: resetOrderCancelErrors,

  [Types.INIT_ORDER_STATE]: initOrderState,
  [Types.INIT_ORDER_STATE_EXCEPT_SENDED_ORDER_AND_RECEIVED_ORDER_DATA]: initOrderStateExceptSendedOrderAndReceivedOrderData,

  [Types.SET_RE_ORDER_ERRORS_MODAL_DATA]: setReOrderErrorsModalData,

  [Types.SET_NUM_TABLE]: setNumTable,
});
