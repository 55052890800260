import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import configureStore from "./CreateStore";
import rootSaga from "../Sagas/";
import ReduxPersist from "../Config/ReduxPersist";
import { reducer as modalReducer } from "redux-saga-modal";
import { INITIAL_STATE } from "./FranchiseRedux";

/* ------------- Assemble The Reducers ------------- */
export const combinedReducer = combineReducers({
  startup: require("./StartupRedux").reducer,
  login: require("./LoginRedux").reducer,
  user: require("./UserRedux").reducer,
  franchise: require("./FranchiseRedux").reducer,
  shop: require("./ShopRedux").reducer,
  cart: require("./CartRedux").reducer,
  menu: require("./MenuRedux").reducer,
  category: require("./CategoryRedux").reducer,
  product: require("./ProductRedux").reducer,
  option: require("./OptionRedux").reducer,
  order: require("./OrderRedux").reducer,
  payment: require("./PaymentRedux").reducer,
  synchronisation: require("./SynchronisationRedux").reducer,
  modals: modalReducer,
});

// for reset state
const reducers = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    // when logout init state to startup; startup is to config app device in a start of application
    const { startup, franchise, product, option, category, menu, payment, order, synchronisation } = state;
    return combinedReducer({ startup, franchise, product, option, category, menu, payment, order, synchronisation }, action);
  }
  
  if (action.type === "RESET_CACHE_REQUEST") {
    const newState = {};
    if (action?.resetType === 'data') {
      const { franchise } = state;
      const newFranchise = franchise?.merge?.({...INITIAL_STATE, selectedDevicesInfoData: franchise.selectedDevicesInfoData})
      newState.franchise = newFranchise;
    }
    return combinedReducer(newState, action);
  }
  return combinedReducer(state, action);
};

export default () => {
  let finalReducers = reducers;
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers);
  }

  let { store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga);

  // related to hot reloading. Will reload all reducers and sagas.
  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require("./").reducers;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require("../Sagas").default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return store;
};
