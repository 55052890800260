import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import Option from "./Option";
import OptionVariant from "./OptionVariant";
import Product from "./Product";
import Shop from "./Shop";

export default class ProductCart extends BaseEntity {
  // { selectedProduct, qty, finalPrice, selectedOptions, instructionSpecial }
  constructor(props) {
    super(props);
    // if(props.shops) props.shops = Immutable(props.shops.map((shop)=> Immutable(new Shop(this.getImmutableAsObj(shop)), {prototype: Shop.prototype})));
    if (props.selectedProduct)
      props.selectedProduct = Immutable(Immutable(new Product(this.getImmutableAsObj(props.selectedProduct)), { prototype: Product.prototype }));
    // if(props.selectedOptions) props.selectedOptions = Immutable(props.selectedOptions?.map((option)=> Immutable(new Option(this.getImmutableAsObj(option)), {prototype: Option.prototype})));

    props && Object.assign(this, Immutable(props));
  }

  getOptionsVariantsNamePrice(langCode = "fr_FR", isSideComponent = false) {
    if (!this.selectedOptions || this.selectedOptions === {}) return [];

    const optionsNameAsArray = [];
    Object.entries(this.selectedOptions).forEach(([index, option]) => {
      if (option.option?.sideComponent !== isSideComponent) {
        return; //Equivalent de continue pour forEach
      }
      const optionsVariantNameAsArray = [];
      Object.values(option.optionVariants).forEach((optionVariant) => {
        const optionVariantEnt = Immutable(new OptionVariant(this.getImmutableAsObj(optionVariant)), { prototype: OptionVariant.prototype });
        optionsVariantNameAsArray.push({ name: optionVariantEnt.getName?.(langCode), price: optionVariantEnt.price, quantity: optionVariantEnt.quantity });
      });
      // const optionEnt = Immutable(new Option(this.getImmutableAsObj(option)), {prototype: Option.prototype});
      optionsNameAsArray.push({ optionId: option?.option?.id, optionName: option?.option?.name, optionVariants: optionsVariantNameAsArray });
    });
    return optionsNameAsArray;
  }
}
