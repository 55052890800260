import { put, call, select } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import CategoryActions, { CategoriesSelectors } from "../Redux/CategoryRedux";
import MenuActions, { MenuSelectors } from "../Redux/MenuRedux";
import { synchronisation } from "./SynchronisationSagas";
import { ShopSelectors } from "../Redux/ShopRedux";
import functions from "../Shared/functions";
import { serverTimeZone } from "../Shared/Constants";

export function* getCategories(api, { menuId, status, page }) {
  const apiCall = call(api.getCategories, { menuId, status, page });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    const shop = yield select(ShopSelectors.getShop);
    const serverTimeZoneVal = serverTimeZone;
    yield put(CategoryActions.getCategoriesSuccess(response.data, shop, false));

    yield call(getSynchCategories, api, { menuId, serverTimeZoneVal });
  } else {
    yield put(CategoryActions.getCategoriesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

// sync Categories
export function* getSynchCategories(api, { menuId, serverTimeZoneVal }) {
  const menu = yield select(MenuSelectors.getMenu);
  const endPointApi = "getCategoriesSync";
  if (!menuId && !menu?.id) return;

  const params = { menuId: menu ? menu.id : menuId };

  const getSinceSelectors = CategoriesSelectors.getSinceCategories;

  yield call(synchronisation, api, {
    endPointApi,
    params,
    getSinceSelectors,
    callbackFunc: function* (resultSync) {
      const categoriesPagination = yield select(CategoriesSelectors.getCategoriesPagination);

      if (resultSync.length > 0 && !categoriesPagination?.next) {
        const finalCategories = resultSync.map((category) => {
          if (!category.id && !!category["@id"]) {
            category.id = parseInt(category["@id"].replace("/api/categories/", ""));
          }
          return category;
        });
        const categories = yield select(CategoriesSelectors.getCategories);
        const shop = yield select(ShopSelectors.getShop);
        const finalArraySync = functions.getFinalArraySync(categories, finalCategories);
        yield put(CategoryActions.getCategoriesSuccess(finalArraySync, shop, true));
      } else if (!categoriesPagination?.next) {
        yield put(CategoryActions.setSinceCategories(serverTimeZoneVal));
      }
    },
  });
}
