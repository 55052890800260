import Immutable from "seamless-immutable";

import BaseEntity from "./BaseEntity";
import FacebookSvg from "../../Shared/Icons/FacebookSvg";
import InstagramSvg from "../../Shared/Icons/InstagramSvg";
import LinkedinSvg from "../../Shared/Icons/LinkedinSvg";
import TiktokSvg from "../../Shared/Icons/TiktokSvg";
import TwitterSvg from "../../Shared/Icons/TwitterSvg";
import GoogleSvg from "../../Shared/Icons/GoogleSvg";

export default class Social extends BaseEntity {
  constructor(props) {
    super(props);
    props && Object.assign(this, Immutable(props));
  }

  getName() {
    return this.socialName?.name;
  }

  getLink() {
    return this.link;
  }

  getSocialSVG(template_name_code = null) {
    if (this.getName().includes("facebook")) return <FacebookSvg template_name_code={template_name_code} />;
    else if (this.getName().includes("instagram")) return <InstagramSvg template_name_code={template_name_code} />;
    else if (this.getName().includes("linkedin")) return <LinkedinSvg template_name_code={template_name_code} />;
    else if (this.getName().includes("tiktok")) return <TiktokSvg template_name_code={template_name_code} />;
    else if (this.getName().includes("twitter")) return <TwitterSvg template_name_code={template_name_code} />;
    else if (this.getName().includes("google")) return <GoogleSvg template_name_code={template_name_code} />;
  }
}
