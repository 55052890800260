import { put, call, select } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import FranchiseActions, { FranchiseSelectors } from "../Redux/FranchiseRedux";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import ShopActions, { ShopSelectors } from "../Redux/ShopRedux";
import { synchronisation } from "./SynchronisationSagas";
import functions from "../Shared/functions";
import { KIOSK_SOURCE } from "../Shared/Constants";

export function* getFranchise(api, { franchiseId }) {
  if (!franchiseId) {
    yield put(FranchiseActions.getFranchiseFailure("mandatoryParamsError", null));
    return false;
  }
  const apiCall = call(api.getFranchise, franchiseId);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    const selectedTemplate = functions?.getTemplate(response.data);
    const isBorne = selectedTemplate === "borne";
    if (isBorne) {
      yield call(api.setApiSource, KIOSK_SOURCE);
    }
    yield call(api.setFranchiseToken, response.data);
    yield put(FranchiseActions.getFranchiseSuccess(response.data));

    yield call(getSynchShops, api, {});
  } else {
    yield put(FranchiseActions.getFranchiseFailure(getApiResponseError(response) ?? response.data, ["unknownErrorPTAL"]));
  }
}

export function* getCities(api) {
  const apiCall = call(api.getCities);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(FranchiseActions.getCitiesSuccess(response.data));
  } else {
    yield put(FranchiseActions.getCitiesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

// closing days
export function* getSynchShops(api, {}) {
  const franchise = yield select(FranchiseSelectors.getFranchise);

  const endPointApi = "getShopsSync";
  if (!franchise?.id) return;
  // getShopsSync = ({franchiseId, since, page, enabled})
  const params = { franchiseId: franchise.id };
  const getSinceSelectors = FranchiseSelectors.getSinceShops;

  yield call(synchronisation, api, {
    endPointApi,
    params,
    getSinceSelectors,
    callbackFunc: function* (resultSync) {
      if (resultSync.length > 0) {
        const franchiseFresh = yield select(FranchiseSelectors.getFranchise);
        const shop = yield select(ShopSelectors.getShop);

        let selectedShop = null;

        const finalShops = resultSync?.map((shopRes) => {
          const originalShop = franchiseFresh.shops?.find((oShop) => oShop.id === shopRes.id);
          if (originalShop.shopModes) shopRes.shopModes = originalShop.shopModes;
          if (originalShop.closingDays) shopRes.closingDays = originalShop.closingDays;
          if (originalShop.openingHours) shopRes.openingHours = originalShop.openingHours;
          if (originalShop.partners) shopRes.partners = originalShop.partners;
          if (originalShop.franchise) shopRes.franchise = originalShop.franchise;

          if (shop && shop?.id === shopRes?.id) {
            const originalShopModes = originalShop.shopModes.asMutable?.({ deep: true }) ?? originalShop.shopModes;
            if (shop.shopModes) shopRes.shopModes = originalShopModes;
            if (shop.closingDays) shopRes.closingDays = shop.closingDays;
            if (shop.openingHours) shopRes.openingHours = shop.openingHours;
            if (shop.partners) shopRes.partners = shop.partners;
            if (shop.franchise) shopRes.franchise = shop.franchise;
            selectedShop = shopRes;
          }
          return shopRes;
        });

        if (selectedShop) {
          yield put(ShopActions.getShopSuccess(selectedShop, true, false, false, false));
        }

        const finalFranchiseFresh = franchiseFresh?.isImmutable ? franchiseFresh.asMutable({ deep: true }) : franchiseFresh;
        const finalArraySync = functions.getFinalArraySync(finalFranchiseFresh.shops, finalShops);
        const formatedFranchise = { ...finalFranchiseFresh, shops: finalArraySync };

        yield put(FranchiseActions.getFranchiseSuccess(formatedFranchise));
      } else {
        yield put(FranchiseActions.setSinceShops());
      }
    },
  });
}

export function* getDevices(api, { tempSelectedShop, context }) {
  if (tempSelectedShop && tempSelectedShop.token) {
    yield call(api.setShopToken, tempSelectedShop);
  }
  const apiCall = call(api.getDevices, context);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    // const franchise = yield select(FranchiseSelectors.getFranchise);
    yield put(FranchiseActions.getDevicesSuccess(response.data, tempSelectedShop?.id));
  } else {
    yield put(FranchiseActions.getDevicesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* setSelectedDevicesInfo(api, { selectedDevicesInfo }) {
  if (selectedDevicesInfo?.token) {
    yield call(api.setDeviceToken, selectedDevicesInfo);
  }
}
