import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import User from "../Logic/Entities/User";
import Address from "../Logic/Entities/Address";
import moment from "moment";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getUserRequest: ["userId", "context"],
  getUserSuccess: ["userData"],
  getUserFailure: ["userError", "userErrors"],

  getAddressesRequest: ["userId"],
  getAddressesSuccess: ["userId", "addresses"],
  getAddressesFailure: ["addressesError", "addressesErrors"],
  updatePasswordRequest: ["params"],
  updateAddressesRequest: ["address"],
  updateAddressesSuccess: ["address"],
  updateAddressesFailure: ["addressesError", "addressesErrors"],
  createAddressesRequest: ["address"],
  createAddressesSuccess: ["address"],
  createAddressesFailure: ["addressesError", "addressesErrors"],
  deleteAddressesRequest: ["idAddress"],
  deleteAddressesSuccess: ["idAddress"],
  deleteAddressesFailure: ["addressesError", "addressesErrors"],
  setDefaultAddressesRequest: ["idAddress", "isCheck"],
  setDefaultAddressesSuccess: ["idAddress", "isCheck"],
  setDefaultAddressesFailure: ["addressesError", "addressesErrors"],

  updatePasswordSuccess: null,
  updatePasswordFailure: ["updatePasswordError", "updatePasswordErrors"],

  setUserPositionRequest: null,
  setUserPositionSuccess: ["userPosition"],
  setUserPositionFailure: ["userPositionError", "userPositionErrors"],

  contactUsRequest: ["contactUs"],
  contactUsSuccess: null,
  contactUsFailure: ["contactUsError", "contactUsErrors"],
  resetAllContactUsError: null,

  qoodosSubscriptionRequest: null,
  qoodosSubscriptionSuccess: null,
  qoodosSubscriptionFailure: ["qoodosSubscriptionError", "qoodosSubscriptionErrors"],

  qoodosUnSubscriptionRequest: null,
  qoodosUnSubscriptionSuccess: null,
  qoodosUnSubscriptionFailure: ["qoodosUnSubscriptionError", "qoodosUnSubscriptionErrors"],
  resetFidErrors: null,

  searchCustomerRequest: null,
  searchCustomerSuccess: ["searchCustomerDataResult", "processInitiatedSearch"],
  searchCustomerFailure: ["searchCustomerError", "searchCustomerErrors"],

  shareUserDataToFranchiseRequest: ["customerId"],
  shareUserDataToFranchiseSuccess: null,
  shareUserDataToFranchiseFailure: ["shareUserDataToFranchiseError", "shareUserDataToFranchiseErrors"],

  resetSearchAndShareUserDataErrors: null,

  resetAddressesErrors: null,

  resetUpdatePasswordErrors: null,

  setDefaultLanguageRequest: ["language"],
  setDefaultLanguageSuccess: ["language"],
  setDefaultLanguageFailure: ["setDefaultLanguageError", "setDefaultLanguageErrors"],
});

export const UserTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  userFetching: false,
  userError: null,
  userErrors: null,
  currentUser: null,
  ordersFetching: false,
  ordersError: null,
  ordersErrors: null,
  orders: null,
  currentOrdersFiltersSlug: null,
  ordersTotalPages: 0,
  addressesFetching: false,
  addressesError: null,
  addressesErrors: null,
  updatePasswordFetching: false,
  updatePasswordError: null,
  updatePasswordErrors: null,
  userPosition: null,
  userPositionError: false,
  userPositionErrors: false,
  contactUsFetching: null,
  contactSucceeded: false,
  contactUsError: null,
  contactUsErrors: null,
  qoodosSubscriptionFetching: false,
  qoodosSubscriptionSucceeded: null,
  qoodosUnSubscriptionFetching: false,
  qoodosUnSubscriptionSucceeded: null,

  searchCustomerFetching: false,
  searchCustomerError: null,
  searchCustomerErrors: null,
  searchCustomerData: Immutable({}),

  shareUserDataToFranchiseFetching: false,
  shareUserDataToFranchiseSucceeded: null,
  shareUserDataToFranchiseError: null,
  shareUserDataToFranchiseErrors: null,

  setDefaultLanguageFetching: false,
  setDefaultLanguageError: null,
  setDefaultLanguageErrors: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

function getImmutableAsObj(immutable) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return entityObj;
}

function checkUserHaseFidQoodos(state) {
  const isUserHaseFidQoodos = state.user.currentUser?.customerFranchises?.find(
    (franchise) => franchise?.franchise?.id === state.franchise?.franchiseData?.id && franchise?.qoodosId,
  );
  return !!isUserHaseFidQoodos;
}

/* ------------- Selectors ------------- */
export const UserSelectors = {
  getConnectedUser: (state) => getImmutableAsEntity(state.user.currentUser ?? null, User),
  getAddresses: (state) => {
    const adresses =
      state.user?.addresses?.length &&
      Immutable(
        state.user?.addresses?.map((adress) => {
          const adressAsEntity = getImmutableAsEntity(adress, Address);
          return Immutable(adressAsEntity, { prototype: Address.prototype });
        }),
      );

    if (!adresses) return [];

    const sortedAdresses = Immutable.asMutable(adresses).sort((address1, address2) => {
      return address1.isDefault === address2.isDefault ? 0 : address1.isDefault ? -1 : 1;
    });

    return Immutable(sortedAdresses);
  },
  getUserPosition: (state) => getImmutableAsObj(state.user?.userPosition),
  userHaseFidQoodos: (state) => checkUserHaseFidQoodos(state),
  getRewardsQoodos: (state) => state.user.currentUser?.qoodosRewards,
  getAvailableRewardsQoodos: (state) =>
    state.user.currentUser?.qoodosRewards?.filter(
      (reward) =>
        reward.available &&
        (reward.value || reward.discount_type === "product_sku") &&
        (!reward.expiration_date || moment(reward.expiration_date, "YYYY-MM-DD").isAfter(new Date()))
    ),
};

/* ------------- Reducers ------------- */

export const getUserRequest = (state) => state.merge({ userFetching: true });

export const getUserSuccess = (state, { userData }) => {
  const adaptedQoodosRewards = userData?.qoodosRewards?.map((reward) => {
    if (reward.discount_type === "product_sku") {
      const productsOffredIds = (reward.external_identifier?.split(",") ?? [])?.map((productIdStr) => parseInt(productIdStr));
      return { ...reward, external_identifier: productsOffredIds };
    }
    return reward;
  });
  userData.qoodosRewards = adaptedQoodosRewards;
  return state.merge({
    currentUser: userData,
    userFetching: false,
    userError: null,
    userErrors: null,
  });
};

export const getUserFailure = (state, { userError, userErrors }) =>
  state.merge({
    userFetching: false,
    userError,
    userErrors,
  });

export const getAddressesRequest = (state) => state.merge({ addressesFetching: true });

// const getPageFromUrl = (url)=>{
//   url
// }

export const getAddressesSuccess = (state, { userId, addresses }) => {
  return state.merge({
    addresses: addresses["hydra:member"],
    addressesFetching: false,
    addressesError: null,
    addressesErrors: null,
  });
};

export const getAddressesFailure = (state, { addressesError, addressesErrors }) =>
  state.merge({
    addressesFetching: false,
    addressesError,
    addressesErrors,
  });

export const createAddressesRequest = (state) => state.merge({ addressesCUFetching: true });

export const createAddressesSuccess = (state, { address }) => {
  const addressesObj = getImmutableAsObj(state.addresses);
  const adressObj = getImmutableAsObj(address);
  addressesObj.push(adressObj);
  const addressImm = Immutable(addressesObj);

  return state.merge({
    addresses: addressImm,
    addressesCUFetching: false,
    addressesError: null,
    addressesErrors: null,
  });
};

export const createAddressesFailure = (state, { addressesError, addressesErrors }) =>
  state.merge({
    addressesCUFetching: false,
    addressesError,
    addressesErrors,
  });

export const updateAddressesRequest = (state) => state.merge({ addressesCUFetching: true });

export const updateAddressesSuccess = (state, { address }) => {
  const objIndex = state.addresses?.findIndex((obj) => obj.id === address.id);

  // if(!objIndex) return state;

  return state.merge({
    addresses: state.addresses.set(objIndex, address),
    addressesCUFetching: false,
    addressesError: null,
    addressesErrors: null,
  });
};

export const updateAddressesFailure = (state, { addressesError, addressesErrors }) =>
  state.merge({
    addressesCUFetching: false,
    addressesError,
    addressesErrors,
  });

export const deleteAddressesRequest = (state) => state.merge({ addressesFetching: true });

export const deleteAddressesSuccess = (state, { idAddress }) => {
  const addressesObj = getImmutableAsObj(state.addresses);
  const finalAddresses = addressesObj.filter((adress) => adress.id !== idAddress);

  return state.merge({
    addresses: Immutable(finalAddresses),
    addressesFetching: false,
    addressesError: null,
    addressesErrors: null,
  });
};

export const deleteAddressesFailure = (state, { addressesError, addressesErrors }) =>
  state.merge({
    addressesFetching: false,
    addressesError,
    addressesErrors,
  });

export const setDefaultAddressesRequest = (state) => state.merge({ addressesFetching: true });

export const setDefaultAddressesSuccess = (state, { idAddress, isCheck }) => {
  const finalAddresses =
    state.addresses?.length &&
    state.addresses?.map(function (address, index) {
      return address.id === idAddress ? { ...address, isDefault: isCheck } : { ...address, isDefault: !isCheck };
    });

  return state.merge({
    addresses: Immutable(finalAddresses),
    addressesFetching: false,
    addressesError: null,
    addressesErrors: null,
  });
};

export const setDefaultAddressesFailure = (state, { addressesError, addressesErrors }) =>
  state.merge({
    addressesFetching: false,
    addressesError,
    addressesErrors,
  });

export const updatePasswordRequest = (state) => state.merge({ updatePasswordFetching: true });

export const updatePasswordSuccess = (state) => {
  return state.merge({
    updatePasswordFetching: false,
    updatePasswordError: null,
    updatePasswordErrors: null,
  });
};

export const updatePasswordFailure = (state, { updatePasswordError, updatePasswordErrors }) =>
  state.merge({
    updatePasswordFetching: false,
    updatePasswordError,
    updatePasswordErrors,
  });

export const setUserPositionRequest = (state) => {
  return state;
};

export const setUserPositionSuccess = (state, { userPosition }) => {
  return state.merge({ userPosition });
};

export const setUserPositionFailure = (state, { userPositionError, userPositionErrors }) =>
  state.merge({
    userPositionError,
    userPositionErrors,
  });

export const contactUsRequest = (state) => state.merge({ contactUsFetching: true, contactSucceeded: false });

export const contactUsSuccess = (state) => {
  return state.merge({
    contactUsFetching: false,
    contactSucceeded: true,
    contactUsError: null,
    contactUsErrors: null,
  });
};

export const contactUsFailure = (state, { contactUsError, contactUsErrors }) =>
  state.merge({
    contactUsFetching: false,
    contactUsError,
    contactUsErrors,
  });

export const resetAllContactUsError = (state) =>
  state.merge({
    contactUsError: null,
    contactUsErrors: null,
  });

export const qoodosSubscriptionRequest = (state) => state.merge({ qoodosSubscriptionFetching: true, qoodosSubscriptionSucceeded: null });

export const qoodosSubscriptionSuccess = (state) => {
  return state.merge({
    qoodosSubscriptionFetching: false,
    qoodosSubscriptionSucceeded: true,
    qoodosSubscriptionError: null,
    qoodosSubscriptionErrors: null,
  });
};

export const qoodosSubscriptionFailure = (state, { qoodosSubscriptionError, qoodosSubscriptionErrors }) =>
  state.merge({
    qoodosSubscriptionFetching: false,
    qoodosSubscriptionSucceeded: false,
    qoodosSubscriptionError,
    qoodosSubscriptionErrors,
  });

export const qoodosUnSubscriptionRequest = (state) => state.merge({ qoodosUnSubscriptionFetching: true, qoodosUnSubscriptionSucceeded: null });

export const qoodosUnSubscriptionSuccess = (state) => {
  return state.merge({
    qoodosUnSubscriptionFetching: false,
    qoodosUnSubscriptionSucceeded: true,
    qoodosUnSubscriptionError: null,
    qoodosUnSubscriptionErrors: null,
  });
};

export const qoodosUnSubscriptionFailure = (state, { qoodosUnSubscriptionError, qoodosUnSubscriptionErrors }) =>
  state.merge({
    qoodosUnSubscriptionFetching: false,
    qoodosUnSubscriptionSucceeded: false,
    qoodosUnSubscriptionError,
    qoodosUnSubscriptionErrors,
  });

export const resetFidErrors = (state) =>
  state.merge({
    qoodosSubscriptionSucceeded: null,
    qoodosSubscriptionError: null,
    qoodosSubscriptionErrors: null,
    qoodosUnSubscriptionSucceeded: null,
    qoodosUnSubscriptionError: null,
    qoodosUnSubscriptionErrors: null,
  });

export const searchCustomerRequest = (state) => state.merge({ searchCustomerFetching: true });
export const searchCustomerSuccess = (state, { searchCustomerDataResult, processInitiatedSearch }) => {
  return state.merge({
    searchCustomerData: state.searchCustomerData?.set(processInitiatedSearch, searchCustomerDataResult),
    searchCustomerFetching: false,
    searchCustomerError: null,
    searchCustomerErrors: null,
  });
};
export const searchCustomerFailure = (state, { searchCustomerError, searchCustomerErrors }) =>
  state.merge({
    searchCustomerFetching: false,
    searchCustomerError,
    searchCustomerErrors,
  });

export const shareUserDataToFranchiseRequest = (state) => state.merge({ shareUserDataToFranchiseFetching: true, shareUserDataToFranchiseSucceeded: null });
export const shareUserDataToFranchiseSuccess = (state) => {
  return state.merge({
    shareUserDataToFranchiseFetching: false,
    shareUserDataToFranchiseSucceeded: true,
    shareUserDataToFranchiseError: null,
    shareUserDataToFranchiseErrors: null,
  });
};
export const shareUserDataToFranchiseFailure = (state, { shareUserDataToFranchiseError, shareUserDataToFranchiseErrors }) =>
  state.merge({
    shareUserDataToFranchiseFetching: false,
    shareUserDataToFranchiseSucceeded: false,
    shareUserDataToFranchiseError,
    shareUserDataToFranchiseErrors,
  });

export const resetSearchAndShareUserDataErrors = (state) =>
  state.merge({
    searchCustomerData: Immutable({}),
    searchCustomerFetching: false,
    searchCustomerError: null,
    searchCustomerErrors: null,
    shareUserDataToFranchiseFetching: false,
    shareUserDataToFranchiseSucceeded: null,
    shareUserDataToFranchiseError: null,
    shareUserDataToFranchiseErrors: null,
  });

export const resetAddressesErrors = (state) =>
  state.merge({
    addressesError: null,
    addressesErrors: null,
  });

export const resetUpdatePasswordErrors = (state) =>
  state.merge({
    updatePasswordError: null,
    updatePasswordErrors: null,
  });

export const setDefaultLanguageRequest = (state) => state.merge({ setDefaultLanguageFetching: true });
export const setDefaultLanguageSuccess = (state, { language }) => {
  const currentUser = { ...state?.currentUser, defaultLanguage: language };
  return state.merge({
    currentUser,
    setDefaultLanguageFetching: false,
    setDefaultLanguageError: null,
    setDefaultLanguageErrors: null,
  });
};
export const setDefaultLanguageFailure = (state, { setDefaultLanguageError, setDefaultLanguageErrors }) =>
  state.merge({
    setDefaultLanguageFetching: false,
    setDefaultLanguageError,
    setDefaultLanguageErrors,
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAILURE]: getUserFailure,

  [Types.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAILURE]: updatePasswordFailure,
  [Types.SET_USER_POSITION_REQUEST]: setUserPositionRequest,
  [Types.SET_USER_POSITION_SUCCESS]: setUserPositionSuccess,
  [Types.SET_USER_POSITION_FAILURE]: setUserPositionFailure,

  [Types.GET_ADDRESSES_REQUEST]: getAddressesRequest,
  [Types.GET_ADDRESSES_SUCCESS]: getAddressesSuccess,
  [Types.GET_ADDRESSES_FAILURE]: getAddressesFailure,
  [Types.CREATE_ADDRESSES_REQUEST]: createAddressesRequest,
  [Types.CREATE_ADDRESSES_SUCCESS]: createAddressesSuccess,
  [Types.CREATE_ADDRESSES_FAILURE]: createAddressesFailure,
  [Types.UPDATE_ADDRESSES_REQUEST]: updateAddressesRequest,
  [Types.UPDATE_ADDRESSES_SUCCESS]: updateAddressesSuccess,
  [Types.UPDATE_ADDRESSES_FAILURE]: updateAddressesFailure,
  [Types.DELETE_ADDRESSES_REQUEST]: deleteAddressesRequest,
  [Types.DELETE_ADDRESSES_SUCCESS]: deleteAddressesSuccess,
  [Types.DELETE_ADDRESSES_FAILURE]: deleteAddressesFailure,
  [Types.SET_DEFAULT_ADDRESSES_REQUEST]: setDefaultAddressesRequest,
  [Types.SET_DEFAULT_ADDRESSES_SUCCESS]: setDefaultAddressesSuccess,
  [Types.SET_DEFAULT_ADDRESSES_FAILURE]: setDefaultAddressesFailure,

  [Types.CONTACT_US_REQUEST]: contactUsRequest,
  [Types.CONTACT_US_SUCCESS]: contactUsSuccess,
  [Types.CONTACT_US_FAILURE]: contactUsFailure,
  [Types.RESET_ALL_CONTACT_US_ERROR]: resetAllContactUsError,

  [Types.QOODOS_SUBSCRIPTION_REQUEST]: qoodosSubscriptionRequest,
  [Types.QOODOS_SUBSCRIPTION_SUCCESS]: qoodosSubscriptionSuccess,
  [Types.QOODOS_SUBSCRIPTION_FAILURE]: qoodosSubscriptionFailure,

  [Types.QOODOS_UN_SUBSCRIPTION_REQUEST]: qoodosUnSubscriptionRequest,
  [Types.QOODOS_UN_SUBSCRIPTION_SUCCESS]: qoodosUnSubscriptionSuccess,
  [Types.QOODOS_UN_SUBSCRIPTION_FAILURE]: qoodosUnSubscriptionFailure,

  [Types.RESET_FID_ERRORS]: resetFidErrors,

  [Types.SEARCH_CUSTOMER_REQUEST]: searchCustomerRequest,
  [Types.SEARCH_CUSTOMER_SUCCESS]: searchCustomerSuccess,
  [Types.SEARCH_CUSTOMER_FAILURE]: searchCustomerFailure,

  [Types.SHARE_USER_DATA_TO_FRANCHISE_REQUEST]: shareUserDataToFranchiseRequest,
  [Types.SHARE_USER_DATA_TO_FRANCHISE_SUCCESS]: shareUserDataToFranchiseSuccess,
  [Types.SHARE_USER_DATA_TO_FRANCHISE_FAILURE]: shareUserDataToFranchiseFailure,

  [Types.RESET_SEARCH_AND_SHARE_USER_DATA_ERRORS]: resetSearchAndShareUserDataErrors,

  [Types.RESET_ADDRESSES_ERRORS]: resetAddressesErrors,

  [Types.RESET_UPDATE_PASSWORD_ERRORS]: resetUpdatePasswordErrors,

  [Types.SET_DEFAULT_LANGUAGE_REQUEST]: setDefaultLanguageRequest,
  [Types.SET_DEFAULT_LANGUAGE_SUCCESS]: setDefaultLanguageSuccess,
  [Types.SET_DEFAULT_LANGUAGE_FAILURE]: setDefaultLanguageFailure,
});
