import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import City from "./City";
import Category from "./Category";
import ShopMode from "./ShopMode";
import { isPointInPolygon } from "geolib";

export default class Zone extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.city && props.city?.constructor?.name !== City.prototype)
      props.city = Immutable(new City(this.getImmutableAsObj(props.city)), { prototype: City.prototype });

    props && Object.assign(this, Immutable(props));
  }

  getName() {
    return this.name;
  }

  getCityNameWithPlace() {
    return `${this?.city?.name}${!!this.place ? ` - ${this.place}` : ""}`;
  }

  isAdressInZone(address) {
    if (!address) {
      return false;
    }
    if (this.type === "map") {
      const location = { latitude: address?.latitude, longitude: address?.longitude };
      return this.isLocationInZone(location);
    }
    if (!this.postalCodes?.length || !address.postalCode?.code) {
      return false;
    }
    return !!this.postalCodes?.find((postalCode) => postalCode.code === address.postalCode.code);
  }

  isLocationInZone(location) {
    if (!location || !location.latitude || !location.longitude) {
      return false;
    }
    const polygonData = this.polygonData && (Array.isArray(this.polygonData) ? this.polygonData : JSON.parse(this.polygonData));
    if (!polygonData?.length) return false;
    return isPointInPolygon(location, polygonData);
  }
}
