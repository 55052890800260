import { call, put, select } from "redux-saga/effects";
import LoginActions, { LoginSelectors } from "../Redux/LoginRedux";
import UserActions from "../Redux/UserRedux";
import * as RootNavigation from "../Navigation/RootNavigation";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import { decodeToken } from "react-jwt";
import callApi from "./CallApiSaga";
import { FranchiseSelectors } from "../Redux/FranchiseRedux";
import { ShopSelectors } from "../Redux/ShopRedux";

// attempts to login
export function* loginAndCustomerSearch(api, { email, password, from, refreshToken = false, stayConnected, franchiseId }) {
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const apiSearchCustomerCall = call(api.searchCustomerByFranchise, { email, franchiseId: franchise?.id });
  const searchCustomerResponse = yield call(callApi, apiSearchCustomerCall);
  if (searchCustomerResponse.ok || (searchCustomerResponse.ok === false && searchCustomerResponse.status === 404)) {
    const customerState = searchCustomerResponse.data?.response;
    // if (customerState?.customer_id && customerState?.exists && customerState?.in_franchise) {
    if (searchCustomerResponse.ok === false || customerState?.in_franchise) {
      yield put(LoginActions.loginRequest(email, password, from, refreshToken, stayConnected, franchiseId));
    }
    yield put(UserActions.searchCustomerSuccess(customerState, "login"));
  } else {
    yield put(LoginActions.loginFailure(null, email, null));
    yield put(UserActions.searchCustomerFailure(getApiResponseError(searchCustomerResponse), getApiResponseErrors(searchCustomerResponse)));
  }
}

export function* login(api, { email, password, from, refreshToken = false, franchiseId }) {
  yield call(api.removeAuthToken);
  const response = refreshToken ? yield call(api.refreshTokenAndLogin, { refreshToken, franchiseId }) : yield call(api.login, { email, password, franchiseId });

  if (response.ok) {
    yield call(api.setAuthToken, response.data);
    // reset navigation as if it was the first screen
    if (from === "loginScreen") yield navigateToBestAvailaibleScreenForAfterLogin();

    yield put(LoginActions.loginSuccess(response.data, { email, password, franchiseId }));
    const dataUser = decodeToken(response.data.token);
    yield put(UserActions.getUserRequest(dataUser.id ?? null));
  } else {
    let error = null;
    if (response.data?.message === "Veuillez activer votre compte avant de vous connecter.") {
      error = "accountNotActivated";
    }
    yield put(LoginActions.loginFailure(error ? null : "invalidCredentials", email, error));

    // if (!reLoginAttempt) {
    // yield put(reset('loginForm'))
    // yield put(initialize('loginForm', { email }))
    // }
  }
}

function* navigateToBestAvailaibleScreenForAfterLogin() {
  const navigationRouteNames = RootNavigation.getState()?.routeNames;
  if (navigationRouteNames?.includes("AddToCart")) {
    yield RootNavigation.navigate("AddToCart");
  } else {
    yield RootNavigation.navigate("Shops");
  }
}

// attempts to logout
export function* logout(api) {
  // reset state to initial
  yield put(LoginActions.logoutSuccess());
  yield call(api.removeAuthToken);
  // reset navigation as if it was the first screen
  yield RootNavigation.reset({
    index: 0,
    routes: [{ name: "Launch" }],
  });
}

// loads the login
export function* autoLogin(api) {
  const authObj = yield select(LoginSelectors.getAuthentication);
  // only set the token if we have it
  if (authObj) {
    yield call(api.setAuthToken, authObj);
    yield put(LoginActions.autoLoginSuccess());
    const dataUser = decodeToken(authObj.token);
    yield put(UserActions.getUserRequest(dataUser.id ?? null));
  } else {
    yield put(LoginActions.autoLoginFailure());
  }
}

// attempts to signup a new client
export function* signupAndCustomerSearch(api, { params }) {
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const apiSearchCustomerCall = call(api.searchCustomerByFranchise, { email: params.email, franchiseId: franchise?.id });
  const searchCustomerResponse = yield call(callApi, apiSearchCustomerCall);
  if (searchCustomerResponse.ok || (searchCustomerResponse.ok === false && searchCustomerResponse.status === 404)) {
    const customerState = searchCustomerResponse.data?.response;
    const customerNotInBD = searchCustomerResponse.ok === false && searchCustomerResponse.status === 404;
    if (customerNotInBD || !customerState?.exists) {
      yield signup(api, { params });
    }
    yield put(UserActions.searchCustomerSuccess(customerState, "signup"));
  } else {
    yield put(LoginActions.signupFailure(null, null, params.email));
    yield put(UserActions.searchCustomerFailure(getApiResponseError(searchCustomerResponse), getApiResponseErrors(searchCustomerResponse)));
  }
}
export function* signup(api, { params }) {
  const apiCall = call(api.signUp, params);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(LoginActions.signupSuccess({ email: params.email, password: params.password }));
    yield RootNavigation.navigate("Login");
  } else {
    yield put(LoginActions.signupFailure(getApiResponseError(response), getApiResponseErrors(response), params.email));
  }
}

// update profile
export function* updateProfile(api, { params }) {
  const apiCall = call(api.updateProfile, params);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(LoginActions.updateProfileSuccess({ email: params.email, password: params.password }));
    yield put(UserActions.getUserSuccess(response.data));
    if (!params.dontNavigate) {
      yield RootNavigation.reset({ index: 0, routes: [{ name: "Profil" }] });
    }
  } else {
    yield put(LoginActions.updateProfileFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* resetPasswordInit(api, { email }) {
  // const responseLogin = yield call(api.login, { email: null, password: null });
  // if (responseLogin.ok) {
  const host = window.location?.host;
  const url = host + "/reset-password"; // generate url to send into reset mail confirmation
  const apiCall = call(api.resetPasswordInit, { email, url });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(LoginActions.resetPasswordInitSuccess());
  } else {
    yield put(LoginActions.resetPasswordInitFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
  // } else {
  //   yield put(LoginActions.resetPasswordFailure(getApiResponseError(responseLogin), getApiResponseErrors(responseLogin)));
  // }
}

export function* checkResetPasswordToken(api, { token }) {
  const apiCall = call(api.checkResetPasswordToken, token);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(LoginActions.checkResetPasswordTokenSuccess());
  } else {
    yield put(LoginActions.checkResetPasswordTokenFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* resetPassword(api, { password, token }) {
  const apiCall = call(api.resetPassword, { password, token });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(LoginActions.resetPasswordSuccess());
  } else {
    yield put(LoginActions.resetPasswordFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* socialAuthentication(api, { socialAuthenticationParms }) {
  const { typeAuth, qoodosSubscription, qoodosEmailNotification, qoodosPhoneNotification, tokenSocialAuthentication, stayConnected, from, redirectUri } =
    socialAuthenticationParms;
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const shop = yield select(ShopSelectors.getShop);

  const franchiseId = franchise.id;
  let defaultShopId = null;
  if (shop?.id) {
    defaultShopId = shop.id;
  } else if (franchise?.shops?.length === 1) {
    defaultShopId = franchise?.shops[0]?.id;
  }
  const shopId = defaultShopId ?? shop?.id;
  const apiCode = tokenSocialAuthentication; //token

  const socialAuthen = typeAuth === "google" ? api.googleAuth : api.appleAuth;

  const apiCall = call(socialAuthen, {
    franchiseId,
    shopId,
    apiCode,
    qoodosSubscription,
    qoodosEmailNotification,
    qoodosPhoneNotification,
    redirectUri,
    stayConnected,
  });

  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield call(api.setAuthToken, response.data);
    if (from === "loginScreen") yield navigateToBestAvailaibleScreenForAfterLogin();
    yield put(LoginActions.socialAuthenticationSuccess(response.data, { email: "", password: "", franchiseId, typeAuth }));
    const dataUser = decodeToken(response.data.token);
    yield put(UserActions.getUserRequest(dataUser.id ?? null));
  } else {
    yield put(LoginActions.socialAuthenticationFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}
