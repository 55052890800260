import { put, call, select } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import UserActions, { UserSelectors } from "../Redux/UserRedux";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import LoginActions from "../Redux/LoginRedux";
import { FranchiseSelectors } from "../Redux/FranchiseRedux";

export function* getUser(api, { userId, context }) {
  if (!userId) {
    yield put(UserActions.getUserFailure("mandatoryParamsError", null));
    return false;
  }
  const apiCall = call(api.getUser, userId, context);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.getUserSuccess(response.data));
  } else {
    yield put(UserActions.getUserFailure(getApiResponseError(response), getApiResponseErrors(response)));
    yield put(LoginActions.logoutRequest());
  }
}

export function* getAddresses(api, { userId }) {
  const apiCall = call(api.getAddresses, userId);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.getAddressesSuccess(userId, response.data));
  } else {
    yield put(UserActions.getAddressesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* updateAddresses(api, { address }) {
  const apiCall = call(api.updateAddresses, address);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.updateAddressesSuccess(response.data));
  } else {
    yield put(UserActions.updateAddressesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* createAddresses(api, { address }) {
  const connectedUser = yield select(UserSelectors.getConnectedUser);

  const apiCall = call(api.createAddresses, { ...address, customer: connectedUser?.id });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.createAddressesSuccess(response.data));
  } else {
    yield put(UserActions.createAddressesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* deleteAddresses(api, { idAddress }) {
  const apiCall = call(api.deleteAddresses, idAddress);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.deleteAddressesSuccess(idAddress));
  } else {
    yield put(UserActions.deleteAddressesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* setDefaultAddresses(api, { idAddress, isCheck }) {
  const apiCall = call(api.setDefaultAddresses, { idAddress, isDefault: isCheck });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.setDefaultAddressesSuccess(idAddress, isCheck));
  } else {
    yield put(UserActions.setDefaultAddressesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* updatePassword(api, { params }) {
  const apiCall = call(api.updatePassword, params);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.updatePasswordSuccess());
  } else {
    yield put(UserActions.updatePasswordFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* setUserPositionRequest(api) {
  const userPosition = yield new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (userPosition) => {
        resolve(userPosition.coords);
      },
      () => {
        console.log("Position could not be determined.");
      },
    );
  });

  if (userPosition) {
    yield put(UserActions.setUserPositionSuccess({ latitude: userPosition.latitude, longitude: userPosition.longitude }));
  }
}

export function* contactUs(api, { contactUs }) {
  const apiCall = call(api.contactUs, contactUs);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.contactUsSuccess());
  } else {
    yield put(UserActions.contactUsFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* qoodosSubscription(api) {
  const apiCall = call(api.qoodosSubscription);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.qoodosSubscriptionSuccess());
    const connectedUser = yield select(UserSelectors.getConnectedUser);
    yield put(UserActions.getUserRequest(connectedUser.id));
  } else {
    yield put(UserActions.qoodosSubscriptionFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* qoodosUnSubscription(api) {
  const apiCall = call(api.qoodosUnSubscription);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.qoodosUnSubscriptionSuccess());
    const connectedUser = yield select(UserSelectors.getConnectedUser);
    yield put(UserActions.getUserRequest(connectedUser.id));
  } else {
    yield put(UserActions.qoodosUnSubscriptionFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* shareUserDataToFranchise(api, { customerId }) {
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const apiCall = call(api.shareUserDataToFranchise, { customerId, franchiseId: franchise?.id });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.shareUserDataToFranchiseSuccess());
  } else {
    yield put(UserActions.shareUserDataToFranchiseFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* setDefaultLanguage(api, { language }) {
  const connectedUser = yield select(UserSelectors.getConnectedUser);
  const userId = connectedUser?.id;

  const apiCall = call(api.setDefaultLanguage, { language, userId });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(UserActions.setDefaultLanguageSuccess(language));
  } else {
    yield put(UserActions.setDefaultLanguageFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}
