import EscPosConstants from "./EscPosConstants";
import L from "../../I18n/tr";
import functions from "../../Shared/functions";
import { Br, Cut, Line, Printer, Text, Row, render, Image, QRCode, Raw } from "react-thermal-printer";
import errorImg from "../../Assets/AssetsBorne/images/error.png";

export const TicketFoxFormater = async (
  shopName,
  shopDesc,
  mode,
  productsIntoCart,
  deliveriesModesByLink,
  selectedShop,
  locale,
  promotionsApplied,
  totalToPaid,
  orderNum,
  image,
  adress,
  siret,
  tax,
  numBorne
) => {
  const renderOptionEsc = (productIntoCart) => {
    return productIntoCart.getOptionsVariantsNamePrice(locale).map((option, index) => {
      return option.optionVariants.map((optionVariant, indexVariant) => {
        const optionVariantQuantity = optionVariant.quantity && optionVariant.quantity > 1 ? optionVariant.quantity : null;
        const price = functions.formatPrice(optionVariant.price, selectedShop);

        let optionStr = "";
        optionStr += optionVariantQuantity ? `   ${optionVariantQuantity} ` : "   ";
        optionStr += `${optionVariant.name} ${!price.includes("0,00") && !price.includes("0.00") ? "(" + price + ")" : ""}`;
        return (
          <Text size={{ width: 1, height: 1 }} align="left" key={indexVariant}>
            {optionStr}
          </Text>
        );
      });
    });
  };

  const renderUnSelectedProductComponentsEsc = (productIntoCart) => {
    const unSelectedProductComponents = productIntoCart.getOptionsVariantsNamePrice(locale, true);
    if (!unSelectedProductComponents.length) {
      return null;
    }
    return (
      <>
        <Text size={{ width: 1, height: 1 }} align="left">{`${L("without")} : `}</Text>
        {unSelectedProductComponents.map((option, index) => {
          return option.optionVariants.map((optionVariant, indexVariant) => {
            const optionVariantQuantity = optionVariant.quantity && optionVariant.quantity > 1 ? optionVariant.quantity : null;
            const price = functions.formatPrice(optionVariant.price, selectedShop);

            let optionStr = "";
            optionStr += optionVariantQuantity ? `   ${optionVariantQuantity} ` : "   ";
            optionStr += `${optionVariant.name} ${!price.includes("0,00") && !price.includes("0.00") ? "(" + price + ")" : ""}`;
            return (
              <Text size={{ width: 1, height: 1 }} align="left" key={indexVariant}>
                {optionStr}
              </Text>
            );
          });
        })}
      </>
    );
  };

  const renderInstructionSpec = (productIntoCart) => {
    const instructionSpecialTxt = getInstructionSpecialTxt(productIntoCart);

    if (instructionSpecialTxt != "") {
      return (
        <Text size={{ width: 1, height: 1 }} align="left">
          {instructionSpecialTxt}
        </Text>
      );
    }
  };
  const renderProductsEsc = () => {
    return Object.entries(productsIntoCart)?.map(([indexProduct, productIntoCart], index) => {
      const prodInfo = `${productIntoCart.qty} ${productIntoCart?.selectedProduct?.getName?.(locale)}`;
      const prodPrice = `${getProductPrice(productIntoCart, deliveriesModesByLink, selectedShop)}`;

      return (
        <div key={index}>
          <Row left={prodInfo} right={prodPrice} />
          {renderOptionEsc(productIntoCart)}
          {renderUnSelectedProductComponentsEsc(productIntoCart)}
          {renderInstructionSpec(productIntoCart)}
        </div>
      );
    });
  };
  const renderPromotionEsc = () => {
    const promos = renderPromos(promotionsApplied);
    if (promos != "") {
      return (
        <Text size={{ width: 1, height: 1 }} align="left">
          {promos}
        </Text>
      );
    }
  };

  const renderManagementFeesEsc = () => {
    if (selectedShop?.shopSettings?.managementFees && selectedShop?.shopSettings?.managementFees !== 0) {
      return <Row left={L("managementFees")} right={functions.formatPrice(selectedShop?.shopSettings?.managementFees ?? 0)} />;
    }
  };

  const renderShopDescEsc = () => {
    return (
      <div>
        {adress && adress !== "" ? <Text size={{ width: 1, height: 1 }} align="center">{`${adress}`}</Text> : null}
        {siret && siret !== "" ? <Text size={{ width: 1, height: 1 }} align="center">{`SIRET : ${siret}`}</Text> : null}
      </div>
    );
  };

  const receipt = (
    <Printer type="epson" width={48} characterSet="pc858_euro">
      {/* RESET Printer + pc858_euro encodage */}
      <Raw data={Uint8Array.from([0x1b, 0x40, 0x1b, 0x74, 19])}></Raw>
      {image && (
        <>
          <Image src={image} align="center" />
          <Br />
        </>
      )}
      <Text size={{ width: 2, height: 2 }} align="center">
        {shopName}
      </Text>
      <Br />
      {renderShopDescEsc()}
      <Br />
      <Row
        left={<Text size={{ width: 2, height: 2 }}>{`${L("order")} ${orderNum}`}</Text>}
        right={<Text size={{ width: 2, height: 2 }}>{`BORNE #${numBorne}`}</Text>}
      />
      <Text size={{ width: 2, height: 2 }} align="center">
        {mode}
      </Text>
      <Br />
      <QRCode align="center" content={orderNum} model={2} cellSize={12} correction={"L"} />
      <Br />
      <Text size={{ width: 1, height: 1 }} align="left">
        {functions.getNowDateForTicket("DD/MM/YYYY HH:mm:ss")}
      </Text>
      <Br />
      {renderProductsEsc()}
      {renderPromotionEsc()}
      <Br />
      {renderManagementFeesEsc()}
      <Br />
      <Row
        left={
          <Text size={{ width: 2, height: 2 }} bold={true}>
            {L("Total")}
          </Text>
        }
        right={
          <Text size={{ width: 2, height: 2 }} bold={true} align="right">
            {functions.formatPrice(totalToPaid)}
          </Text>
        }
      />
      <Br />
      <Row
        left={
          <Text size={{ width: 1, height: 1 }} bold={true}>
            {L("TvaMsg")}
          </Text>
        }
        right={
          <Text size={{ width: 1, height: 1 }} bold={true} align="right">
            {functions.formatPrice(tax)}
          </Text>
        }
      />
      <Br />

      <Text size={{ width: 1, height: 1 }} align="center">
        {L("thankForTrust")}
      </Text>
      <Br />
      <Cut partial={true} />
    </Printer>
  );
  const data = await render(receipt);

  return data;
  /*let ticketFormat = EscPosConstants.INIT_PRINTER + EscPosConstants.CHARCODE_PC1252;

  // "\x1B\x2A\x00\x00\x01"
  // \x1b\x2a\x00
  // "\x1d\x76\x30"
  ticketFormat += escposCommands + "\n"; //EscPosConstants.ESCPOS_IMAGEFILE(window, image, 33, true, 127);
  ticketFormat += shopName + "\n";
  ticketFormat += EscPosConstants.TXT_4SQUARE;
  ticketFormat += EscPosConstants.ALIGN_CENTER;

  ticketFormat += shopName + "\n";

  ticketFormat += EscPosConstants.TXT_NORMAL;

  ticketFormat += shopDesc + "\n";

  ticketFormat += EscPosConstants.LINE_FEED;
  // ticketFormat += `${EscPosConstants.DRAW_LINE()}\n`;
  // ticketFormat += EscPosConstants.LINE_FEED;

  ticketFormat += EscPosConstants.TXT_4SQUARE;
  ticketFormat += `${L("order")} ${orderNum}\n`;
  ticketFormat += `${mode}\n`;

  ticketFormat += EscPosConstants.LINE_FEED;
  ticketFormat += EscPosConstants.TXT_NORMAL;

  ticketFormat += `${EscPosConstants.GENERATE_QR_CODE(`${orderNum}`)}\n`;

  ticketFormat += EscPosConstants.LINE_FEED;
  ticketFormat += EscPosConstants.ALIGN_LEFT;

  ticketFormat += `${functions.getNowDateForTicket("DD/MM/YYYY HH:mm:ss")}\n`;

  ticketFormat += EscPosConstants.LINE_FEED;
  // ticketFormat += `${EscPosConstants.DRAW_LINE()}\n`;
  // ticketFormat += EscPosConstants.LINE_FEED;

  ticketFormat += EscPosConstants.TXT_NORMAL;

  Object.entries(productsIntoCart)?.map(([indexProduct, productIntoCart], index) => {
    // ticketFormat += `${productIntoCart.qty} X ${productIntoCart?.selectedProduct?.getName?.(locale)} ---- ${getProductPrice(productIntoCart, deliveriesModesByLink, selectedShop)}\n`;
    const prodInfo = `${productIntoCart.qty} ${productIntoCart?.selectedProduct?.getName?.(locale)}`;
    const prodPrice = `${getProductPrice(productIntoCart, deliveriesModesByLink, selectedShop)}`;

    ticketFormat += EscPosConstants.DRAW_COLUMNS([prodInfo, prodPrice]);
    // ticketFormat += `\n`;

    productIntoCart.getOptionsVariantsNamePrice(locale).map((option, index) => {
      option.optionVariants.map((optionVariant, indexVariant) => {
        const optionVariantQuantity = optionVariant.quantity && optionVariant.quantity > 1 ? optionVariant.quantity : null;
        ticketFormat += optionVariantQuantity ? `   ${optionVariantQuantity} ` : "   ";
        ticketFormat += `${optionVariant.name} (${functions.formatPrice(optionVariant.price, selectedShop)})\n`;
      });
    });
    ticketFormat += EscPosConstants.LINE_FEED;

    const instructionSpecialTxt = getInstructionSpecialTxt(productIntoCart);
    if (instructionSpecialTxt != "") {
      ticketFormat += `${instructionSpecialTxt}\n`;
      ticketFormat += EscPosConstants.LINE_FEED;
    }
  });
  ticketFormat += EscPosConstants.LINE_FEED;

  const promos = renderPromos(promotionsApplied);
  if (promos != "") {
    ticketFormat += `${promos}\n`;
    ticketFormat += EscPosConstants.LINE_FEED;
  }

  // ticketFormat += EscPosConstants.TXT_2HEIGHT;
  // ticketFormat += EscPosConstants.TXT_2WIDTH;
  ticketFormat += EscPosConstants.ALIGN_RIGHT;
  if (selectedShop?.shopSettings?.managementFees && selectedShop?.shopSettings?.managementFees !== 0) {
    // ticketFormat += `${L("managementFees")} : ${functions.formatPrice(selectedShop?.shopSettings?.managementFees ?? 0)}\n`;
    ticketFormat += EscPosConstants.DRAW_COLUMNS([L("managementFees"), functions.formatPrice(selectedShop?.shopSettings?.managementFees ?? 0)]);
    ticketFormat += `\n`;
    ticketFormat += EscPosConstants.LINE_FEED;
  }

  // ticketFormat += EscPosConstants.TXT_2HEIGHT;
  ticketFormat += EscPosConstants.TXT_4SQUARE;
  // ticketFormat += EscPosConstants.TXT_FONT_BOLD;
  // ticketFormat += `${L("Total")} : ${functions.formatPrice(totalToPaid)}\n`;

  ticketFormat += EscPosConstants.DRAW_COLUMNS_TXT_2([L("Total"), functions.formatPrice(totalToPaid)]);
  ticketFormat += `\n`;

  ticketFormat += EscPosConstants.LINE_FEED;
  ticketFormat += EscPosConstants.ALIGN_LEFT;
  ticketFormat += EscPosConstants.TXT_NORMAL;
  // ticketFormat += `${EscPosConstants.DRAW_LINE()}\n`;
  // ticketFormat += EscPosConstants.LINE_FEED;

  ticketFormat += EscPosConstants.ALIGN_CENTER;
  ticketFormat += `${L("thankForTrust")}\n`;
  ticketFormat += EscPosConstants.LINE_FEED;
  ticketFormat += EscPosConstants.TXT_NORMAL;

  ticketFormat += `\n\n\n`;
  ticketFormat += EscPosConstants.PAPER_PART_CUT;*/
};

function getProductPrice(productIntoCart, deliveriesModesByLink, selectedShop) {
  if (!productIntoCart.selectedProduct) return;
  const price = productIntoCart.selectedProduct?.getFinalPrice(productIntoCart.selectedOptions, productIntoCart.qty, deliveriesModesByLink);

  return functions.formatPrice(price, selectedShop);
}

function getInstructionSpecialTxt(productIntoCart) {
  if (!productIntoCart || productIntoCart?.instructionSpecial?.length <= 0) return "";
  return `${L("Instructions")} : ${productIntoCart?.instructionSpecial}`;
}

function renderPromos(promotionsApplied) {
  if (!promotionsApplied) return "";
  let promos = "";
  promotionsApplied.map((promotion, index) => {
    return (promos += `${promotion.name} ${promotion.getValueAsString(selectedShop)} \n`);
  });

  return promos;
}

export const TicketCBFormater = async (ticket) => {
  const receipt = (
    <Printer type="epson" width={48} characterSet="pc858_euro">
      {/* RESET Printer + pc858_euro encodage */}
      <Raw data={Uint8Array.from([0x1b, 0x40, 0x1b, 0x74, 19])}></Raw>
      <Text size={{ width: 1, height: 1 }}>{ticket}</Text>
      <Br />
      <Cut partial={true} />
    </Printer>
  );
  const data = await render(receipt);

  return data;
  // let ticketFormat = EscPosConstants.INIT_PRINTER + EscPosConstants.CHARCODE_PC1252;
  // ticketFormat += ticket + "\n";

  // ticketFormat += `\n\n\n`;
  // ticketFormat += EscPosConstants.PAPER_PART_CUT;

  // return ticketFormat;
};

export const DevicePairFormater = async () => {
  const receipt = (
    <Printer type="epson" width={48} characterSet="pc858_euro">
      {/* RESET Printer + pc858_euro encodage */}
      <Raw data={Uint8Array.from([0x1b, 0x40, 0x1b, 0x74, 19])}></Raw>
      <Text size={{ width: 1, height: 1 }}>{"Vous avez bien appairé votre imprimante!"}</Text>
      <Br />
      <Cut partial={true} />
    </Printer>
  );
  const data = await render(receipt);

  return data;
};

export const ErrorsFormater = async (ticket) => {
  const receipt = (
    <Printer type="epson" width={48} characterSet="pc858_euro">
      {/* RESET Printer + pc858_euro encodage */}
      <Raw data={Uint8Array.from([0x1b, 0x40, 0x1b, 0x74, 19])}></Raw>
      <Text size={{ width: 1, height: 1 }}>{ticket}</Text>
      <Br />
      <Cut partial={true} />
    </Printer>
  );
  const data = await render(receipt);

  return data;

  // const msgs = errorsMsg.join("\n").toUpperCase();

  // const receipt = (
  //   <Printer type="epson" width={48} characterSet="pc858_euro">
  //     <Raw data={Uint8Array.from([0x1b, 0x40, 0x1b, 0x74, 19])}></Raw>
  //     <Image src={errorImg} align="center" />
  //     <Br />
  //     <Br />
  //     <Text size={{ width: 1, height: 1 }} align="center">
  //       {msgs}
  //     </Text>
  //     <Br />
  //     <Cut partial={true} />
  //   </Printer>
  // );
  // const data = await render(receipt);

  // return data;
};

// export default TicketFoxFormater;
