import hexRgb from "hex-rgb";
import Immutable from "seamless-immutable";
import Franchise from "../Logic/Entities/Franchise";
import functions from "./functions";

function hexToRgb(color) {
  const colorRgb = hexRgb(color, { format: "array" });

  return `${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}`;
}

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return new Entity(entityObj);
}

function configColors(franchise) {
  const franchiseObj = getImmutableAsEntity(franchise.franchiseData, Franchise);
  const tempate = franchiseObj.getTemplateCode();
  const colors = franchiseObj.getColors();
  if (__DEV__) console.log("foxOrders tempate, colors", tempate, colors);

  if (tempate === "fox") {
    // #EA6A12 var(--bs-primary)
    // #EA1212 var(--bs-danger)
    // var(--bs-warning): #EAB012
    // var(--bs-success): #3BB77E;
    // var(--bs-info) : #12CDEA;
    // var(--bs-secondary) : #959895;

    document.documentElement.style.setProperty("--bs-primary", colors.primary || colors.color1 || "#EA6A12");
    document.documentElement.style.setProperty("--bs-secondary", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-success", colors.thirdColor || "#3BB77E");
    document.documentElement.style.setProperty("--bs-info", colors.fourthColor || "#12CDEA");
    document.documentElement.style.setProperty("--bs-warning", colors.fifthColor || "#EAB012");
    document.documentElement.style.setProperty("--bs-danger", colors.sixthColor || "#EA1212");
    document.documentElement.style.setProperty("--bs-body-bg", colors.background || "#faf9f5");
    document.documentElement.style.setProperty("--bs-logo-modes", colors.logoModes || "#ffffff");
    document.documentElement.style.setProperty("--bs-body-color", colors.text || "#000000");
    document.documentElement.style.setProperty("--bs-body-color-rgb", hexToRgb(colors.text || "#000000"));
    document.documentElement.style.setProperty("--bs-text-input-color", "#000000");
    document.documentElement.style.setProperty("--bs-bg-input-color", "#FFFFFF");
    document.documentElement.style.setProperty("--bs-bg-special-element", hexToRgb(colors.bgSpecialElement || "#fcfcfc"));

    document.documentElement.style.setProperty("--bs-primary-rgb", hexToRgb(colors.primary || colors.color1 || "#EA6A12"));
    document.documentElement.style.setProperty("--bs-secondary-rgb", hexToRgb(colors.secondary || colors.color7 || "#959895"));
    document.documentElement.style.setProperty("--bs-success-rgb", hexToRgb(colors.thirdColor || "#3BB77E"));
    document.documentElement.style.setProperty("--bs-info-rgb", hexToRgb(colors.fourthColor || "#12CDEA"));
    document.documentElement.style.setProperty("--bs-warning-rgb", hexToRgb(colors.fifthColor || "#EAB012"));
    document.documentElement.style.setProperty("--bs-danger-rgb", hexToRgb(colors.sixthColor || "#EA1212"));
    const bodyBgRgb = hexToRgb(colors.background || "#faf9f5");
    const bodyBgRgbModified = bodyBgRgb
      .split(",")
      .map((x) => (x >= 5 ? x - 5 : x + 5))
      .join();
    document.documentElement.style.setProperty("--bs-body-bg-rgb", bodyBgRgb);
    document.documentElement.style.setProperty("--bs-body-bg-rgb-modified", bodyBgRgbModified);

    document.documentElement.style.setProperty("--bs-hover-focus-bg", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border-2", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-select-color", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-hover", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-border", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-primary-clear-30", functions.adjust(colors.primary || colors.color1 || "#EA6A12", 30));
    document.documentElement.style.setProperty("--bs-secondary-darkened-60", functions.adjust(colors.secondary || colors.color7 || "#959895", -60));
  } else if (tempate === "template2") {
    // #EA6A12 var(--bs-primary)
    // #EA1212 var(--bs-danger)
    // var(--bs-warning): #EAB012
    // var(--bs-success): #3BB77E;
    // var(--bs-info) : #12CDEA;
    // var(--bs-secondary) : #959895;

    document.documentElement.style.setProperty("--bs-primary", colors.primary || colors.color1 || "#EA6A12");
    document.documentElement.style.setProperty("--bs-secondary", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-success", colors.thirdColor || "#3BB77E");
    document.documentElement.style.setProperty("--bs-info", colors.fourthColor || "#12CDEA");
    document.documentElement.style.setProperty("--bs-warning", colors.fifthColor || "#EAB012");
    document.documentElement.style.setProperty("--bs-danger", colors.sixthColor || "#EA1212");
    document.documentElement.style.setProperty("--bs-body-bg", colors.background || "#faf9f5");
    document.documentElement.style.setProperty("--bs-logo-modes", colors.logoModes || "#ffffff");
    document.documentElement.style.setProperty("--bs-body-color", colors.text || "#000000");
    document.documentElement.style.setProperty("--bs-body-color-rgb", hexToRgb(colors.text || "#000000"));
    document.documentElement.style.setProperty("--bs-text-input-color", "#000000");
    document.documentElement.style.setProperty("--bs-bg-input-color", "#FFFFFF");
    document.documentElement.style.setProperty("--bs-bg-special-element", hexToRgb(colors.bgSpecialElement || "#fcfcfc"));

    document.documentElement.style.setProperty("--bs-primary-rgb", hexToRgb(colors.primary || colors.color1 || "#EA6A12"));
    document.documentElement.style.setProperty("--bs-secondary-rgb", hexToRgb(colors.secondary || colors.color7 || "#959895"));
    document.documentElement.style.setProperty("--bs-success-rgb", hexToRgb(colors.thirdColor || "#3BB77E"));
    document.documentElement.style.setProperty("--bs-info-rgb", hexToRgb(colors.fourthColor || "#12CDEA"));
    document.documentElement.style.setProperty("--bs-warning-rgb", hexToRgb(colors.fifthColor || "#EAB012"));
    document.documentElement.style.setProperty("--bs-danger-rgb", hexToRgb(colors.sixthColor || "#EA1212"));
    const bodyBgRgb = hexToRgb(colors.background || "#faf9f5");
    const bodyBgRgbModified = bodyBgRgb
      .split(",")
      .map((x) => (x >= 5 ? x - 5 : x + 5))
      .join();
    document.documentElement.style.setProperty("--bs-body-bg-rgb", bodyBgRgb);
    document.documentElement.style.setProperty("--bs-body-bg-rgb-modified", bodyBgRgbModified);

    document.documentElement.style.setProperty("--bs-hover-focus-bg", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border-2", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-select-color", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-hover", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-border", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-primary-clear-30", functions.adjust(colors.primary || colors.color1 || "#EA6A12", 30));
    document.documentElement.style.setProperty("--bs-secondary-darkened-60", functions.adjust(colors.secondary || colors.color7 || "#959895", -75));
  } else if (tempate === "borne") {
    // #EA6A12 var(--bs-primary)
    // #EA1212 var(--bs-danger)
    // var(--bs-warning): #EAB012
    // var(--bs-success): #3BB77E;
    // var(--bs-info) : #12CDEA;
    // var(--bs-secondary) : #959895;

    const primary = colors.primary;
    const background = functions.adjust(colors.primary || "#faf9f5", 100);
    const secondary = functions.adjust(colors.primary || "#959895", 50);
    const logoModes = functions.adjust(colors.primary || "#ffffff", 50);

    document.documentElement.style.setProperty("--bs-primary", primary || "#EA6A12");
    document.documentElement.style.setProperty("--bs-secondary", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-success", colors.thirdColor || "#3BB77E");
    document.documentElement.style.setProperty("--bs-info", colors.fourthColor || "#12CDEA");
    document.documentElement.style.setProperty("--bs-warning", colors.fifthColor || "#EAB012");
    document.documentElement.style.setProperty("--bs-danger", colors.sixthColor || "#EA1212");
    document.documentElement.style.setProperty("--bs-body-bg", background || "#faf9f5");
    document.documentElement.style.setProperty("--bs-logo-modes", logoModes || "#ffffff");
    document.documentElement.style.setProperty("--bs-body-color", colors.text || "#000000");
    document.documentElement.style.setProperty("--bs-body-color-rgb", hexToRgb(colors.text || "#000000"));
    document.documentElement.style.setProperty("--bs-text-input-color", "#000000");
    document.documentElement.style.setProperty("--bs-bg-input-color", "#FFFFFF");
    document.documentElement.style.setProperty("--bs-bg-special-element", hexToRgb(colors.bgSpecialElement || "#fcfcfc"));

    document.documentElement.style.setProperty("--bs-primary-rgb", hexToRgb(primary || "#EA6A12"));
    document.documentElement.style.setProperty("--bs-secondary-rgb", hexToRgb(secondary || "#959895"));
    document.documentElement.style.setProperty("--bs-success-rgb", hexToRgb(colors.thirdColor || "#3BB77E"));
    document.documentElement.style.setProperty("--bs-info-rgb", hexToRgb(colors.fourthColor || "#12CDEA"));
    document.documentElement.style.setProperty("--bs-warning-rgb", hexToRgb(colors.fifthColor || "#EAB012"));
    document.documentElement.style.setProperty("--bs-danger-rgb", hexToRgb(colors.sixthColor || "#EA1212"));
    const bodyBgRgb = hexToRgb(background || "#faf9f5");
    const bodyBgRgbModified = bodyBgRgb
      .split(",")
      .map((x) => (x >= 5 ? x - 5 : x + 5))
      .join();
    document.documentElement.style.setProperty("--bs-body-bg-rgb", bodyBgRgb);
    document.documentElement.style.setProperty("--bs-body-bg-rgb-modified", bodyBgRgbModified);

    document.documentElement.style.setProperty("--bs-hover-focus-bg", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border-2", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-select-color", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-hover", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-border", secondary || "#959895");
    document.documentElement.style.setProperty("--bs-primary-clear-30", functions.adjust(primary || "#EA6A12", 30));
    document.documentElement.style.setProperty("--bs-secondary-darkened-60", functions.adjust(secondary || "#959895", -75));
  } else {
    // #EA6A12 var(--bs-primary)
    // #EA1212 var(--bs-danger)
    // var(--bs-warning): #EAB012
    // var(--bs-success): #3BB77E;
    // var(--bs-info) : #12CDEA;
    // var(--bs-secondary) : #959895;

    document.documentElement.style.setProperty("--bs-primary", colors.primary || colors.color1 || "#EA6A12");
    document.documentElement.style.setProperty("--bs-secondary", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-success", colors.thirdColor || "#3BB77E");
    document.documentElement.style.setProperty("--bs-info", colors.fourthColor || "#12CDEA");
    document.documentElement.style.setProperty("--bs-warning", colors.fifthColor || "#EAB012");
    document.documentElement.style.setProperty("--bs-danger", colors.sixthColor || "#EA1212");
    document.documentElement.style.setProperty("--bs-body-bg", colors.background || "#faf9f5");
    document.documentElement.style.setProperty("--bs-logo-modes", colors.logoModes || "#ffffff");
    document.documentElement.style.setProperty("--bs-body-color", colors.text || "#000000");
    document.documentElement.style.setProperty("--bs-body-color-rgb", hexToRgb(colors.text || "#000000"));
    document.documentElement.style.setProperty("--bs-text-input-color", "#000000");
    document.documentElement.style.setProperty("--bs-bg-input-color", "#FFFFFF");
    document.documentElement.style.setProperty("--bs-bg-special-element", hexToRgb(colors.bgSpecialElement || "#fcfcfc"));

    document.documentElement.style.setProperty("--bs-primary-rgb", hexToRgb(colors.primary || colors.color1 || "#EA6A12"));
    document.documentElement.style.setProperty("--bs-secondary-rgb", hexToRgb(colors.secondary || colors.color7 || "#959895"));
    document.documentElement.style.setProperty("--bs-success-rgb", hexToRgb(colors.thirdColor || "#3BB77E"));
    document.documentElement.style.setProperty("--bs-info-rgb", hexToRgb(colors.fourthColor || "#12CDEA"));
    document.documentElement.style.setProperty("--bs-warning-rgb", hexToRgb(colors.fifthColor || "#EAB012"));
    document.documentElement.style.setProperty("--bs-danger-rgb", hexToRgb(colors.sixthColor || "#EA1212"));
    const bodyBgRgb = hexToRgb(colors.background || "#faf9f5");
    const bodyBgRgbModified = bodyBgRgb
      .split(",")
      .map((x) => (x >= 5 ? x - 5 : x + 5))
      .join();
    document.documentElement.style.setProperty("--bs-body-bg-rgb", bodyBgRgb);
    document.documentElement.style.setProperty("--bs-body-bg-rgb-modified", bodyBgRgbModified);

    document.documentElement.style.setProperty("--bs-hover-focus-bg", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-color-bg-border-2", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-select-color", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-hover", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-btn-danger-border", colors.secondary || colors.color7 || "#959895");
    document.documentElement.style.setProperty("--bs-primary-clear-30", functions.adjust(colors.primary || colors.color1 || "#EA6A12", 30));
    document.documentElement.style.setProperty("--bs-secondary-darkened-60", functions.adjust(colors.secondary || colors.color7 || "#959895", -75));
  }
}

export default {
  configColors,
  getImmutableAsEntity,
};
