import functions from "../../Shared/functions";
import { deliveriesModes } from "../Shared/Constants";
import { DevicePairFormater, ErrorsFormater, TicketCBFormater, TicketFoxFormater } from "./TicketFormater";

export async function DoPrintFoxTicket({
  selectedShop,
  orderInitData,
  locale,
  promotionsApplied,
  receivedOrderData,
  franchise,
  printer,
  borne,
  isFromWebView,
}) {
  const shopName = selectedShop.name;
  const shopDesc = selectedShop.getDescription();
  const mode = deliveriesModes[orderInitData?.shopMode?.mode?.code]?.name;
  const productsIntoCart = orderInitData?.productsIntoCart;
  const deliveriesModesByLink = orderInitData?.shopMode?.mode?.code;
  const totalToPaid = orderInitData?.totalSynthesisData?.totalToPaid;
  const orderNum = receivedOrderData?.preparationNumber;
  const adress = selectedShop.address;
  const siret = selectedShop.siret;
  // ce calcule de taxe n'est plus utilisé, c'est la valeur retourné par creation order qu'il faut utiliser
  // const tax = orderInitData?.totalSynthesisData?.tax;
  const tax = receivedOrderData?.taxesTotal;
  const numBorne = borne.retailCenterPosNumber;

  // const image = await imageUrlToBase64("https://staging-foxorders.fr/uploads/images/medias/mts-65cf7a7dc5aa1924570549.png");
  const logoPrinter = franchise.getMedia?.("logo_printer", false);
  const image = logoPrinter?.imageUrl ? functions.getFormatedImgForCrop(logoPrinter.imageUrl, false, "200x200", false) : null; //"https://staging-foxorders.fr/image/crop/medias/200x200/image-5-66349d94a24b1753823975-663aa386930ac114617534.png";

  const EscPosFormatTicket = await TicketFoxFormater(
    shopName,
    shopDesc,
    mode,
    productsIntoCart,
    deliveriesModesByLink,
    selectedShop,
    locale,
    promotionsApplied,
    totalToPaid,
    orderNum,
    image,
    adress,
    siret,
    tax,
    numBorne
  );

  return DoPrint(EscPosFormatTicket, printer, isFromWebView);
}

export async function DoPrintCBTicket({ ticket, printer, isFromWebView }) {
  // const tickTest =
  //   "CiAgQ0FSVEUgQkFOQ0FJUkUKICAgICAgU0FOUyBDT05UQUNUClRSQU5TQUNUSU9OIERFIFRFU1QgQ0IKQTAwMDAwMDA0MjEwMTAKQ0IKTGUgMDcvMDUvMjAyNCBhIDA5OjQ2OjU2ClRFU1QKNDQgTEEgTU9OVEFHTkUKMTk5OTI3MiAKMjY1NTAKMDAwMDAwMDE5OTkyNzIgCiMjIyMjIyMjIyMjIzA3NDAKOTc1Mjc4MENCQTgzM0UxNwowMDEgMDAxIDAwMDM1OSBDIEAgCk5vIEFVVE8gOiAzMjA2ICAKCk1PTlRBTlQgOgogICAgICAgICAxLDgyIEVVUgpERUJJVApDQVJURSBERSBURVNUCgpUSUNLRVQgQ0xJRU5UCkEgQ09OU0VSVkVSCkNFTlRSRSBERSBURVNUIENCCg==";

  if (ticket && ticket !== "") {
    const ticketCBFormater = await TicketCBFormater(functions.b64DecodeUnicode(ticket));
    // const finalData = ticketCBFormater.split("").reduce((previousValue, currentValue) => {
    //   if (currentValue === "€") return [...previousValue, 128];
    //   return [...previousValue, currentValue.charCodeAt(0)];
    // }, []);
    return DoPrint(ticketCBFormater, printer, isFromWebView);
  }
}

export async function DoPrintTest() {
  const tickTest =
    "CiAgQ0FSVEUgQkFOQ0FJUkUKICAgICAgU0FOUyBDT05UQUNUClRSQU5TQUNUSU9OIERFIFRFU1QgQ0IKQTAwMDAwMDA0MjEwMTAKQ0IKTGUgMDcvMDUvMjAyNCBhIDA5OjQ2OjU2ClRFU1QKNDQgTEEgTU9OVEFHTkUKMTk5OTI3MiAKMjY1NTAKMDAwMDAwMDE5OTkyNzIgCiMjIyMjIyMjIyMjIzA3NDAKOTc1Mjc4MENCQTgzM0UxNwowMDEgMDAxIDAwMDM1OSBDIEAgCk5vIEFVVE8gOiAzMjA2ICAKCk1PTlRBTlQgOgogICAgICAgICAxLDgyIEVVUgpERUJJVApDQVJURSBERSBURVNUCgpUSUNLRVQgQ0xJRU5UCkEgQ09OU0VSVkVSCkNFTlRSRSBERSBURVNUIENCCg==";

  // const escposCommands = await imageToEscPos("https://greatbasinseeds.com/wp-content/uploads/2013/09/koma4-100x100.jpg");

  // DoPrint(escposCommands.toString(), Buffer.concat([escposCommands, Buffer.from([0x0a, 0x0a, 0x0a, 0x0a, 0x0a]), Buffer.from([0x1d, 0x56, 0x00])]));
  const ticketCBFormater = await TicketCBFormater(functions.b64DecodeUnicode(tickTest));
  // return DoPrint(ticketCBFormater);

  // const receipt = (
  //   <Printer type="epson" width={48} characterSet="pc858_euro">
  //     <Text size={{ width: 2, height: 2 }}>9,500€</Text>
  //     <Text bold={true}>Test é&àáäâèéëêìíïîòóöôùúüûñç</Text>
  //     <Br />
  //     <Line />
  //     <Image
  //       src="https://staging-foxorders.fr/image/crop/medias/200x200/image-5-66349d94a24b1753823975-663aa386930ac114617534.png"
  //       align="center"
  //       // transforms={[transforms.floydSteinberg]}
  //     />
  //     <Cut />
  //   </Printer>
  // );
  // const data = await render(receipt);
  // DoPrint(data);
}

async function DoPrint(EscPosFormatTicket, printer = false, isFromWebView) {
  if (isFromWebView) {
    const EscPosFormatTicketB64 = btoa(String.fromCharCode.apply(null, EscPosFormatTicket));
    const myVar = window.Android.printTicketNatively(EscPosFormatTicketB64);
    return myVar;
  }

  if (!("usb" in navigator)) {
    console.log("WebUSB API is not supported!");
  }

  // await navigator?.usb?.requestDevice({ filters: [] }); // a faire une seule fois
  const devices = await navigator?.usb?.getDevices();
  try {
    // const device = await navigator.usb.requestDevice({ filters: [{ vendorId: devices[0]?.vendorId }] });
    const device = devices?.find((dec) => dec.vendorId === printer?.vendorId);
    await device
      .open()
      .then(() => device.selectConfiguration(1))
      .then(() => device.claimInterface(device.configuration.interfaces[0].interfaceNumber));

    // var string = "this is a first foxorders recipe print  \n\n\n this is a first foxorders recipe print \n\n\n\n \n\n\n\n";
    // string += "\x1d\x56\x01"; //Paper cutting code
    // var encoder = new TextEncoder();
    // const decoder = new TextDecoder("Windows-1252");
    // const decDate = decoder.decode(new Uint8Array([128]));
    // var data = encoder.encode(decDate + "----éèçà\n\n\n\x0a\x1d\x56\x01");
    // const finalData = EscPosFormatTicket.split("").reduce((previousValue, currentValue) => {
    //   if (currentValue === "€") return [...previousValue, 128];
    //   return [...previousValue, currentValue.charCodeAt(0)];
    // }, []);
    await device.transferOut(1, EscPosFormatTicket).catch((error) => {
      console.log("error", error);
    });
    await device.close();
    return;
  } catch (error) {
    console.log("Error: ", error.message);
    return;
  }
}

export async function DoPairPrint() {
  if (!("usb" in navigator)) {
    console.log("WebUSB API is not supported!");
  }

  // const device = (await navigator.hid.requestDevice({filters:[]}))[0];
  // console.log(device);
  // device.open();
  // device.oninputreport = function(inp) {
  //   console.log(inp.data.getUint8(0));
  // }
  // USBDevice.reset()
  // const devices = await navigator.usb.getDevices();

  // devices.forEach(async (device, index, array) => {
  //   const devReset = await device
  //     ?.open()
  //     .then(() => device.selectConfiguration(1))
  //     .then(() => device.claimInterface(device.configuration.interfaces[0].interfaceNumber));
  //   const resultReset = await device.reset();
  // });

  // await navigator.usb.getDevices().then((devices) => {
  //   console.log(`Total devices: ${devices.length}`);
  //   return devices.forEach(async (device) => {
  //     console.log(`Product name: ${device.productName}, serial number ${device.serialNumber}`);
  //     const resultReset = await device?.open().then(() => device.forget());
  //     return resultReset;
  //   });
  // });

  return await navigator?.usb
    ?.requestDevice({ filters: [] })
    .then(async (usbDevice) => {
      console.log("kamal usbDevice", usbDevice);
      return usbDevice
        ?.open()
        .then(() => usbDevice.selectConfiguration(1))
        .then(() => usbDevice.claimInterface(usbDevice.configuration.interfaces[0].interfaceNumber))
        .then(async () => {
          const devicePairFormater = await DevicePairFormater();
          await usbDevice.transferOut(1, devicePairFormater).catch((error) => {
            console.log("error", error);
          });
          await usbDevice.close();
          return usbDevice;
        });
    })
    .catch((e) => {
      console.error(`There is no usbDevice. ${e}`);
      return false;
    });
}

export async function DoErrorPrint({ errorsMsg, printer, isFromWebView }) {
  const ticketCBFormater = await ErrorsFormater(functions.b64DecodeUnicode(errorsMsg));
  return DoPrint(ticketCBFormater, printer, isFromWebView);

  // if (Array.isArray(errorsMsg) && errorsMsg.length > 0) {
  //   const errorsFormater = await ErrorsFormater(errorsMsg);
  //   DoPrint(errorsFormater, printer);
  // }
}

const imageUrlToBase64 = async (url) => {
  const base64 = await fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });

  return base64;
};

export default DoPrint;
