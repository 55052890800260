import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import Menu from "../Logic/Entities/Menu";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getMenuRequest: ["shopId", "enabled"],
  getMenuSuccess: ["menuData"],
  getMenuFailure: ["error", "errors"],

  initMenuState: null,
});

export const MenuTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  menuFetching: false,
  menuError: null,
  menuErrors: null,
  menuData: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

/* ------------- Selectors ------------- */
export const MenuSelectors = {
  getMenu: (state) => getImmutableAsEntity(state.menu?.menuData ?? null, Menu),
};

/* ------------- Reducers ------------- */

export const getMenuRequest = (state) => state.merge({ menuFetching: true /*, menuData: null*/ });

export const getMenuSuccess = (state, { menuData }) => {
  return state.merge({
    menuData: Array.isArray(menuData) && menuData.length > 0 ? menuData[0] : [],
    menuFetching: false,
    menuError: null,
    menuErrors: null,
  });
};

export const getMenuFailure = (state, { menuError, menuErrors }) =>
  state.merge({
    menuFetching: false,
    menuError,
    menuErrors,
  });

export const initMenuState = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MENU_REQUEST]: getMenuRequest,
  [Types.GET_MENU_SUCCESS]: getMenuSuccess,
  [Types.GET_MENU_FAILURE]: getMenuFailure,

  [Types.INIT_MENU_STATE]: initMenuState,
});
