const fonts = {
  principal: {
    name: "Oswald-VariableFont",
    font: require("../Assets/Fonts/Oswald-VariableFont_wght.ttf"),
  },
  extraLight: {
    name: "Oswald-ExtraLight",
    font: require("../Assets/Fonts/Oswald-ExtraLight.ttf"),
  },
  light: {
    name: "Oswald-Light",
    font: require("../Assets/Fonts/Oswald-Light.ttf"),
  },
  regular: {
    name: "Oswald-Regular",
    font: require("../Assets/Fonts/Oswald-Regular.ttf"),
  },
  medium: {
    name: "Oswald-Medium",
    font: require("../Assets/Fonts/Oswald-Medium.ttf"),
  },
  semiBold: {
    name: "Oswald-SemiBold",
    font: require("../Assets/Fonts/Oswald-SemiBold.ttf"),
  },
  bold: {
    name: "Oswald-Bold",
    font: require("../Assets/Fonts/Oswald-Bold.ttf"),
  },
  futuraLight: {
    name: "FuturaLight",
    font: require("../Assets/Fonts/Futura-PT-Light.ttf"),
  },
  futuraMedium: {
    name: "FuturaMedium",
    font: require("../Assets/Fonts/Futura-PT-Medium.ttf"),
  },
  futuraDemi: {
    name: "FuturaDemi",
    font: require("../Assets/Fonts/Futura-PT-Demi.ttf"),
  },
};

// \return an object with keys being the font name and their values being the font data
// This method is needed to load tham asynchronously by expo (and maybe other)
export const getFontsToLoad = (os) => {
  // pour ne pas load plusieurs fonts
  const fontsToLoad = Object.assign(...Object.entries(fonts).map(([k, v]) => ({ [v.name]: v.font })));

  return fontsToLoad;
};

export default fonts;
