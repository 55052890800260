import { put, call, select } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import OptionActions, { OptionsSelectors } from "../Redux/OptionRedux";
import { ShopSelectors } from "../Redux/ShopRedux";
import { synchronisation } from "./SynchronisationSagas";
import functions from "../Shared/functions";
import { serverTimeZone } from "../Shared/Constants";

export function* getOptions(api, { shopId, status, page }) {
  const apiCall = call(api.getOptions, { shopId, status, page });
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    const shop = yield select(ShopSelectors.getShop);
    const serverTimeZoneVal = serverTimeZone;
    yield put(OptionActions.getOptionsSuccess(response.data, shop, serverTimeZoneVal));

    yield call(getSynchOptions, api, { serverTimeZoneVal });
  } else {
    yield put(OptionActions.getOptionsFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

// sync Options
export function* getSynchOptions(api, { serverTimeZoneVal }) {
  const shop = yield select(ShopSelectors.getShop);
  if (!shop) {
    return null;
  }
  const endPointApi = "getOptionsSync";
  if (!shop.id) return;
  const params = { shopId: shop.id };

  const getSinceSelectors = OptionsSelectors.getSinceOptions;

  yield call(synchronisation, api, {
    endPointApi,
    params,
    getSinceSelectors,
    callbackFunc: function* (resultSync) {
      const optionsPagination = yield select(OptionsSelectors.getOptionsPagination);
      if (resultSync.length > 0 && !optionsPagination?.next) {
        const optionsFresh = yield select(OptionsSelectors.getOptions);

        const finalArraySync = functions.getFinalArraySync(optionsFresh, resultSync);
        const shop = yield select(ShopSelectors.getShop);

        yield put(OptionActions.getOptionsFromSyncSuccess(finalArraySync, shop, serverTimeZoneVal));
      } else if (!optionsPagination?.next) {
        yield put(OptionActions.setSinceOptions(serverTimeZoneVal));
      }
    },
  });
}
