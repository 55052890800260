import { put, call, take } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import CartActions from "../Redux/CartRedux";

// export function * addProductToCard (api, {product}) {

//   yield put(CartActions.addProductToCardSuccess(product));

//   // yield put(CategoryActions.getCategoriesFailure(getApiResponseError(response), getApiResponseErrors(response)));
// }

export function* checkCodePromotion(api, { shopModeId, code, params }) {
  const apiCall = call(api.checkCodePromotion, { shopModeId, code });
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    const promotionErrorCode = checkPromotionError(response.data, params);
    if (!promotionErrorCode) {
      yield put(CartActions.checkCodePromotionSuccess(response.data));
    } else {
      yield put(CartActions.checkCodePromotionFailure({ code: promotionErrorCode, data: response.data }, true));
    }
  } else {
    // yield put(CartActions.checkCodePromotionFailure(getApiResponseError(response), getApiResponseErrors(response)));
    yield put(CartActions.checkCodePromotionFailure(true, true));
  }
}

function checkPromotionError(promotion, params) {
  if (!promotion?.minimumAmount || promotion.minimumAmount === 0) {
    return null;
  }
  if (!params?.total || promotion.minimumAmount > params.total) {
    return 1;
  }
  return null;
}
