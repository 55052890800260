import { put, call, take } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import MenuActions from "../Redux/MenuRedux";

export function* getMenu(api, { shopId, enabled }) {
  const apiCall = call(api.getMenu, { shopId, enabled });
  const response = yield call(callApi, apiCall);

  // const response = {"duration":119,"problem":null,"originalError":null,"ok":true,"status":200,"headers":{"cache-control":"no-cache, private","content-length":"112","content-type":"application/json; charset=utf-8"},"config":{"url":"/shops/7/menuses","method":"get","headers":{"Accept":"application/json","Cache-Control":"no-cache"},"params":{"enabled":true},"baseURL":"http://141.94.64.208/api","transformRequest":[null],"transformResponse":[null],"timeout":10000,"xsrfCookieName":"XSRF-TOKEN","xsrfHeaderName":"X-XSRF-TOKEN","maxContentLength":-1,"maxBodyLength":-1,"transitional":{"silentJSONParsing":true,"forcedJSONParsing":true,"clarifyTimeoutError":false}},
  //                   "data":[{"id":4,"name":"carte de test 2","description":"Description de la carte test 2","note":"note carte de test 2"}]};
  if (response.ok) {
    yield put(MenuActions.getMenuSuccess(response.data));
  } else {
    yield put(MenuActions.getMenuFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}
