import { createNavigationContainerRef } from "@react-navigation/native";

export const navigationRef = createNavigationContainerRef();

export function navigate(name, params) {
  if (navigationRef.isReady()) {
    return navigationRef.navigate(name, params);
  }
}
export function reset(newNavigationState) {
  if (navigationRef.isReady()) {
    return navigationRef.reset(newNavigationState);
  }
}

export function getCurrentRoute() {
  if (navigationRef.isReady()) {
    return navigationRef.getCurrentRoute();
  }
}

export function getParent() {
  if (navigationRef.isReady()) {
    return navigationRef.getParent();
  }
}

export function isReady() {
  return navigationRef.isReady();
}

export function getState() {
  if (navigationRef.isReady()) {
    return navigationRef.getState();
  }
}
