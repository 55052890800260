import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import Shop from "../Logic/Entities/Shop";
import ShopMode from "../Logic/Entities/ShopMode";
import Zone from "../Logic/Entities/Zone";
import City from "../Logic/Entities/City";
import functions from "../Shared/functions";
import { dateFormatSyncInStore, serverTimeZone } from "../Shared/Constants";
import moment from "moment";
import momentTz from "moment-timezone";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getShopRequest: ["shopId"],
  getShopSuccess: ["shopData", "isForShop", "isForClosingDays", "isForOpeningHours", "isForShopFromShop"],
  getShopFailure: ["shopError", "shopErrors"],
  setSelectedShop: ["shop", "selectedMode", "selectedZone"],

  getShopTempDetailRequest: ["shopId"],
  getShopTempDetailSuccess: ["shopTempDetailData"],
  getShopTempDetailFailure: ["tempDetailError", "tempDetailErrors"],

  getShopModesRequest: ["shopId", "status", "context"],
  getShopModesSuccess: ["shopModesData", "shopId", "context"],
  getShopModesFailure: ["shopModesError", "shopModesErrors"],

  getAvailableOrderingHoursByShopRequest: ["shopId", "orderingHoursDate"],
  getAvailableOrderingHoursByShopSuccess: ["shopId", "orderingHoursDate", "orderingHours"],
  getAvailableOrderingHoursByShopFailure: ["orderingHoursError", "orderingHoursErrors"],

  setSinceShop: null,
  setSinceClosingDays: null,
  setSinceOpeningHours: null,

  initShopState: null,

  initShopStateBorne: null,

  setUserCanOrderRequest: null,
  setUserCanOrderSuccess: ["canOrder"],
});

export const ShopTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  shopFetching: false,
  shopError: null,
  shopErrors: null,
  shopData: null,
  selectedMode: false,
  selectedZone: false,
  shopTempDetailData: null,
  shopModesData: null,
  shopModesDataByShop: null,
  shopModesFetching: false,
  shopModesError: false,
  shopModesErrors: false,
  orderingHours: null,
  orderingHoursFetching: false,
  orderingHoursError: false,
  orderingHoursErrors: false,
  shopTimeZone: false,
  sinceShop: false,
  isForShop: false,
  isForClosingDays: false,
  sinceClosingDays: false,
  isForOpeningHours: false,
  sinceOpeningHours: false,
  isForShopFromShop: false,
  fetchShopModesForMenuScreenFinished: false,
  userCanOrder: false,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

function getValidateShopMode(shop, shopModes, franchise) {
  // if(shop?.licence === "light") return shopModes?.filter((mode)=>mode?.mode?.code === "on-site")?.map((mode)=> getImmutableAsEntity(mode, ShopMode));
  // return shopModes?.map((mode)=> getImmutableAsEntity(mode, ShopMode));
  return shopModes
    ?.filter((shopMode) => {
      if (shop?.licence === "light") return shopMode.mode?.code === "on-site" && shopMode.enabled;
      if (franchise?.isTemplateBorne) return shopMode.enabled && shopMode.mode?.code !== "delivery";
      return shopMode.enabled && shopMode.visible;
    })
    ?.map((mode) => getImmutableAsEntity(mode, ShopMode));
}

// ici voir le faite qu'on a enlever le test shopMode.visible sur la borne
function getShopWithEnabledMode(shop, franchise) {
  if (!shop) return null;
  const shopModes = shop.shopModes.filter((shopMode) => {
    if (franchise?.isTemplateBorne) return shopMode.enabled && shopMode.mode?.code !== "delivery";
    return shopMode.enabled && shopMode.visible;
  });
  return getImmutableAsEntity({ ...shop, shopModes }, Shop);
}

/* ------------- Selectors ------------- */
export const ShopSelectors = {
  getShop: (state) => getShopWithEnabledMode(state.shop?.shopData, state.franchise?.franchiseData),
  getSelectedMode: (state) => getImmutableAsEntity(state.shop?.selectedMode ?? null, ShopMode),
  getSelectedZone: (state) => getImmutableAsEntity(state.shop?.selectedZone ?? null, Zone),
  getShopTempDetailData: (state) => getImmutableAsEntity(state.shop?.shopTempDetailData ?? null, Shop),
  // getShopModeData: sstate => getImmutableAsEntity(state.shop?.shopModesData??null, ShopMode),
  getShopModesData: (state) => getValidateShopMode(state.shop?.shopData, state.shop?.shopModesData, state.franchise?.franchiseData), //state.shop?.shopModesData?.filter((mode)=>mode.code!=="lightmenu")?.map((mode)=> getImmutableAsEntity(mode, ShopMode)),
  getShopModesDataByShop: (state) => {
    let shopModesDataByShop = {};
    if (state.shop?.shopModesDataByShop) {
      Object.entries(state.shop?.shopModesDataByShop).forEach(([shopId, shopModes]) => {
        shopModesDataByShop = { ...shopModesDataByShop, [shopId]: getValidateShopMode(state.shop?.shopData, shopModes, state.franchise?.franchiseData) };
      });
    }
    return shopModesDataByShop !== {} ? Immutable(shopModesDataByShop) : null;
  },
  getAvailableOrderingHoursByShop: (state) => state.shop?.orderingHours?.getIn([state.shop?.shopData?.id]),
  getAvailableOrderingHoursDataByShop: (state) => state.shop?.orderingHoursData?.getIn([state.shop?.shopData?.id]),
  getShopTimeZone: (state) => state?.shop?.shopTimeZone ?? "Europe/Paris",
  getSinceShop: (state) => state?.shop?.sinceShop ?? false,
  getSinceClosingDays: (state) => state?.shop?.sinceClosingDays ?? false,
  getSinceOpeningHours: (state) => state?.shop?.sinceOpeningHours ?? false,
  shopHaseFidQoodos: (state) => !!state.shop?.shopData?.qoodosId,
  getUserCanOrder: (state) => state.shop?.userCanOrder,
  getOrderingHoursFetching: (state) => state.shop?.orderingHoursFetching,
};

/* ------------- Reducers ------------- */

export const getShopRequest = (state) => state.merge({ shopFetching: true });

export const getShopSuccess = (state, { shopData, isForShop, isForClosingDays, isForOpeningHours, isForShopFromShop }) => {
  const shopTimeZone = shopData?.timeZone;
  const sinceShop = isForShop ? functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true) : state.sinceShop;
  const sinceClosingDays = isForClosingDays || isForShopFromShop ? functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true) : state.sinceClosingDays;
  const sinceOpeningHours =
    isForOpeningHours || isForShopFromShop ? functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true) : state.sinceOpeningHours;

  const shopDataFiltred = functions.normalizeShop(shopData);
  if (shopDataFiltred.pickupHours) {
    shopDataFiltred.pickupHours = shopDataFiltred.pickupHours.filter?.((pickupHour) => {
      if (!pickupHour.enabled) {
        return false;
      }
      const openingHoursInDay = shopDataFiltred.openingHours?.filter?.((openingHour) => openingHour.enabled && openingHour.day == pickupHour.day);
      if (openingHoursInDay?.length) {
        const pickupHourInOpeningHours = openingHoursInDay?.find((openingHourElt) => {
          const startingTimeApi = openingHourElt?.startingTimeApi;
          const endingTimeApi = openingHourElt?.endingTimeApi;
          return (
            moment(pickupHour.startingTimeApi, "HH:mm:ss").isBetween(moment(startingTimeApi, "HH:mm:ss"), moment(endingTimeApi, "HH:mm:ss"), undefined, "[]") &&
            moment(pickupHour.endingTimeApi, "HH:mm:ss").isBetween(moment(startingTimeApi, "HH:mm:ss"), moment(endingTimeApi, "HH:mm:ss"), undefined, "[]")
          );
        });
        return pickupHourInOpeningHours;
      }
      return false;
    });
  }

  let syncData = {};
  if (state.selectedZone) {
    const newSelectedZone = shopDataFiltred?.zones?.find((zone) => zone.id === state.selectedZone.id);
    if (newSelectedZone) {
      syncData = { selectedZone: newSelectedZone };
    }
  }

  return state.merge({
    shopData: shopDataFiltred,
    shopFetching: false,
    shopError: null,
    shopErrors: null,
    shopTimeZone,
    sinceShop,
    sinceClosingDays,
    sinceOpeningHours,
    ...syncData,
  });
};

export const getShopFailure = (state, { shopError, shopErrors }) =>
  state.merge({
    shopFetching: false,
    shopError,
    shopErrors,
  });

export const setSinceShop = (state) => {
  const sinceShops = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({ sinceShops });
};

export const setSinceClosingDays = (state) => {
  const sinceClosingDays = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({ sinceClosingDays });
};

export const setSinceOpeningHours = (state) => {
  const sinceOpeningHours = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({ sinceOpeningHours });
};

export const setSelectedShop = (state, { selectedMode, selectedZone }) => {
  return state.merge({ shopFetching: true, selectedMode, selectedZone });
};

export const getShopTempDetailRequest = (state) => state.merge({ shopFetching: true, shopTempDetailData: null });

export const getShopTempDetailSuccess = (state, { shopTempDetailData }) => {
  return state.merge({
    shopTempDetailData: functions.normalizeShop(shopTempDetailData),
    shopFetching: false,
    tempDetailError: null,
    tempDetailErrors: null,
  });
};

export const getShopTempDetailFailure = (state, { tempDetailError, tempDetailErrors }) =>
  state.merge({
    shopFetching: false,
    tempDetailError,
    tempDetailErrors,
  });

export const getShopModesRequest = (state) => state.merge({ shopModesFetching: true, shopModesData: null, fetchShopModesForMenuScreenFinished: false });

export const getShopModesSuccess = (state, { shopModesData, shopId, context }) => {
  const oldShopModesDataByShop = state.shopModesDataByShop ?? Immutable({});
  const normalizedShopModesData = shopModesData?.map((shopMode) => functions.normalizeShopMode(shopMode, state?.shopData?.timeZone));

  const isPickupMode = state?.shopData?.shopSettings?.pickupMode;
  const filteredShopModesData =
    !isPickupMode && state?.shopData?.openingHours?.length
      ? functions.filterOrderingHoursInShopModeByOpeningHours(normalizedShopModesData, state?.shopData?.openingHours)
      : normalizedShopModesData;

  const newShopModesData = filteredShopModesData?.map?.((mode) => getImmutableAsEntity(mode, ShopMode));

  let syncData = {};
  if (state.selectedMode) {
    const newSelectedMode = newShopModesData?.find((shopMode) => shopMode.id === state.selectedMode.id);
    if (newSelectedMode) {
      syncData = { selectedMode: newSelectedMode };
    }
  }

  const fetchShopModesForMenuScreenFinished = context === "menuScreen" ? true : false;
  return state.merge({
    ...syncData,
    shopModesData: filteredShopModesData,
    shopModesDataByShop: shopId ? oldShopModesDataByShop?.set(shopId, newShopModesData) : oldShopModesDataByShop,
    shopModesFetching: false,
    shopModesError: null,
    shopModesErrors: null,
    fetchShopModesForMenuScreenFinished,
  });
};

export const getShopModesFailure = (state, { shopModesError, shopModesErrors }) =>
  state.merge({
    shopModesFetching: false,
    shopModesError,
    shopModesErrors,
  });

export const getAvailableOrderingHoursByShopRequest = (state) => state.merge({ orderingHoursFetching: true });

export const getAvailableOrderingHoursByShopSuccess = (state, { shopId, orderingHoursDate, orderingHours }) => {
  /*const oldOrderingHours = state.orderingHours??Immutable({});
  const oldShopOrderingHours = oldOrderingHours?.[shopId]??Immutable({});
  // const newShopOrderingHours = oldShopOrderingHours.set(orderingHoursDate, orderingHours);
  return state.merge({
    orderingHours: oldOrderingHours?.set(shopId, oldShopOrderingHours.set(orderingHoursDate, orderingHours)),*/

  // ce traitement c'est pour garantir que les horaires de commandes sont définie dans les horaires d'ouverture
  const dayIndex = functions.getDayIndex(orderingHoursDate, functions.apiDateFormat());
  const openingHoursInDay = state?.shopData?.openingHours?.filter?.((openingHour) => openingHour.enabled && openingHour.day == dayIndex);
  const userTimeZone = momentTz.tz.guess();
  const isPickupMode = state?.shopData?.shopSettings?.pickupMode;
  const filtredOrderingHours = Object.keys(orderingHours).reduce(
    (resultedOrderingHours, modeCode) => {
      resultedOrderingHours.justValues[modeCode] = {};
      resultedOrderingHours.withAllData[modeCode] = {};
      let slotInDayByMode = [];
      if (!isPickupMode && openingHoursInDay?.length) {
        slotInDayByMode = orderingHours?.[modeCode]?.[dayIndex]?.filter((slotData) => {
          const slotInOpeningHours = openingHoursInDay?.find((openingHourElt) => {
            const startingTimeApi =
              openingHourElt?.timeZone === userTimeZone
                ? functions.convertFromLocalTimeZoneToServerTimeZone(openingHourElt?.startingTimeApi, "HH:mm:ss", state?.shopData?.timeZone)
                : openingHourElt?.startingTimeApi;
            const endingTimeApi =
              openingHourElt?.timeZone === userTimeZone
                ? functions.convertFromLocalTimeZoneToServerTimeZone(openingHourElt?.endingTimeApi, "HH:mm:ss", state?.shopData?.timeZone)
                : openingHourElt?.endingTimeApi;
            return moment(slotData.slot, "HH:mm").isBetween(moment(startingTimeApi, "HH:mm:ss"), moment(endingTimeApi, "HH:mm:ss"), undefined, "[]");
          });
          return slotInOpeningHours && slotData.slot;
        });
      } else {
        slotInDayByMode = orderingHours?.[modeCode]?.[dayIndex];
      }
      // extraire juste les valeurs des slots sans l'information available
      const slotInDayByModeVersions = slotInDayByMode.reduce(
        (slotsVersions, slotData) => {
          const slotValue = functions.convertFromServerTimeZoneToLocalTimeZone(slotData.slot, "HH:mm", state?.shopData?.timeZone);
          slotsVersions.values.push(slotValue);
          slotData.slot = slotValue;
          slotsVersions.allData.push(slotData);
          return slotsVersions;
        },
        { values: [], allData: [] }
      );
      resultedOrderingHours.justValues[modeCode][dayIndex] = slotInDayByModeVersions.values;
      resultedOrderingHours.withAllData[modeCode][dayIndex] = slotInDayByModeVersions.allData;
      return resultedOrderingHours;
    },
    { justValues: {}, withAllData: {} }
  );

  const newOrderingHours = state.orderingHours ?? Immutable({});
  const oldOrderingHoursData = state.orderingHoursData ?? Immutable({});
  return state.merge({
    orderingHours: newOrderingHours?.setIn([shopId, orderingHoursDate], filtredOrderingHours.justValues),
    orderingHoursData: oldOrderingHoursData?.setIn([shopId, orderingHoursDate], filtredOrderingHours.withAllData),
    orderingHoursFetching: false,
    orderingHoursError: null,
    orderingHoursErrors: null,
  });
};

export const getAvailableOrderingHoursByShopFailure = (state, { orderingHoursError, orderingHoursErrors }) =>
  state.merge({
    orderingHoursFetching: false,
    orderingHoursError,
    orderingHoursErrors,
  });

export const initShopState = (state) => INITIAL_STATE;

export const initShopStateBorne = (state) => {
  return INITIAL_STATE.merge({
    shopData: state.shopData,
  });
};

// export const setUserCanOrderRequest = (state) => state.merge({ userCanOrder: false });
export const setUserCanOrderRequest = (state) => state;
export const setUserCanOrderSuccess = (state, { canOrder }) => state.merge({ userCanOrder: canOrder });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SHOP_REQUEST]: getShopRequest,
  [Types.GET_SHOP_SUCCESS]: getShopSuccess,
  [Types.GET_SHOP_FAILURE]: getShopFailure,
  [Types.SET_SELECTED_SHOP]: setSelectedShop,

  [Types.GET_SHOP_TEMP_DETAIL_REQUEST]: getShopTempDetailRequest,
  [Types.GET_SHOP_TEMP_DETAIL_SUCCESS]: getShopTempDetailSuccess,
  [Types.GET_SHOP_TEMP_DETAIL_FAILURE]: getShopTempDetailFailure,

  [Types.GET_SHOP_MODES_REQUEST]: getShopModesRequest,
  [Types.GET_SHOP_MODES_SUCCESS]: getShopModesSuccess,
  [Types.GET_SHOP_MODES_FAILURE]: getShopModesFailure,

  [Types.GET_AVAILABLE_ORDERING_HOURS_BY_SHOP_REQUEST]: getAvailableOrderingHoursByShopRequest,
  [Types.GET_AVAILABLE_ORDERING_HOURS_BY_SHOP_SUCCESS]: getAvailableOrderingHoursByShopSuccess,
  [Types.GET_AVAILABLE_ORDERING_HOURS_BY_SHOP_FAILURE]: getAvailableOrderingHoursByShopFailure,

  [Types.SET_SINCE_SHOP]: setSinceShop,
  [Types.SET_SINCE_CLOSING_DAYS]: setSinceClosingDays,
  [Types.SET_SINCE_OPENING_HOURS]: setSinceOpeningHours,

  [Types.INIT_SHOP_STATE]: initShopState,
  [Types.INIT_SHOP_STATE_BORNE]: initShopStateBorne,

  [Types.SET_USER_CAN_ORDER_REQUEST]: setUserCanOrderRequest,
  [Types.SET_USER_CAN_ORDER_SUCCESS]: setUserCanOrderSuccess,
});
