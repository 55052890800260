import React, { Component } from "react";
import { Provider } from "react-redux";
import createStore from "./Redux";
import IndexContainer from "./IndexContainer";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Secrets from "../config.env";
// import * as Sentry from 'sentry-expo';

// Sentry.init({
//   dsn: 'https://ddf26d4894fa43afafa8974b8429a9be@o135926.ingest.sentry.io/6145225',
//   enableInExpoDevelopment: false,
//   debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
// });

// create our store
const store = createStore();
window.storeData = store;

class App extends Component {
  // componentDidMount () {
  //   // Intercept react-native error handling
  //   this.defaultHandler = ErrorUtils.getGlobalHandler()
  //   ErrorUtils.setGlobalHandler(this.wrapGlobalHandler.bind(this))
  // }

  // async wrapGlobalHandler (error, isFatal) {
  //   // If the error kills our app in Release mode, make sure we don't rehydrate
  //   // with an invalid Redux state and cleanly go back to login page instead
  //   if (isFatal && !__DEV__) AsyncStorage.clear()

  //   // Once finished, make sure react-native also gets the error
  //   if (this.defaultHandler) this.defaultHandler(error, isFatal)
  // }

  render() {
    return (
      <GoogleOAuthProvider clientId={Secrets.GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <IndexContainer />
        </Provider>
      </GoogleOAuthProvider>
    );
  }
}

// allow reactotron overlay for fast design in dev mode
export default App;
