import L from "../I18n/tr";

export const getApiResponseError = (apiResponse) => {
  let errorMessage = null;
  if (isAnInternetOrConnexionProblem(apiResponse)) {
    errorMessage = "noInternet";
  } else if (apiResponse.data?.violations) {
    errorMessage = apiResponse.data.violations;
  } else if (apiResponse.data?.error) {
    errorMessage = apiResponse.data.error;
  } else if (apiResponse.data?.detail) {
    errorMessage = apiResponse.data.detail;
  } else if (apiResponse.data?.title) {
    errorMessage = apiResponse.data.title;
  } else if (apiResponse.data?.message) {
    errorMessage = apiResponse.data.message;
  }

  if (errorMessage?.includes?.("\n")) {
    errorMessage = errorMessage.split("\n");
  }
  return errorMessage;
};

function isAnInternetOrConnexionProblem(response) {
  return response.problem === "TIMEOUT_ERROR" ||
        response.problem === "CONNECTION_ERROR" ||
        response.problem === "NETWORK_ERROR" ||
        response.problem === "CANCEL_ERROR";
}

export const getApiResponseErrors = (apiResponse) => {
  return apiResponse.data?.errors ?? null;
};
