import React from "react";
import { TEMPLATE2_NAME_CODE } from "../Constants";
function GoogleSvg(props) {
  if (props.template_name_code === TEMPLATE2_NAME_CODE) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="35" height="35">
        <path
          fill="var(--bs-primary)"
          d="M14.003 12.305L14.003 15.984L19.244 15.984C18.559 18.21 16.696 19.807 14.003 19.807C10.796 19.807 8.196 17.206 8.196 14C8.196 10.794 10.794 8.193 14.003 8.193C15.445 8.193 16.762 8.723 17.777 9.595L20.484 6.885C18.775 5.325 16.499 4.375 14.003 4.375C8.685 4.375 4.375 8.685 4.375 14C4.375 19.315 8.685 23.625 14.003 23.625C22.084 23.625 23.867 16.068 23.074 12.319Z"
        />
      </svg>
    );
  }
  return (
    <svg fill="var(--bs-primary)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="40px" height="40px">
      <path d="M 16.003906 14.0625 L 16.003906 18.265625 L 21.992188 18.265625 C 21.210938 20.8125 19.082031 22.636719 16.003906 22.636719 C 12.339844 22.636719 9.367188 19.664063 9.367188 16 C 9.367188 12.335938 12.335938 9.363281 16.003906 9.363281 C 17.652344 9.363281 19.15625 9.96875 20.316406 10.964844 L 23.410156 7.867188 C 21.457031 6.085938 18.855469 5 16.003906 5 C 9.925781 5 5 9.925781 5 16 C 5 22.074219 9.925781 27 16.003906 27 C 25.238281 27 27.277344 18.363281 26.371094 14.078125 Z" />
    </svg>
  );
}

export default GoogleSvg;
