import Immutable from "seamless-immutable";
import functions from "../../Shared/functions";
import BaseEntity from "./BaseEntity";

export default class SpecialOffer extends BaseEntity {
  constructor(props) {
    super(props);
    props && Object.assign(this, Immutable(props));
  }

  getValueAsString(selectedShop) {
    if (this.type.toLowerCase() === "pourcentage") return `${functions.roundNumber(this.value * 100)}%`;
    return functions.formatPrice(functions.roundNumber(this.value), selectedShop);
  }
}
