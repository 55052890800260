// fake localStorage - i use this with edux-persist when localStorage/localForage isn't available
class FakeLocalStorage {
  constructor() {
    this.storage = {}
  }

  setItem(key, value, callback) {
    return new Promise((resolve, reject) => {
      this.storage[key] = value;
      if (callback) callback(null, value);
      resolve(value);
    })
  }

  getItem(key, callback) {
    return new Promise((resolve, reject) => {
      const value = this.storage[key];
      if (callback) callback(null, value);
      resolve(value);
    })
  }

  removeItem(key, callback) {
    return new Promise((resolve, reject) => {
      const value = this.storage[key];
      delete this.storage[key];
      if (callback) callback(null, value);
      resolve(value);
    })
  }

  getAllKeys(callback) {
    return new Promise((resolve, reject) => {
      const keys = Object.keys(this.storage);
      if (callback) callback(null, keys);
      resolve(keys);
    })
  }
}

export default new FakeLocalStorage;