import React from "react";
import { TEMPLATE2_NAME_CODE } from "../Constants";

function InstagramSvg(props) {
  if (props.template_name_code === TEMPLATE2_NAME_CODE) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="var(--bs-primary)">
        <defs>
          <clipPath id="instagram-clip-path">
            <rect width="23" height="23" transform="translate(245 279)" />
          </clipPath>
        </defs>
        <g transform="translate(-245 -279)" clipPath="url(#instagram-clip-path)">
          <g transform="translate(245 279)">
            <path d="M11.5,2.072c3.071,0,3.435.012,4.647.067A5.073,5.073,0,0,1,19.6,3.4a5.04,5.04,0,0,1,1.256,3.458c.056,1.212.067,1.576.067,4.647s-.011,3.435-.067,4.647A5.1,5.1,0,0,1,19.6,19.6a5.039,5.039,0,0,1-3.458,1.256c-1.212.056-1.576.067-4.647.067s-3.435-.011-4.647-.067A5.135,5.135,0,0,1,3.4,19.6a5.068,5.068,0,0,1-1.256-3.458c-.056-1.212-.067-1.576-.067-4.647s.012-3.435.067-4.647A5.116,5.116,0,0,1,3.4,3.4,5.051,5.051,0,0,1,6.853,2.139c1.212-.056,1.576-.067,4.647-.067M11.5,0C8.377,0,7.985.013,6.758.069A7.107,7.107,0,0,0,1.93,1.93,7.1,7.1,0,0,0,.069,6.758C.013,7.985,0,8.377,0,11.5s.013,3.515.069,4.742A7.115,7.115,0,0,0,1.93,21.07a7.1,7.1,0,0,0,4.828,1.861C7.985,22.987,8.377,23,11.5,23s3.515-.013,4.742-.069A7.112,7.112,0,0,0,21.07,21.07a7.092,7.092,0,0,0,1.861-4.828c.056-1.227.069-1.619.069-4.742s-.013-3.515-.069-4.742A7.11,7.11,0,0,0,21.07,1.93,7.108,7.108,0,0,0,16.242.069C15.015.013,14.623,0,11.5,0Z" />
            <path d="M11.5,5.595A5.905,5.905,0,1,0,17.405,11.5,5.905,5.905,0,0,0,11.5,5.595Zm0,9.739A3.833,3.833,0,1,1,15.333,11.5,3.834,3.834,0,0,1,11.5,15.333Z" />
            <circle cx="1.38" cy="1.38" r="1.38" transform="translate(16.259 3.981)" />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg fill="var(--bs-primary)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="40px" height="40px">
      {" "}
      <path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z" />
    </svg>
  );
}

export default InstagramSvg;
