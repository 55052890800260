import ReduxPersist from "../Config/ReduxPersist";
import { persistStore } from "redux-persist";
import StartupActions from "../Redux/StartupRedux";
import DebugConfig from "../Config/DebugConfig";
import LocalStorage from "../Shared/LocalStorage";

const updateReducers = (store) => {
  const reducerVersion = ReduxPersist.reducerVersion;
  // once store is rehydrated, called this function
  const startup = () => store.dispatch(StartupActions.startup());

  // Check to ensure latest reducer version
  LocalStorage.getItem("reducerVersion")
    .then((localVersion) => {
      if (localVersion !== reducerVersion) {
        if (DebugConfig.useReactotron) {
          console.tron.display({
            name: "PURGE",
            value: {
              "Old Version:": localVersion,
              "New Version:": reducerVersion,
            },
            preview: "Reducer Version Change Detected",
            important: true,
          });
        }
        // Purge store
        persistStore(store, null, startup).purge();
        LocalStorage.setItem("reducerVersion", reducerVersion);
      } else {
        persistStore(store, null, startup);
      }
    })
    .catch(() => {
      persistStore(store, null, startup).purge();
      LocalStorage.setItem("reducerVersion", reducerVersion);
    });
};

export default { updateReducers };
