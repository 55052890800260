import Immutable from "seamless-immutable";

export default class BaseEntity {
  constructor(props) {}

  getImmutableAsObj(immutable) {
    if (!immutable) return null;
    const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
    return entityObj;
  }
}
