// api url prod ==> http://57.128.85.179
// api url staging ==> https://staging-foxorders.fr

const isDevOrStaging = false; //process.env.NODE_ENV === "development";
const DEV_BASE_API_URL = "https://staging-foxorders.fr/";
const PROD_BASE_API_URL = "https://foxorders.fr/";
const DEV_API_URL = `${DEV_BASE_API_URL}api/v2`;
const PROD_API_URL = `${PROD_BASE_API_URL}api/v2`;
// const TOKEN_API_DEV = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2ODkxNDk4MzYsImV4cCI6MTY5Mjc0OTgzNiwicm9sZXMiOlsiUk9MRV9BUEkiXSwidXNlcm5hbWUiOiJhcGlAZm94b3JkZXJzLmNvbSIsImlwIjoiMTk2LjExNy4yMy4xNDYifQ.jwCFx4RVBm9oR1084WTOPsigRqeykUXNUpc4dmPd61K0v6ak_QiGYRHgMxwccUKb_3laYsDQX5oPqAcsM_7sH2aOi9-ROI4TKfxr8KuuTYaDLoaQ3d0KuiEmYLdotDmgE6FpJYqgwgDA1CFj7F0HnLUJMlgRnZi5q8AqAVEQDlJETGUxhUconD6dFwvnlf6VtjlcEG7k-HTdzJ9iHWgRc1jESe6yvUtv6SCI1tN2EmDYe0uytYvsfZcBlXQ5Qt4wcRlwjRf_aQJg-CC7bWD6w9_2OMEf5Yd0tN-uIbfzej140faru7Kh2nptBH0JwZjTwECk6-bfjBbET4BKQMYxsg";
// const TOKEN_API_DEV = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2ODU1MjAwMjYsImV4cCI6MTY4OTEyMDAyNiwicm9sZXMiOlsiUk9MRV9BUEkiXSwidXNlcm5hbWUiOiJhcGlAZm94b3JkZXJzLmNvbSIsImlwIjoiMTk2LjExNy4xMTkuMTYzIn0.f-MeVSZJOzXWStS2l-byoDShId9lTZbQnvreTzw_yvWnBgcvwqoCjZzWvnuHcdf2KRMvAdy5rDHZCvAHHnwVKFRcLB6NevS44blatxTw3U8-CNyq7mqCnlAVCgC1K3D7h_nLEXiX-ChWlf2glqctLAY9j_MWuY9gwB9LJ2RgEeBOAwAPcLUYdBnpdsmk7m2TyBfWtqLRzfRAEjsMmZqi8GeYQUjOzcNhA1HZQbaELCoQpvX9gh3Q3eH8uE_Tv4bGahERAAqviRSka1f0kRlv8YKvTxSijK1zBDxsz16Qmwj1VjoAaE-dYTDBWwIfNa8TyeB5_ufHgPxdboOogbPjcw";
// const TOKEN_API_PROD = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2ODkxNDkyNTksImV4cCI6MTY5Mjc0OTI1OSwicm9sZXMiOlsiUk9MRV9BUEkiXSwidXNlcm5hbWUiOiJhcGlAZm94b3JkZXJzLmNvbSIsImlwIjoiMTk2LjExNy4yMy4xNDYifQ.vAUbGlD5MTizoS04Wsw7cAlJJLSpa8E6s_NG8ubTzRwXf1kLD1_v5bl7eUfnpZ1vPYIhnYrM0gEaXYcgNs0q2DriQ_7-Ktr8aZlUJwinN0uhMO62nMZwMkwNCoKmBBA6rvtIczHcOi0KyDkGwuSEqIUa6x-w1rlVt5nkzJUS6yPkR2zP-zW9_QowpESfQ0FNzZ5tovGkaOeRr34dTvkNyuw8lgUjOb1Osgnn26XdtS1brmq9CXZ4CcgD9uYYpRYKftx-vGka7FlcDRiuE-tLcjWkOz6wkktAP6em2ptqtZX8GoZBJirOMClhkWZztIFFUtSpHTNyaxxLRkhjvVaGBw";
const PAYGREENJS_URI_DEV = "https://sb-pgjs.paygreen.fr/latest/paygreen.min.js";
const PAYGREENJS_URI_PROD = "https://pgjs.paygreen.fr/latest/paygreen.min.js";
const PAYGREENCSS_URI_DEV = "https://sb-pgjs.paygreen.fr/latest/paygreen.min.css";
const PAYGREENCSS_URI_PROD = "https://pgjs.paygreen.fr/latest/paygreen.min.css";

const defaultConfigByVariant = {
  default: {
    LOCAL_API_URL: isDevOrStaging ? DEV_API_URL : PROD_API_URL,
    BASE_API_URL: isDevOrStaging ? DEV_BASE_API_URL : PROD_BASE_API_URL,
    // TOKEN_API: isDevOrStaging ? TOKEN_API_DEV : TOKEN_API_PROD,
    IS_DEV_OR_STAGING: isDevOrStaging,
    PAYMENTS_DATA: {
      PAYXPERT: {
        PAYXPERT_PAYMENT_URL: "https://connect2.payxpert.com",
        ORIGINATORID: "108503",
        API_KAY: "h1Z164!.8x'<-faf",
      },
      STRIPE: {
        STRIPE_APIKEY: "sk_test_51IGSXMH7bonu8LHPNZtzIeZIPQ8w0GNW8gZMeXRl4xDmKjIcMGNCn2EsCjkmLzmMQrJhPbelXkUN9tJFJe0l9x4Z005W1kOEHf",
        STRIPE_PUBLISHKEY: "pk_test_51IGSXMH7bonu8LHP55NtG6C7IOx8iLTZSTIIDKwEmvoVMHz6zp8zZKHN2h6hGVdQBzL3eB7bXU8iibs85jmGQxWd00MswtBDRL",
        STRIPE_SIGNING_SECRET: "whsec_ZFUV29vYuJPhMrxE7JXbrSYN0Sl0Wf4T",
      },
      PAYGREEN: {
        IDENTIFIANT: "77be82b367211cc67aeb3767d97fe379",
        PRIVATE_KAY: "6372-4b12-a4ca-505261638fd1",
      },
    },
    GOOGLE_MAP_API_Key: "AIzaSyDNDoOXDm_tTzynWJNW4URQiqWccjctzNA",
    //app id
    PAYGREENJS_URI: isDevOrStaging ? PAYGREENJS_URI_DEV : PAYGREENJS_URI_PROD,
    PAYGREENCSS_URI: isDevOrStaging ? PAYGREENCSS_URI_DEV : PAYGREENCSS_URI_PROD,
    APPLE_CLIENT_ID: "fr.foxorders.services.id",
    APPLE_REDIRECT_URI: "https://mts.staging-foxorders.fr",
    GOOGLE_CLIENT_ID: isDevOrStaging
      ? "1049223902070-89pp2p0vvree9f5ttt3fbbska35pbnb7.apps.googleusercontent.com"
      : "1049223902070-m7d1op14t3ji7svjllp751iqlsuhm4qe.apps.googleusercontent.com",
  },
};
export default defaultConfigByVariant.default;
