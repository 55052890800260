import { put, take, call, select, race } from "redux-saga/effects";
import LoginActions from "../Redux/LoginRedux";
import StartupActions, { StartupSelectors, StartupTypes } from "../Redux/StartupRedux";
import { LoginTypes, LoginSelectors } from "../Redux/LoginRedux";
import { isNil } from "ramda";
import Secrets from "../../config.env";
// import * as Sentry from 'sentry-expo';
import { ShopSelectors } from "../Redux/ShopRedux";
import FranchiseActions, { FranchiseSelectors } from "../Redux/FranchiseRedux";
import L from "../I18n/tr";
import { modal } from "../TemplateBorne/Shared/SagaModalComponent";
import { DEVICE_DISABLED } from "../Shared/Constants";
import functions from "../Shared/functions";

// this saga is used for reconnecting user when we got
// an authentication error. It could mean that we need to refresh the token.
// If the error occurred twice, it means this is a credential issue
// and not the need to refresh the access token.
// In that case, it goes to the LoginScreen
// if login is successful, it will reattempt the request

function isBadToken(resp) {
  // which status is returned in case of bad token
  const isGoogleAuth = resp?.config?.url && resp?.config?.url === "customers/authenticate/google";
  const isAppleAuth = resp?.config?.url && resp?.config?.url === "customers/authenticate/apple";
  return resp.status === 401 && !isGoogleAuth && !isAppleAuth;
}

// response.problem : The problem property on responses is filled with the best guess on where the problem lies. You can use a switch to check the problem.
// The values are exposed as CONSTANTS hanging on your built API.
// Constant        VALUE               Status Code   Explanation
// ----------------------------------------------------------------------------------------
// NONE             null               200-299       No problems.
// CLIENT_ERROR     'CLIENT_ERROR'     400-499       Any non-specific 400 series error.
// SERVER_ERROR     'SERVER_ERROR'     500-599       Any 500 series error.
// TIMEOUT_ERROR    'TIMEOUT_ERROR'    ---           Server didn't respond in time.
// CONNECTION_ERROR 'CONNECTION_ERROR' ---           Server not available, bad dns.
// NETWORK_ERROR    'NETWORK_ERROR'    ---           Network not available.
// CANCEL_ERROR     'CANCEL_ERROR'     ---           Request has been cancelled. Only possible if `cancelToken` is provided in config, see axios `Cancellation`.

function isExcludedCallToDeconnexion(response) {
  const IS_TIMEOUT_ERROR = response.problem === "TIMEOUT_ERROR";
  const IS_CONNECTION_ERROR = response.problem === "CONNECTION_ERROR";
  const IS_NETWORK_ERROR = response.problem === "NETWORK_ERROR";
  const IS_CANCEL_ERROR = response.problem === "CANCEL_ERROR";
  // const isEcludedNetErr = (response.problem); // see doc above
  return IS_TIMEOUT_ERROR || IS_CONNECTION_ERROR || IS_NETWORK_ERROR || IS_CANCEL_ERROR;
}

function* waitForLoginResult() {
  const action = yield take([LoginTypes.LOGIN_SUCCESS, LoginTypes.LOGIN_FAILURE]);

  if (isNil(action) || action.type === LoginTypes.LOGIN_FAILURE) {
    return yield put(LoginActions.logoutRequest());
  }
}

function isASyncOperation(response, apiCallPayload) {
  const isASyncUrl = new RegExp(".*/sync/");
  if (isASyncUrl.test(response.config?.url)) {
    return true;
  }
  const apiFunctionName = apiCallPayload.fn?.name;
  return (
    (apiFunctionName === "getShopModes" && apiCallPayload.args?.[0]?.context === "forSync") ||
    (apiFunctionName === "getUser" && apiCallPayload.args?.[1] === "forSync") ||
    (apiFunctionName === "getDevices" && apiCallPayload.args?.[0] === "forSync")
  );
}

export default function* callApi(apiCall) {
  const isGeneratingOrGetingTokenFetching = yield select(StartupSelectors.isGeneratingOrGetingTokenFetching);
  const apiFunctionName = apiCall?.payload?.fn?.name;
  if (isGeneratingOrGetingTokenFetching && apiFunctionName !== "generateToken" && apiFunctionName !== "getGeneratedToken") {
    //take pas obligatoire
    yield take([
      StartupTypes.GENERATE_TOKEN_SUCCESS,
      StartupTypes.GENERATE_TOKEN_FAILURE,
      StartupTypes.GET_GENERATED_TOKEN_SUCCESS,
      StartupTypes.GET_GENERATED_TOKEN_FAILURE,
    ]);
    // return {ok: false};
    return yield apiCall;
  }
  const response = yield apiCall;
  // if (!response?.ok && response?.data?.code === 'DEVICE_DISABLED') {
  if (!response?.ok && response?.data?.detail === DEVICE_DISABLED) {
    const selectedShop = yield select(ShopSelectors.getShop);
    if (selectedShop) {
      yield put(FranchiseActions.getDevicesRequest(selectedShop));
    }
  }
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const isBorne = franchise?.isTemplateBorneFn();
  if (isBorne && isExcludedCallToDeconnexion(response) && !isASyncOperation(response, apiCall?.payload)) {
    // isExcludedCallToDeconnexion : true => problème de connexion/internet
    yield modal.show({
      title: L("dataError"),
      text: L("anOperationFailedDueToAConnectionProblem_PCICATA"),
      positifBtnText: L("tryAgain"),
      // negatifBtnText: L("no"),
    });
    const [submit] = yield race([modal.takeSubmit(), modal.takeHide()]);
    yield modal.hide();
    // if (submit?.meta?.name === 'confirmed') {
    if (!!submit) {
      return yield call(callApi, apiCall);
    }
    return response;
  }

  if (!isBadToken(response)) {
    return response;
  }
  const functionsUsesApiToken = [
    "login",
    "signUp",
    "resetPasswordInit",
    "checkResetPasswordToken",
    "resetPassword",
    "getFranchise",
    "getShop",
    "contactUs",
    "getCities",
    "getMenu",
    "getCategories",
    "getProducts",
    "getOptions",
    "getOptionsByProduct",
    "getShopModes",
    "getAvailableOrderingHoursByShop",
    "checkCodePromotion",
    "getClosingDaysSync",
    "getOpeningHoursSync",
    "getShopsSync",
    "getCategoriesSync",
    "getProductsSync",
    "getOptionsSync",
    "searchCustomerByFranchise",
    "shareUserDataToFranchise",
    "getLocales",
    "getDevices",
    "sendPaymentResult",
    "googleAuth",
    "appleAuth",

  ];
  const authObj = yield select(LoginSelectors.getAuthentication);
  if (!authObj) {
    functionsUsesApiToken.concat(["addOrder", "createPayment", "updatePaymentIntent", "paymentWithPaygreen", "paymentWithNeptingRetail"]);
  }
  if (functionsUsesApiToken.includes(apiFunctionName)) {
    // s'assurer d'avoir le dernier token avant de le regérer; car il se peut que le nouveau token est généré par un autre user
    yield put(StartupActions.getGeneratedTokenRequest(Secrets.IS_DEV_OR_STAGING ? "staging" : "prod"));
    const getGeneratedTokenAction = yield take([StartupTypes.GET_GENERATED_TOKEN_SUCCESS, StartupTypes.GET_GENERATED_TOKEN_FAILURE]);
    if (getGeneratedTokenAction.type === StartupTypes.GET_GENERATED_TOKEN_SUCCESS) {
      const response = yield apiCall;
      if (!isBadToken(response)) {
        return response;
      }
    }
    return yield call(doActionsApiToken, apiCall, response);
  } else {
    return yield doActionsUserToken(apiCall, response);
  }
}

function* doActionsApiToken(apiCall, apiCallResponse) {
  yield put(StartupActions.generateTokenRequest(Secrets.IS_DEV_OR_STAGING ? "staging" : "prod"));
  const generateTokenAction = yield take([StartupTypes.GENERATE_TOKEN_SUCCESS, StartupTypes.GENERATE_TOKEN_FAILURE]);
  if (generateTokenAction.type === StartupTypes.GENERATE_TOKEN_SUCCESS) {
    yield put(StartupActions.getGeneratedTokenRequest(Secrets.IS_DEV_OR_STAGING ? "staging" : "prod"));
    const getGeneratedTokenAction = yield take([StartupTypes.GET_GENERATED_TOKEN_SUCCESS, StartupTypes.GET_GENERATED_TOKEN_FAILURE]);
    if (getGeneratedTokenAction.type === StartupTypes.GET_GENERATED_TOKEN_SUCCESS) {
      // return yield call(callApi,apiCall);
      return yield apiCall;
    }
  }
  return apiCallResponse;
}

function* doActionsUserToken(apiCall, apiCallResponse) {
  const stayConnected = yield select(LoginSelectors.getStayConnectedValue);
  // si l'utilisateur ne souhaite pas le garder connecter on ne tente pas de refraichire son token automatiquement.
  if (!stayConnected) {
    if (apiCallResponse.data?.code === 401) {
      const isGeneratingOrGetingTokenFetching = yield select(StartupSelectors.isGeneratingOrGetingTokenFetching);
      if (isGeneratingOrGetingTokenFetching) {
        // return {ok: false};
        return yield apiCall;
      }
    }
    return yield put(LoginActions.logoutRequest());
  }

  const lockRefreshToken = yield select(LoginSelectors.getLockRefreshToken);

  if (lockRefreshToken) {
    yield call(waitForLoginResult);
    return yield call(callApi, apiCall);
  }

  const credentials = yield select(LoginSelectors.getCredentials);
  if (isNil(credentials)) {
    if (__DEV__ && console.tron) {
      console.tron.log({
        error: "Bad state in app: no credentials in CallApiSaga." + "Could not get credentials from state",
        credentials,
      });
    }
    // Bad state in app: no credentials in CallApiSaga
    return yield put(LoginActions.logoutRequest());
  }

  const { username, password } = credentials;
  const auth = yield select(LoginSelectors.getAuthentication);

  if (auth && auth.refresh_token) {
    const refreshToken = auth.refresh_token;

    const credentials = yield select(LoginSelectors.getCredentials);
    const callLogin = yield put(LoginActions.loginRequest(username, password, false, refreshToken, true, credentials?.franchiseId));

    yield call(waitForLoginResult);

    const response2 = yield apiCall;

    if (!isBadToken(response2) || isExcludedCallToDeconnexion(response2)) {
      return response2;
    }
    // Failed to access protected data after successful re-login.
    return yield put(LoginActions.logoutRequest());
  } else {
    yield put(LoginActions.loginRequest(username, password));

    yield call(waitForLoginResult);

    const response3 = yield apiCall;

    if (!isBadToken(response3) || isExcludedCallToDeconnexion(response3)) {
      return response3;
    }

    // Failed to access protected data after successful re-login.
    return yield put(LoginActions.logoutRequest());
  }

  // Sentry.setUserContext({email: username});
}
