import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";

export default class Color extends BaseEntity {
  constructor(props) {
    super(props);
    props && Object.assign(this, Immutable(props));
  }

  getColor() {
    return { [this.colorName.name]: this.value };
  }
}
