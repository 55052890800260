import { sagaModal, createModal } from "redux-saga-modal";
import ModalShared from "./ModalShared";
import { Row } from "react-bootstrap";

const Confirm = ({ isOpen, submit, hide, title, text, positifBtnText, negatifBtnText }) => {
  const handleSubmit = () => {
    submit();
  };

  function renderYesBtn() {
    if (!positifBtnText) {
      return null;
    }
    return (
      <Row className="">
        <button className="btn btn-primary text-white w-100 fs-3 fw-bold rounded-0-50 border-0 text-uppercase" onClick={() => handleSubmit()}>
          {`${positifBtnText}`}
        </button>
      </Row>
    );
  }

  function renderNoBtn() {
    if (!negatifBtnText) {
      return null;
    }
    return (
      <Row className="">
        <button className="btn bg-secondary text-white w-100 fs-3 fw-bold rounded-0-50 border-0 text-uppercase" onClick={() => hide()}>
          {`${negatifBtnText}`}
        </button>
      </Row>
    );
  }

  function renderFooterComponnent() {
    return (
      <Row className="w-100">
        {renderYesBtn()}
        {renderNoBtn()}
      </Row>
    );
  }

  function confirmModal() {
    return (
      <ModalShared
        show={isOpen}
        title={title}
        large
        footerComponnent={renderFooterComponnent}
        hideHeader={true}
        classNameData="bg-white-modal"
        // hideFooter={true}
      >
        <div style={{ whiteSpace: "pre-wrap" }} className="text-primary fs-1 text-center text-uppercase fw-bolder p-4">
          {text}
        </div>
      </ModalShared>
    );
  }

  return confirmModal();
};

const modal = createModal("confirm");

const ConnectedConfirmModal = sagaModal({
  name: modal.name,
})(Confirm);

export { modal, ConnectedConfirmModal };
