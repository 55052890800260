import L from "../../I18n/tr";

const takeAway = (sizeDim = 20) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={sizeDim} height={sizeDim} viewBox={`0 0 16 16`}>
    <defs>
      <clipPath id="take-away-clip-path">
        <rect width={sizeDim} height={sizeDim} transform="translate(124 502)" />
      </clipPath>
    </defs>
    <g fill="currentColor" transform="translate(-124 -502)" clipPath="url(#take-away-clip-path)">
      <path
        d="M14,4H12A4,4,0,1,0,4,4H2A2,2,0,0,0,0,6v6.667A3.337,3.337,0,0,0,3.333,16h9.333A3.337,3.337,0,0,0,16,12.667V6A2,2,0,0,0,14,4ZM8,1.333A2.667,2.667,0,0,1,10.667,4H5.333A2.667,2.667,0,0,1,8,1.333Zm6.667,11.333a2,2,0,0,1-2,2H3.333a2,2,0,0,1-2-2V6A.667.667,0,0,1,2,5.333H4V6.667a.667.667,0,0,0,1.333,0V5.333h5.333V6.667a.667.667,0,1,0,1.333,0V5.333h2A.667.667,0,0,1,14.667,6Z"
        transform="translate(124 502)"
      />
    </g>
  </svg>
);

const onSite = (sizeDim = 20) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={sizeDim} height={sizeDim} viewBox={`0 0 16 16`}>
    <defs>
      <path id="on-site-clip-path">
        <rect width={sizeDim} height={sizeDim} transform="translate(131 502)" />
      </path>
    </defs>
    <g fill="currentColor" transform="translate(-131 -502)" clipPath="url(#on-site-clip-path)">
      <path
        d="M8,.667v4A3.339,3.339,0,0,1,5.333,7.933v7.4a.667.667,0,0,1-1.333,0v-7.4A3.339,3.339,0,0,1,1.333,4.667v-4a.667.667,0,1,1,1.333,0v4A2,2,0,0,0,4,6.544V.667a.667.667,0,0,1,1.333,0V6.544A2,2,0,0,0,6.667,4.667v-4A.667.667,0,1,1,8,.667Zm6.667,6a8.427,8.427,0,0,1-3.333,6.517v2.15a.667.667,0,0,1-1.333,0v-14A1.267,1.267,0,0,1,10.754.145a1.463,1.463,0,0,1,1.571.306,8.983,8.983,0,0,1,2.342,6.216Zm-1.333,0a7.8,7.8,0,0,0-2-5.291v10A6.867,6.867,0,0,0,13.333,6.667Z"
        transform="translate(130.999 502)"
      />
    </g>
  </svg>
);

const delivery = (sizeDim = 20) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={sizeDim} height={sizeDim} viewBox="0 0 16 16">
    <defs>
      <clipPath id="delivery-mode-clip-path">
        <rect width={sizeDim} height={sizeDim} transform="translate(134 501)" strokeWidth="1" />
      </clipPath>
    </defs>
    <g fill="currentColor" transform="translate(-134 -501)" clipPath="url(#delivery-mode-clip-path)">
      <path
        d="M3.333,8.667A3.333,3.333,0,1,0,6.667,12,3.333,3.333,0,0,0,3.333,8.667Zm0,5.333a2,2,0,1,1,2-2A2,2,0,0,1,3.333,14Zm9.333-5.333A3.333,3.333,0,1,0,16,12a3.333,3.333,0,0,0-3.333-3.333Zm0,5.333a2,2,0,1,1,2-2A2,2,0,0,1,12.667,14ZM6.909,7.518a.659.659,0,0,1-.034-.971C6.988,6.44,8.644,4.92,8.644,4.92l2.218,2.218A.667.667,0,1,0,11.8,6.2L9.138,3.529a.641.641,0,0,0-.083-.071,4.3,4.3,0,0,0-2.44-.791c-.935,0-3.282.793-3.282,2.667a2,2,0,0,0,2,2c.016,0,.031,0,.046,0a1.993,1.993,0,0,0,.662,1.2l1.292,1.11V12a.667.667,0,1,0,1.333,0V9.333a.667.667,0,0,0-.233-.506ZM4.667,5.333C4.667,4.524,6.115,4,6.615,4a2.8,2.8,0,0,1,.893.151L5.946,5.591C5.692,5.833,5.483,6,5.333,6A.667.667,0,0,1,4.667,5.333ZM9.333,1.667A1.667,1.667,0,1,1,11,3.333,1.667,1.667,0,0,1,9.333,1.667Z"
        transform="translate(134 501.333)"
      />
    </g>
  </svg>
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const deliveriesModes = {
  //https://developer.mozilla.org/fr/docs/Web/JavaScript/Guide/Working_with_objects#d%C3%A9finir_des_accesseurs_et_des_mutateurs_getters_et_setters
  get "take-out"() {
    return { name: capitalizeFirstLetter(L("takeAway")), icon: takeAway };
  },
  get delivery() {
    return { name: capitalizeFirstLetter(L("delivery")), icon: delivery };
  },
  get "on-site"() {
    return { name: capitalizeFirstLetter(L("onSite")), icon: onSite };
  },
};

export const mappingDeliveriesModesByLink = {
  takeAway: "take-out",
  delivery: "delivery",
  onSite: "on-site",
};
// deliveriesModesLinksByCode is deducted by swaping key with value in mappingDeliveriesModesByLink object
export const deliveriesModesLinksByCode = Object.entries(mappingDeliveriesModesByLink).reduce((r, [k, v]) => ((r[v] = k), r), {});

// takeAway "Vente à emporter"
// delivery "Vente en livraison"
// onSite "Vente sur place"
