import i18n from "i18n-js";
export default (key, options = {}) => i18n.t(key, { defaultValue: (key) => key.split(/(?=[A-Z])/).join(" "), ...options });
export const AutoTranslation = (key) => {
  let finalKey = key;
  if (Array.isArray(key)) {
    finalKey = key.join(".");
  }
  return "[missing " + finalKey + " translation]";
};
export const Lcustom_missing = (key) => i18n.t(key, { defaultValue: AutoTranslation(key) });
