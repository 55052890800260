import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import OptionVariant from "./OptionVariant";

export default class Option extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.optionVariants)
      props.optionVariants = Immutable(
        props.optionVariants.map((optionVariant) =>
          Immutable(new OptionVariant(this.getImmutableAsObj(optionVariant)), { prototype: OptionVariant.prototype }),
        ),
      );

    props && Object.assign(this, Immutable(props));
  }

  getName(langCode = "fr_FR") {
    if (!this.optionTranslations) {
      return this.name;
    }
    let optionTranslations = this.optionTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    optionTranslations = optionTranslations?.name
      ? optionTranslations
      : this.optionTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");
    return optionTranslations?.name ?? "";
  }

  getCountQuantityByOption(selectedOptions) {
    if (!selectedOptions || selectedOptions === {}) return 0;
    const optionVariants = selectedOptions[this.id]?.optionVariants;
    const countQuantityByOption = optionVariants
      ? Object.values(optionVariants).reduce((acc, value) => {
          return acc + value.quantity;
        }, 0)
      : 0;
    return countQuantityByOption;
  }
}
