import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import Product from "./Product";

export default class Category extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.categories)
      props.categories = Immutable(props.categories.map((sc) => Immutable(new Category(this.getImmutableAsObj(sc)), { prototype: Category.prototype })));
    if (props.products)
      props.products = Immutable(props.products.map((prod) => Immutable(new Product(this.getImmutableAsObj(prod)), { prototype: Product.prototype })));

    props && Object.assign(this, Immutable(props));
  }

  getName(langCode = "fr_FR") {
    if (!this.categoryTranslations) {
      return this.name;
    }
    let categoryTranslations = this.categoryTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    categoryTranslations = categoryTranslations?.name
      ? categoryTranslations
      : this.categoryTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");
    return categoryTranslations?.name ?? "";
  }

  getDescription(langCode = "fr_FR") {
    if (!this.categoryTranslations) {
      return this.description;
    }
    let categoryTranslations = this.categoryTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    categoryTranslations = categoryTranslations?.description
      ? categoryTranslations
      : this.categoryTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");
    return categoryTranslations?.description ?? "";
  }

  checkIfInArray(cats) {
    return cats.some((cat) => cat.id === this.id);
  }
}

// utilisation
// const shop = {id: 1, name: "Shop 1", categories:[{id: 1, name: "Cat 1", subCategories: [{id: 1, name: "Sub cat 1", products: [{id: 1, name: "prod 1"}, {id: 2, name: "prod 2"}]}]}, {id: 2, name: "Cat 2", subCategories: [{id: 1, name: "Sub cat 2 1", products: [{id: 1, name: "prod 2 1"}, {id: 2, name: "prod 2 2"}]}]}]};

// const immShop = new Shop(shop);
// console.log("immShop", immShop);
// const objCat = Immutable.getIn(immShop, ["categories", 0]);
// console.log("objCat get name", objCat.getName("ok ok"));
