import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { Dimensions } from "react-native";
import { useRedirectAfterLoadSetting } from "./Shared/Hooks";
import functions from "./Shared/functions";
import ModalShared from "./Template1/Shared/ModalShared";
import L from "./I18n/tr";
import ShopActions from "./Redux/ShopRedux";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;
const marginValue = 30;
const totaleMargin = 4 * marginValue;
const widthWithoutMargins = windowWidth - totaleMargin;
export const MyLoader = () => (
  <ContentLoader viewBox={`0 0 ${windowWidth} ${windowHeight}`}>
    <rect x={marginValue} y={marginValue} rx="20" ry="20" width={widthWithoutMargins * 0.1} height={windowHeight - 2 * marginValue} />
    <rect
      x={widthWithoutMargins * 0.1 + 2 * marginValue}
      y={marginValue}
      rx="50"
      ry="50"
      width={widthWithoutMargins * 0.8}
      height={windowHeight - 2 * marginValue}
    />
    <rect
      x={widthWithoutMargins * 0.9 + 3 * marginValue}
      y={marginValue}
      rx="20"
      ry="20"
      width={widthWithoutMargins * 0.1}
      height={windowHeight * 0.4 - marginValue}
    />
    <rect
      x={widthWithoutMargins * 0.9 + 3 * marginValue}
      y={windowHeight * 0.4 + marginValue}
      rx="21"
      ry="21"
      width={widthWithoutMargins * 0.1}
      height={windowHeight * 0.6 - 2 * marginValue}
    />
  </ContentLoader>
);

// Actions
import StartupActions from "./Redux/StartupRedux";
import { ShopSelectors } from "./Redux/ShopRedux";
import FranchiseActions, { FranchiseSelectors } from "./Redux/FranchiseRedux";
import Images from "./Themes/Images";

function LaunchScreen(props) {
  const getOwnerIdFromHost = () => {
    const ownerId = window.location?.origin ? window.location?.origin : "";
    return ownerId;
  };

  useEffect(() => {
    props.setDeviceType(functions.getDeviceTypeBasedOnDimensions());
    // addEventListener('deviceorientation', (event) => props.setDeviceType(functions.getDeviceTypeBasedOnDimensions()) );
    addEventListener("resize", (event) => props.setDeviceType(functions.getDeviceTypeBasedOnDimensions()));
    props.setUserCanOrderRequest();
  }, []);

  useEffect(() => {
    if (props.franchiseError?.code === 401 && !props.isGenerateTokenFailed) {
      setTimeout(() => {
        props.resetFranchiseError();
        window.location.reload(true);
      }, 2000);
    }
  }, [props.franchiseError?.code, props.isGenerateTokenFailed]);

  useRedirectAfterLoadSetting(props);
  function renderNoFranchiseModal() {
    if (!props.franchiseError && !props.franchiseErrors) {
      return null;
    }
    //Si get franchise error, donc pas de données de template, on a besoin du css modal pour bien afficher le modal.
    import("./Assets/Styles/react-bootstrap-common.css");
    return (
      <ModalShared
        show={true}
        title={L("dataNotAvailable")}
        medium
        // noBackdrop={true}
        positifBtnText={L("tryAgain")}
        handleValid={() => window.location.reload(true)}
      >
        {noFranchiseModalContent()}
      </ModalShared>
    );
  }

  function noFranchiseModalContent() {
    const apiErrorText = functions.getApiErrorText(props.franchiseError?.message ?? props.franchiseError, true);
    const bodyContent =
      apiErrorText ?? functions.getTextTranslation(props.franchiseError?.message ?? props.franchiseError) ?? L("franchiseConfigurationNotAvailable");

    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: "20%" }}>
          <img src={Images.apiError} width={"100%"} height={"auto"} alt="img4" />
        </div>
        <div style={{ width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: 30, whiteSpace: "pre-wrap" }}>
          {bodyContent}
          {"\nsite : " + getOwnerIdFromHost() + "\n" + props.franchiseError}
        </div>
      </div>
    );
  }
  return (
    <div style={{ justifyContent: "center", height: "100%", width: "100%", alignItems: "center" }}>
      <MyLoader />
      {renderNoFranchiseModal()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    customColors: FranchiseSelectors.getCustomColors(state),
    selectedShop: ShopSelectors.getShop(state),
    loadingFont: state.startup?.loadingFont,
    isFontLoaded: state.startup?.isFontLoaded,
    franchise: FranchiseSelectors.getFranchise(state),
    franchiseError: state.franchise.franchiseError,
    franchiseErrors: state.franchise.franchiseErrors,
    isGenerateTokenFailed: state.startup.generateTokenError ?? state.startup.generateTokenErrors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadFontRequest: (os) => dispatch(StartupActions.loadFontRequest(os)),
    setDeviceType: (deviceType) => dispatch(StartupActions.setDeviceType(deviceType)),
    setUserCanOrderRequest: () => dispatch(ShopActions.setUserCanOrderRequest()),
    resetFranchiseError: () => dispatch(FranchiseActions.resetFranchiseError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchScreen);
