import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";

export default class Aliment extends BaseEntity {
  constructor(props) {
    super(props);

    props && Object.assign(this, Immutable(props));
  }

  getName(langCode = "fr_FR") {
    if (!this.alimentTranslations) {
      return this.name;
    }
    let alimentTranslations = this.alimentTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    alimentTranslations = alimentTranslations?.name
      ? alimentTranslations
      : this.alimentTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");
    return alimentTranslations?.name ?? "";
  }
}
