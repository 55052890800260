import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";

export default class City extends BaseEntity {
  constructor(props) {
    super(props);
    props && Object.assign(this, Immutable(props));
  }

  getCityOption() {
    return { label: this.name, value: this.id };
  }
}
