import Immutable from "seamless-immutable";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ShopMode from "./ShopMode";
import BaseEntity from "./BaseEntity";
import { getDistance, convertDistance } from "geolib";
import Menu from "./Menu";
import City from "./City";
import i18n from "i18n-js";
import moment from "moment";
import Zone from "./Zone";
import functions from "../../Shared/functions";
import { serverTimeZone } from "../../Shared/Constants";

export default class Shop extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.menus) props.menus = Immutable(props.menus.map((cat) => Immutable(new Menu(this.getImmutableAsObj(cat)), { prototype: Menu.prototype })));
    if (props.shopModes)
      props.shopModes = Immutable(
        props.shopModes
          ?.filter((shop) => !!shop.enabled && !!shop.visible)
          ?.map((mode) => Immutable(new ShopMode(this.getImmutableAsObj(mode)), { prototype: ShopMode.prototype })),
      );
    if (props?.zones)
      props.zones = Immutable(
        props?.zones?.filter((zone) => !!zone.enabled)?.map((zone) => Immutable(new Zone(this.getImmutableAsObj(zone)), { prototype: Zone.prototype })),
      );

    props && Object.assign(this, Immutable(props));
  }

  getImmutableAsObj(immutable) {
    if (!immutable) return null;
    const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
    return entityObj;
  }

  getDescription(langCode = "fr_FR") {
    if (!this.shopTranslations) {
      return this.description;
    }
    let shopTranslations = this.shopTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    shopTranslations = shopTranslations?.description ? shopTranslations : this.shopTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");

    return shopTranslations?.description ?? null;
  }

  getAddress() {
    return `${this.address.street} - ${this.address.postcode}, ${this.address.city}`;
  }

  getCountry() {
    return this.city?.country ?? "";
  }

  getCountryCode() {
    return this.city?.country?.code ?? "";
  }

  getNameKitchens() {
    const kitchens = this.kitchens?.map((kitchen, index) => {
      return (
        <span key={index}>
          <OverlayTrigger placement="bottom" overlay={<Tooltip>{kitchen.description}</Tooltip>}>
            <span>{kitchen.name}</span>
          </OverlayTrigger>
          {index < this.kitchens.length - 1 ? <span>, </span> : null}
        </span>
      );
    });
    return kitchens;
  }

  getDescKitchens() {
    return this.kitchens.map((kitchen) => `${kitchen.description}`).join(" ,");
  }

  isTakeAway() {
    return !!this.shopModes?.find((mode) => mode.isTakeAway());
  }

  isDelivery() {
    return !!this.shopModes?.find((mode) => mode.isDelivery());
  }

  isOnSite() {
    return !!this.shopModes?.find((mode) => mode.isOnSite());
  }

  getTakeAwayType() {
    return this.shopModes?.find((mode) => mode.isTakeAway());
  }

  getDeliveryType() {
    return this.shopModes?.find((mode) => mode.isDelivery());
  }

  getOnSiteType() {
    return this.shopModes?.find((mode) => mode.isOnSite());
  }

  getTimeSlotMaxProduct() {
    // return 2;
    return this.shopSettings?.timeSlotMaxProduct;
  }

  isTimeSlotBlockingTypeProduct() {
    // return 2;
    return this.shopSettings?.timeSlotBlockingType === "product";
  }

  getTimeSlotMaxOrder() {
    // return 2;
    return this.shopSettings?.timeSlotMaxOrder;
  }

  getShopDistance(location) {
    if (!this.latitude || !this.longitude || !location || !location.latitude || !location.longitude) return false;

    return convertDistance(
      getDistance(
        { latitude: location.latitude, longitude: location.longitude },
        { latitude: this.latitude, longitude: this.longitude },
        0.001, //convert to KM
      ),
      "km",
    );
  }

  getDeliveryModeCities() {
    const citiesObject = this.getDeliveryType()
      ? this?.zones?.reduce((cities, zone) => {
          if (zone?.city?.id && !cities[zone.city.id]) {
            cities[zone.city.id] = Immutable(new City(this.getImmutableAsObj(zone.city)), { prototype: City.prototype });
          }
          return cities;
        }, {}) ?? {}
      : {};
    return Immutable(Object.values(citiesObject));
  }

  isCloseUntil() {
    const closeUntil = this?.shopSettings?.closeUntil;
    const isCloseUntil = closeUntil ? moment(closeUntil).isAfter(new Date()) : false;

    return isCloseUntil ? moment(closeUntil).locale(i18n.locale).format("HH:mm") : false;
  }

  getNotice(type) {
    if (!type) {
      return "";
    }
    return this.notices?.find?.((notice) => notice.type === type)?.notice ?? "";
  }

  getClosingNotice() {
    return this.getNotice("closing");
  }

  getOrderingNotice() {
    return this.getNotice("ordering");
  }

  getLegalNotice() {
    const extraData = this.extraData?.filter((ed) => ed.legal_notice);
    return extraData?.[0]?.legal_notice;
  }

  getExtraDataByKey(extraDataKey) {
    const extraData = this.extraData?.find((ed) => !!ed[extraDataKey]);
    return extraData?.[extraDataKey] ?? false;
  }

  getValidateShopMode() {
    if (this?.licence === "light") return this.shopModes?.filter((mode) => mode?.mode?.code === "on-site");
    return this.shopModes;
  }

  getLogoUrl(franchise = null) {
    if (!this.logoUrl && franchise) {
      return franchise.getMedia?.("franchise_cover")?.imageUrl ?? "";
    }
    if (!this.logoUrl) {
      return "";
    }
    return functions.getFormatedImg(this.logoUrl, false);
  }

  getTimeZone() {
    return this.timeZone ?? serverTimeZone;
  }
}
