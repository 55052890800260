import { put, call, select, take } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import OrderActions from "../Redux/OrderRedux";
import UserActions, { UserSelectors } from "../Redux/UserRedux";
import PaymentActions, { PaymentSelectors, PaymentTypes } from "../Redux/PaymentRedux";
import CartActions from "../Redux/CartRedux";
import Order from "../Logic/Entities/Order";
import configFunctions from "../Shared/InitConfigTemplates";
import { ShopSelectors } from "../Redux/ShopRedux";
import { FranchiseSelectors } from "../Redux/FranchiseRedux";

export function* addOrder(api, { orderData }) {
  const orderAsEntity = configFunctions.getImmutableAsEntity(orderData, Order);
  const selectedFranchise = yield select(FranchiseSelectors.getFranchise);
  const isTemplateBorne = selectedFranchise.isTemplateBorneFn();
  const orderValidationErrors = orderAsEntity.validate(isTemplateBorne);
  if (orderValidationErrors && orderValidationErrors.length) {
    yield put(OrderActions.addOrderFailure(null, { type: "validationError", errors: orderValidationErrors }));
    return null;
  }
  const ordersData = !!orderData?.id && (yield select((state) => state.order?.ordersData[orderData.id]));

  let orderDataForApi = null;

  orderDataForApi = orderAsEntity.getOrderDataForApi();

  // pour fixer un souci de modification en borne
  // if (isTemplateBorne) {
  //   orderDataForApi = orderAsEntity.getOrderDataForApi();
  // } else {
  //   orderDataForApi = !ordersData && orderAsEntity.getOrderDataForApi();
  // }

  const paymentConfig = yield select(PaymentSelectors.getPaymentConfig);
  if (paymentConfig) {
    yield put(PaymentActions.resetPaymentConfig());
  }

  const selectedShop = yield select(ShopSelectors.getShop);
  const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
  const deviceToken = selectedDevicesInfo?.borne?.type === "kiosk" ? selectedDevicesInfo?.borne?.token : false;

  let apiCall = null;
  apiCall = call(api.addOrder, { order: orderDataForApi, deviceToken });

  // pour fixer un souci de modification en borne
  // if (isTemplateBorne) {
  //   apiCall = call(api.addOrder, { order: orderDataForApi, deviceToken });
  // } else {
  //   apiCall = !ordersData && call(api.addOrder, { order: orderDataForApi, deviceToken });
  // }

  const response = apiCall ? yield call(callApi, apiCall) : {};
  if (response.ok || ordersData?.id) {
    yield put(OrderActions.addOrderSuccess(response.data, orderData));
    // yield put(OrderActions.addOrderSuccess(false, orderData));
    if (orderData?.formData?.paymentMethodId && (response.data?.id || ordersData?.id)) {
      const isCashbackAndInsufficient =
        orderData?.formData?.paymentMode === "cashback" && orderData?.totalCashBack < orderData?.totalSynthesisData?.totalToPaid / 100;
      const amountToPay = isCashbackAndInsufficient ? orderData?.totalCashBack * 100 : orderData?.totalSynthesisData?.totalToPaid;
      yield put(
        PaymentActions.createPaymentRequest(
          orderData?.formData?.paymentMethodId,
          response.data?.id ?? ordersData?.id,
          orderData?.formData?.subPaymentMethod,
          amountToPay
        )
      );
      if (orderData?.formData?.paymentMethodType === "offline") {
        const action = yield take([PaymentTypes.CREATE_PAYMENT_SUCCESS, PaymentTypes.CREATE_PAYMENT_FAILURE]);
        if (action.type === PaymentTypes.CREATE_PAYMENT_SUCCESS) {
          // if (orderData?.formData?.paymentMode !== "cashback" || orderData?.totalCashBack >= (orderData?.totalSynthesisData?.totalToPaid / 100)) {
          yield put(PaymentActions.paymentSucceeded());
          if (isCashbackAndInsufficient) {
            yield put(CartActions.setOrderDataSuccess(orderData));
          }
        }
      }
    }
    yield put(OrderActions.addOrderSuccess(response.data, orderData));
  } else {
    yield put(OrderActions.addOrderFailure(null, response.data));
  }
}

export function* getOrders(api, { userId, params }) {
  const apiCall = call(api.getOrders, userId, { franchise: params.franchiseId, page: params.page });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(OrderActions.getOrdersSuccess(userId, params.franchiseId, response.data));
  } else {
    yield put(OrderActions.getOrdersFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* getOrdersByUserAndSlot(api, { slot }) {
  const connectedUser = yield select(UserSelectors.getConnectedUser);
  const idUser = connectedUser?.id;
  const apiCall = call(api.getOrdersByUserAndSlot, { idUser, slot });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(OrderActions.getOrdersByUserAndSlotSuccess(response.data));
  } else {
    yield put(OrderActions.getOrdersByUserAndSlotFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* orderCancel(api, { orderId }) {
  const apiCall = call(api.orderCancel, orderId);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(OrderActions.orderCancelSuccess(response.data));
    const connectedUser = yield select(UserSelectors.getConnectedUser);
    yield put(UserActions.getUserRequest(connectedUser.id));
  } else {
    yield put(OrderActions.orderCancelFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}
