import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import ProductCart from "../Logic/Entities/ProductCart";
import functions from "../Shared/functions";
import SpecialOffer from "../Logic/Entities/SpecialOffer";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  addProductToCartRequest: null,
  addProductToCartSuccess: ["formatedProductToCart", "timeSlotMaxProduct", "selectedShop"],
  addProductToCartFailure: ["error", "errors"],
  deleteProductFromCartSuccess: ["selectedProductInCart", "indexProduct", "selectedShop"],
  editProductQtySuccess: ["selectedProductInCart", "indexProduct", "qty", "timeSlotMaxProduct", "selectedShop"],
  setOrderDataSuccess: ["orderInitData"],
  removeCartSuccess: ["selectedShop"],
  setShowCartModal: ["showCartModal"],
  checkCodePromotionRequest: ["shopModeId", "code", "params"],
  checkCodePromotionSuccess: ["promotionApplied"],
  checkCodePromotionFailure: ["checkCodePromotionError", "checkCodePromotionErrors"],
  deletePromotionRequest: ["promotion"],
  resetPromotionErrorsRequest: null,
  initCartState: null,

  setCartLinesDataRequest: ["cartLinesData"],

  resetPromotionsApplied: null,

  setRewardAppliedRequest: ["rewardsApplied"],
});

export const CartTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  productIntoCartFetching: false,
  productIntoCartError: null,
  productIntoCartErrors: null,
  productsIntoCartData: Immutable({}),
  isUpperToMaxProductRedux: false,
  showCartModal: false,
  promotionsApplied: [],
  checkCodePromotionFetching: false,
  checkCodePromotionError: null,
  checkCodePromotionErrors: null,
  cartLinesData: null,
  rewardsApplied: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

function getImmutableAsObj(immutable) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return entityObj;
}

/* ------------- Selectors ------------- */
export const CartSelectors = {
  getProductsIntoCart: (state) => state.cart?.productsIntoCartData?.[state.shop?.shopData?.id] ?? Immutable({}),
  getOrderInitData: (state) => state.cart?.orderInitData,
  getIsUpperToMaxProduct: (state) => state.cart?.isUpperToMaxProductRedux,
  getShowCartModal: (state) => state.cart?.showCartModal,
  getPromotionsApplied: (state) => state.cart?.promotionsApplied,
  getCartLinesData: (state) => state.cart?.cartLinesData ?? [],
  getRewardsApplied: (state) => state.cart?.rewardsApplied,
};

/* ------------- Reducers ------------- */

export const addProductToCartRequest = (state) => state.merge({ productIntoCartFetching: true });

export const addProductToCartSuccess = (state, { formatedProductToCart, timeSlotMaxProduct, selectedShop }) => {
  const productsIntoCartDataState = formatedProductToCart.oldIndexProduct
    ? Immutable.without(state.productsIntoCartData[selectedShop?.id] ?? {}, [formatedProductToCart.oldIndexProduct])
    : { ...state.productsIntoCartData[selectedShop?.id] };
  const isTimeSlotBlockingTypeProduct = selectedShop?.isTimeSlotBlockingTypeProduct?.();
  const isUpperToMaxProduct_ = functions.isUpperToMaxProduct(
    timeSlotMaxProduct,
    productsIntoCartDataState,
    formatedProductToCart.qty,
    isTimeSlotBlockingTypeProduct
  );

  const getOptionsVariantsIds = () => {
    if (!formatedProductToCart.selectedOptions || formatedProductToCart.selectedOptions === {}) return 0;

    let optionsVariantsIds = [];
    Object.values(formatedProductToCart.selectedOptions).forEach((option) => {
      Object.values(option.optionVariants).forEach((optionsVariant) => {
        optionsVariantsIds.push(optionsVariant.id);
      }, 0);
    });

    return optionsVariantsIds;
  };

  const getJoinedOptionsVariantsIds = () => {
    const OptionsVariantsIds = getOptionsVariantsIds();
    if (OptionsVariantsIds.length > 0) return `_${OptionsVariantsIds.join("-")}`;
    return "";
  };

  const setProductIntoData = () => {
    const instructionSpecialIndex =
      formatedProductToCart?.instructionSpecial && formatedProductToCart?.instructionSpecial.length > 0
        ? `__${functions.hashCode(formatedProductToCart?.instructionSpecial).toString()}`
        : "";
    const formatedIndex = `product_${formatedProductToCart.selectedProduct.id}${getJoinedOptionsVariantsIds()}${instructionSpecialIndex}`;
    const isProductsKeyExist = Immutable.getIn(productsIntoCartDataState, [formatedIndex]);
    if (isProductsKeyExist) {
      const add = (x, y) => {
        return !isUpperToMaxProduct_ ? x + y : x;
      };
      return Immutable.updateIn(productsIntoCartDataState, [formatedIndex, "qty"], add, formatedProductToCart.qty);
    }

    formatedProductToCart.formatedIndex = formatedIndex;

    const formatedProductToCartAsAntity = getImmutableAsEntity(formatedProductToCart, ProductCart);

    return Immutable.setIn(productsIntoCartDataState, [formatedIndex], formatedProductToCartAsAntity);
  };

  const productsIntoCartData = setProductIntoData();

  const newIsUpperToMaxProduct_ = functions.isUpperToMaxProduct(timeSlotMaxProduct, productsIntoCartData, 1, isTimeSlotBlockingTypeProduct);

  return state.merge({
    productsIntoCartData: state.productsIntoCartData?.setIn([selectedShop?.id], productsIntoCartData),
    productIntoCartFetching: false,
    productIntoCartError: null,
    productIntoCartErrors: null,
    isUpperToMaxProductRedux: newIsUpperToMaxProduct_,
  });
};

export const addProductToCartFailure = (state, { productIntoCartError, productIntoCartErrors }) =>
  state.merge({
    productIntoCartFetching: false,
    productIntoCartError,
    productIntoCartErrors,
  });

export const deleteProductFromCartSuccess = (state, { selectedProductInCart, indexProduct, selectedShop }) => {
  const productsIntoCartData = Immutable.update(state.productsIntoCartData, selectedShop?.id, (product) =>
    Immutable.without(product, (value, key) => value.formatedIndex === selectedProductInCart.formatedIndex)
  );
  return state.merge({
    productsIntoCartData,
    productIntoCartFetching: false,
    productIntoCartError: null,
    productIntoCartErrors: null,
  });
};

export const editProductQtySuccess = (state, { selectedProductInCart, indexProduct, qty, timeSlotMaxProduct, selectedShop }) => {
  const isTimeSlotBlockingTypeProduct = selectedShop?.isTimeSlotBlockingTypeProduct?.();
  const isUpperToMaxProduct_ = functions.isUpperToMaxProduct(
    timeSlotMaxProduct,
    state.productsIntoCartData[selectedShop?.id],
    qty,
    isTimeSlotBlockingTypeProduct
  );
  const productBlockedBySlot = selectedProductInCart?.selectedProduct?.isQuantitySlot();
  const add = (x, y) => {
    const somme = x + y;
    return somme > 0 && (!isUpperToMaxProduct_ || !productBlockedBySlot) ? x + y : x;
  };
  const productsIntoCartData = Immutable.updateIn(state.productsIntoCartData, [selectedShop?.id, indexProduct, "qty"], add, qty);

  const newIsUpperToMaxProduct_ = functions.isUpperToMaxProduct(timeSlotMaxProduct, productsIntoCartData[selectedShop?.id], 1, isTimeSlotBlockingTypeProduct);

  return state.merge({
    productsIntoCartData,
    productIntoCartFetching: false,
    productIntoCartError: null,
    productIntoCartErrors: null,
    isUpperToMaxProductRedux: newIsUpperToMaxProduct_,
  });
};

export const removeCartSuccess = (state, { selectedShop }) => {
  if (selectedShop) {
    return state.merge({
      productsIntoCartData: Immutable.without(state.productsIntoCartData, [selectedShop?.id]),
      productIntoCartFetching: false,
      productIntoCartError: null,
      productIntoCartErrors: null,
    });
  } else {
    return state;
  }
};

export const setOrderDataSuccess = (state, { orderInitData }) => {
  return state.merge({
    orderInitData,
  });
};

export const setShowCartModal = (state, { showCartModal }) => state.merge({ showCartModal });

export const checkCodePromotionRequest = (state) => state.merge({ checkCodePromotionFetching: true });

export const checkCodePromotionSuccess = (state, { promotionApplied }) => {
  // const promotionsAppliedObj = getImmutableAsObj(state.promotionsApplied);
  const promotionAppliedObj = getImmutableAsObj(promotionApplied);
  // promotionsAppliedObj.push(Immutable(new SpecialOffer(promotionAppliedObj), {prototype: SpecialOffer.prototype}));
  // promotionsAppliedObj.push(getImmutableAsEntity(promotionAppliedObj, SpecialOffer));

  const promotionsApplied = Immutable([getImmutableAsEntity(promotionAppliedObj, SpecialOffer)]);

  return state.merge({
    promotionsApplied,
    checkCodePromotionFetching: false,
    checkCodePromotionError: null,
    checkCodePromotionErrors: null,
  });
};

export const checkCodePromotionFailure = (state, { checkCodePromotionError, checkCodePromotionErrors }) =>
  state.merge({
    checkCodePromotionFetching: false,
    checkCodePromotionError,
    checkCodePromotionErrors,
  });

export const deletePromotionRequest = (state, { promotion }) => {
  const promotionsAppliedObj = getImmutableAsObj(state.promotionsApplied).filter((promo) => promo.code !== promotion.code);
  const promotionsApplied = Immutable(promotionsAppliedObj);

  return state.merge({
    promotionsApplied,
  });
};

export const resetPromotionErrorsRequest = (state) =>
  state.merge({
    promotionsApplied: [],
    checkCodePromotionError: false,
    checkCodePromotionErrors: false,
  });

export const resetPromotionsApplied = (state) => state.merge({ promotionsApplied: [] });

export const setCartLinesDataRequest = (state, { cartLinesData }) => state.merge({ cartLinesData });

export const setRewardAppliedRequest = (state, { rewardsApplied }) => state.merge({ rewardsApplied });

export const initCartState = (state) => INITIAL_STATE;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADD_PRODUCT_TO_CART_REQUEST]: addProductToCartRequest,
  [Types.ADD_PRODUCT_TO_CART_SUCCESS]: addProductToCartSuccess,
  [Types.ADD_PRODUCT_TO_CART_FAILURE]: addProductToCartFailure,
  [Types.DELETE_PRODUCT_FROM_CART_SUCCESS]: deleteProductFromCartSuccess,
  [Types.EDIT_PRODUCT_QTY_SUCCESS]: editProductQtySuccess,
  [Types.REMOVE_CART_SUCCESS]: removeCartSuccess,
  [Types.SET_ORDER_DATA_SUCCESS]: setOrderDataSuccess,
  [Types.SET_SHOW_CART_MODAL]: setShowCartModal,
  [Types.CHECK_CODE_PROMOTION_REQUEST]: checkCodePromotionRequest,
  [Types.CHECK_CODE_PROMOTION_SUCCESS]: checkCodePromotionSuccess,
  [Types.CHECK_CODE_PROMOTION_FAILURE]: checkCodePromotionFailure,
  [Types.DELETE_PROMOTION_REQUEST]: deletePromotionRequest,
  [Types.RESET_PROMOTION_ERRORS_REQUEST]: resetPromotionErrorsRequest,
  [Types.SET_CART_LINES_DATA_REQUEST]: setCartLinesDataRequest,

  [Types.RESET_PROMOTIONS_APPLIED]: resetPromotionsApplied,
  [Types.SET_REWARD_APPLIED_REQUEST]: setRewardAppliedRequest,

  [Types.INIT_CART_STATE]: initCartState,
});
