import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import Shop from "./Shop";
import functions from "../../Shared/functions";
import moment from "moment";

export default class Order extends BaseEntity {
  // { selectedProduct, qty, finalPrice, selectedOptions, instructionSpecial }
  constructor(props) {
    super(props);
    // if(props.shops) props.shops = Immutable(props.shops.map((shop)=> Immutable(new Shop(this.getImmutableAsObj(shop)), {prototype: Shop.prototype})));
    // if(props.products) props.products = Immutable(props.products.map((prod)=> Immutable(new Product(this.getImmutableAsObj(prod)), {prototype: Product.prototype})));

    props && Object.assign(this, Immutable(props));
  }

  getFixedValue(value) {
    return parseFloat(value.toFixed(2));
  }

  getOrderDataForApi() {
    const rewardsProducts = this.rewardsApplied
      ?.filter((reward) => reward.discount_type === "product_sku" && !reward.out_stock)
      ?.map((reward) => {
        const rewardsProduct = reward?.data?.product;
        return {
          selectedProduct: rewardsProduct,
          selectedOptions: {},
          productShopModes: rewardsProduct?.productShopModes,
          qty: 1,
          isFree: true,
        };
      });
    const productsToAdd = Object.values(this.productsIntoCart ?? {})?.concat(rewardsProducts ?? []);
    const orderItems = productsToAdd?.map((productData) => {
      const product = productData?.selectedProduct;
      const productShopMode = product?.productShopModes?.find((productShopMode) => productShopMode.shopMode?.mode?.code === this.shopMode?.mode?.code);
      const orderItemOptions = Object.values(productData.selectedOptions ?? {});
      const qty = productData.qty ?? 0;
      const price = productShopMode?.price ?? 0;
      const note = productData.instructionSpecial ?? "";
      const isFree = productData?.isFree;
      const total = isFree ? 0 : product.getFinalPrice(productData?.selectedOptions, qty, productShopMode.shopMode?.mode?.code);
      const optionList = orderItemOptions.map((optionData) => {
        return {
          ...optionData?.option.without("optionVariants"),
          // optionVariant: optionData?.option?.optionVariants,
          optionVariant: Object.values(optionData?.optionVariants ?? {})?.map((optionVariant) => {
            return optionVariant.without("@id");
          }),
        };
      });

      // const taxesTotal =  parseFloat(((productShopMode?.tax?.rate??0) * total * qty).toFixed(2));// a commenter
      return {
        product: product?.id,
        productShopMode: productShopMode?.id,
        quantity: qty,
        note,
        total, // est total du prix du produit plus la somme des prix des options
        // taxesTotal: productShopMode?.tax?.rate??0,
        optionList: optionList,
        ...(isFree ? { free: true } : {}),
      };
    });

    const fulfillmentTime = this.fulfillmentTime ? this.fulfillmentTime : this.getTheClosestFulfillmentTime();
    const fulfillmentDate = this.fulfillmentDate ? this.fulfillmentDate : moment().format("YYYY-MM-DD");
    // date api format example: "2022-09-26T09:30"
    const date = fulfillmentDate + "T" + fulfillmentTime;
    // const date = (this.fulfillmentDate??'') + (this.fulfillmentTime ? 'T'+this.fulfillmentTime : 'T'+moment().format('HH:mm'));
    // le itemsTotal est la somme des total des items (sans taxes)
    const itemsTotal = this.getFixedValue(this.totalSynthesisData?.subTotal ?? 0); //convert tocent to addapt the total to the api
    const total = this.totalSynthesisData?.totalToPaid;
    const discountsTotal = this.getFixedValue(this.totalSynthesisData?.totalDiscounts);
    const asap = this.shopMode?.isOnSite() || !this.fulfillmentTime ? true : this.asap;
    const rewardsIds = this.rewardsApplied?.map((reward) => reward.id);

    return {
      notes: this.formData?.specialInstructions ?? null,
      source: "foxorders",
      total: total,
      // customer: this.customer?.id??null,
      itemsTotal: itemsTotal,
      shop: this.shop?.id,
      discountsTotal: discountsTotal,
      date: functions.convertFromLocalTimeZoneToServerTimeZone(date, functions.apiDateTimeFormat(), this.shop?.timeZone),
      shopMode: this.shopMode?.id,
      orderItems: orderItems,
      createdByGuest: this.customer?.id ? false : true,
      // paymentMethodId: this.formData?.paymentMethodId,
      // paymentMethodType: this.formData?.paymentMethodType,
      asap: asap ?? false,
      managementFees: this.managementFees,
      ...(!!this.rewardsApplied ? { qoodosRewards: rewardsIds } : {}),
      ...(!!this.specialOffer ? { promotion: { code: this.specialOffer.code ?? null } } : {}),
      // billingAddress: {},
      // taxesTotal: parseFloat((this.totalSynthesisData?.tax??0).toFixed(2)),// acommenter
      ...(this.deliveryAddress?.id ? { deliveryAddress: this.deliveryAddress.id } : {}),
      ...(this.formData?.numTableExist ? { tableNumber: this.formData.numTableExist } : {}),
      ...(this.formData?.yourName || this.formData?.customerName ? { customerName: this.formData.yourName ?? this.formData?.customerName } : {}),
      ...(this.delivery ? { shippingCost: this.delivery } : {}),
      ...(this.zone?.id ? { zone: this.zone.id } : {}),
      ...(this.formData?.emailReceipt ? { customerEmail: this.formData.emailReceipt } : {}),
      // ...(this.formData?.subPaymentMethod ? { subPaymentMethod: this.formData.subPaymentMethod } : {}),
      ...(this.id ? { id: this.id } : {}),
    };
  }

  getTheClosestFulfillmentTime() {
    const nowTime = moment().format("HH:mm");
    const closestTimeSlot = this.timeSlots?.find?.((timeSlot) => timeSlot?.value >= nowTime);
    return closestTimeSlot?.value ?? moment().format("HH:mm");
  }

  validate(isTemplateBorneFn = false) {
    const errors = [];
    const closingDays = functions.getEnabledClosingDays(this.shop?.closingDays);
    const openingHours = functions.getEnabledOpeningHours(this.shop?.openingHours);
    if (this?.shopMode?.isTakeAway() || this?.shopMode?.isDelivery()) {
      const dateIsInClosingDays = Array.isArray(closingDays) && functions.isInClosingDays?.(this.fulfillmentDate, closingDays ?? []);
      if (dateIsInClosingDays && !isTemplateBorneFn) {
        errors.push("fulfillmentDateIsInClosingDays");
      }
      const dateDayIndex = functions.getDayIndex(this.fulfillmentDate, "YYYY-MM-DD");
      const openingHoursInDay = this.fulfillmentTime && Array.isArray(openingHours) && openingHours?.filter?.((openingHour) => openingHour.day == dateDayIndex);
      const { fulfillmentTime, hoursInDay: adaptedOpeningHoursInDay } = this.getAdaptedfulfillmentTimeAndHours(this.fulfillmentTime, openingHoursInDay);
      const fulfillmentTimeAsMoment = moment(fulfillmentTime, fulfillmentTime?.length > 5 ? "HH:mm:ss" : "HH:mm");
      const dateIsInOpeningHours = adaptedOpeningHoursInDay?.some?.((openingHoursElt) => {
        const startingTimeApi = openingHoursElt.startingTimeApi;
        const endingTimeApi = openingHoursElt.endingTimeApi;
        const endingTimeApiAsMoment = endingTimeApi > startingTimeApi ? moment(endingTimeApi, "HH:mm:ss") : moment(endingTimeApi, "HH:mm:ss").add(1, "day");
        return fulfillmentTimeAsMoment.isBetween(moment(startingTimeApi, "HH:mm:ss"), endingTimeApiAsMoment, undefined, "[]");
      });
      if (!dateIsInOpeningHours && !isTemplateBorneFn) {
        errors.push("fulfillmentTimeIsNotInOpeningHours");
      }
      if (this.shop?.shopSettings?.pickupMode) {
        const dateIsInPickupHours = this.isDateIsInPickupHours(dateDayIndex);
        if (!dateIsInPickupHours && !isTemplateBorneFn) {
          errors.push("fulfillmentTimeIsNotInPickupHours");
        }
      } else {
        const dateIsInOrderingHours = this.isDateIsInOrderingHours(dateDayIndex);
        if (!dateIsInOrderingHours && !isTemplateBorneFn) {
          errors.push("fulfillmentTimeIsNotInOrderingHours");
        }
      }
    }
    // } else if(this.shopMode?.isOnSite()) {
    //   if (!this.formData?.numTableExist && !this.formData?.yourName) {
    // errors.push('noNumTableOrName');
    //   }
    // }
    return errors;
  }

  getAdaptedfulfillmentTimeAndHours = (fulfillmentTime, hoursInDay) => {
    let adaptedHoursInDay = hoursInDay;
    let adaptedFulfillmentTime = fulfillmentTime;
    if (hoursInDay?.[0]?.startingTimeApi?.length) {
      if (hoursInDay[0].startingTimeApi.length > fulfillmentTime?.length) {
        adaptedHoursInDay = hoursInDay.map((hoursElt) => {
          const timeApi = {};
          timeApi.startingTimeApi = hoursElt?.startingTimeApi?.slice(0, fulfillmentTime?.length);
          timeApi.endingTimeApi = hoursElt?.endingTimeApi?.slice(0, fulfillmentTime?.length);
          return { ...hoursElt, ...timeApi };
        });
      } else if (fulfillmentTime?.length > hoursInDay[0].startingTimeApi.length) {
        adaptedFulfillmentTime = fulfillmentTime.slice(0, hoursInDay[0].startingTimeApi.length);
      }
    }
    return { fulfillmentTime: adaptedFulfillmentTime, hoursInDay: adaptedHoursInDay };
  };

  isDateIsInOrderingHours(dateDayIndex) {
    const orderingHoursInDay =
      this.fulfillmentTime &&
      Array.isArray(this.shopMode?.orderingHours) &&
      this.shopMode?.orderingHours?.filter?.((orderingHour) => orderingHour.day == dateDayIndex);
    const { fulfillmentTime: orderingFulfillmentTime, hoursInDay: adaptedOrderingHoursInDay } = this.getAdaptedfulfillmentTimeAndHours(
      this.fulfillmentTime,
      orderingHoursInDay
    );
    const orderingFulfillmentTimeAsMoment = moment(orderingFulfillmentTime, orderingFulfillmentTime?.length > 5 ? "HH:mm:ss" : "HH:mm");
    const dateIsInOrderingHours = adaptedOrderingHoursInDay?.some?.((orderingHoursElt) => {
      const startingTimeApi = orderingHoursElt.startingTimeApi;
      const endingTimeApi = orderingHoursElt.endingTimeApi;
      const endingTimeApiAsMoment = endingTimeApi > startingTimeApi ? moment(endingTimeApi, "HH:mm:ss") : moment(endingTimeApi, "HH:mm:ss").add(1, "day");
      return orderingFulfillmentTimeAsMoment.isBetween(moment(startingTimeApi, "HH:mm:ss"), endingTimeApiAsMoment, undefined, "[]");
    });
    return dateIsInOrderingHours;
  }

  isDateIsInPickupHours(dateDayIndex) {
    const pickupHoursInDay =
      this.fulfillmentTime && Array.isArray(this.shop?.pickupHours) && this.shop?.pickupHours?.filter?.((pickupHour) => pickupHour.day == dateDayIndex);
    const pickUpFulfillmentTimeAsMoment = moment(this.fulfillmentTime, "HH:mm:ss");
    const dateIsInPickupHours = pickupHoursInDay?.some?.((pickupHoursElt) => {
      const startingTimeApi = pickupHoursElt.startingTimeApi;
      const endingTimeApi = pickupHoursElt.endingTimeApi;
      const endingTimeApiAsMoment = endingTimeApi > startingTimeApi ? moment(endingTimeApi, "HH:mm:ss") : moment(endingTimeApi, "HH:mm:ss").add(1, "day");
      return pickUpFulfillmentTimeAsMoment.isBetween(moment(startingTimeApi, "HH:mm:ss"), endingTimeApiAsMoment, undefined, "[]");
    });
    return dateIsInPickupHours;
  }
}
