import Immutable from "seamless-immutable";
import configEnv from "../../../config.env";
import BaseEntity from "./BaseEntity";
import Color from "./Color";
import Shop from "./Shop";
import Social from "./Social";
import functions from "../../Shared/functions";
import { DOMAIN_TEST } from "../../Shared/Constants";

export default class Franchise extends BaseEntity {
  constructor(props) {
    super(props);
    if (props.shops) props.shops = Immutable(props.shops.map((shop) => Immutable(new Shop(this.getImmutableAsObj(shop)), { prototype: Shop.prototype })));
    if (props.socials)
      props.socials = Immutable(props.socials.map((social) => Immutable(new Social(this.getImmutableAsObj(social)), { prototype: Social.prototype })));
    if (props.colors)
      props.colors = Immutable(props.colors.map((color) => Immutable(new Color(this.getImmutableAsObj(color)), { prototype: Color.prototype })));

    props && Object.assign(this, Immutable(props));
  }

  getKeywords() {
    return this.parameter.keywordsArray && Array.isArray(this.parameter.keywordsArray) ? this.parameter.keywordsArray.join(", ") : "";
  }

  getFranchiseMedias() {
    return this.media.reduce(
      (a, v) => ({ ...a, [v.code]: { imageUrl: v.imageUrl ? `${configEnv.BASE_API_URL}${v.imageUrl}` : false, type: v.type ? v.type : false, format: v.format ? v.format : false, typeFile: v.typeFile ? v.typeFile : false } }),
      {}
    );
  }

  getMedia(img, checkForAlternative = true) {
    const franchiseMedia = this.getFranchiseMedias()[img];
    if (!checkForAlternative) {
      return franchiseMedia;
    }
    if (!franchiseMedia?.imageUrl) {
      const alternativeFranchiseMediaCode = this.getAlternativeMediaCode(img);
      const alternativeFranchiseMedia = alternativeFranchiseMediaCode && this.getMedia(alternativeFranchiseMediaCode);
      if (alternativeFranchiseMedia) {
        return alternativeFranchiseMedia;
      }
    }
    return franchiseMedia;
  }

  getAlternativeMediaCode(mediaCode) {
    if (["favicon", "franchise_cover", "homepage_logo"].includes(mediaCode)) {
      return "logo";
    }
    if (mediaCode === "login_background_image") {
      return "franchise_cover";
    }
    return null;
  }

  getAddress() {
    // return `${this.address?.street} - ${this.address?.postcode}, ${this.address?.city}`;
    return `${this.get("defaultAddress.street")} - ${this.get("defaultAddress.postcode")}, ${this.get("defaultAddress.city")}`;
  }

  getOwnerIdFromHost() {
    if (__DEV__) return DOMAIN_TEST;
    const ownerId = window.location?.origin ? window.location?.origin : "";
    return ownerId;
  }

  getSelectedTemplate() {
    const selectedTemplate = this.domains?.filter((domain) => {
      return domain.url === this.getOwnerIdFromHost();
    });

    return selectedTemplate;
  }

  getTemplateName() {
    return this.getSelectedTemplate()?.[0]?.template?.templateName?.name;
  }

  getTemplateCode() {
    return this.getSelectedTemplate()?.[0]?.template?.templateName?.code;
  }

  isTemplateBorneFn() {
    return this.isTemplateBorne;
  }

  convertArrayToObject(array) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        ...item,
      };
    }, initialValue);
  }

  getColors() {
    const franchiseColors = this.convertArrayToObject(this.colors.map((color) => color.getColor()));
    const addaptedColors = this.getAlternativeColors({ ...franchiseColors });
    return franchiseColors.merge(addaptedColors);
  }

  getAlternativeColors(initialColors) {
    if (initialColors?.primary) {
      if (!initialColors?.secondary) {
        initialColors.secondary = functions.adjust(initialColors.primary, 50);
      }
      if (!initialColors?.qrcode) {
        initialColors.qrcode = initialColors.primary;
      }
      if (!initialColors?.logoModes) {
        initialColors.logoModes = initialColors.primary;
      }
      if (!initialColors?.bgSpecialElement) {
        initialColors.bgSpecialElement = initialColors.primary;
      }
    }

    if (!initialColors?.background) {
      initialColors.background = "#ffffff";
    }
    if (!initialColors?.text) {
      initialColors.text = "#000000";
    }
    return initialColors;
  }

  get = (property, defaultValue = "") => {
    const propertyPath = property?.split(".");
    if (property === "imageUrl") return `${configEnv.BASE_API_URL}${Immutable.getIn(this, propertyPath, defaultValue)}`;
    return Immutable.getIn(this, propertyPath, defaultValue);
    // return this[property]??defaultValue;
  };

  getSocials() {
    this.socials;
  }
}
