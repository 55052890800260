// a library to wrap and simplify api calls
import apisauce from "apisauce";

import Secrets from "../../config.env";
import { DOMAIN_TEST } from "../Shared/Constants";
import functions from "../Shared/functions";
import moment from "moment";

const getHeaders = (api = null) => {
  const storeState = window.storeData?.getState?.();
  const newToken = storeState?.startup?.generatedToken?.data;
  const headers = { Authorization: "Bearer " + newToken };
  if (!api?.headers?.["franchise-token"]) {
    const franchiseToken = storeState?.franchise?.franchiseData?.token ?? "";
    if (franchiseToken) {
      headers["franchise-token"] = franchiseToken;
    }
  }
  if (!api?.headers?.["shop-token"]) {
    const shopToken = storeState?.shop?.shopData?.token ?? "";
    if (shopToken) {
      headers["shop-token"] = shopToken;
    }
  }
  if (!headers["device-token"]) {
    const selectedShop = storeState?.shop?.shopData;
    if (selectedShop?.id) {
      const selectedDevicesInfo = storeState.franchise?.selectedDevicesInfoData?.[selectedShop.id];
      // Si un borne est selectionné et pas de token sur le header on l'ajout
      if (selectedDevicesInfo?.borne?.token) {
        headers["device-token"] = selectedDevicesInfo.borne.token;
      }
    }
  }
  return headers;
};

// const newToken = Secrets.TOKEN_API;
// token prod ==> // const newToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2NzQyMTg0NzgsImV4cCI6MTY3NzgxODQ3OCwicm9sZXMiOlsiUk9MRV9BUEkiXSwidXNlcm5hbWUiOiJhcGlAZm94b3JkZXJzLmNvbSIsImlwIjoiMTk2LjExOC4xMjkuMjAwIiwibmFtZSI6IkFQSSAiLCJpZCI6NSwiaW1hZ2VVcmwiOiI1Ny4xMjguODUuMTc5L3VwbG9hZHMvaW1hZ2VzL3VzZXJzL2ZveG9yZGVycy12Mi1sb2dvLTYzYmVlYWQyOTY5ODc4ODM4MTM2NTIucG5nIn0.xDHFs3jLD0EBAV_CQljlATyuOE1bqbXkKmvn9batzPjgzXUJhiKwTXJtj8fbRyyXS6Ud1q3mOEiXXrnjZMXzU0xZl0uQ1h8lDBc-SN4T3oE100ppKRJezQphIXv-oLt8Mc1wS1VkGwqzbvC5eSBrvlw7l5rBGSUgtrVrG5Opw24apqukwdPgtIGhgfWRMvn-IplWOHP04NlY82lVwR5SCP5u9Cec9VhJ0xx7XC69OYQ_pP7u86zCxiLO1qVSpzrykSGOYDNNopLei8M9i61_wIRgQyp_1GLy1MlD6zYGa0UeE41gr97nZVPNUc6p3uKMIP_P6GF3NoZSVwCctMh2yw";

// our "constructor"
const create = () => {
  // ------
  // STEP 1
  // ------
  //
  // Create and configure an apisauce-based api object.
  //
  const baseURL = Secrets.LOCAL_API_URL; // || 'https://127.0.0.1'

  const api = apisauce.create({
    // base URL is read from the "constructor"
    baseURL,
    // here are some default headers
    headers: {
      "Cache-Control": "no-cache",
      // Authorization: 'Bearer ' + newToken,
      source: "foxorders-front",
      version: "2.0.0",
    },
    // 10 second timeout...
    timeout: 10000,
  });

  api.addAsyncRequestTransform((request) => async () => {
    if (request.url === "franchises/domain" && !request.headers?.Authorization) {
      await getGeneratedToken1({ env: Secrets.IS_DEV_OR_STAGING ? "staging" : "prod" }).then((response) => {
        setAuthToken({ token: response.data.data });
        api.setBaseURL(Secrets.LOCAL_API_URL);
        request.headers.Authorization = "Bearer " + response.data.data;
      });
    }
  });

  const getGeneratedToken1 = ({ env }) => {
    // http://localhost:4000
    // api.setBaseURL(Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242");
    const baseURL = Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242";
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };

    return api.get("/get-generated-token", { env }, { headers, baseURL });
  };

  const login = ({ email, password, franchiseId }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.post(
      "login",
      {
        username: email,
        password: password,
        franchiseId,
      },
      { headers }
    );
  };

  const getUser = (id) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {};
    if (!api?.headers?.["franchise-token"]) {
      //Si actualisé et pas encore seter franchise-token
      const franchiseToken = window.storeData?.getState?.()?.franchise?.franchiseData?.token ?? "";
      if (franchiseToken) {
        headers["franchise-token"] = franchiseToken;
      }
    }
    return api.get("customers/" + id, {}, { headers });
  };

  const refreshTokenAndLogin = ({ refreshToken, franchiseId }) => {
    return api.post("token/refresh", {
      refresh_token: refreshToken,
      franchiseId,
    });
  };

  const signUp = (params) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const form = new FormData();
    if (params.image) {
      params.image.map((file) => {
        form.append("imageFile", file, file.name);
      });
    }
    // form.append('address', params.address);
    // form.append('city', params.city);
    form.append("confirmPassword", params.confirmPassword);
    form.append("email", params.email);
    form.append("firstName", params.firstName);
    form.append("gender", params.gender);
    form.append("lastName", params.lastName);
    form.append("password", params.password);
    form.append("phone", params.phone);
    // if(typeof params.notificationEmail !== "undefined") form.append('notificationEmail', params.notificationEmail);
    // if(typeof params.notificationSms !== "undefined") form.append('notificationSms', params.notificationSms);

    if (typeof params.qoodosEmailNotification !== "undefined") form.append("qoodosEmailNotification", params.qoodosEmailNotification);
    if (typeof params.qoodosPhoneNotification !== "undefined") form.append("qoodosPhoneNotification", params.qoodosPhoneNotification);
    if (typeof params.qoodosSubscription !== "undefined") form.append("qoodosSubscription", params.qoodosSubscription);
    if (!!params.dontAskForSubscriptionToQoodos) form.append("askForSubscriptionToQoodos", false);
    // form.append('zipCode', params.zipCode);
    // form.append('enterprise', params.enterprise);
    // form.append('floor', params.floor);
    // form.append('building', params.building);
    // form.append('elevator', params.elevator);
    // form.append('digicode1', params.digicode1);
    // form.append('digicode2', params.digicode2);
    // form.append('intercom', params.intercom);
    // form.append('specialInstructions', params.specialInstructions);

    form.append("shops", JSON.stringify([params.shop])); // a supp une fois shopId est en staging et prod
    form.append("shopId", params.shop);
    form.append("franchiseId", params.franchiseId);

    const deliveryAddresses = [
      {
        isDefault: true,
        floor: params.floor,
        building: params.building,
        city: params.city,
        street: params.street1,
        street2: params.street2,
        zipCode: params.zipCode,
        phone: params.phone,
        phone2: params.phone2,
        fax: params.fax,
        country: params.country,
        description: params.description,
        buildingType: params.buildingType,
      },
    ];

    form.append("deliveryAddresses", JSON.stringify(deliveryAddresses));
    form.append("defaultLanguage", params.defaultLanguage);

    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      ...getHeaders(api),
      // "boundary": 123
    };
    // for (var key of form.entries()) {
    //   console.log("signUp form ", key[0] + ', ' + key[1]);
    // }
    return api.post("customers", form, { headers });
    // return api.post('customers', params);
  };

  const updateProfile = (params = {}) => {
    if (!params?.id) {
      return { ok: false };
    }

    const profilePropreties = [
      "email",
      "firstName",
      "gender",
      "lastName",
      "password",
      "phone",
      "shop",
      "qoodosSubscription",
      "qoodosPhoneNotification",
      "qoodosEmailNotification",
      "askForSubscriptionToQoodos",
      "franchiseId" /*, 'notificationSms', 'notificationEmail', 'city', 'shop'*/,
    ];
    const form = new FormData();
    if (params.image) {
      params.image.map((file) => {
        form.append("imageFile", file, file.name);
      });
    }
    profilePropreties.forEach((propretyName) => {
      if (typeof params[propretyName] !== "undefined") {
        form.append(propretyName, params[propretyName]);
      }
    });

    // for FormData form.entries().next().done equal true means that the form is empty
    if (form.entries().next().done) {
      return { ok: false };
    }
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
    return api.post("customers/" + params.id + "/update", form, { headers });
  };

  const resetPasswordInit = (params) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const email = params.email;
    // url to send into reset mail confirmation
    const url = params.url;
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.post("customers/password-reset/request", { email, url }, { headers });
  };

  const updatePassword = ({ userId, oldPassword, password }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/merge-patch+json",
    };
    return api.patch("customers/" + userId + "/update-password", { old_password: oldPassword, new_password: password }, { headers });
  };

  const checkResetPasswordToken = (token) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.get("customers/password-reset/check-token", { token }, { headers });
  };

  const resetPassword = ({ password, token }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };
    return api.patch("customers/password-reset", { plainPassword: password, token }, { headers });
  };

  const setAuthToken = ({ token }) => {
    return api.setHeaders({
      Authorization: "Bearer " + token,
    });
  };

  const removeAuthToken = () => api.setHeader("Authorization", "");

  const getOrders = (userId, { franchise, page }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    if (!api.headers?.Authorization) {
      return { ok: false };
    }
    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
    };
    return api.get("customers/" + (userId ?? "") + "/orders", { franchise, page, "order[date]": "desc" }, { headers });
  };

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getFranchise = (id) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    // DOMAIN_TEST
    return api.get(`franchises/domain`, { url: __DEV__ ? DOMAIN_TEST : id }, { headers });
  };

  const getShop = (id) => {
    const key = "getShop_" + id;
    const lastResponse = window.responsesStore?.[key];
    const now = moment();
    const responseIsStillValid = lastResponse?.time && now.diff(lastResponse?.time) < 30000;
    if (responseIsStillValid && lastResponse?.response) {
      return lastResponse.response;
    }

    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    const getResponse = async () => {
      return await api.get("shops/" + id, {}, { headers });
    };
    const response = getResponse();
    if (!window.responsesStore) {
      window.responsesStore = {};
    }
    window.responsesStore[key] = { time: now, response: response };

    return response;
  };

  const getAddresses = (userId) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
    };
    if (!api?.headers?.["franchise-token"]) {
      //Si actualisé et pas encore seter franchise-token
      const franchiseToken = window.storeData?.getState?.()?.franchise?.franchiseData?.token ?? "";
      if (franchiseToken) {
        headers["franchise-token"] = franchiseToken;
      }
    }
    return api.get(`customers/${userId}/delivery_addresses`, { page: 1 }, { headers });
  };

  const createAddresses = (address) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    return api.post(`delivery_addresses`, address);
  };

  const updateAddresses = (address) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    return api.put(`delivery_addresses/${address.id}`, address);
  };

  const deleteAddresses = (idAddress) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    return api.delete(`delivery_addresses/${idAddress}`);
  };

  const setDefaultAddresses = ({ idAddress, isDefault }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/merge-patch+json",
    };
    return api.patch(`delivery_addresses/${idAddress}/set-default`, { isDefault }, { headers });
  };

  const contactUs = (contactUs) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.post(`contacts`, contactUs, { headers });
  };

  const getCities = () => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.get("cities", {}, { headers });
  };

  /* ----------------------------------------------Card--------------------------------------------- */

  const getMenu = ({ shopId, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.get(`/shops/${shopId}/menuses`, { enabled }, { headers });
  };

  const getCategories = ({ menuId, status, page }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.get(`/menus/${menuId}/categories`, { enabled: status, page }, { headers });
  };

  const getProducts = ({ menuId, status, page }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };
    return api.get(`/menus/${menuId}/products`, { enabled: status, page }, { headers });
  };

  const getOptions = ({ shopId, status, page }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    return api.get(`/shops/${shopId}/options`, { enabled: status, page }, { headers });
  };

  const getOptionsByProduct = ({ productId, status }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.get(`/products/${productId}/options`, { enabled: status }, { headers });
  };

  const getShopModes = ({ shopId, status }) => {
    const key = functions.string_to_slug("getShopModes_" + JSON.stringify({ shopId, status }));
    const lastResponse = window.responsesStore?.[key];
    const now = moment();
    const responseIsStillValid = lastResponse?.time && now.diff(lastResponse?.time) < 30000;
    if (responseIsStillValid && lastResponse?.response) {
      return lastResponse.response;
    }
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    const getResponse = async () => {
      const finalResponse = await api.get(`/shops/${shopId}/shop_modes`, { enabled: status }, { headers });
      return finalResponse;
    };
    const response = getResponse();
    if (!window.responsesStore) {
      window.responsesStore = {};
    }
    window.responsesStore[key] = { time: now, response: response };
    return response;
  };

  const getAvailableOrderingHoursByShop = ({ shopId, orderingHoursDate }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.get("/ordering_hours/time_slots", { shop: shopId, date: orderingHoursDate, enabled: true }, { headers });
  };

  const checkCodePromotion = ({ shopModeId, code }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.get(`/promotions/check`, { shopMode: shopModeId, code, visible: true }, { headers });
  };

  /* ------------------------------------------ Orders --------------------------------------------- */

  const addOrder = async ({ order, deviceToken }) => {
    const methodApi = order?.id ? "put" : "post";
    const ressourceUrl = methodApi === "put" ? "/orders/" + order?.id : "/orders";
    if (!!api.headers?.Authorization) {
      const headers = {
        "Cache-Control": "no-cache",
        ...(deviceToken ? { "device-token": deviceToken } : {}),
      };
      return api[methodApi](ressourceUrl, order, { headers });
    } else {
      const headers = {
        "Cache-Control": "no-cache",
        ...getHeaders(api),
        // ...(deviceToken ? { "device-token": deviceToken } : {}),
      };
      return api[methodApi](ressourceUrl, order, { headers });
    }
    // return {ok: false};
    // api.setBaseURL(Secrets.LOCAL_API_URL);
    // return api.get('orders/', { order, idUser });
  };

  const getOrdersByUser = (idUser) => {
    return {};
    // api.setBaseURL(Secrets.LOCAL_API_URL);
    // return api.get('orders/', { idUser });
  };

  const getOrdersByUserAndSlot = ({ idUser, slot }) => {
    return {};
    // api.setBaseURL(Secrets.LOCAL_API_URL);
    // return api.get('orders/', { idUser, slot });
  };

  //////////////////////// Payment ////////////////////////////

  // const getStripeClientSecret = ({ amount, currency, paymentMethodTypes }) => {
  //   api.setBaseURL("http://localhost:4242");
  //   const headers = {
  //     'Cache-Control': 'no-cache',
  //     "Access-Control-Allow-Origin": '*',
  //   }
  //   return api.get('/create-payment-intent', { amount, currency, paymentMethodTypes }, { headers });
  // }

  const updatePaymentIntent = ({ amount, email, name, paymentIntentId }) => {
    api.setBaseURL("http://localhost:4242");
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };
    return api.get("/update-payment-intent", { amount, email, name, paymentIntentId }, { headers });
  };

  const paymentWithPaygreen = ({ payment }) => {
    api.setBaseURL("http://localhost:4242");
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };
    return api.get("/payment-with-paygreen", { payment }, { headers });

    /*
    const identifiant = Secrets.PAYMENTS_DATA.PAYGREEN.IDENTIFIANT;
    const privateKay = Secrets.PAYMENTS_DATA.PAYGREEN.PRIVATE_KAY;
    api.setBaseURL(`https://sandbox.paygreen.fr/api/${identifiant}/payins/transaction/cash`);
    const headers = {
      'Cache-Control': 'no-cache',
      "Access-Control-Allow-Origin": '*',
      "Authorization": `Bearer ${privateKay}`,
    }
    return api.post('', {...payment}, { headers });*/
  };

  // const paymentWithPayxpert = ({ cart, amount }) => {

  //   // api.setBaseURL("http://localhost:4242");
  //   const headers = {
  //     'Cache-Control': 'no-cache',
  //     "Access-Control-Allow-Origin": '*',
  //   }
  //   return api.get('/create-payment-with-payxpert', { cart, amount }, { headers });
  // }

  const createPayment = async ({ paymentMethodId, orderId, subPaymentMethod, amountToPay, posNumber }) => {
    // return { ok: true, data: { merchantId: "228_382050682", transactionNumber: "0060" } };
    api.setBaseURL(Secrets.LOCAL_API_URL);
    if (!!api.headers?.Authorization) {
      return api.post("/payments/create", {
        orderId,
        paymentMethodId,
        amount: amountToPay,
        ...(posNumber ? { posNumber } : {}),
        ...(subPaymentMethod ? { method: subPaymentMethod } : {}),
      });
    } else {
      const headers = {
        "Cache-Control": "no-cache",
        ...getHeaders(api),
      };
      return api.post(
        "/payments/create",
        { orderId, paymentMethodId, amount: amountToPay, ...(posNumber ? { posNumber } : {}), ...(subPaymentMethod ? { method: subPaymentMethod } : {}) },
        { headers }
      );
    }
  };

  const sendPaymentResult = async ({ transactionNumber, result }) => {
    // return { ok: true, data: { merchantId: "228_382050682", transactionNumber: "0060" } };
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.post("/payments/result", { payment_external_id: transactionNumber, result }, { headers });
  };

  const orderCancel = (orderId) => {
    const headers = {
      "Cache-Control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/merge-patch+json",
    };
    return api.patch(`/orders/${orderId}/cancel`, {}, { headers });
  };

  // synch
  // closing days

  const getClosingDaysSync = ({ shopId, since, page, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    // const headers = {
    //   'Cache-Control': 'no-cache',
    //   Authorization: 'Bearer ' + newToken,
    // }

    return api.get("/closing_days/sync/", { shopId, since, page, enabled }, { headers });
  };

  const getOpeningHoursSync = ({ shopId, since, page, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    // const headers = {
    //   'Cache-Control': 'no-cache',
    //   Authorization: 'Bearer ' + newToken,
    // }

    return api.get("/opening_hours/sync/", { shopId, since, page, enabled }, { headers });
  };

  // shops sync

  const getShopsSync = ({ franchiseId, since, page, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    // const headers = {
    //   'Cache-Control': 'no-cache',
    //   Authorization: 'Bearer ' + newToken,
    // }

    return api.get("/shops/sync/", { franchiseId, since, page, enabled }, { headers });
  };

  // Categories sync

  const getCategoriesSync = ({ menuId, since, page, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    // const headers = {
    //   'Cache-Control': 'no-cache',
    //   Authorization: 'Bearer ' + newToken,
    // }

    return api.get(`/menus/${menuId}/categories/sync/`, { since, page, publish: enabled, enabled }, { headers });
  };

  // Products sync

  const getProductsSync = ({ menuId, since, page, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    // const headers = {
    //   'Cache-Control': 'no-cache',
    //   Authorization: 'Bearer ' + newToken,
    // }

    return api.get(`/menus/${menuId}/products/sync/`, { since, page, publish: enabled, enabled }, { headers });
  };

  // Options sync

  const getOptionsSync = ({ shopId, since, page, enabled }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      Accept: "application/ld+js",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };

    // const headers = {
    //   'Cache-Control': 'no-cache',
    //   Authorization: 'Bearer ' + newToken,
    // }

    return api.get("/options/sync/", { shopId, since, page, enabled }, { headers });
  };

  // Fid qoodos
  const qoodosSubscription = () => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    return api.post("/customers/qoodos-subscription", {});
  };

  const qoodosUnSubscription = () => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    return api.delete("/customers/qoodos-unsubscription", {});
  };

  const searchCustomerByFranchise = ({ email, franchiseId }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.get("/customers/search", { email, franchiseId }, { headers });
  };

  const shareUserDataToFranchise = ({ customerId, franchiseId }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/merge-patch+json",
      ...getHeaders(api),
    };
    return api.patch("/customers/subscribe-existing", { user_id: customerId, franchiseId }, { headers });
  };

  const getLocales = ({ generatedToken }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      Authorization: "Bearer " + generatedToken,
    };
    return api.get("locales", {}, { headers });
  };

  const generateToken = ({ env }) => {
    // http://localhost:4000
    // api.setBaseURL(Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242");
    const baseURL = Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242";
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };
    return api.get("/generate-token", { env }, { headers, baseURL });
  };

  const getGeneratedToken = ({ env }) => {
    // http://localhost:4000
    // api.setBaseURL(Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242");
    const baseURL = Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242";
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };
    return api.get("/get-generated-token", { env }, { headers, baseURL });
  };

  const setDefaultLanguage = async ({ language, userId }) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/merge-patch+json",
    };
    // return {ok: true};
    return api.patch("customers/" + userId + "/set-default-language", { locale_code: language }, { headers });
  };

  const sendNeptingRetail = ({ env }) => {
    // http://localhost:4000
    // api.setBaseURL(Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242");
    const baseURL = Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242";
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };
    return api.get("/send-nepting-retail", { env }, { headers, baseURL });
  };

  const getResultNeptingRetail = ({ env, merchantId, transactionNumber }) => {
    // http://localhost:4000
    // api.setBaseURL(Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242");
    const baseURL = Secrets.IS_DEV_OR_STAGING ? "https://staging-foxorders.fr:4242" : "https://foxorders.fr:4242";
    const headers = {
      "Cache-Control": "no-cache",
      "Access-Control-Allow-Origin": "*",
    };
    return api.get("/get-result-nepting-retail", { env, merchantId, transactionNumber }, { headers, baseURL });
  };

  const googleAuth = async ({ franchiseId, shopId, apiCode, qoodosSubscription, qoodosEmailNotification, qoodosPhoneNotification, redirectUri }) => {
    // return { ok: false, data: { error: "test error" } };
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.post(
      "customers/authenticate/google",
      { franchiseId, shopId, apiCode, qoodosSubscription, qoodosEmailNotification, qoodosPhoneNotification, redirectUri },
      { headers }
    );
  };

  const appleAuth = async ({ franchiseId, shopId, apiCode, qoodosSubscription, qoodosEmailNotification, qoodosPhoneNotification, redirectUri }) => {
    // return { ok: false, data: { error: "test error" } };
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    return api.post(
      "customers/authenticate/apple",
      { franchiseId, shopId, apiCode, qoodosSubscription, qoodosEmailNotification, qoodosPhoneNotification, redirectUri },
      { headers }
    );
  };

  const setFranchiseToken = ({ token }) => {
    return api.setHeaders({ "franchise-token": token });
  };
  const setShopToken = ({ token }) => {
    return api.setHeaders({ "shop-token": token });
  };

  const getDevices = () => {
    api.setBaseURL(Secrets.LOCAL_API_URL);
    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };
    return api.get("/devices", { page: 1 }, { headers });
  };

  const setDeviceToken = ({ token }) => {
    if (!token) {
      return api;
    }
    return api.setHeaders({ "device-token": token });
  };

  const setApiSource = (source) => {
    return api.setHeaders({ source: source });
  };

  const getPayementPayPlugResult = ({ payplugPaymentId }) => {
    api.setBaseURL(Secrets.LOCAL_API_URL);

    const headers = {
      "Cache-Control": "no-cache",
      ...getHeaders(api),
    };

    // return { ok: false, data: { error: "test error" } };
    // return { ok: true, data: { state: "awaiting_payment" } };
    // return { ok: true, data: { state: "paid" } };
    // return { ok: true, data: { state: "error" } };

    return api.get(`payments/${payplugPaymentId}/status`, {}, { headers });
  };

  return {
    // a list of the API functions from step 2
    login,
    signUp,
    updateProfile,
    resetPasswordInit,
    checkResetPasswordToken,
    resetPassword,
    removeAuthToken,
    setAuthToken,
    refreshTokenAndLogin,
    getUser,
    getOrdersByUser,
    addOrder,
    getOrders,
    getOrdersByUserAndSlot,
    getFranchise,
    getAddresses,
    updateAddresses,
    createAddresses,
    deleteAddresses,
    setDefaultAddresses,
    updatePassword,
    getShop,
    contactUs,
    getCities,
    getMenu,
    getCategories,
    getProducts,
    getOptions,
    getOptionsByProduct,
    getShopModes,
    getAvailableOrderingHoursByShop,
    // getStripeClientSecret,
    updatePaymentIntent,
    paymentWithPaygreen,
    // paymentWithPayxpert,
    checkCodePromotion,
    createPayment,
    orderCancel,
    getClosingDaysSync,
    getOpeningHoursSync,
    getShopsSync,
    getCategoriesSync,
    getProductsSync,
    getOptionsSync,
    qoodosSubscription,
    qoodosUnSubscription,
    searchCustomerByFranchise,
    shareUserDataToFranchise,
    getLocales,
    generateToken,
    getGeneratedToken,
    setDefaultLanguage,
    sendNeptingRetail,
    getResultNeptingRetail,
    googleAuth,
    appleAuth,
    setFranchiseToken,
    setShopToken,
    getDevices,
    setDeviceToken,
    setApiSource,
    sendPaymentResult,
    getPayementPayPlugResult,
  };
};

// let's return back our create method as the default.
export default {
  create,
};
