import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  synchronisationRequest: null,
  synchronisationSuccess: ["synchronisationData"],
  synchronisationFailure: ["synchronisationError", "synchronisationErrors"],
  setIdleSkipRequest: ["idleSkipRequest"],
});

export const SynchronisationTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  synchronisationFetching: false,
  synchronisationError: null,
  synchronisationErrors: null,
  idleSkipRequest: false,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

/* ------------- Selectors ------------- */
export const SynchronisationSelectors = { getIdleSkipRequest: (state) => state.synchronisation?.idleSkipRequest };

/* ------------- Reducers ------------- */

export const synchronisationRequest = (state) => state.merge({ synchronisationFetching: true });

export const synchronisationSuccess = (state, {}) => {
  return state.merge({
    synchronisationFetching: false,
    synchronisationError: null,
    synchronisationErrors: null,
  });
};

export const synchronisationFailure = (state, { synchronisationError, synchronisationErrors }) =>
  state.merge({
    synchronisationFetching: false,
    synchronisationError,
    synchronisationErrors,
  });

export const setIdleSkipRequest = (state, { idleSkipRequest }) =>
  state.merge({
    idleSkipRequest,
  });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SYNCHRONISATION_REQUEST]: synchronisationRequest,
  [Types.SYNCHRONISATION_SUCCESS]: synchronisationSuccess,
  [Types.SYNCHRONISATION_FAILURE]: synchronisationFailure,
  [Types.SET_IDLE_SKIP_REQUEST]: setIdleSkipRequest,
});
