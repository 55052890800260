import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import { decodeToken } from "react-jwt";
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ["email", "password", "from", "refreshToken", "stayConnected", "franchiseId"],
  loginAndCustomerSearchRequest: ["email", "password", "from", "refreshToken", "stayConnected", "franchiseId"],
  loginSuccess: ["auth", "credentials"],
  loginFailure: ["error", "email", "errors"],

  resetErrors: null,
  logoutRequest: null,
  logoutSuccess: null,

  autoLoginRequest: null,
  autoLoginSuccess: null,
  autoLoginFailure: null,

  signupRequest: ["params"],
  signupSuccess: ["auth", "credentials"],
  signupFailure: ["signupError", "signupErrors", "email"],

  updateProfileRequest: ["params"],
  updateProfileSuccess: ["credentials"],
  updateProfileFailure: ["error", "errors"],

  resetPasswordInitRequest: ["email"],
  resetPasswordInitSuccess: null,
  resetPasswordInitFailure: ["error", "errors"],

  resetPasswordRequest: ["password", "token"],
  resetPasswordSuccess: null,
  resetPasswordFailure: ["error", "errors"],

  checkResetPasswordTokenRequest: ["token"],
  checkResetPasswordTokenSuccess: null,
  checkResetPasswordTokenFailure: ["error", "errors"],

  socialAuthenticationRequest: ["socialAuthenticationParms"],
  socialAuthenticationSuccess: ["auth", "credentials"],
  socialAuthenticationFailure: ["error", "errors", "email"],

  setAskedForSubscriptionToQoodos: ["isAsked"],
});

export const LoginTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  auth: null,
  credentials: null,
  error: null,
  errors: null,
  fetching: false,
  autoLoginAttempt: false,
  signupError: null,
  signupErrors: null,
  signupFetching: false,
  successfulSignup: false,
  initializingResetPassword: false,
  resetPasswordInitError: null,
  resetPasswordInitErrors: null,
  resetPasswordTokenChecked: false,
  resetPasswordTokenValidated: false,
  checkResetPasswordTokenError: false,
  checkResetPasswordTokenErrors: false,
  resettingPassword: false,
  resetPasswordSucceeded: false,
  resetPasswordError: null,
  resetPasswordErrors: null,
  lockRefreshToken: false,
  stayConnected: false,
  socialAuthenticationFetching: false,
  socialAuthenticationError: null,
  socialAuthenticationErrors: null,
  authenticationMode: null,
  askedForSubscriptionToQoodos: false,
});

/* ------------- Selectors ------------- */
export const LoginSelectors = {
  getAuthentication: (state) => state.login?.auth,
  getCredentials: (state) => state.login?.credentials,
  getLockRefreshToken: (state) => state.login.lockRefreshToken,
  getToken: (state) => state.login?.auth?.token,
  getTokenDecode: (state) => decodeToken(state.login?.auth?.token),
  getStayConnectedValue: (state) => state.login.stayConnected,
  getAuthenticationMode: (state) => state.login?.authenticationMode,
  getAskedForSubscriptionToQoodos: (state) => state.login?.askedForSubscriptionToQoodos,
};

/* ------------- Reducers ------------- */

// we're attempting to login
export const request = (state, { email, password, stayConnected }) =>
  state.merge({
    fetching: true,
    credentials: {
      email,
      password,
    },
    lockRefreshToken: true,
    stayConnected: !!stayConnected,
    authenticationMode: null,
    askedForSubscriptionToQoodos: false,
  });

export const loginAndCustomerSearchRequest = (state) => state.merge({ fetching: true });

// we've successfully logged in
export const success = (state, { auth, credentials }) => {
  return state.merge({
    fetching: false,
    error: null,
    errors: null,
    auth,
    credentials,
    lockRefreshToken: false,
    authenticationMode: 'standard'
  });
};

// we've had a problem to log in
export const failure = (state, { error, email, errors }) =>
  state.merge({
    fetching: false,
    auth: null,
    credentials: email
      ? {
          email,
          password: null,
        }
      : null,
    error,
    errors,
    lockRefreshToken: true,
    authenticationMode: null
  });

// we need to logout, meaning clear access tokens and everything
export const logoutRequest = (state) => state;
// we've logged out
export const logoutSuccess = (state) => INITIAL_STATE;

// we're attempting to auto login (from startup saga)
export const autoLogin = (state) => state.merge({ autoLoginAttempt: true, lockRefreshToken: false });
export const autoLoginSuccess = (state) => state.merge({ autoLoginAttempt: false, lockRefreshToken: false });
export const autoLoginFailure = (state) => state.merge({ error: null, errors: null, autoLoginAttempt: false, lockRefreshToken: false });

// we're attempting to signup
export const signupRequest = (state, { params }) => {
  return state.merge({
    signupFetching: true,
    credentials: {
      email: params?.email,
      password: params?.password,
    },
  });
};

// we've successfully signed up
export const signupSuccess = (state, { auth, credentials }) => {
  return state.merge({
    signupFetching: false,
    signupError: null,
    signupErrors: null,
    successfulSignup: true,
    // auth,
    // credentials
  });
};

// we've had a problem to sign up
export const signupFailure = (state, { signupError, signupErrors, email }) =>
  state.merge({
    signupFetching: false,
    auth: null,
    credentials: email
      ? {
          email,
          password: null,
        }
      : null,
    signupError,
    signupErrors,
  });

// update profile
export const updateProfileRequest = (state, { params }) => state.merge({ signupFetching: true });

export const updateProfileSuccess = (state, { credentials }) => {
  const { credentials: oldCredentials = {} } = state;
  const newCredentials = { ...oldCredentials };
  const email = credentials?.email;
  if (!!email) {
    newCredentials.email = email;
  }
  const password = credentials?.password;
  if (!!password) {
    newCredentials.password = password;
  }
  return state.merge({
    signupFetching: false,
    signupError: null,
    signupErrors: null,
    credentials: newCredentials,
  });
};

export const updateProfileFailure = (state, { error, errors }) =>
  state.merge({
    signupFetching: false,
    signupError: error,
    signupErrors: errors,
  });

/* reset password init */
export const resetPasswordInitRequest = (state) => state.merge({ initializingResetPassword: true });

export const resetPasswordInitSuccess = (state) => {
  return state.merge({
    initializingResetPassword: false,
    resetPasswordInitError: null,
    resetPasswordInitErrors: null,
  });
};

export const resetPasswordInitFailure = (state, { error, errors }) =>
  state.merge({
    initializingResetPassword: false,
    resetPasswordInitError: error,
    resetPasswordInitErrors: errors,
  });

/* check reset password token */
export const checkResetPasswordTokenRequest = (state) => state.merge({ resetPasswordTokenChecked: false, resetPasswordTokenValidated: false });

export const checkResetPasswordTokenSuccess = (state) => {
  return state.merge({
    resetPasswordTokenChecked: true,
    resetPasswordTokenValidated: true,
    checkResetPasswordTokenError: null,
    checkResetPasswordTokenErrors: null,
  });
};

export const checkResetPasswordTokenFailure = (state, { error, errors }) =>
  state.merge({
    resetPasswordTokenChecked: true,
    resetPasswordTokenValidated: false,
    checkResetPasswordTokenError: error,
    checkResetPasswordTokenErrors: errors,
  });

/* reset password */
export const resetPasswordRequest = (state) => resetErrors(state).merge({ resettingPassword: true, resetPasswordSucceeded: false });

export const resetPasswordSuccess = (state) => {
  return state.merge({
    resettingPassword: false,
    resetPasswordSucceeded: true,
    resetPasswordError: null,
    resetPasswordErrors: null,
  });
};

export const resetPasswordFailure = (state, { error, errors }) =>
  state.merge({
    resettingPassword: false,
    resetPasswordSucceeded: false,
    resetPasswordError: error,
    resetPasswordErrors: errors,
  });

export const resetErrors = (state) =>
  state.merge({
    fetching: false,
    error: null,
    errors: null,
    signupFetching: false,
    signupError: null,
    signupErrors: null,
    resetPasswordInitError: null,
    resetPasswordInitErrors: null,
    checkResetPasswordTokenError: null,
    checkResetPasswordTokenErrors: null,
    resetPasswordError: null,
    resetPasswordErrors: null,
    successfulSignup: false,
    socialAuthenticationError: null,
    socialAuthenticationErrors: null,
  });

/* social Authentication */
export const socialAuthenticationRequest = (state, { socialAuthenticationParms }) => {
  const stayConnected = socialAuthenticationParms.stayConnected;
  return state.merge({ socialAuthenticationFetching: true, stayConnected: !!stayConnected, authenticationMode: null, askedForSubscriptionToQoodos: false });
};

export const socialAuthenticationSuccess = (state, { auth, credentials }) => {
  return state.merge({
    socialAuthenticationFetching: false,
    socialAuthenticationError: null,
    socialAuthenticationErrors: null,
    auth,
    credentials,
    authenticationMode: 'oAuth',
  });
};

export const socialAuthenticationFailure = (state, { error, errors }) => {
  return state.merge({
    socialAuthenticationFetching: false,
    socialAuthenticationError: error,
    socialAuthenticationErrors: errors,
    authenticationMode: null,
  });
};

export const setAskedForSubscriptionToQoodos = (state, {isAsked}) => state.merge({ askedForSubscriptionToQoodos: isAsked });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_AND_CUSTOMER_SEARCH_REQUEST]: loginAndCustomerSearchRequest,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,
  [Types.RESET_ERRORS]: resetErrors,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,

  [Types.AUTO_LOGIN_REQUEST]: autoLogin,
  [Types.AUTO_LOGIN_SUCCESS]: autoLoginSuccess,
  [Types.AUTO_LOGIN_FAILURE]: autoLoginFailure,

  [Types.SIGNUP_REQUEST]: signupRequest,
  [Types.SIGNUP_SUCCESS]: signupSuccess,
  [Types.SIGNUP_FAILURE]: signupFailure,

  [Types.UPDATE_PROFILE_REQUEST]: updateProfileRequest,
  [Types.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
  [Types.UPDATE_PROFILE_FAILURE]: updateProfileFailure,

  [Types.RESET_PASSWORD_INIT_REQUEST]: resetPasswordInitRequest,
  [Types.RESET_PASSWORD_INIT_SUCCESS]: resetPasswordInitSuccess,
  [Types.RESET_PASSWORD_INIT_FAILURE]: resetPasswordInitFailure,

  [Types.CHECK_RESET_PASSWORD_TOKEN_REQUEST]: checkResetPasswordTokenRequest,
  [Types.CHECK_RESET_PASSWORD_TOKEN_SUCCESS]: checkResetPasswordTokenSuccess,
  [Types.CHECK_RESET_PASSWORD_TOKEN_FAILURE]: checkResetPasswordTokenFailure,

  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,

  [Types.SOCIAL_AUTHENTICATION_REQUEST]: socialAuthenticationRequest,
  [Types.SOCIAL_AUTHENTICATION_SUCCESS]: socialAuthenticationSuccess,
  [Types.SOCIAL_AUTHENTICATION_FAILURE]: socialAuthenticationFailure,

  [Types.SET_ASKED_FOR_SUBSCRIPTION_TO_QOODOS]: setAskedForSubscriptionToQoodos,
});
