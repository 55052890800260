import { put, call, take, select, all } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import ShopActions, { ShopSelectors, ShopTypes } from "../Redux/ShopRedux";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import MenuActions from "../Redux/MenuRedux";
import functions from "../Shared/functions";
import { synchronisation } from "./SynchronisationSagas";

export function* getShop(api, { shopId }) {
  if (!shopId) {
    yield put(ShopActions.getShopFailure("mandatoryParamsError", null));
    return false;
  }
  const apiCall = call(api.getShop, shopId);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield call(api.setShopToken, response.data);
    // data to send 'shopData', 'isForShop', "isForClosingDays", "isForOpeningHours"
    yield put(ShopActions.getShopSuccess(response.data, true, false, false, true));

    // sync closing days & opening hours
    yield all([call(getSynchClosingDays, api, {}), call(getSynchOpeningHours, api, {})]);
  } else {
    yield put(ShopActions.getShopFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* setSelectedShop(api, { shop }) {
  if (!shop) {
    return null;
  }
  yield call(getShop, api, { shopId: shop.id });
  yield call(getShopModes, api, { shopId: shop.id, status: true });

  yield put(MenuActions.getMenuRequest(shop.id, true));
}

export function* getShopTempDetail(api, { shopId }) {
  if (!shopId) {
    yield put(ShopActions.getShopFailure("mandatoryParamsError", null));
    return false;
  }
  const apiCall = call(api.getShop, shopId);
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(ShopActions.getShopTempDetailSuccess(response.data));
  } else {
    yield put(ShopActions.getShopTempDetailFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* getShopModes(api, { shopId, status, context }) {
  const apiCall = call(api.getShopModes, { shopId, status, context });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(ShopActions.getShopModesSuccess(response.data, shopId, context));
  } else {
    yield put(ShopActions.getShopModesFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* getAvailableOrderingHoursByShop(api, { shopId, orderingHoursDate }) {
  const apiCall = call(api.getAvailableOrderingHoursByShop, { shopId, orderingHoursDate });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(ShopActions.getAvailableOrderingHoursByShopSuccess(shopId, orderingHoursDate, response.data));
  } else {
    yield put(ShopActions.getAvailableOrderingHoursByShopFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

// synch related shop

// closing days
export function* getSynchClosingDays(api, {}) {
  const shop = yield select(ShopSelectors.getShop);

  const endPointApi = "getClosingDaysSync";

  if (!shop?.id) return;

  const params = { shopId: shop?.id };
  const getSinceSelectors = ShopSelectors.getSinceClosingDays;

  yield call(synchronisation, api, {
    endPointApi,
    params,
    getSinceSelectors,
    callbackFunc: function* (resultSync) {
      if (resultSync.length > 0) {
        const shopFinal = yield select(ShopSelectors.getShop);
        const finalShop = shopFinal?.isImmutable ? shopFinal.asMutable({ deep: true }) : shopFinal;
        const finalArraySync = functions.getFinalArraySync(finalShop.closingDays, resultSync);
        const formatedShop = { ...finalShop, closingDays: finalArraySync };
        yield put(ShopActions.getShopSuccess(formatedShop, false, true, false, false));
        // data to send 'shopData', 'isForShop', "isForClosingDays", "isForOpeningHours"
      } else {
        yield put(ShopActions.setSinceClosingDays());
      }
    },
  });
}

// Opening hours
export function* getSynchOpeningHours(api, {}) {
  const shop = yield select(ShopSelectors.getShop);
  const endPointApi = "getOpeningHoursSync";

  if (!shop?.id) return;

  const params = { shopId: shop?.id };
  const getSinceSelectors = ShopSelectors.getSinceOpeningHours;

  yield call(synchronisation, api, {
    endPointApi,
    params,
    getSinceSelectors,
    callbackFunc: function* (resultSync) {
      if (resultSync.length > 0) {
        const shopFinal = yield select(ShopSelectors.getShop);
        const finalShop = shopFinal?.isImmutable ? shopFinal.asMutable({ deep: true }) : shopFinal;
        const finalArraySync = functions.getFinalArraySync(functions.getEnabledOpeningHours(finalShop?.openingHours), resultSync);
        const formatedShop = { ...finalShop, openingHours: finalArraySync };
        yield put(ShopActions.getShopSuccess(formatedShop, false, false, true, false));
        // data to send 'shopData', 'isForShop', "isForClosingDays", "isForOpeningHours"
      } else {
        yield put(ShopActions.setSinceOpeningHours());
      }
    },
  });
}

export function* setUserCanOrder() {
  const selectedShop = yield select(ShopSelectors.getShop);
  const selectedMode = yield select(ShopSelectors.getSelectedMode);
  const availableOrderingHoursByShop = yield select(ShopSelectors.getAvailableOrderingHoursByShop);
  if (selectedShop) {
    const isUserCanOrder = functions.canUserOrder(selectedShop, selectedMode, availableOrderingHoursByShop);
    yield put(ShopActions.setUserCanOrderSuccess(isUserCanOrder));
  }
  yield take([
    ShopTypes.SET_SELECTED_SHOP,
    ShopTypes.GET_SHOP_SUCCESS,
    ShopTypes.GET_SHOP_MODES_SUCCESS,
    ShopTypes.GET_AVAILABLE_ORDERING_HOURS_BY_SHOP_SUCCESS,
  ]);
  yield call(setUserCanOrder);
}
