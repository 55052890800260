import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import Option from "../Logic/Entities/Option";
import functions from "../Shared/functions";
import { dateFormatSyncInStore, serverTimeZone } from "../Shared/Constants";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getOptionsRequest: ["shopId", "status", "page"],
  getOptionsSuccess: ["optionsData", "selctedShop", "shopTimeZone"],
  getOptionsFailure: ["error", "errors"],

  setSinceOptions: ["shopTimeZone"],
  getOptionsFromSyncSuccess: ["optionsData", "selctedShop", "shopTimeZone"],
});

export const OptionsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  optionsFetching: false,
  optionsError: null,
  optionsErrors: null,
  optionsData: null,
  optionsPaginationData: null,
  sinceOptions: false,
  lastOptionsSyncTime: null,
  toggleRefreshOptions: false,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

function getImmutableAsObj(immutable) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return entityObj;
}

function getSortedOptions(options, shopId) {
  if (!options) return null;
  const sortedOptions = Immutable(getImmutableAsObj(options?.[shopId])?.sort((a, b) => a.position - b.position));
  return sortedOptions?.map((option) => getImmutableAsEntity(option, Option));
}

/* ------------- Selectors ------------- */
export const OptionsSelectors = {
  getOptions: (state) => getSortedOptions(state.option?.optionsData, state.shop?.shopData?.id), //state.option?.optionsData?.[state.shop?.shopData?.id]?.map((option) => getImmutableAsEntity(option, Option)),
  getOptionsPagination: (state) => state?.option?.optionsPaginationData?.[state.shop?.shopData?.id],
  getSinceOptions: (state) => state?.option?.sinceOptions ?? false,
  getToggleRefreshOptions: (state) => state?.option?.toggleRefreshOptions,
  getLastOptionsSyncTime: (state) => state?.option?.lastOptionsSyncTime ?? null,
};

/* ------------- Reducers ------------- */

export const getOptionsRequest = (state) => state.merge({ optionsFetching: true });

export const getOptionsSuccess = (state, { optionsData, selctedShop, shopTimeZone }) => {
  const optionsPaginationDataByShop = {
    [selctedShop?.id]: optionsData["hydra:view"]
      ? {
          first: optionsData["hydra:view"]["hydra:first"],
          last: optionsData["hydra:view"]["hydra:last"],
          next: optionsData["hydra:view"]["hydra:next"],
          current: optionsData["hydra:view"]["@id"],
          totalItems: optionsData["hydra:totalItems"],
        }
      : { first: 0, last: 0, next: 0, current: 0, totalItems: 0 },
  };
  const optionsPaginationData = { ...state.optionsPaginationData, ...optionsPaginationDataByShop };
  const fetechedOptions = optionsData["hydra:member"]?.map((option) => {
    option.optionVariants = option.optionVariants?.filter?.((optionVariant) => optionVariant.enabled === true);
    return option;
  });
  const formatedOptionsByShop = state.optionsData
    ? { [selctedShop?.id]: [...(state.optionsData[selctedShop?.id] ?? []), ...fetechedOptions] }
    : { [selctedShop?.id]: [...fetechedOptions] };
  const allOptionsData = { ...state.optionsData, ...formatedOptionsByShop };
  const sinceOptions = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  if (__DEV__) console.log("foxOrders allOptionsData", allOptionsData);

  return state.merge({
    optionsData: allOptionsData,
    optionsPaginationData,
    optionsFetching: false,
    optionsError: null,
    optionsErrors: null,
    sinceOptions,
  });
};

export const getOptionsFailure = (state, { optionsError, optionsErrors }) =>
  state.merge({
    optionsFetching: false,
    optionsError,
    optionsErrors,
  });

export const setSinceOptions = (state, { shopTimeZone }) => {
  const sinceOptions = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({ sinceOptions });
};

export const getOptionsFromSyncSuccess = (state, { optionsData, selctedShop, shopTimeZone }) => {
  const optionsPaginationDataByShop = { [selctedShop?.id]: { ...state.optionsPaginationData[selctedShop?.id], totalItems: optionsData.length } };
  const optionsPaginationData = { ...state.optionsPaginationData, ...optionsPaginationDataByShop };

  const synchedOptions = optionsData?.map((option) => {
    option.optionVariants = option.optionVariants?.filter?.((optionVariant) => optionVariant.enabled === true);
    return option;
  });
  const formatedOptionsByShop = { [selctedShop?.id]: [...synchedOptions] };
  const allOptionsData = { ...state.optionsData, ...formatedOptionsByShop };
  const sinceOptions = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  if (__DEV__) console.log("foxOrders getOptionsFromSyncSuccess allOptionsData", allOptionsData);

  return state.merge({
    optionsData: allOptionsData,
    optionsPaginationData,
    optionsFetching: false,
    optionsError: null,
    optionsErrors: null,
    sinceOptions,
    lastOptionsSyncTime: sinceOptions,
    toggleRefreshOptions: !state.toggleRefreshOptions,
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_OPTIONS_REQUEST]: getOptionsRequest,
  [Types.GET_OPTIONS_SUCCESS]: getOptionsSuccess,
  [Types.GET_OPTIONS_FAILURE]: getOptionsFailure,
  [Types.GET_OPTIONS_FROM_SYNC_SUCCESS]: getOptionsFromSyncSuccess,
  [Types.SET_SINCE_OPTIONS]: setSinceOptions,
});
