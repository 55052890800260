import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";

export default class OptionVariant extends BaseEntity {
  constructor(props) {
    super(props);

    props && Object.assign(this, Immutable(props));
  }

  getName(langCode = "fr_FR") {
    if (!this.optionVariantTranslations) {
      return this.name;
    }
    let optionVariantTranslations = this.optionVariantTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    optionVariantTranslations = optionVariantTranslations?.name
      ? optionVariantTranslations
      : this.optionVariantTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");
    return optionVariantTranslations?.name ?? "";
  }
}
