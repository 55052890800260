import { takeLatest, all } from "redux-saga/effects";

import API from "../Services/Api";

/* ------------- Types ------------- */

import { StartupTypes } from "../Redux/StartupRedux";
import { FranchiseTypes } from "../Redux/FranchiseRedux";
import { ShopTypes } from "../Redux/ShopRedux";
import { UserTypes } from "../Redux/UserRedux";
import { LoginTypes } from "../Redux/LoginRedux";
import { MenuTypes } from "../Redux/MenuRedux";
import { CategoriesTypes } from "../Redux/CategoryRedux";
import { ProductsTypes } from "../Redux/ProductRedux";
import { OptionsTypes } from "../Redux/OptionRedux";
import { CartTypes } from "../Redux/CartRedux";

/* ------------- Sagas ------------- */

import { startup, changeStartupState, loadFont, generateToken, getGeneratedToken } from "./StartupSagas";
import {
  getUser,
  getAddresses,
  updatePassword,
  setUserPositionRequest,
  updateAddresses,
  createAddresses,
  deleteAddresses,
  setDefaultAddresses,
  contactUs,
  qoodosSubscription,
  qoodosUnSubscription,
  shareUserDataToFranchise,
  setDefaultLanguage,
} from "./UserSagas";
import { getFranchise, getCities, getDevices, setSelectedDevicesInfo } from "./FranchiseSagas";
import { getShop, setSelectedShop, getShopTempDetail, getShopModes, getAvailableOrderingHoursByShop, setUserCanOrder } from "./ShopSagas";
import {
  login,
  loginAndCustomerSearch,
  logout,
  autoLogin,
  signupAndCustomerSearch,
  updateProfile,
  resetPasswordInit,
  checkResetPasswordToken,
  resetPassword,
  socialAuthentication,
} from "./LoginSagas";
import { getMenu } from "./MenuSagas";
import { getCategories } from "./CategorySagas";
import { getOptionsByProduct, getProducts } from "./ProductSagas";
import { getOptions } from "./OptionSagas";
import { OrderTypes } from "../Redux/OrderRedux";
import { addOrder, getOrders, getOrdersByUserAndSlot, orderCancel } from "./OrderSagas";
import { PaymentTypes } from "../Redux/PaymentRedux";
import {
  createPayment,
  paymentWithPaygreen,
  paymentWithStripe,
  paymentSucceeded,
  paymentWithNeptingRetail,
  getResultNeptingRetail,
  retrievePaymentPayPlugResult,
} from "./PaymentSagas";
import { checkCodePromotion } from "./CartSagas";
import { SynchronisationTypes } from "../Redux/SynchronisationRedux";
import { synchronisation } from "./SynchronisationSagas";

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along to the sagas which need it.
const api = API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(StartupTypes.STARTUP, startup, api),

    // some sagas receive extra parameters in addition to an action
    takeLatest(StartupTypes.CHANGE_STARTUP_STATE, changeStartupState, api),
    takeLatest(StartupTypes.LOAD_FONT_REQUEST, loadFont),
    takeLatest(StartupTypes.GENERATE_TOKEN_REQUEST, generateToken, api),
    takeLatest(StartupTypes.GET_GENERATED_TOKEN_REQUEST, getGeneratedToken, api),

    takeLatest(LoginTypes.LOGIN_REQUEST, login, api),
    takeLatest(LoginTypes.LOGOUT_REQUEST, logout, api),
    takeLatest(LoginTypes.AUTO_LOGIN_REQUEST, autoLogin, api),
    takeLatest(LoginTypes.SIGNUP_REQUEST, signupAndCustomerSearch, api),
    takeLatest(LoginTypes.UPDATE_PROFILE_REQUEST, updateProfile, api),
    takeLatest(LoginTypes.RESET_PASSWORD_INIT_REQUEST, resetPasswordInit, api),
    takeLatest(LoginTypes.CHECK_RESET_PASSWORD_TOKEN_REQUEST, checkResetPasswordToken, api),
    takeLatest(LoginTypes.RESET_PASSWORD_REQUEST, resetPassword, api),
    takeLatest(LoginTypes.LOGIN_AND_CUSTOMER_SEARCH_REQUEST, loginAndCustomerSearch, api),
    takeLatest(LoginTypes.SOCIAL_AUTHENTICATION_REQUEST, socialAuthentication, api),

    takeLatest(UserTypes.GET_USER_REQUEST, getUser, api),
    takeLatest(UserTypes.GET_ADDRESSES_REQUEST, getAddresses, api),
    takeLatest(UserTypes.UPDATE_ADDRESSES_REQUEST, updateAddresses, api),
    takeLatest(UserTypes.CREATE_ADDRESSES_REQUEST, createAddresses, api),
    takeLatest(UserTypes.DELETE_ADDRESSES_REQUEST, deleteAddresses, api),
    takeLatest(UserTypes.SET_DEFAULT_ADDRESSES_REQUEST, setDefaultAddresses, api),
    takeLatest(UserTypes.CONTACT_US_REQUEST, contactUs, api),

    takeLatest(UserTypes.UPDATE_PASSWORD_REQUEST, updatePassword, api),
    takeLatest(UserTypes.SET_USER_POSITION_REQUEST, setUserPositionRequest, api),

    takeLatest(FranchiseTypes.GET_FRANCHISE_REQUEST, getFranchise, api),
    takeLatest(FranchiseTypes.GET_CITIES_REQUEST, getCities, api),
    takeLatest(FranchiseTypes.GET_DEVICES_REQUEST, getDevices, api),
    takeLatest(FranchiseTypes.SET_SELECTED_DEVICES_INFO_REQUEST, setSelectedDevicesInfo, api),

    takeLatest(ShopTypes.GET_SHOP_REQUEST, getShop, api),
    takeLatest(ShopTypes.SET_SELECTED_SHOP, setSelectedShop, api),

    takeLatest(ShopTypes.GET_SHOP_TEMP_DETAIL_REQUEST, getShopTempDetail, api),
    takeLatest(ShopTypes.GET_SHOP_MODES_REQUEST, getShopModes, api),
    takeLatest(ShopTypes.GET_AVAILABLE_ORDERING_HOURS_BY_SHOP_REQUEST, getAvailableOrderingHoursByShop, api),
    takeLatest(ShopTypes.SET_USER_CAN_ORDER_REQUEST, setUserCanOrder, api),

    takeLatest(MenuTypes.GET_MENU_REQUEST, getMenu, api),
    takeLatest(CategoriesTypes.GET_CATEGORIES_REQUEST, getCategories, api),

    takeLatest(ProductsTypes.GET_PRODUCTS_REQUEST, getProducts, api),
    takeLatest(ProductsTypes.GET_OPTIONS_BY_PRODUCT_REQUEST, getOptionsByProduct, api),

    takeLatest(OptionsTypes.GET_OPTIONS_REQUEST, getOptions, api),

    takeLatest(OrderTypes.ADD_ORDER_REQUEST, addOrder, api),
    takeLatest(OrderTypes.GET_ORDERS_REQUEST, getOrders, api),
    takeLatest(OrderTypes.GET_ORDERS_BY_USER_AND_SLOT_REQUEST, getOrdersByUserAndSlot, api),
    takeLatest(OrderTypes.ORDER_CANCEL_REQUEST, orderCancel, api),

    takeLatest(PaymentTypes.CREATE_PAYMENT_REQUEST, createPayment, api),
    takeLatest(PaymentTypes.PAYMENT_WITH_NEPTING_RETAIL_REQUEST, paymentWithNeptingRetail, api),
    takeLatest(PaymentTypes.GET_RESULT_NEPTING_RETAIL_REQUEST, getResultNeptingRetail, api),

    // takeLatest(PaymentTypes.GET_STRIPE_CLIENT_SECRET_REQUEST, getStripeClientSecret, api),
    takeLatest(PaymentTypes.PAYMENT_WITH_STRIPE_REQUEST, paymentWithStripe, api),
    takeLatest(PaymentTypes.PAYMENT_WITH_PAYGREEN_REQUEST, paymentWithPaygreen, api),
    // takeLatest(PaymentTypes.PAYMENT_WITH_PAYXPERT_REQUEST, paymentWithPayxpert, api),
    takeLatest(PaymentTypes.PAYMENT_SUCCEEDED, paymentSucceeded, api),

    takeLatest(CartTypes.CHECK_CODE_PROMOTION_REQUEST, checkCodePromotion, api),

    takeLatest(SynchronisationTypes.SYNCHRONISATION_REQUEST, synchronisation, api),

    takeLatest(UserTypes.QOODOS_SUBSCRIPTION_REQUEST, qoodosSubscription, api),
    takeLatest(UserTypes.QOODOS_UN_SUBSCRIPTION_REQUEST, qoodosUnSubscription, api),
    takeLatest(UserTypes.SHARE_USER_DATA_TO_FRANCHISE_REQUEST, shareUserDataToFranchise, api),

    takeLatest(UserTypes.SET_DEFAULT_LANGUAGE_REQUEST, setDefaultLanguage, api),

    takeLatest(PaymentTypes.RETRIEVE_PAYMENT_PAY_PLUG_RESULT_REQUEST, retrievePaymentPayPlugResult, api),
  ]);
}
