import configEnv from "../../config.env";
import L, { AutoTranslation, Lcustom_missing } from "../I18n/tr";
import moment from "moment";
import momentTz from "moment-timezone";
import { mappingDeliveriesModesByLink } from "../Template1/Shared/Constants";
import { serverTimeZone, ordersStates, paymentsStatesColors, burnedStates, ordersStatesColors, checkoutsStatesColors, DOMAIN_TEST } from "./Constants";
import Immutable from "seamless-immutable";
import OptionVariant from "../Logic/Entities/OptionVariant";
import Option from "../Logic/Entities/Option";
import Product from "../Logic/Entities/Product";
import Category from "../Logic/Entities/Category";

const getFormatedImg = (img, fromLocal) => {
  if (fromLocal) return img;
  return `${configEnv.BASE_API_URL}${img}`;
};

const getFormatedImgForCrop = (img, fromLocal, size, useBaseUrl = true) => {
  if (fromLocal) return img;
  if (!img) return "";

  const splitedImagePath = img?.split("/");
  const lastPartPath = splitedImagePath[splitedImagePath.length - 1];
  splitedImagePath[splitedImagePath.length - 1] = `${size}/${lastPartPath}`;
  const joinedImagePath = useBaseUrl
    ? splitedImagePath.join("/").replace("uploads/images", "image/crop").replace("//", "/")
    : splitedImagePath.join("/").replace("uploads/images", "image/crop").replace("medias//", "medias/");
  return `${useBaseUrl ? configEnv.BASE_API_URL : ""}${joinedImagePath}`;
};

const getFormatedFileLink = (file) => {
  return `${configEnv.BASE_API_URL}${file}`;
};

const roundNumber = (num) => {
  return parseFloat(num).toFixed(2);
};

const getAddress = (address) => {
  return `${address.street} - ${address.postcode}, ${address.city}`;
};

function getButtonAllStyle(color, isActive = false, borderColor = "") {
  const _hover = adjust(color, 50);
  const styledBtn = isActive ? { borderWidth: 2, borderColor, bg: color } : { bg: color };

  return { ...styledBtn, _hover: { backgroundColor: _hover }, _pressed: { backgroundColor: color }, _focus: { backgroundColor: color }, zIndex: 999 };
}

function getButtonDateStyle(color, bg, bgHover, textColor) {
  const styledBtn = { borderWidth: 2, borderColor: color, bg, borderRadius: "full" };

  return {
    ...styledBtn,
    _hover: { backgroundColor: bgHover },
    _pressed: { backgroundColor: bg },
    _focus: { backgroundColor: bg },
    _text: { color: textColor },
  };
}

function getButtonRoundStyle(color, fontType, iconName, size) {
  const primeryData = { _icon: { color: color, size: size, as: fontType, name: iconName } };
  const _hover = { _hover: { bg: adjust(color, 100) } };
  const _pressed = {
    _pressed: {
      bg: adjust(color, 70),
    },
  };

  return { ...primeryData, ..._hover, ..._pressed };
}

function adjust(color, amount) {
  return "#" + color.replace(/^#/, "").replace(/../g, (color) => ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();
  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç/_:;";
  var to = "aaaaeeeeiiiioooouuuunc----";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  str = str
    .replace(/[^a-z0-9., -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export const slugifyTableFilters = (filters) => {
  const filtersAsObject = filters.asMutable({ deep: true });
  if (!filtersAsObject.page && !filtersAsObject.search) {
    return "";
  }
  const searchFilters = filtersAsObject.search;
  if (searchFilters) {
    const searchFiltersEntries = Object.entries(searchFilters);
    if (!searchFiltersEntries || !searchFiltersEntries.length) {
      return "";
    }
    //si on a des filtres pour recherche on trie les entrées par key
    return string_to_slug("search_" + JSON.stringify(Object.fromEntries(searchFiltersEntries.sort())));
  }
  return string_to_slug(JSON.stringify(filtersAsObject));
};

function isMobile() {
  const deviceType = getDeviceTypeBasedOnDimensions();
  return deviceType === "mobile" || deviceType === "tablet";
}

function getDeviceTypeBasedOnDimensions() {
  const innerWidth = window.innerWidth;
  // const scale = window.devicePixelRatio || 1;
  // const realWindowWidth = innerWidth * scale;
  const realWindowWidth = innerWidth;
  if (realWindowWidth < 576) {
    return "mobile";
  }
  if (realWindowWidth < 992) {
    return "tablet";
  }
  return "desktop";
}

function getOptionsByIds(options, ids) {
  return options?.filter((option) => ids?.includes(option.id));
}

function formatPrice(price, selectedShop) {
  const adaptedPrice = price / 100;
  if (isNaN(adaptedPrice)) {
    return "";
  }
  let formatedPrice = adaptedPrice;
  try {
    const currencyCodeValue = selectedShop?.currency?.code;
    const currency = (currencyCodeValue ? currencyCodeValue : "EUR").toUpperCase();
    formatedPrice = adaptedPrice?.toLocaleString("fr-FR", { style: "currency", currency: currency });
  } catch (error) {
    formatedPrice = adaptedPrice?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" });
  }
  return formatedPrice.replace(",", ".");
}

function getTotalSynthesisData(productsIntoCart, deliveriesModesByLink, selectedZone, promotionsApplied, selectedShop, rewardsApplied) {
  // promotionsApplied ==>[{
  // active: true
  // end: "2022-09-30T00:00:00+00:00"
  // id: 7
  // image: null
  // name: "promo 1"
  // start: "2022-08-30T00:00:00+00:00"
  // type: "pourcentage" || "value"
  // value: 0.1 }]

  if (!productsIntoCart || Object.keys(productsIntoCart).length === 0) return {};

  let subTotal = 0;
  let tax = 0;

  //ce calcule de taxe n'est plus utilisé, c'est la valeur retourné par creation order qu'il faut utiliser
  Object.values(productsIntoCart)?.forEach((productIntoCart) => {
    const price = productIntoCart.selectedProduct?.getFinalPrice(productIntoCart.selectedOptions, productIntoCart.qty, deliveriesModesByLink);
    subTotal = subTotal + price;
    const tva =
      productIntoCart.selectedProduct?.getTaxByShopMode(deliveriesModesByLink, productIntoCart.qty) +
      productIntoCart.selectedProduct?.getOptionsVariantsTax(productIntoCart.selectedOptions, productIntoCart.qty, deliveriesModesByLink);
    tax = tax + tva;
  });

  // const totalInit = subTotal + tax;
  const delivery = selectedZone ? selectedZone.shippingCost : 0;

  // total sans promo
  const total = subTotal;

  // total avec promo
  const totalPromos = promotionsApplied && promotionsApplied.length > 0 ? total - getTotalWithPromo(promotionsApplied, total) : 0;

  // total avec reward
  const totalReward = rewardsApplied && rewardsApplied.length > 0 ? total - getTotalWithReward(rewardsApplied, total) : 0;

  const totalDiscounts = totalPromos + totalReward;

  const totalWithDiscounts = total - totalDiscounts;

  // frais de gestion
  const managementFees = selectedShop.shopSettings?.managementFees ?? 0;

  // le total à payé
  const totalToPaid = totalWithDiscounts + delivery + managementFees;
  const totalToPaidWithoutDiscounts = totalToPaid + totalDiscounts;
  return {
    subTotal,
    delivery,
    total,
    promotionsApplied,
    totalWithDiscounts,
    totalToPaid,
    totalToPaidWithoutDiscounts,
    totalReward,
    totalPromos,
    totalDiscounts,
    rewardsApplied,
    tax,
  };
}

function getTotalWithPromo(promotionsApplied, total) {
  return promotionsApplied?.reduce((previousValue, currentValue) => {
    if (currentValue.type === "pourcentage") return previousValue - previousValue * currentValue.value;
    return previousValue - currentValue.value;
  }, total);
}

function getTotalWithReward(rewardsApplied, total) {
  return (
    rewardsApplied?.reduce((previousValue, currentValue) => {
      if (currentValue.value && currentValue.value > 0) {
        if (currentValue.discount_type === "value") {
          return previousValue - currentValue.value * 100;
        } else if (currentValue.discount_type === "percentage") {
          return previousValue - previousValue * (currentValue.value / 100);
        }
      }
      return previousValue;
    }, total) ?? 0
  );
}

function hashCode(s) {
  let h = 0;
  let i = s.length;
  while (i > 0) {
    h = ((h << 5) - h + s.charCodeAt(--i)) | 0;
  }
  return h;
}

export const days = [L("sunday"), L("monday"), L("tuesday"), L("wednesday"), L("thursday"), L("friday"), L("saturday")];
function getDayNameByIndex(index) {
  return days[index] ?? "";
}

function getGroupedSlots(openingHour) {
  if (openingHour.length > 0) {
    return openingHour.map((hour) => {
      return `${moment(hour.startingTimeApi, "HH:mm:ss").format("HH:mm")} - ${moment(hour.endingTimeApi, "HH:mm:ss").format("HH:mm")}`;
    });
  }

  return "";
}

export const isUpperToMaxProduct = (timeSlotMaxProduct, productsIntoCartData, qty, isTimeSlotBlockingTypeProduct) => {
  if (!isTimeSlotBlockingTypeProduct) return false;
  if (!timeSlotMaxProduct) return false;
  const productsIntoCartDataObj = productsIntoCartData.isImmutable ? productsIntoCartData.asMutable({ deep: true }) : productsIntoCartData;

  const somQty = Object.values(productsIntoCartDataObj)
    .filter((productIntoCart) => {
      return productIntoCart.selectedProduct?.isQuantitySlot();
    })
    ?.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.qty;
    }, 0);

  return timeSlotMaxProduct < somQty + qty;
};

function canUserOrder(shop, selectedMode, availableOrderingHoursByShop, useSlots = true) {
  const canFutureOrdering = shop?.shopSettings?.futureOrders;
  if (!selectedMode?.isOnSite() && canFutureOrdering) {
    return true;
  }
  // shop?.timeZone, "YYYY-MM-DD HH:mm"
  const currentDateTime = getNowDate(false, false, false);
  // const currentDateTime = moment().format();
  const toDayInClosingDays = isInClosingDays(currentDateTime, getEnabledClosingDays(shop?.closingDays) ?? []);
  if (toDayInClosingDays) {
    return false;
  }
  if (useSlots && !isTimeSlotsAvailable(currentDateTime, availableOrderingHoursByShop, selectedMode, shop?.timeZone)) {
    return false;
  }
  if (!Array.isArray(selectedMode?.orderingHours)) {
    return false;
  }
  const isPickupMode = shop?.shopSettings?.pickupMode;
  const canOrderInOrderingHours = ongoingOrImminentOrderingHours(currentDateTime, selectedMode.orderingHours, isPickupMode);
  if (!canOrderInOrderingHours) {
    return false;
  }
  if (isPickupMode) {
    return true;
  }
  if (!Array.isArray(shop?.openingHours)) {
    return false;
  }
  const canOrderInOpeningHours = ongoingOrImminentOpeningHours(currentDateTime, getEnabledOpeningHours(shop?.openingHours));
  if (!canOrderInOpeningHours) {
    return false;
  }
  // selectedMode.orderingHours
  //canOrderInOrderingHours === true && canOrderInOpeningHours === true
  return true;
}

function isTimeSlotsAvailable(dateTime, availableOrderingHoursByShop, selectedMode, shopTimeZone) {
  const apiDateFormatValue = apiDateFormat();
  const localFormat = `${moment().creationData().locale.longDateFormat("L")} HH:mm:ss`;
  const localFormatDate = moment().creationData().locale.longDateFormat("L");
  const timeConverted = convertFromLocalTimeZoneToServerTimeZone(dateTime, localFormat, shopTimeZone);
  const todayDate = moment(timeConverted, localFormatDate).format(apiDateFormatValue);
  const dayIndex = getDayIndex(dateTime, apiDateFormatValue);
  const timeSlots = availableOrderingHoursByShop?.[todayDate]?.[selectedMode?.mode?.code]?.[dayIndex] ?? [];
  const timeToCheck = dateTime.toLocaleTimeString();
  return (
    Array.isArray(timeSlots) &&
    timeSlots.some((timeSlot) => {
      return timeToCheck < timeSlot;
    })
  );
}

function ongoingOrImminentOrderingHours(dateTime, orderingHours, onlyOngoingOrderingHours = false) {
  // const orderingHours = JSON.parse('[{ "id": 1, "day": "0", "status": true, "startingTimeApi": "16:00:00", "endingTimeApi": "16:00:00"}]');
  if (!orderingHours?.length) {
    return false;
  }
  // const dateTimeDay = dateTime.getDay() - 1; //we start days keys from Monday, for js is starting keys from sunday
  const dateTimeDay = getDayIndex(dateTime);
  // const dateTimeDay = 0; //pour test
  // const orderingHoursForDay = orderingHours?.[dateTimeDay]?.day === dateTimeDay ? orderingHours?.[dateTimeDay] : orderingHours?.find(orderingHour => orderingHour.day === dateTimeDay);
  const orderingHoursInDay = orderingHours?.filter((orderingHour) => orderingHour.enabled && orderingHour.day == dateTimeDay);

  const timeToCheck = dateTime.toLocaleTimeString();
  // const timeToCheck = '17:59:58';
  return orderingHoursInDay.some((orderingHour) => {
    //est ce qu'on doit retrancher shop.OrderInterval du orderingHou.endingTime ou pas??
    // const lastTimeToOrder = (new Date(new Date(orderingHour?.endingTime).getTime() + shop.OrderInterval*60000)).toLocaleTimeString();
    // const lastTimeToOrder = new Date(orderingHour?.endingTime).toLocaleTimeString();
    const startingTimeApi = orderingHour?.startingTimeApi;
    const endingTimeApi = orderingHour?.endingTimeApi;
    const lastTimeToOrder = endingTimeApi > startingTimeApi ? moment(endingTimeApi, "HH:mm:ss") : moment(endingTimeApi, "HH:mm:ss").add(1, "day");
    return onlyOngoingOrderingHours
      ? moment(timeToCheck, "HH:mm:ss").isBetween(moment(startingTimeApi, "HH:mm:ss"), lastTimeToOrder, undefined, "[]")
      : moment(timeToCheck, "HH:mm:ss").isBefore(lastTimeToOrder);
  });
}

function ongoingOrImminentOpeningHours(dateTime, openingHours) {
  // const openingHours = JSON.parse('[{ "id": 1, "day": "0", "status": true, "startingTimeApi": "16:00:00", "endingTimeApi": "16:00:00"}]');
  if (!openingHours?.length) {
    return false;
  }
  // const dateTimeDay = dateTime.getDay() - 1; //we start days keys from Monday, for js is starting keys from sunday
  const dateTimeDay = getDayIndex(dateTime);
  // const dateTimeDay = 0; //pour test
  const openingHoursInDay = openingHours?.filter((openingHour) => openingHour.enabled && openingHour.day == dateTimeDay);
  const timeToCheck = dateTime.toLocaleTimeString();
  // const timeToCheck = '17:59:58';
  return openingHoursInDay.some((openingHour) => {
    const startingTimeApi = openingHour.startingTimeApi;
    const endingTimeApi = openingHour.endingTimeApi;
    const lastTimeToOrder = endingTimeApi > startingTimeApi ? moment(endingTimeApi, "HH:mm:ss") : moment(endingTimeApi, "HH:mm:ss").add(1, "day");
    return moment(timeToCheck, "HH:mm:ss").isBefore(lastTimeToOrder);
  });
}

function isInClosingDays(dateTime, closingDays) {
  const dateTimeToCheck = moment(dateTime);
  return (
    Array.isArray(closingDays) &&
    closingDays?.find((closingDaysElt) => {
      return closingDaysElt.enabled && dateTimeToCheck.isBetween(closingDaysElt?.startingDateApi, closingDaysElt?.endingDateApi, "days", "[]");
    })
  );
}

function isShopOpen(dateTime, openingHours) {
  if (!Array.isArray(openingHours)) {
    return false;
  }
  const dateTimeDay = getDayIndex(dateTime);
  const openingHoursInDay = openingHours?.filter((openingHour) => openingHour.enabled && openingHour.day == dateTimeDay);
  if (!openingHoursInDay) {
    return false;
  }
  const timeToCheck = dateTime.toLocaleTimeString();
  return openingHoursInDay?.find((openingHoursElt) => {
    return timeToCheck > openingHoursElt?.startingTimeApi && timeToCheck < openingHoursElt?.endingTimeApi;
  });
}

function comingOpeningHourInDay(dateTime, openingHours) {
  if (!openingHours?.length) {
    return false;
  }
  const dateTimeDay = getDayIndex(dateTime);
  const openingHoursInDayImm = openingHours?.filter((openingHour) => openingHour.enabled && openingHour.day == dateTimeDay);
  const timeToCheck = dateTime.toLocaleTimeString();
  const openingHoursInComingDays = typeof openingHoursInDayImm.asMutable !== "undefined" ? Immutable.asMutable(openingHoursInDayImm) : openingHoursInDayImm;
  const sortedOpeningHoursInComingDays = openingHoursInComingDays.sort((a, b) => a.startingTimeApi > b.startingTimeApi);
  return sortedOpeningHoursInComingDays?.find((openingHour) => {
    const firstTimeToOrder = openingHour?.startingTimeApi;
    return timeToCheck < firstTimeToOrder;
  });
}

function comingOpeningHourInNextDays(dateTime, openingHours) {
  if (!openingHours?.length) {
    return false;
  }
  const dateTimeDay = getDayIndex(dateTime);
  let openingHoursInComingDaysImm = openingHours?.filter((openingHour) => openingHour.enabled && openingHour.day > dateTimeDay);
  if (openingHoursInComingDaysImm?.length <= 0) {
    openingHoursInComingDaysImm = openingHours?.filter((openingHour) => openingHour.enabled && openingHour.day <= dateTimeDay);
  }
  const openingHoursInComingDays =
    typeof openingHoursInComingDaysImm.asMutable !== "undefined" ? Immutable.asMutable(openingHoursInComingDaysImm) : openingHoursInComingDaysImm;
  const sortedOpeningHoursInComingDays = openingHoursInComingDays.sort((a, b) => {
    if (parseInt(a.day) > parseInt(b.day)) {
      return 1;
    }
    if (parseInt(a.day) < parseInt(b.day)) {
      return -1;
    }
    if (a.startingTimeApi > b.startingTimeApi) {
      return 1;
    }
    if (a.startingTimeApi < b.startingTimeApi) {
      return -1;
    }
    return 0;
  });
  return sortedOpeningHoursInComingDays?.[0] ?? null;
}

// function isShopOpened(openingHours) {
//   const now = getNowDate();
//   if(openingHours.length > 0){
//     return openingHours.map((hour)=>{
//       return `${hour.startingTimeApi} - ${hour.endingTimeApi}`;
//     })
//   }

//   return false;
// }

// function getSlotByDate(date, openingHours){
//   const slotIndex = getDayIndex(date);
//   return Array.isArray(openingHours) && openingHours.some(openingHour => {
//     return moment(dateTime).isBetween(openingHour?.startingDateApi, openingHour?.endingDateApi);
//   });
// }

function getDate(date) {
  // return moment(date).format();
  return new Date(date);
}

function getNowDate(shopTimeZone = false, format = false, returnAsMoment = true) {
  if (!returnAsMoment) {
    if (!shopTimeZone && !format) return new Date();
    else if (!shopTimeZone && format) return moment().format(format).toDate();
    const localFormat = !format ? `${moment().creationData().locale.longDateFormat("L")} HH:mm:ss` : format;
    return new Date(momentTz().tz(shopTimeZone).format(localFormat));
  } else {
    if (!shopTimeZone && !format) return new Date();
    else if (!shopTimeZone && format) return moment().format(format);
    return momentTz().tz(shopTimeZone).format(format);
  }
}

function convertFromServerTimeZoneToLocalTimeZone(dateTime, format = "DD/MM/YYYY HH:mm:ss", shopTimeZone) {
  const originalDateTime = momentTz.tz(dateTime, format, shopTimeZone ?? serverTimeZone);
  return originalDateTime?.local().format(format);
}

function convertFromLocalTimeZoneToServerTimeZone(dateTime, format = "DD/MM/YYYY HH:mm:ss", shopTimeZone) {
  const originalDateTime = momentTz(dateTime, format)
    .tz(shopTimeZone ?? serverTimeZone)
    .format(format);
  return originalDateTime;
}
// function getNowDate($format = 'DD/MM/YYYY') {
//   return moment().format($format);
// }

const getDayIndex = (dayDate, dateFormat = "DD/MM/YYYY") => {
  const date = (moment.isMoment(dayDate) ? dayDate : moment(dayDate, dateFormat)).toDate();
  const currentDayIndex = date.getDay();

  return currentDayIndex;
};

function apiDateFormat() {
  const apiDateFormat = "YYYY-MM-DD";
  return apiDateFormat;
}

function apiDateTimeFormat() {
  const apiDateFormat = "YYYY-MM-DDTHH:mm:ssz";
  return apiDateFormat;
}

function localDateFormat() {
  const localDateFormat = "DD/MM/YYYY";
  return localDateFormat;
}

const toMomentLocale = (locale) => {
  let momentLocale = locale;
  momentLocale = momentLocale.replace("_", "-").toLowerCase();
  momentLocale = ["en-gb", "en-us"].includes(locale) ? momentLocale : momentLocale.split("-")[0];
  return momentLocale;
};

function getCartLinesFromOrder(order, products, optionsProducts, extrarData) {
  const orderItems = order?.orderItems ?? [];
  const orderItemsDataIds = orderItems?.reduce((products, value) => {
    const productId = value.product?.id;
    if (productId) {
      products.push(productId);
    }
    return products;
  }, []);
  const availableProducts = products?.filter((product) => orderItemsDataIds?.includes?.(product.id));
  const reOrderErrors = [];
  const deliveriesModesByLink = mappingDeliveriesModesByLink[extrarData?.location?.params?.path] ?? order.shopMode?.mode?.code;

  const cartLinesData = orderItems?.reduce((cartLinesData, orderItem) => {
    const productId = orderItem.product?.id;
    const product = availableProducts?.find((product) => product.id == productId);
    if (product && !product.outOfStock) {
      const productOptions = getOptionsByIds(optionsProducts, product?.getOptionsAsArray());
      const optionsErrors = [];
      const selectedOptions = orderItem?.optionList?.reduce?.((selectedOptions, optionData) => {
        const option = productOptions?.find((productOption) => productOption.id === optionData.id);
        if (option) {
          const optionVariants =
            optionData.optionVariant?.reduce?.((optionVariants, optionVariantInOrderItem) => {
              const optionVariantInOrderItemEnt = new OptionVariant(optionVariantInOrderItem);
              const optionVariant = option.optionVariants?.find((optionV) => optionV.id === optionVariantInOrderItem.id);
              if (optionVariant && !optionVariant.outOfStock) {
                optionVariants[optionVariant.id] = optionVariant;
              } else if (!optionVariant) {
                optionsErrors.push(`${L("option")} -(${optionVariantInOrderItemEnt?.getName?.(extrarData.langCode)})- ${L("noLongerAvailable")}`);
              } else if (optionVariant.outOfStock) {
                optionsErrors.push(`${L("ProductOutOfStock")} -(${optionVariantInOrderItemEnt?.getName?.(extrarData.langCode)})-`);
              }
              if (optionVariant && optionVariant.price !== optionVariantInOrderItem.price) {
                optionsErrors.push(`${L("option")} -(${optionVariantInOrderItemEnt?.getName?.(extrarData.langCode)})- ${L("priceChanged")}`);
              }
              return optionVariants;
            }, {}) ?? {};
          const optionObj = typeof option.asMutable !== "undefined" ? { ...option?.asMutable({ deep: true }) } : { ...option };
          selectedOptions[option.id] = { option: optionObj, optionVariants: optionVariants };
        } else {
          const optionEnt = new Option(optionData);
          optionsErrors.push(`${L("option")} -(${optionEnt?.getName(extrarData.langCode) ?? ""})- ${L("noLongerAvailable")}`);
        }
        return selectedOptions;
      }, {});
      const orderLineData = {
        selectedProduct: product,
        qty: orderItem.quantity,
        selectedOptions: selectedOptions,
        instructionSpecial: orderItem.notes ?? "",
        selectedProductInCart: false,
      };

      cartLinesData.push(orderLineData);

      const productPrice = product?.getPriceByShopMode(deliveriesModesByLink) ?? 0;
      const productPriceChanged = productPrice !== orderItem.unitPrice;
      if (optionsErrors?.length) {
        const productErrors = productPriceChanged ? [L("priceChanged"), ...optionsErrors] : optionsErrors;
        reOrderErrors.push({ productName: `${L("product")} : ${product?.getName(extrarData.langCode) ?? ""}`, productErrors });
      } else if (productPriceChanged) {
        reOrderErrors.push(`${L("product")} -(${product?.getName(extrarData.langCode)})- ${L("priceChanged")}`);
      }
    } else if (!product) {
      reOrderErrors.push(`${L("product")} -(${orderItem?.productName ?? ""})- ${L("noLongerAvailable")}`);
    } else if (product.outOfStock) {
      reOrderErrors.push(`${L("ProductOutOfStock")} -(${product?.getName(extrarData.langCode)})-`);
    }
    return cartLinesData;
  }, []);

  return { cartLinesData, reOrderErrors };
}

function getIdFromAPIPlatformString(apiPlatformString) {
  const apiPlatformStringLinkArray = apiPlatformString?.split?.("/");
  if (!apiPlatformStringLinkArray?.length) {
    return null;
  }
  const id = apiPlatformStringLinkArray[apiPlatformStringLinkArray.length - 1] + "";

  return parseInt(id);
}

function getFinalArraySync(originalArray, syncsArray) {
  const createdData = syncsArray?.filter((syncArray) => syncArray.syncAction === "created");
  const updatedData = syncsArray?.filter((syncArray) => syncArray.syncAction === "updated");
  const deletedData = syncsArray?.filter((syncArray) => syncArray.syncAction === "deleted");

  let finalArray = originalArray && Immutable.isImmutable(originalArray) ? [...originalArray.asMutable({ deep: true })] : [...originalArray];
  if (createdData?.length > 0) {
    const createdDataIds = createdData.map((element) => element.id);
    const notDuplicatedElm = finalArray.filter((element) => !element.id || !createdDataIds.includes(element.id));
    finalArray = [...notDuplicatedElm, ...createdData];
  }

  if (updatedData?.length > 0) {
    const elementsAddedIds = [];
    finalArray = finalArray.map((fArray) => {
      const filtredData = updatedData.find((uData) => uData.id === fArray.id);
      if (filtredData) {
        elementsAddedIds.push(filtredData.id);
        return filtredData;
      } else return fArray;
    });

    // prendre en charge des élémensts supprimés/éliminés par des filtres
    const elementsToMerge = updatedData.filter((uData) => !elementsAddedIds.includes(uData.id));
    if (elementsToMerge?.length) {
      finalArray = finalArray.concat?.(elementsToMerge);
    }
  }

  if (deletedData?.length > 0) {
    finalArray = finalArray.filter((fArray) => {
      return !deletedData.find((dData) => dData.id === fArray.id);
    });
  }

  finalArray = finalArray.filter?.((element) => {
    if ("status" in element || "enabled" in element) {
      //(ie: element.status !== undefined || element.enabled !== undefined)
      return element.status || element.enabled;
    }
    return true;
  });
  return finalArray;
}

function getEnabledClosingDays(closingDays) {
  return closingDays?.filter((closingDay) => closingDay.enabled);
}

function getEnabledOpeningHours(openingHours) {
  return openingHours?.filter((openingHour) => openingHour.enabled);
}

function getSecondsBetweenTwoTimes(timeStart, timeEnd, shopTimeZone) {
  const formatedTimeStart = moment(momentTz(timeStart).tz(shopTimeZone));
  const formatedTimeEnd = moment(momentTz(timeEnd).tz(shopTimeZone));

  const duration = moment.duration(formatedTimeEnd.diff(formatedTimeStart));
  const seconds = duration.asSeconds();

  return typeof seconds == "number" ? Math.abs(seconds) : 0;
}

function normalizeShop({ ...shop }) {
  const userTimeZone = momentTz.tz.guess();
  const openingHoursToNormalize = shop?.openingHours;
  if (openingHoursToNormalize?.length) {
    shop.openingHours = openingHoursToNormalize
      .filter((openingHour) => openingHour.enabled)
      ?.map(({ ...openingHour }) => {
        if (openingHour.timeZone !== userTimeZone) {
          openingHour.startingTimeApi = convertFromServerTimeZoneToLocalTimeZone(openingHour.startingTimeApi, "HH:mm:ss", shop?.timeZone);
          openingHour.endingTimeApi = convertFromServerTimeZoneToLocalTimeZone(openingHour.endingTimeApi, "HH:mm:ss", shop?.timeZone);
          openingHour.timeZone = userTimeZone;
        }
        return openingHour;
      });
  }
  const pickupHoursToNormalize = shop?.pickupHours;
  if (pickupHoursToNormalize) {
    shop.pickupHours = pickupHoursToNormalize.map(({ ...pickupHour }) => {
      if (pickupHour.timeZone !== userTimeZone) {
        pickupHour.startingTimeApi = convertFromServerTimeZoneToLocalTimeZone(pickupHour.startingTimeApi, "HH:mm:ss", shop?.timeZone);
        pickupHour.endingTimeApi = convertFromServerTimeZoneToLocalTimeZone(pickupHour.endingTimeApi, "HH:mm:ss", shop?.timeZone);
        pickupHour.timeZone = userTimeZone;
      }
      return pickupHour;
    });
  }
  if (shop?.closingDays?.length) {
    shop.closingDays = shop.closingDays.filter((closingDay) => closingDay.enabled);
  }
  return shop;
}

function normalizeShopMode(shopMode, shopTimeZone) {
  const orderingHoursToNormalize = shopMode?.orderingHours;
  let localShopMod = { ...shopMode };
  if (orderingHoursToNormalize?.length) {
    const userTimeZone = momentTz.tz.guess();
    localShopMod.orderingHours = orderingHoursToNormalize.map(({ ...orderingHour }) => {
      if (orderingHour.timeZone !== userTimeZone) {
        orderingHour.startingTimeApi = convertFromServerTimeZoneToLocalTimeZone(orderingHour.startingTimeApi, "HH:mm:ss", shopTimeZone);
        orderingHour.endingTimeApi = convertFromServerTimeZoneToLocalTimeZone(orderingHour.endingTimeApi, "HH:mm:ss", shopTimeZone);
        orderingHour.timeZone = userTimeZone;
      }
      return orderingHour;
    });
  }
  return localShopMod;
}

function filterOrderingHoursInShopModeByOpeningHours(shopModes, openingHours) {
  return shopModes.map((shopMode) => {
    let localShopMod = { ...shopMode };
    const orderingHoursToFilter = shopMode?.orderingHours;
    if (orderingHoursToFilter?.length) {
      localShopMod.orderingHours = orderingHoursToFilter.filter((orderingHour) => {
        if (!orderingHour?.enabled) {
          return false;
        }
        const openingHoursInDay = openingHours?.filter?.((openingHour) => openingHour.enabled && openingHour.day == orderingHour.day);
        return openingHoursInDay?.find((openingHourElt) => {
          const startingTimeApi = openingHourElt?.startingTimeApi;
          const endingTimeApi = openingHourElt?.endingTimeApi;
          return (
            moment(orderingHour.startingTimeApi, "HH:mm:ss").isBetween(
              moment(startingTimeApi, "HH:mm:ss"),
              moment(endingTimeApi, "HH:mm:ss"),
              undefined,
              "[]"
            ) &&
            moment(orderingHour.endingTimeApi, "HH:mm:ss").isBetween(moment(startingTimeApi, "HH:mm:ss"), moment(endingTimeApi, "HH:mm:ss"), undefined, "[]")
          );
        });
      });
    }
    return localShopMod;
  });
}

function getStateTextAndColor(orderState) {
  const orderStateAttr = `STATE_${orderState?.toUpperCase()}`;
  return { paymentColorClass: ordersStatesColors[orderStateAttr], paymentText: L(orderStateAttr) };
}

function getStateComponent(paymentState, orderState, checkoutState) {
  const paymentAttr = `STATE_${paymentState?.toUpperCase()}`;
  const orderStateAttr = `STATE_${orderState?.toUpperCase()}`;
  const checkoutStateAttr = `STATE_${checkoutState?.toUpperCase()}`;
  if (burnedStates.includes(orderState)) {
    return <span className={`badge mb-1 ${ordersStatesColors[orderStateAttr]}`}>{L(orderStateAttr)}</span>;
  }
  if (orderState === ordersStates.STATE_NEW) {
    return (
      <>
        <span className={`badge mb-1 me-1 ${ordersStatesColors[orderStateAttr]}`}>{L(orderStateAttr)}</span>
        <span className={`badge mb-1 ${paymentsStatesColors[paymentAttr]}`}>{L(paymentAttr)}</span>
      </>
    );
  }
  return (
    <>
      <span className={`badge mb-1 me-1 ${ordersStatesColors[orderStateAttr]}`}>{L(orderStateAttr)}</span>
      <span className={`badge mb-1 ${checkoutsStatesColors[checkoutStateAttr]}`}>{L(checkoutStateAttr)}</span>
    </>
  );
}

function capitalizeFirstLetter(string) {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function concatStringArr(arr, separator) {
  return arr.filter((val) => val).join(separator);
}

function getProgressPerCent(val, total) {
  let perCent = 0;
  if (!total) perCent = 0;
  else if (!val) perCent = 100;
  else if (total && val) perCent = (total / val) * 100;

  return perCent > 100 ? 100 : perCent;
}

function getRewardToString(reward, selectedShop, selectedMode) {
  if (reward.discount_type === "value") {
    const currencyCodeValue = selectedShop?.currency?.code;
    const currency = (currencyCodeValue ? currencyCodeValue : "EUR").toUpperCase();
    return `${reward.value}${currency}`;
  } else if (reward.discount_type === "percentage") {
    return `${reward.value}%`;
  } else if (reward.discount_type === "product_sku") {
    const productPrice = reward.data?.product?.getPriceByShopMode(selectedMode?.mode?.code) ?? 0;
    return `${formatPrice(productPrice, selectedShop)}`;
  }
  return 0;
}

function formatedCatPath(catPathToFormat) {
  if (catPathToFormat?.length === 1) {
    const catPath = catPathToFormat[0];
    return {
      category_id: catPath.id,
      category_name: catPath.name,
    };
  } else if (catPathToFormat?.length > 1) {
    const catPath = catPathToFormat[0];
    const subCatPath2 = catPathToFormat[1];

    return {
      category_id: catPath.id,
      category_name: catPath.name,
      sub_category_id: subCatPath2.id,
      sub_category_name: subCatPath2.name,
    };
  }
}

function getCountQuantityOptionVariantByOption(optionVariants) {
  if (!optionVariants || optionVariants === {}) return 0;
  const countQuantity = Object.values(optionVariants).reduce((acc, value) => {
    return acc + value.quantity;
  }, 0);
  return countQuantity;
}

function getAvailableTimeSlotsAsOptions(
  dateToCheckAsString,
  dateFormat,
  params = {},
  productsIntoCart,
  selectedMode,
  availableOrderingHoursDataByShop,
  selectedShop
) {
  // const orderingHours = props.availableOrderingHoursByShop?.[selectedMode?.mode?.code];
  if (!dateToCheckAsString) {
    return [];
  }
  // const dayIndex = concernedDay.getDay() - 1;
  const dayIndex = getDayIndex(dateToCheckAsString, dateFormat);
  const orderingHours = dateToCheckAsString && availableOrderingHoursDataByShop?.getIn([dateToCheckAsString])?.[selectedMode?.mode?.code]?.[dayIndex]; //mode- temp
  if (!orderingHours?.length) {
    return [];
  }

  const toDay = moment(getNowDate(false, false, false));
  const dateToCheck = moment(
    convertFromServerTimeZoneToLocalTimeZone(dateToCheckAsString + " " + toDay.format("HH:mm:ss"), dateFormat + " HH:mm:ss", selectedShop?.getTimeZone())
  );
  // let selectedDate = null;
  // if (dateToCheck) {
  const isToDaySelected = dateToCheck.isSame?.(toDay, "day");
  // selectedDate = isToDaySelected ? dateToCheck.toDate() : dateToCheck.startOf('day').toDate();
  const selectedDate = isToDaySelected ? toDay : dateToCheck.startOf?.("day");
  // }
  const concernedDay = selectedDate ?? toDay;

  //add max product preparation
  const maxPreparationTime = Object.values(productsIntoCart ?? {})?.reduce((accumulator, currentProduct) => {
    return currentProduct?.selectedProduct?.preparationTime > accumulator ? currentProduct?.selectedProduct?.preparationTime : accumulator;
  }, 0);

  const concernedDayWithMaxPreparationTime = concernedDay.add(maxPreparationTime, "minutes").toDate();

  const timeToCheck = concernedDayWithMaxPreparationTime?.toLocaleTimeString();
  let indexMinValue = orderingHours?.length ? 0 : undefined;

  //get indexMinValue by using reduce to orderingHours
  orderingHours?.length &&
    orderingHours?.reduce?.((p, v, index) => {
      if (p.slot < v.slot) {
        return p;
      }
      indexMinValue = index;
      return v;
    });
  // const indexMinValue = orderingHours?.length && orderingHours?.findIndex?.(orderingHourData => orderingHourData.slot === minValue);
  const indexMinValueIsValidAndNotAtTheStart = indexMinValue !== undefined && indexMinValue !== 0;
  const numberOfProductsInCard = params.numberOfProductsInCard ?? 0;
  const isTimeSlotBlockingTypeProduct = selectedShop.isTimeSlotBlockingTypeProduct();
  return orderingHours
    .filter((orderingHourData, index) => {
      if (isTimeSlotBlockingTypeProduct && orderingHourData?.available < numberOfProductsInCard) {
        return false;
      }
      // si la date du jour concerné est aujourd'hui, timeToCheck est l'heure courante, sinon timeToCheck est égal à 00:00:00.
      if (indexMinValueIsValidAndNotAtTheStart && index >= indexMinValue) {
        //ce check est pour le cas ou on a dans les crénaux des heures de la journée suivante
        return true;
      }
      return orderingHourData.slot > timeToCheck;
    })
    .asMutable()
    .map((orderingHourData, index) => ({ label: orderingHourData.slot, value: orderingHourData.slot }));
  //a penser s'il faut gérer avec openingHour pour le cas ou on a pas orderingHour
}

function getAllowedPickupTimeAsOptions(dateToCheck, selectedShop, minuteStep = 10) {
  const dateTimeDay = getDayIndex(dateToCheck);
  const currentDateTime = getNowDate(false, false, false);
  const timeToCheck = currentDateTime.toLocaleTimeString();
  const isToDaySelected = moment(dateToCheck).isSame(currentDateTime, "day");
  const allowedPickupTime = selectedShop?.pickupHours?.filter((pickupHour) => {
    if (pickupHour?.day != dateTimeDay) {
      return false;
    }
    if (!isToDaySelected) {
      return true;
    }
    const startingTimeApi = pickupHour?.startingTimeApi;
    const endingTimeApi = pickupHour?.endingTimeApi;
    const endingTimeApiAsMoment = endingTimeApi > startingTimeApi ? moment(endingTimeApi, "HH:mm:ss") : moment(endingTimeApi, "HH:mm:ss").add(1, "day");
    return moment(timeToCheck, "HH:mm:ss").isBefore(endingTimeApiAsMoment);
  });

  const allowedPickupTimeByOrder = (Immutable.isImmutable(allowedPickupTime) ? Immutable.asMutable(allowedPickupTime) : allowedPickupTime)
    .sort((a, b) => a?.startingTimeApi.localeCompare(b?.startingTimeApi))
    .reduce((orderedPickupTime, pickupHour) => {
      const startingPickupTimeInit = moment(pickupHour.startingTimeApi, "HH:mm:ss");
      const currentDateTimeAsMoment = moment();
      // Le sécinario possible ici : un client prépare la carte durant les heurs de commandes, mais il la laisse ouverte pour un moment avant de continuer a commander
      const startingPickupTime = !isToDaySelected
        ? startingPickupTimeInit
        : startingPickupTimeInit > currentDateTimeAsMoment
          ? startingPickupTimeInit
          : currentDateTimeAsMoment;

      const startingPickupMinute = startingPickupTime ? startingPickupTime?.minute?.() : 0;
      const restOfDivision = startingPickupMinute ? startingPickupMinute % minuteStep : 0;
      const minuteToAdd = restOfDivision ? minuteStep - restOfDivision : 0;
      startingPickupTime.add(minuteToAdd, "minutes");

      const endingPickupTime = moment(pickupHour.endingTimeApi, "HH:mm:ss");
      const slots = [];
      while (startingPickupTime < endingPickupTime) {
        const slotTime = startingPickupTime.format("HH:mm");
        slots.push({ label: slotTime, value: slotTime });
        startingPickupTime.add(minuteStep, "minutes");
      }
      return orderedPickupTime.concat(slots);
    }, []);
  return allowedPickupTimeByOrder;
}

function getApiErrorText(apiErrorCode, strictTranslation = false) {
  const translationResult = getTextTranslation(apiErrorCode, "apiErrorMessages");
  if (translationResult) {
    return translationResult;
  }
  return strictTranslation ? null : L("unknownErrorPTAL");
}

function getTextTranslation(textKey, context = "") {
  const messageId = context ? context + "." + textKey : textKey;
  const translationResult = Lcustom_missing(messageId);
  const autoTranslation = AutoTranslation(messageId);
  if (translationResult && translationResult !== autoTranslation) {
    return translationResult;
  }
  return null;
}

function isAvailableAndValidReward(reward, orderTotal, productsByShopMode) {
  if (!reward.available) {
    return false;
  }
  if (reward.discount_type === "product_sku") {
    return productsByShopMode?.find((product) => reward.external_identifier?.includes?.(product.id));
  }
  if (reward.value && (reward.discount_type === "percentage" || (reward.discount_type === "value" && reward.value <= orderTotal))) {
    return true;
  }
  return false;
}

function getNowDateForTicket(format) {
  return moment().format(format);
}

function decode_base64(s) {
  var e = {},
    i,
    k,
    v = [],
    r = "",
    w = String.fromCharCode;
  var n = [
    [65, 91],
    [97, 123],
    [48, 58],
    [43, 44],
    [47, 48],
  ];

  for (let z in n) {
    for (i = n[z][0]; i < n[z][1]; i++) {
      v.push(w(i));
    }
  }
  for (i = 0; i < 64; i++) {
    e[v[i]] = i;
  }

  for (i = 0; i < s.length; i += 72) {
    var b = 0,
      c,
      x,
      l = 0,
      o = s.substring(i, i + 72);
    for (let x = 0; x < o.length; x++) {
      c = e[o.charAt(x)];
      b = (b << 6) + c;
      l += 6;
      while (l >= 8) {
        r += w((b >>> (l -= 8)) % 256);
      }
    }
  }
  return r;
}

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function countProductIntoCart(idProduct, productsIntoCart) {
  return Object.values(productsIntoCart)?.reduce((previousValue, currentValue) => {
    if (currentValue.selectedProduct.id === idProduct) {
      return previousValue + currentValue.qty;
    } else return previousValue;
  }, 0);
}

function countCatIntoCart(idCat, productsIntoCart) {
  return Object.values(productsIntoCart)?.reduce((previousValue, currentValue) => {
    const isInCats = currentValue.selectedProduct?.productCategories.find((cat) => {
      return cat.category.id === idCat;
    });
    if (isInCats) {
      return previousValue + currentValue.qty;
    } else return previousValue;
  }, 0);
}

function getProductIntoCartByCategories(productsIntoCart, locale) {
  let productIntoCartByCategories = Immutable({});

  Object.entries(productsIntoCart)?.forEach(([indexProduct, productIntoCart], index) => {
    const cat = productIntoCart.selectedProduct.selectedCategory?.[0] ?? new Category(productIntoCart.selectedProduct.productCategories?.[0]?.category);
    const catName = cat.getName?.(locale);
    const idName = cat.id;
    productIntoCartByCategories = Immutable.setIn(productIntoCartByCategories, [catName + "#" + idName, indexProduct], productIntoCart);
  });

  return productIntoCartByCategories;
}

function getNumberOfProducts(productsIntoCart) {
  return Object.values(productsIntoCart)?.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.qty;
  }, 0);
}

const getOwnerIdFromHost = () => {
  if (__DEV__) return DOMAIN_TEST;
  const ownerId = window.location?.origin ? window.location?.origin : "";
  return ownerId;
};

function getTemplate(franchise) {
  if (!franchise) return false;
  const selectedTemplate = franchise?.domains?.filter((domain) => {
    return domain.url === getOwnerIdFromHost();
  });

  return selectedTemplate?.[0]?.template?.templateName?.code;
}

function setUrlLanguage(newLanguage, routePath) {
  const availableLangs = ["fr", "en", "de", "es"];
  let langCode = "";
  if (availableLangs.includes((langCode = newLanguage?.slice(0, 2)))) {
    const urlParts = routePath?.split("/");
    if (urlParts?.length && availableLangs.includes(urlParts[urlParts?.length - 1])) {
      const notUsedAvailableLangs = availableLangs.filter((lang) => lang !== langCode);
      if (notUsedAvailableLangs.includes(urlParts[urlParts?.length - 1])) {
        urlParts[urlParts?.length - 1] = langCode;
        const newUrl = urlParts.join("/");
        setTimeout(() => {
          history.pushState({}, null, newUrl);
        }, 200);
      }
    }
  }
}

function getPaymentMethodsWithAnonymounsCheck(paymentMethods, isSignedIn) {
  if (isSignedIn) return paymentMethods;

  return paymentMethods?.filter((pm) => pm.anonymousAuthorized || pm.kioskOnly);
}

function paymentIsPending(status) {
  const STATE_AWAITING_PAYMENT = "awaiting_payment";
  const STATE_NEW = "new";
  const STATE_PROCESSING = "processing";
  const STATE_REQUIRE_ACTION = "requires_action";

  const isPending = [STATE_AWAITING_PAYMENT, STATE_NEW, STATE_PROCESSING, STATE_REQUIRE_ACTION].includes(status);

  return isPending;
}

function paymentIsPaid(status) {
  const STATE_PAID = "paid";
  const STATE_PARTIALLY_PAID = "partially_paid";

  const isPaid = [STATE_PAID, STATE_PARTIALLY_PAID].includes(status);

  return isPaid;
}

function paymentIsError(status) {
  const STATE_FAILED = "failed";
  const STATE_ERROR = "error";
  const STATE_EXPIRED = "expired";
  const STATE_UNKNOWN = "unknown";
  const STATE_CANCELLED = "cancelled";
  const STATE_REFUNDED = "refunded";
  const STATE_PAYMENT_SKIPPED = "skipped";

  const isError = [STATE_FAILED, STATE_ERROR, STATE_EXPIRED, STATE_UNKNOWN, STATE_CANCELLED, STATE_REFUNDED, STATE_PAYMENT_SKIPPED].includes(status);

  return isError;
}

const getErrorsMessage = (paymentErrors) => {
  if (!paymentErrors) {
    return null;
  }
  const isValidationErrors = paymentErrors?.type === "validationError" && paymentErrors?.errors;
  const errors = isValidationErrors ? paymentErrors.errors : paymentErrors;
  const multipleMessage = Array.isArray(errors);
  if (!multipleMessage) {
    return getErrorText(errors, isValidationErrors);
  }
  if (errors?.length === 1) {
    return getErrorText(errors[0], isValidationErrors);
  }
  let index = 0;
  return (
    <ul>
      {errors?.map?.((error) => (
        <li key={index++}>{getErrorText(error, isValidationErrors)}</li>
      ))}
    </ul>
  );
};

const getErrorText = (error, isValidationErrors) => {
  if (error?.detail) {
    return isValidationErrors ? L(error.detail) : getApiErrorText(error.detail);
  }
  return isValidationErrors ? L(error) : getApiErrorText(error);
};

export default {
  roundNumber,
  getAddress,
  getButtonAllStyle,
  getButtonDateStyle,
  getButtonRoundStyle,
  slugifyTableFilters,
  string_to_slug,
  getFormatedImg,
  isMobile,
  getDeviceTypeBasedOnDimensions,
  getOptionsByIds,
  formatPrice,
  getTotalSynthesisData,
  hashCode,
  getDayNameByIndex,
  isUpperToMaxProduct,
  getNowDate,
  convertFromServerTimeZoneToLocalTimeZone,
  convertFromLocalTimeZoneToServerTimeZone,
  getDayIndex,
  getDate,
  canUserOrder,
  isInClosingDays,
  isShopOpen,
  comingOpeningHourInDay,
  comingOpeningHourInNextDays,
  getGroupedSlots,
  apiDateFormat,
  apiDateTimeFormat,
  localDateFormat,
  toMomentLocale,
  getCartLinesFromOrder,
  getIdFromAPIPlatformString,
  getFormatedFileLink,
  getFinalArraySync,
  getEnabledClosingDays,
  getEnabledOpeningHours,
  getSecondsBetweenTwoTimes,
  normalizeShop,
  normalizeShopMode,
  filterOrderingHoursInShopModeByOpeningHours,
  getStateTextAndColor,
  capitalizeFirstLetter,
  concatStringArr,
  getProgressPerCent,
  getRewardToString,
  formatedCatPath,
  adjust,
  getCountQuantityOptionVariantByOption,
  getAvailableTimeSlotsAsOptions,
  getAllowedPickupTimeAsOptions,
  // isShopOpened
  getApiErrorText,
  getTextTranslation,
  getStateComponent,
  getFormatedImgForCrop,
  isAvailableAndValidReward,
  getNowDateForTicket,
  decode_base64,
  countProductIntoCart,
  countCatIntoCart,
  getProductIntoCartByCategories,
  getNumberOfProducts,
  getTemplate,
  setUrlLanguage,
  b64DecodeUnicode,
  getPaymentMethodsWithAnonymounsCheck,
  paymentIsPending,
  paymentIsPaid,
  paymentIsError,
  getErrorsMessage,
};
