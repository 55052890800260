import L from "../../I18n/tr";

const takeAway = (sizeDim = 20, colorMode = "currentColor") => (
  <svg xmlns="http://www.w3.org/2000/svg" width={sizeDim} height={sizeDim} viewBox="0 0 65.808 65.808">
    <g id="Groupe_4562" data-name="Groupe 4562" transform="translate(5036.308 9068.711)">
      <g className="g-border" data-name="Rectangle 781" transform="translate(-4970.5 -9068.711) rotate(90)" fill="none" stroke={colorMode} strokeWidth="2">
        <rect width="65.808" height="65.808" rx="32.904" stroke="none" />
        <rect x="1" y="1" width="63.808" height="63.808" rx="31.904" fill="none" />
      </g>
      <g id="Groupe_4552" data-name="Groupe 4552" transform="translate(-5016.507 -9057.455)">
        <g id="Tracé_583428" data-name="Tracé 583428" transform="translate(4.658 2)" fill="none">
          <path d="M7.82,0c4.319,0,7.82,4.966,7.82,11.092S0,17.218,0,11.092,3.5,0,7.82,0Z" stroke="none" />
          <path
            d="M 7.820468425750732 2.999999046325684 C 6.355408668518066 2.999999046325684 5.263128280639648 4.238959312438965 4.742428779602051 4.97748851776123 C 3.635087966918945 6.548069000244141 2.999998092651367 8.776759147644043 2.999998092651367 11.09209823608398 C 2.999998092651367 11.17198944091797 3.010868072509766 11.21997833251953 3.078198432922363 11.31038856506348 C 3.157558441162109 11.41696929931641 3.361108779907227 11.63251876831055 3.832598686218262 11.87873840332031 C 4.801258087158203 12.38459873199463 6.292048454284668 12.686598777771 7.820468425750732 12.686598777771 C 9.348888397216797 12.686598777771 10.83967781066895 12.38459873199463 11.8083381652832 11.87873840332031 C 12.27982807159424 11.63251876831055 12.48337841033936 11.41695880889893 12.5627384185791 11.31038856506348 C 12.6300687789917 11.21996879577637 12.6409387588501 11.17198944091797 12.6409387588501 11.09209823608398 C 12.6409387588501 8.776759147644043 12.0058479309082 6.548069000244141 10.89850807189941 4.977478981018066 C 10.37780857086182 4.238959312438965 9.285537719726562 2.999999046325684 7.820468425750732 2.999999046325684 M 7.820468425750732 -1.9073486328125e-06 C 12.13959884643555 -1.9073486328125e-06 15.6409387588501 4.966098785400391 15.6409387588501 11.09209823608398 C 15.6409387588501 17.21809959411621 -1.9073486328125e-06 17.21809959411621 -1.9073486328125e-06 11.09209823608398 C -1.9073486328125e-06 4.966098785400391 3.501348495483398 -1.9073486328125e-06 7.820468425750732 -1.9073486328125e-06 Z"
            stroke="none"
            fill={colorMode}
          />
        </g>
        <rect id="Rectangle_364" data-name="Rectangle 364" width="24.957" height="30.965" rx="5" transform="translate(0 9.719)" fill={colorMode} />
      </g>
    </g>
  </svg>
);

const onSite = (sizeDim = 20, colorMode = "currentColor") => (
  <svg xmlns="http://www.w3.org/2000/svg" width={sizeDim} height={sizeDim} viewBox="0 0 65.808 65.808">
    <g id="Groupe_4561" data-name="Groupe 4561" transform="translate(5036.308 9164.711)">
      <g className="g-border" data-name="Rectangle 780" transform="translate(-4970.5 -9164.711) rotate(90)" fill="none" stroke={colorMode} strokeWidth="2">
        <rect width="65.808" height="65.808" rx="32.904" stroke="none" />
        <rect x="1" y="1" width="63.808" height="63.808" rx="31.904" fill="none" />
      </g>
      <g id="Groupe_4551" data-name="Groupe 4551" transform="translate(-5013.55 -9147.217)">
        <line
          id="Ligne_664"
          data-name="Ligne 664"
          y2="31.145"
          transform="translate(17.146 0)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Ligne_666"
          data-name="Ligne 666"
          y2="9.951"
          transform="translate(19.52 0.866)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Ligne_667"
          data-name="Ligne 667"
          y2="10.512"
          transform="translate(7.489 0)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Ligne_670"
          data-name="Ligne 670"
          y2="10.512"
          transform="translate(3.745 0)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Ligne_668"
          data-name="Ligne 668"
          y2="10.512"
          transform="translate(0 0)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          id="Ligne_669"
          data-name="Ligne 669"
          x1="6.307"
          transform="translate(0.701 10.512)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Ligne_671"
          data-name="Ligne 671"
          x1="2.373"
          y1="0.866"
          transform="translate(17.146 0)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Ligne_672"
          data-name="Ligne 672"
          x1="2.373"
          transform="translate(17.146 10.817)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Ligne_665"
          data-name="Ligne 665"
          y2="20.633"
          transform="translate(3.745 10.512)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </g>
  </svg>
);

const delivery = (sizeDim = 20, colorMode = "currentColor") => (
  <svg xmlns="http://www.w3.org/2000/svg" width={sizeDim} height={sizeDim} viewBox="0 0 65.808 65.808">
    <g id="Groupe_4563" data-name="Groupe 4563" transform="translate(5036.308 8972.711)">
      <g className="g-border" data-name="Rectangle 782" transform="translate(-4970.5 -8972.711) rotate(90)" fill="none" stroke={colorMode} strokeWidth="2">
        <rect width="65.808" height="65.808" rx="32.904" stroke="none" />
        <rect x="1" y="1" width="63.808" height="63.808" rx="31.904" fill="none" />
      </g>
      <g id="Groupe_4553" data-name="Groupe 4553" transform="translate(-5024.739 -8959.455)">
        <g id="Tracé_583430" data-name="Tracé 583430" transform="translate(20.534)" fill="none">
          <path d="M7.82,0c4.319,0,7.82,4.966,7.82,11.092S0,17.218,0,11.092,3.5,0,7.82,0Z" stroke="none" />
          <path
            d="M 7.820468425750732 2.999999046325684 C 6.355408668518066 2.999999046325684 5.263128280639648 4.238959312438965 4.742428779602051 4.97748851776123 C 3.635087966918945 6.548069000244141 2.999998092651367 8.776759147644043 2.999998092651367 11.09209823608398 C 2.999998092651367 11.17198944091797 3.010868072509766 11.21997833251953 3.078198432922363 11.31038856506348 C 3.157558441162109 11.41696929931641 3.361108779907227 11.63251876831055 3.832598686218262 11.87873840332031 C 4.801258087158203 12.38459873199463 6.292048454284668 12.686598777771 7.820468425750732 12.686598777771 C 9.348888397216797 12.686598777771 10.83967781066895 12.38459873199463 11.8083381652832 11.87873840332031 C 12.27982807159424 11.63251876831055 12.48337841033936 11.41695880889893 12.5627384185791 11.31038856506348 C 12.6300687789917 11.21996879577637 12.6409387588501 11.17198944091797 12.6409387588501 11.09209823608398 C 12.6409387588501 8.776759147644043 12.0058479309082 6.548069000244141 10.89850807189941 4.977478981018066 C 10.37780857086182 4.238959312438965 9.285537719726562 2.999999046325684 7.820468425750732 2.999999046325684 M 7.820468425750732 -1.9073486328125e-06 C 12.13959884643555 -1.9073486328125e-06 15.6409387588501 4.966098785400391 15.6409387588501 11.09209823608398 C 15.6409387588501 17.21809959411621 -1.9073486328125e-06 17.21809959411621 -1.9073486328125e-06 11.09209823608398 C -1.9073486328125e-06 4.966098785400391 3.501348495483398 -1.9073486328125e-06 7.820468425750732 -1.9073486328125e-06 Z"
            stroke="none"
            fill={colorMode}
          />
        </g>
        <line
          id="Ligne_660"
          data-name="Ligne 660"
          x2="15.876"
          transform="translate(6.666 14.15)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3.5"
        />
        <line
          id="Ligne_661"
          data-name="Ligne 661"
          x2="15.876"
          transform="translate(0 20.342)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3.5"
        />
        <line
          id="Ligne_662"
          data-name="Ligne 662"
          x2="15.876"
          transform="translate(0 26.534)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3.5"
        />
        <line
          id="Ligne_663"
          data-name="Ligne 663"
          x2="15.876"
          transform="translate(6.666 32.726)"
          fill="none"
          stroke={colorMode}
          strokeLinecap="round"
          strokeWidth="3.5"
        />
        <path
          id="Tracé_583429"
          data-name="Tracé 583429"
          d="M3.451,0H21.506a3.451,3.451,0,0,1,3.451,3.451V27.514a3.451,3.451,0,0,1-3.451,3.451H3.451A3.451,3.451,0,0,1,0,27.514V23.365H5.745c2.354,0,2.132-2.736,0-2.736H0V17.165H2.522c2.535,0,2.506-2.735,0-2.735H0V10.952H5.745c2.556,0,2.526-2.716,0-2.716H0V3.451A3.451,3.451,0,0,1,3.451,0Z"
          transform="translate(15.876 7.648)"
          fill={colorMode}
        />
      </g>
    </g>
  </svg>
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const deliveriesModes = {
  //https://developer.mozilla.org/fr/docs/Web/JavaScript/Guide/Working_with_objects#d%C3%A9finir_des_accesseurs_et_des_mutateurs_getters_et_setters
  get "take-out"() {
    return { name: capitalizeFirstLetter(L("takeAway")), icon: takeAway };
  },
  get delivery() {
    return { name: capitalizeFirstLetter(L("delivery")), icon: delivery };
  },
  get "on-site"() {
    return { name: capitalizeFirstLetter(L("onSite")), icon: onSite };
  },
};

export const mappingDeliveriesModesByLink = {
  takeAway: "take-out",
  delivery: "delivery",
  onSite: "on-site",
};
// deliveriesModesLinksByCode is deducted by swaping key with value in mappingDeliveriesModesByLink object
export const deliveriesModesLinksByCode = Object.entries(mappingDeliveriesModesByLink).reduce((r, [k, v]) => ((r[v] = k), r), {});

// takeAway "Vente à emporter"
// delivery "Vente en livraison"
// onSite "Vente sur place"
