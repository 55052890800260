import React from "react";
import { TEMPLATE2_NAME_CODE } from "../Constants";

function FacebookSvg(props) {
  if (props.template_name_code === TEMPLATE2_NAME_CODE) {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
        <defs>
          <clipPath id="facebook-clip-path">
            <rect width="23" height="23" transform="translate(283 279)" />
          </clipPath>
        </defs>
        <g transform="translate(-283 -279)" clipPath="url(#facebook-clip-path)">
          <g transform="translate(283 279)">
            <path
              fill="var(--bs-primary)"
              d="M23,11.57a11.509,11.509,0,0,1-9.708,11.361V14.916h2.673l.509-3.316H13.292V9.449a1.657,1.657,0,0,1,1.869-1.791h1.446V4.835a17.626,17.626,0,0,0-2.567-.224c-2.62,0-4.332,1.588-4.332,4.462V11.6H6.8v3.316H9.708V22.93A11.5,11.5,0,1,1,23,11.57Z"
            />
          </g>
        </g>
      </svg>
    );
  }
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="40px" height="40px">
      <path fill="var(--bs-primary)" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z" />
      <path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z" />
    </svg>
  );
}

export default FacebookSvg;
