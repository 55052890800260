import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import Product from "../Logic/Entities/Product";
import functions from "../Shared/functions";
import { dateFormatSyncInStore, serverTimeZone } from "../Shared/Constants";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getProductsRequest: ["menuId", "status", "page"],
  getProductsSuccess: ["productsData", "selctedShop", "shopTimeZone", "page"],
  getProductsFailure: ["error", "errors"],
  resetProductListByShop: ["shopId"],

  getOptionsByProductRequest: ["prductId", "status"],
  getOptionsByProductSuccess: ["optionsData"],
  getOptionsByProductFailure: ["error", "errors"],

  setSinceProducts: ["shopTimeZone"],
  getProductsFromSyncSuccess: ["productsData", "selctedShop", "shopTimeZone"],

  setShowAddProductToCardModal: ["showAddProductToCardModal"],

  setCurrentProductToAddToCart: ["currentProductToAddToCart", "category"],
});

export const ProductsTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  productsFetching: false,
  productsError: null,
  productsErrors: null,
  productsData: null,
  productsPaginationData: null,
  optionsData: null,
  productOptionsFetching: false,
  productOptionsError: null,
  productOptionsErrors: null,
  sinceProducts: false,
  lastProductsSyncTime: null,
  toggleRefreshProducts: false,
  showAddProductToCardModal: false,
  currentProductToAddToCart: null,
  dateTimeGetProduct: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

function getProductsByShopMode(state, shopModeCode) {
  if (!shopModeCode) return state.product?.productsData?.[state.shop?.shopData?.id]?.map((product) => getImmutableAsEntity(product, Product));

  return state.product?.productsData?.[state.shop?.shopData?.id]
    ?.filter((prod) => {
      const enabledShopMode = prod.productShopModes?.find?.((shopMode) => shopMode.enabled && shopMode.shopMode?.mode?.code === shopModeCode);
      return !!enabledShopMode;
    })
    ?.map((product) => getImmutableAsEntity(product, Product));
}

/* ------------- Selectors ------------- */
export const ProductsSelectors = {
  getProducts: (state, shopModeCode) => getProductsByShopMode(state, shopModeCode), //state.product?.productsData?.[state.shop?.shopData?.id]?.map((product)=> getImmutableAsEntity(product, Product)),
  getProductsPagination: (state) => state?.product?.productsPaginationData?.[state.shop?.shopData?.id],
  getSinceProducts: (state) => state?.product?.sinceProducts ?? false,
  getLastProductsSyncTime: (state) => state?.product?.lastProductsSyncTime ?? null,
  getToggleRefreshProducts: (state) => state.product?.toggleRefreshProducts,
  getShowAddProductToCardModal: (state) => state.product?.showAddProductToCardModal,
  getCurrentProductToAddToCart: (state) => {
    const currentProductToAddToCart = state.product?.currentProductToAddToCart;
    if (!currentProductToAddToCart) return null;
    // Après actualisation, l'element perd son prototype
    return currentProductToAddToCart.constructor.name === "Product" ? currentProductToAddToCart : getImmutableAsEntity(currentProductToAddToCart, Product);
  },
  getDateTimeGetProduct: (state) => state.product?.dateTimeGetProduct?.[state.shop?.shopData?.id],
};

/* ------------- Reducers ------------- */

export const getProductsRequest = (state) => state.merge({ productsFetching: true });

export const getProductsSuccess = (state, { productsData, selctedShop, shopTimeZone, page }) => {
  const productsPaginationDataByShop = {
    [selctedShop?.id]: productsData["hydra:view"]
      ? {
          first: productsData["hydra:view"]["hydra:first"],
          last: productsData["hydra:view"]["hydra:last"],
          next: productsData["hydra:view"]["hydra:next"],
          current: productsData["hydra:view"]["@id"],
          totalItems: productsData["hydra:totalItems"],
        }
      : { first: 0, last: 0, next: 0, current: 0, totalItems: 0 },
  };
  const productsPaginationData = { ...state.productsPaginationData, ...productsPaginationDataByShop };
  // const allProductsData = state?.productsData??[];
  // allProductsData[selctedShop?.id] = [...(allProductsData?.[selctedShop?.id]??[]), ...productsData["hydra:member"]];
  const oldProductsData = page === 1 ? [] : state.productsData?.[selctedShop?.id] ?? [];
  const newProducts = productsData["hydra:member"] ?? [];
  const formatedProductsByShop = { [selctedShop?.id]: mergeProducts(oldProductsData, newProducts) };
  const allProductsData = { ...state.productsData, ...formatedProductsByShop };
  const sinceProducts = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  const currentDateTime = { [selctedShop?.id]: functions.getNowDate(false, false, false) };
  if (__DEV__) console.log("foxOrders allProductsData", allProductsData);

  // const formatedProducts = productsData.setIn(selctedShop, )
  return state.merge({
    // productsData: [...state?.productsData??[], ...productsData["hydra:member"]],
    productsData: allProductsData,
    productsPaginationData,
    productsFetching: false,
    productsError: null,
    productsErrors: null,
    sinceProducts,
    dateTimeGetProduct: currentDateTime,
  });
};

function mergeProducts(oldProducts, newProducts) {
  if (!newProducts.length) {
    return oldProducts;
  }
  if (!oldProducts.length) {
    return newProducts;
  }
  const newProductsAddedIds = [];
  const oldProductsDataUpdated = oldProducts.map((oldProduct) => {
    const newProductVersion = newProducts.find((newProduct) => newProduct.id === oldProduct.id);
    if (newProductVersion) {
      newProductsAddedIds.push(newProductVersion.id);
      return newProductVersion;
    }
    return oldProduct;
  });
  if (newProductsAddedIds.length) {
    return oldProductsDataUpdated.concat(newProducts.filter((newProduct) => !newProductsAddedIds.includes(newProduct.id)));
  } else {
    return oldProductsDataUpdated.concat(newProducts);
  }
  // return oldProductsDataUpdated;
}
export const getProductsFailure = (state, { productsError, productsErrors }) =>
  state.merge({
    productsFetching: false,
    productsError,
    productsErrors,
  });

export const setSinceProducts = (state, { shopTimeZone }) => {
  const sinceProducts = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({ sinceProducts });
};

export const getProductsFromSyncSuccess = (state, { productsData, selctedShop, shopTimeZone }) => {
  const productsPaginationDataByShop = { [selctedShop?.id]: { ...state.productsPaginationData[selctedShop?.id], totalItems: productsData.length } };
  const productsPaginationData = { ...state.productsPaginationData, ...productsPaginationDataByShop };

  const formatedProductsByShop = { [selctedShop?.id]: [...productsData] };
  const allProductsData = { ...state.productsData, ...formatedProductsByShop };
  const sinceProducts = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  if (__DEV__) console.log("foxOrders getProductsFromSyncSuccess allProductsData", allProductsData);

  return state.merge({
    productsData: allProductsData,
    productsPaginationData,
    // productsFetching: false,
    productsError: null,
    productsErrors: null,
    sinceProducts,
    lastProductsSyncTime: sinceProducts,
    toggleRefreshProducts: !state.toggleRefreshProducts,
  });
};

export const resetProductListByShop = (state, { shopId }) =>
  state.merge({
    productsData: { ...state.productsData, [shopId]: [] },
    productsPaginationData: { ...state.productsPaginationData, [shopId]: null },
  });

export const getOptionsByProductRequest = (state) => state.merge({ productOptionsFetching: true });

export const getOptionsByProductSuccess = (state, { productId, optionsData }) => {
  return state;
  // return state.merge({
  //   productsData,
  //   productsFetching: false,
  //   productsError: null,
  //   productsErrors: null
  // });
};

export const getOptionsByProductFailure = (state, { productOptionsError, productOptionsErrors }) =>
  state.merge({
    productOptionsFetching: false,
    productOptionsError,
    productOptionsErrors,
  });

export const setShowAddProductToCardModal = (state, { showAddProductToCardModal }) => state.merge({ showAddProductToCardModal });

export const setCurrentProductToAddToCart = (state, { currentProductToAddToCart, category }) => {
  if (category) {
    const oldSelectedCategory = currentProductToAddToCart?.selectedCategory ?? [];
    const selectedCategory = [...oldSelectedCategory, category];
    return state.merge({ currentProductToAddToCart: { ...currentProductToAddToCart, selectedCategory } });
  }

  return state.merge({ currentProductToAddToCart });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCTS_REQUEST]: getProductsRequest,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [Types.GET_PRODUCTS_FAILURE]: getProductsFailure,
  [Types.RESET_PRODUCT_LIST_BY_SHOP]: resetProductListByShop,

  [Types.GET_OPTIONS_BY_PRODUCT_REQUEST]: getOptionsByProductRequest,
  [Types.GET_OPTIONS_BY_PRODUCT_SUCCESS]: getOptionsByProductSuccess,
  [Types.GET_OPTIONS_BY_PRODUCT_FAILURE]: getOptionsByProductFailure,
  [Types.GET_PRODUCTS_FROM_SYNC_SUCCESS]: getProductsFromSyncSuccess,
  [Types.SET_SINCE_PRODUCTS]: setSinceProducts,

  [Types.SET_SHOW_ADD_PRODUCT_TO_CARD_MODAL]: setShowAddProductToCardModal,

  [Types.SET_CURRENT_PRODUCT_TO_ADD_TO_CART]: setCurrentProductToAddToCart,
});
