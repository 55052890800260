import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import Category from "../Logic/Entities/Category";
import functions from "../Shared/functions";
import { dateFormatSyncInStore, serverTimeZone } from "../Shared/Constants";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getCategoriesRequest: ["menuId", "status", "page"],
  getCategoriesSuccess: ["categoriesData", "selctedShop", "isFromSync"],
  getCategoriesFailure: ["error", "errors"],
  setSinceCategories: ["shopTimeZone"],
});

export const CategoriesTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  categoriesFetching: false,
  categoriesError: null,
  categoriesErrors: null,
  categoriesData: null,
  categoriesPaginationData: null,
  lastCategoriesSyncTime: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

function getImmutableAsObj(immutable) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return entityObj;
}

function sortCats(state) {
  const cats = getImmutableAsObj(state.category?.categoriesData?.[state.shop?.shopData?.id]);

  const sortedCats = cats
    ?.filter(
      (value, index, self) => {
        return !!value?.visible && index ===
        self.findIndex((t) => {
          return t.id === value.id;
        })
      }
    )
    .sort((cat1, cat2) => {
      return cat1?.position - cat2?.position;
    });

  return sortedCats?.map((category) => getImmutableAsEntity(category, Category));
}

/* ------------- Selectors ------------- */
export const CategoriesSelectors = {
  getCategories: (state) => sortCats(state),
  getAllCategoriesLength: (state) => state.category?.categoriesData?.[state.shop?.shopData?.id]?.length?? 0,
  getCategoriesPagination: (state) => state?.category?.categoriesPaginationData?.[state.shop?.shopData?.id],
  getSinceCategories: (state) => state?.category?.sinceCategories ?? false,
  getLastCategoriesSyncTime: (state) => state?.category?.lastCategoriesSyncTime ?? false,
};

/* ------------- Reducers ------------- */

export const getCategoriesRequest = (state) => state.merge({ categoriesFetching: true });

export const getCategoriesSuccess = (state, { categoriesData, selctedShop, isFromSync }) => {
  const sinceCategories = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  const categoriesPaginationDataByShop = isFromSync
    ? { [selctedShop?.id]: { ...state.categoriesPaginationData[selctedShop?.id], totalItems: categoriesData.length } }
    : {
        [selctedShop?.id]: categoriesData["hydra:view"]
          ? {
              first: categoriesData["hydra:view"]["hydra:first"],
              last: categoriesData["hydra:view"]["hydra:last"],
              next: categoriesData["hydra:view"]["hydra:next"],
              current: categoriesData["hydra:view"]["@id"],
              totalItems: categoriesData["hydra:totalItems"],
            }
          : { first: 0, last: 0, next: 0, current: 0, totalItems: 0 },
      };
  const categoriesPaginationData = { ...state.categoriesPaginationData, ...categoriesPaginationDataByShop };
  const newCategories = categoriesData["hydra:member"];
  const formatedCategoriesByShop = isFromSync
    ? { [selctedShop?.id]: [...categoriesData] }
    : state.categoriesData
      ? { [selctedShop?.id]: [...(state.categoriesData[selctedShop?.id] ?? []), ...newCategories] }
      : { [selctedShop?.id]: [...newCategories] };
  const allCategoriesData = { ...state.categoriesData, ...formatedCategoriesByShop };

  const lastCategoriesSyncTime = isFromSync ? sinceCategories : state.lastCategoriesSyncTime;
  return state.merge({
    categoriesData: allCategoriesData,
    categoriesPaginationData,
    categoriesFetching: false,
    categoriesError: null,
    categoriesErrors: null,
    sinceCategories,
    lastCategoriesSyncTime,
  });
};

export const getCategoriesFailure = (state, { categoriesError, categoriesErrors }) =>
  state.merge({
    categoriesFetching: false,
    categoriesError,
    categoriesErrors,
  });

export const setSinceCategories = (state, { shopTimeZone }) => {
  const sinceCategories = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
  return state.merge({
    sinceCategories,
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CATEGORIES_REQUEST]: getCategoriesRequest,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAILURE]: getCategoriesFailure,
  [Types.SET_SINCE_CATEGORIES]: setSinceCategories,
});
