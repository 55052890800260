import LocalStorage from "../Shared/LocalStorage";
import immutablePersistenceTransform from "../Transforms/ImmutablePersistenceTransform";

// More info here:  https://shift.infinite.red/shipping-persistant-reducers-7341691232b1
const REDUX_PERSIST = {
  active: true,
  reducerVersion: "3.3",
  storeConfig: {
    key: "primary",
    storage: LocalStorage,
    // Reducer keys that you do NOT want stored to persistence here.
    // blacklist: ['login'],
    // Optionally, just specify the keys you DO want stored to persistence.
    // An empty array means 'don't store any reducers' -> infinitered/ignite#409
    whitelist: ["login", "startup", "user", "franchise", "shop", "menu", "product"],
    // , 'menu', 'card', 'category', 'product', 'option'
    transforms: [immutablePersistenceTransform],
    debug: __DEV__,
  },
};

export default REDUX_PERSIST;
