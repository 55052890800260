export const paymentsStates = {
  STATE_AUTHORIZED: "authorized",
  STATE_AWAITING_PAYMENT: "awaiting_payment",
  STATE_NEW: "new",
  STATE_PROCESSING: "processing",
  STATE_FAILED: "failed",
  STATE_EXPIRED: "expired",
  STATE_UNKNOWN: "unknown",
  STATE_CANCELLED: "cancelled",
  STATE_PAID: "paid",
  STATE_REFUNDED: "refunded",
  STATE_PAYMENT_SKIPPED: "skipped",
  STATE_REQUIRE_ACTION: "requires_action",
};

export const ordersStates = {
  STATE_NEW: "new",
  STATE_IN_PROGRESS: "in_progress",
  STATE_CANCELLED: "cancelled",
  STATE_FULFILLED: "fulfilled",
  STATE_EXPIRED: "expired",
  STATE_FAILED: "failed",
};

export const checkoutsStates = {
  STATE_NEW: "new",
  STATE_SENDING: "sending",
  STATE_AWAITING_PAYMENT: "awaiting",
  STATE_CANCELLED: "cancelled",
  STATE_FAILED: "failed",
  STATE_PENDING: "pending",
  STATE_ACCEPTED: "accepted",
  STATE_REJECTED: "rejected",
  STATE_LATE_ARRIVAL: "late",
  STATE_DELIVRED: "delivered",
  STATE_REFUNDED: "refunded",
  STATE_READY: "ready",
  STATE_REQUIRE_ACTION: "require_action",
};

export const paymentsStatesColors = {
  STATE_AUTHORIZED: "bg-soft-success",
  STATE_AWAITING_PAYMENT: "bg-soft-warning",
  STATE_NEW: "bg-info",
  STATE_PROCESSING: "bg-soft-success",
  STATE_FAILED: "bg-danger",
  STATE_EXPIRED: "bg-soft-danger",
  STATE_UNKNOWN: "bg-soft-dark",
  STATE_CANCELLED: "bg-soft-danger",
  STATE_PAID: "bg-success",
  STATE_REFUNDED: "bg-dark",
  STATE_PAYMENT_SKIPPED: "bg-soft-danger",
  STATE_REQUIRE_ACTION: "bg-primary",
};

export const ordersStatesColors = {
  STATE_NEW: "bg-info",
  STATE_CANCELLED: "bg-soft-danger",
  STATE_FULFILLED: "bg-success",
  STATE_IN_PROGRESS: "bg-soft-success",
  STATE_FAILED: "bg-danger",
  STATE_EXPIRED: "bg-danger",
};

export const checkoutsStatesColors = {
  STATE_NEW: "bg-info",
  STATE_SENDING: "bg-warning",
  STATE_AWAITING_PAYMENT: "bg-soft-warning",
  STATE_CANCELLED: "bg-soft-danger",
  STATE_FAILED: "bg-danger",
  STATE_PENDING: "bg-secondary",
  STATE_ACCEPTED: "bg-soft-info",
  STATE_REJECTED: "bg-soft-danger",
  STATE_LATE_ARRIVAL: "bg-soft-warning",
  STATE_DELIVRED: "bg-success",
  STATE_REFUNDED: "bg-dark",
  STATE_READY: "bg-soft-success",
  STATE_REQUIRE_ACTION: "bg-warning",
};

export const acceptedPaymentMethodsCode = [
  "cash",
  "check",
  "stripe",
  "payxpert",
  "payplug",
  "paygreen",
  "paiement-comptoir",
  "paiement-livreur",
  "nepting-retail-center",
];

// methode offline + ==>
export const acceptedPaymentMethodsCodeForBorne = ["nepting-retail-center"];

export const burnedStates = [ordersStates.STATE_CANCELLED, ordersStates.STATE_EXPIRED, ordersStates.STATE_FAILED];

export const delaySync = 124000; //(2 * 60000) + 4000;

export const serverTimeZone = "Europe/Paris";

export const isSynch = true;

export const dateFormatSyncInStore = "YYYY-MM-DD HH:mm:ss";
export const dateFormatSyncForApi = "YYYY-MM-DD HH:mm";

export const byQoodos = (colorSvg = "currentColor") => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="Calque_1" x="0px" y="0px" viewBox="0 0 198.9 60.3" width={"30%"} height={"30%"}>
    <g>
      <g>
        <circle fill={colorSvg} r="11.47227" cy="12.97227" cx="13.37227" />
        <path
          fill={colorSvg}
          d="m104.73217,12.76314c-1.94192,-1.82242 -4.60086,-2.92782 -7.97681,-2.92782c-3.13695,0 -5.64651,0.95602 -7.52868,2.53943c0,0 -1.43403,0.83652 -4.15272,7.76768l-3.70459,10.576l-6.81166,17.38766c-0.62739,1.70292 -2.24068,2.86807 -4.0631,2.86807l0,0c-1.61329,0 -2.77844,-0.59751 -3.49546,-1.28466c-1.2249,-1.2249 -1.37428,-2.59919 -1.37428,-3.94359l0,-23.4823c0,-1.37428 0.1195,-2.71869 1.37428,-3.94359c0.71702,-0.71702 1.88217,-1.28466 3.49546,-1.28466s2.77844,0.59751 3.49546,1.28466l2.80832,2.59919l4.3021,-5.22825l-2.68881,-2.15105c-1.94192,-1.79254 -4.57098,-2.86807 -7.91706,-2.86807c-3.37595,0 -6.03489,1.1054 -7.97681,2.92782c-3.10707,2.92782 -3.37595,6.21415 -3.37595,9.64985l0,21.51051c0,3.43571 0.26888,6.72203 3.37595,9.64985c1.94192,1.82242 4.60086,2.92782 7.97681,2.92782c1.04565,0 2.03155,-0.1195 2.92782,-0.29876c2.68881,-0.59751 4.86974,-2.47968 6.03489,-4.95937c0.9859,-2.18093 2.56931,-5.94527 4.95937,-12.39842l8.24569,-20.40511c0.62739,-1.76267 2.24068,-3.04732 4.09297,-3.0772c1.67304,0 2.83819,0.59751 3.55521,1.28466c1.2249,1.2249 1.37428,2.59919 1.37428,3.94359l0,23.45243c0,1.37428 -0.1195,2.71869 -1.37428,3.94359c-0.71702,0.71702 -1.88217,1.28466 -3.49546,1.28466s-1.9718,-0.23901 -3.49546,-1.28466l-3.46558,-2.83819l-4.57098,4.75024l3.61496,2.83819c2.30043,1.94192 4.86974,2.98757 7.91706,2.86807c3.37595,-0.14938 6.03489,-1.1054 7.97681,-2.92782c3.10707,-2.92782 3.37595,-6.21415 3.37595,-9.64985l0,-21.51051c-0.05975,-3.37595 -0.32863,-6.69216 -3.43571,-9.5901z"
          className="st0"
        />
        <g>
          <path
            fill={colorSvg}
            d="m28.28025,22.83125c0,-3.43571 0.26888,-6.75191 3.37595,-9.64985c1.94192,-1.82242 4.60086,-2.92782 7.97681,-2.92782s6.03489,1.1054 7.97681,2.92782c3.10707,2.92782 3.37595,6.21415 3.37595,9.64985l0,21.51051c0,2.59919 -0.05975,4.09297 -1.04565,6.09464l4.09297,3.22658l-3.3162,4.00334l-4.00334,-3.16682c-1.61329,1.49379 -3.55521,2.44981 -7.05067,2.39006c-2.83819,-0.05975 -5.64651,-0.77677 -7.97681,-2.92782c-3.10707,-2.92782 -3.37595,-6.21415 -3.37595,-9.64985l0,-21.48063l-0.02988,0zm9.97849,25.00596l3.3162,-4.00334l2.92782,2.3303l0,-24.28895c0,-1.37428 -0.1195,-2.71869 -1.37428,-3.94359c-0.71702,-0.71702 -1.88217,-1.28466 -3.49546,-1.28466s-2.77844,0.59751 -3.49546,1.28466c-1.2249,1.2249 -1.37428,2.59919 -1.37428,3.94359l0,23.45243c0,1.37428 0.1195,2.71869 1.37428,3.94359c0.71702,0.71702 1.88217,1.28466 3.49546,1.28466c0.65727,0 1.16515,-0.05975 1.76267,-0.26888l-3.13695,-2.44981z"
            className="st0"
          />
          <path
            fill={colorSvg}
            d="m124.50989,10.91085c2.71869,0 6.60253,0.1195 9.4706,2.71869c3.3162,3.04732 3.49546,7.82743 3.49546,9.76936l0,20.01672c0,3.10707 -0.32863,6.42328 -2.92782,9.26147c-2.83819,3.10707 -6.60253,3.55521 -9.14197,3.55521l-9.64985,0l0,-45.32144l8.75358,0zm-2.27055,38.98779l2.92782,0c1.37428,0 3.04732,-0.20913 4.33198,-1.55354c1.37428,-1.49379 1.49379,-3.82409 1.49379,-5.31788l0,-20.07647c0,-1.28466 -0.17925,-2.92782 -1.2249,-4.21247c-1.2249,-1.49379 -2.59919,-1.49379 -4.21247,-1.49379l-3.3162,0l0,32.65415z"
            className="st0"
          />
          <path
            fill={colorSvg}
            d="m144.73574,22.83125c0,-3.43571 0.26888,-6.75191 3.37595,-9.64985c1.94192,-1.82242 4.60086,-2.92782 7.97681,-2.92782c3.37595,0 6.03489,1.1054 7.97681,2.92782c3.10707,2.92782 3.37595,6.21415 3.37595,9.64985l0,21.51051c0,3.43571 -0.26888,6.72203 -3.37595,9.64985c-1.94192,1.82242 -4.60086,2.92782 -7.97681,2.92782c-3.37595,0 -6.03489,-1.1054 -7.97681,-2.92782c-3.10707,-2.92782 -3.37595,-6.21415 -3.37595,-9.64985l0,-21.51051zm16.19263,-0.9859c0,-1.37428 -0.1195,-2.71869 -1.37428,-3.94359c-0.71702,-0.71702 -1.88217,-1.28466 -3.49546,-1.28466c-1.61329,0 -2.77844,0.59751 -3.49546,1.28466c-1.2249,1.2249 -1.37428,2.59919 -1.37428,3.94359l0,23.45243c0,1.37428 0.1195,2.71869 1.37428,3.94359c0.71702,0.71702 1.88217,1.28466 3.49546,1.28466c1.61329,0 2.77844,-0.59751 3.49546,-1.28466c1.2249,-1.2249 1.37428,-2.59919 1.37428,-3.94359l0,-23.45243z"
            className="st0"
          />
          <path
            fill={colorSvg}
            d="m189.87793,22.23374c0,-0.9859 -0.05975,-2.83819 -1.1054,-4.15272c-0.59751,-0.71702 -1.55354,-1.49379 -3.43571,-1.49379c-0.89627,0 -2.15105,0.20913 -3.16682,1.2249c-1.2249,1.28466 -1.37428,3.10707 -1.37428,4.60086c0,1.2249 0.20913,3.49546 1.04565,4.72036c0.71702,1.1054 2.00167,1.61329 2.98757,2.00167l3.94359,1.43403c1.94192,0.71702 4.21247,1.55354 5.88551,3.88384c1.76267,2.39006 1.94192,4.98924 1.94192,7.82743l0,1.55354c0,4.60086 -1.2249,8.09632 -3.10707,10.03824c-1.67304,1.76267 -4.66061,2.98757 -8.42495,2.98757c-4.60086,0 -7.58843,-1.88217 -9.08221,-3.94359c-2.06142,-2.77844 -2.06142,-7.05067 -2.06142,-7.64818l0,-1.49379l6.48303,0l0,1.2249c0,1.04565 0.1195,2.83819 1.04565,4.00334c1.1054,1.37428 2.71869,1.49379 3.55521,1.49379c2.44981,0 3.55521,-0.9859 4.09297,-1.61329c0.9859,-1.2249 1.04565,-3.10707 1.04565,-4.48136l0,-1.67304c0,-1.55354 -0.20913,-3.49546 -1.04565,-4.66061c-0.83652,-1.16515 -2.39006,-1.76267 -3.10707,-2.00167l-4.72036,-1.67304c-2.3303,-0.83652 -4.66061,-2.00167 -5.94527,-5.04899c-0.89627,-2.06142 -0.9859,-4.33198 -0.9859,-6.60253l0,-1.28466c0,-3.76434 1.49379,-6.60253 3.37595,-8.42495c2.15105,-2.06142 4.86974,-2.77844 7.82743,-2.77844c6.48303,0 10.81501,4.21247 10.81501,11.71128l0,1.49379l-6.48303,0l0,-1.2249z"
            className="st0"
          />
        </g>
        <g>
          <path
            fill="${Colors.primaryColor}"
            d="m9.15979,15.95984l0,-8.18594l1.70292,0c0.38838,0 0.68714,0.05975 0.95602,0.17925s0.47801,0.26888 0.65727,0.44814c0.17925,0.17925 0.29876,0.41826 0.35851,0.65727c0.05975,0.23901 0.1195,0.50789 0.1195,0.77677l0,0.29876c0,0.20913 -0.02988,0.41826 -0.05975,0.56764c-0.02988,0.14938 -0.08963,0.29876 -0.14938,0.38838c-0.1195,0.20913 -0.32863,0.38838 -0.59751,0.53776c0.26888,0.1195 0.47801,0.32863 0.59751,0.56764c0.1195,0.23901 0.20913,0.59751 0.20913,1.04565l0,0.44814c0,0.71702 -0.17925,1.28466 -0.53776,1.64316c-0.35851,0.38838 -0.89627,0.56764 -1.67304,0.56764l-1.58341,0l0,0.05975zm1.19503,-7.11042l0,2.24068l0.53776,0c0.38838,0 0.65727,-0.08963 0.77677,-0.29876c0.1195,-0.20913 0.20913,-0.47801 0.20913,-0.8664c0,-0.35851 -0.05975,-0.65727 -0.23901,-0.83652c-0.14938,-0.17925 -0.41826,-0.26888 -0.80664,-0.26888l-0.47801,0l0,0.02988zm0,3.28633l0,2.62906l0.50789,0c0.23901,0 0.41826,-0.02988 0.56764,-0.08963c0.1195,-0.05975 0.23901,-0.17925 0.29876,-0.29876c0.05975,-0.1195 0.1195,-0.26888 0.1195,-0.44814c0.02988,-0.17925 0.02988,-0.35851 0.02988,-0.53776c0,-0.20913 0,-0.38838 -0.02988,-0.53776c-0.02988,-0.14938 -0.05975,-0.29876 -0.14938,-0.38838c-0.08963,-0.1195 -0.17925,-0.17925 -0.29876,-0.23901c-0.1195,-0.05975 -0.29876,-0.05975 -0.50789,-0.05975l-0.53776,0l0,-0.02988z"
            className="st1"
          />
          <path
            fill="${Colors.primaryColor}"
            d="m13.52165,10.1042l1.25478,0l0.80664,3.64484l0.02988,0l0.80664,-3.64484l1.19503,0l-1.67304,6.84154c-0.05975,0.26888 -0.14938,0.47801 -0.26888,0.65727c-0.1195,0.17925 -0.23901,0.29876 -0.38838,0.41826c-0.14938,0.1195 -0.29876,0.17925 -0.47801,0.23901s-0.32863,0.05975 -0.50789,0.05975l-0.32863,0l0,-1.13528l0.26888,0c0.1195,0 0.20913,-0.02988 0.29876,-0.05975s0.14938,-0.08963 0.23901,-0.20913c0.05975,-0.1195 0.1195,-0.26888 0.17925,-0.44814c0.05975,-0.20913 0.1195,-0.47801 0.17925,-0.80664l-1.61329,-5.55688z"
            className="st1"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const kCal = (colorSvg = "currentColor", sizeCal = "30px") => (
  <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={sizeCal} height={sizeCal}>
    <circle fill="none" cx="256" cy="256" r="256" />
    <path
      fill={colorSvg}
      d="m170.62,122.12c-4.84,3.26-9.44,6.87-13.76,10.8-18.05,16.35-34.79,34.08-50.08,53.04-46.82,57.69-55.26,137.56-21.52,203.76,50,95.2,154.72,90.64,164.88,89.92,1.44.16,4.8.32,9.68.32,33.68,0,138.96-9.44,177.44-123.2,46-136-80.24-203.92-81.52-204.64-1.98-1.05-4.31-1.19-6.4-.4-2.1.73-3.81,2.29-4.72,4.32-17.04,39.6-9.84,66,1.12,82.32-39.68-15.76-44.72-54.72-43.68-79.2.9-12.97-.34-26-3.68-38.56-24-85.84-112.64-88.56-113.52-88.56h-.16c-2.97,0-5.71,1.63-7.12,4.24-1.39,2.66-1.17,5.87.56,8.32,12.08,17.36,16.88,33.28,14.16,47.2-2.9,12.59-10.71,23.5-21.68,30.32h0Z"
    />
    <path
      fill="#fff"
      d="m319.74,433.65c.01,3.32,2.72,6,6.03,5.98,0,0,0,0,0,0h9.43c3.34,0,6.04,2.71,6.04,6.04s-2.71,6.04-6.04,6.04h-9.43c-9.99,0-18.09-8.08-18.13-18.07v-60.06c0-3.34,2.71-6.04,6.04-6.04s6.04,2.71,6.04,6.04"
    />
    <path
      fill="#fff"
      d="m266.88,413.29c-3.34,0-6.04-2.71-6.04-6.04s2.71-6.04,6.04-6.04h20.72c6.54.01,11.83,5.31,11.84,11.84v22.11c-.01,9.14-7.42,16.54-16.56,16.56h-5.44c-9.15.02-16.59-7.38-16.62-16.54-.01-4.4,1.73-8.62,4.83-11.74,3.16-3.06,7.38-4.77,11.78-4.77h9.91v-5.62l-20.48.24Z"
    />
    <path
      fill={colorSvg}
      d="m274.25,432.02c-.84.83-1.32,1.96-1.33,3.14-.02,2.45,1.95,4.45,4.4,4.47.04,0,.09,0,.13,0h5.44c2.46,0,4.47-1.99,4.47-4.45,0,0,0-.01,0-.02v-4.41h-9.91c-1.2-.03-2.35.43-3.2,1.27h0Z"
    />
    <path
      fill="#fff"
      d="m217.09,419.87c.02-10.33,8.41-18.69,18.75-18.67,9.28.02,17.14,6.83,18.47,16.01.47,3.28-1.8,6.33-5.08,6.83-3.31.44-6.37-1.84-6.89-5.14-.55-3.62-3.94-6.1-7.55-5.54-3.22.49-5.6,3.25-5.62,6.51v13.17c.04,3.65,3,6.58,6.65,6.59,3.42.04,6.3-2.57,6.59-5.98.32-3.32,3.27-5.75,6.59-5.44,3.32.32,5.75,3.27,5.44,6.59h0c-.99,10.29-10.13,17.82-20.42,16.83-9.58-.92-16.9-8.96-16.92-18.58v-13.17Z"
    />
    <path
      fill="#fff"
      d="m207.91,392.44c3.18,1.03,4.93,4.43,3.93,7.61-2.36,7.66-7.18,14.33-13.72,18.97,6.39,7.32,10.28,16.48,11.12,26.16.24,3.33-2.24,6.23-5.56,6.53h-.48c-3.15,0-5.78-2.41-6.04-5.56-.72-8.05-4.27-15.59-10.03-21.27-1.4.56-2.83,1.02-4.29,1.39v19.39c0,3.34-2.71,6.04-6.04,6.04s-6.04-2.71-6.04-6.04v-73.59c0-3.34,2.71-6.04,6.04-6.04s6.04,2.71,6.04,6.04v41.63c.79-.36,1.63-.66,2.54-1.15.32-.09.63-.23.91-.42,6.65-3.06,11.73-8.75,14.02-15.71,1.02-3.19,4.41-4.97,7.61-3.99h0Z"
    />
  </svg>
);

export const TEMPLATE2_NAME_CODE = "template2";

export const IGNORE_AUTO_SELECT_MODE = ["https://monzu.staging-foxorders.fr", "https://monzu.foxorders.fr"];

export const DURATION_TO_RELOAD_PRODUCTS = 30; //par minute

export const globalStatus = {
  accepted: "Success",
  refused: "Refused",
  error: 3,
  unknown: 4,
};

export const extendedStatus = {
  Sys_Accepted: "2",
  AcceptedOffline: "10",
  AcceptedOnline: "11",
};

export const svgBackCornerBorne = () => (
  <svg width="247" height="204" viewBox="0 0 247 204" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M246.69 -96.6319L138.338 -5.84252L138.358 1.39422C138.404 18.5359 124.419 32.4026 107.278 32.2108L107.856 34.9289C111.812 53.5386 94.3015 69.5413 76.1223 63.9295C78.196 83.5191 58.8913 98.4176 40.4668 91.4467L37.7914 90.4344C36.4019 106.19 21.7035 117.296 6.16739 114.328L-2.9908 112.578L-111.343 203.368L-164.031 140.488C-168.287 135.408 -167.62 127.84 -162.54 123.583L177.098 -161.003C182.178 -165.259 189.746 -164.592 194.003 -159.512L246.69 -96.6319Z"
      fill="#FCF9F8"
    />
  </svg>
);

export const INACTIVITY_TIME = 30 * 1000; //par secondes
export const INACTIVITY_TIME_5_MIN = 900 * 1000; //par secondes (15min)
export const CLOSE_ORDER_TIME = 15 * 1000; //par secondes
export const CLOSE_PAYMENT_TIME = 180 / 2; //par secondes
export const CLOSE_PAYMENT_TIME_ERRORS_OR_RECAP = 8 * 1000; //par secondes

export const DEVICE_DISABLED = "Device disabled";
export const KIOSK_SOURCE = "kiosk";

// MONZU : https://staging-foxorders.fr:8080
// LOUVRINE : https://staging-foxorders.fr:8081
// GEGE : https://staging-foxorders.fr:8082
// MONZU PROD : https://monzu.foxorders.fr
// MTS staging : https://mts.staging-foxorders.fr
// MTS staging borne : https://mts-borne.staging-foxorders.fr
// rimon staging borne : https://bornerimon.staging-foxorders.fr

export const DOMAIN_TEST = "https://mts-borne.staging-foxorders.fr";
