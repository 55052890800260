import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import functions from "../Shared/functions";

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  createPaymentRequest: ["paymentMethodId", "orderId", "subPaymentMethod", "amountToPay"],
  createPaymentSuccess: ["paymentConfig"],
  createPaymentFailure: ["paymentError", "paymentErrors"],

  paymentWithNeptingRetailRequest: [
    "merchantid",
    "login",
    "password",
    "amount",
    "posNumber",
    "merchantReference",
    "currencyCode",
    "transactionType",
    "merchantPrivateData",
  ],
  paymentWithNeptingRetailSuccess: ["paymentConfig"],
  paymentWithNeptingRetailFailure: ["paymentError", "paymentErrors"],

  getResultNeptingRetailRequest: ["merchantId", "transactionNumber"],
  getResultNeptingRetailSuccess: ["transactionNeptingResult"],
  getResultNeptingRetailFailure: ["getResultNeptingRetailError", "getResultNeptingRetailErrors"],

  // getStripeClientSecretRequest: ['amount', 'currency', 'paymentMethodTypes'],
  // getStripeClientSecretSuccess: ['paymentIntent'],
  // getStripeClientSecretFailure: ['paymentError', 'paymentErrors'],

  paymentWithStripeRequest: ["amount", "elements", "stripe", "clientSecret", "emailAdress", "name", "paymentIntentId"],
  paymentWithStripeSuccess: ["paymentIntentStatus"],
  paymentWithStripeFailure: ["paymentWithStripeMsgError"],
  resetStripePaymentData: null,

  paymentWithPaygreenRequest: ["amount", "selectedAddress", "yourName"],
  paymentWithPaygreenSuccess: ["urlPaymentWithPaygreen"],
  paymentWithPaygreenFailure: ["paymentWithPaygreenError", "paymentWithPaygreenErrors"],

  // paymentWithPayxpertRequest: ['amount'],
  // paymentWithPayxpertSuccess: ['customerPayxpertToken'],
  // paymentWithPayxpertFailure: ["paymentWithPayxpertError", "paymentWithPayxpertErrors"],

  resetPaymentConfig: null,
  resetAllPaymentErrors: null,
  paymentSucceeded: null,

  onShowHidePayment: ["paymentModalStatus"],

  retrievePaymentPayPlugResultRequest: ["payplugPaymentId"],
  retrievePaymentPayPlugResultSuccess: ["retrievePaymentPayPlugResult"],
  retrievePaymentPayPlugResultFailure: ["retrievePaymentPayPlugResultError", "retrievePaymentPayPlugResultErrors"],
});

export const PaymentTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  paymentFetching: false,
  paymentError: null,
  paymentErrors: null,
  paymentConfig: null,
  // stripeClientSecret: null,
  paymentIntentId: null,
  paymentWithStripeFetching: false,
  paymentWithStripeMsgError: false,
  paymentIntentStatus: false,
  paymentIntentSuccess: false,
  paymentWithPaygreenFetching: false,
  paymentWithPayxpertFetching: false,
  customerPayxpertToken: null,
  urlPaymentWithPaygreen: null,
  paymentWithNeptingRetailFetching: false,
  paymentWithNeptingRetailError: null,
  paymentWithNeptingRetailErrors: null,
  getResultNeptingRetailFetching: false,
  getResultNeptingRetailError: null,
  getResultNeptingRetailErrors: null,
  transactionNeptingResult: null,
  paymentModalStatus: false,
  retrievePaymentPayPlugResult: null,
  retrievePaymentPayPlugResultFetching: false,
  retrievePaymentPayPlugResultError: null,
  retrievePaymentPayPlugResultErrors: null,
});

function getImmutableAsEntity(immutable, Entity) {
  if (!immutable) return null;
  const entityObj = Immutable.isImmutable(immutable) ? Immutable.asMutable(immutable) : immutable;
  return Immutable(new Entity(entityObj), { prototype: Entity.prototype });
}

/* ------------- Selectors ------------- */
export const PaymentSelectors = {
  getPaymentConfig: (state) => state.payment?.paymentConfig ?? null,
  // getPaymentIntentSecret: state => state.payment?.paymentConfig?.clientSecret,
  // getPublicKey: state => state.payment?.paymentConfig?.publicKey,
  // getStripeClientSecret: state => state.payment?.stripeClientSecret,
  getPaymentIntentId: (state) => state.payment?.paymentIntentId,
  getPaymentWithStripeMsgError: (state) => state.payment?.paymentWithStripeMsgError,
  getPaymentIntentStatus: (state) => state.payment?.paymentIntentStatus,
  getPaymentIntentSuccess: (state) => state.payment?.paymentIntentSuccess,
  getUrlPaymentWithPaygreen: (state) => state.payment?.urlPaymentWithPaygreen,
  getTransactionNeptingResult: (state) => state.payment?.transactionNeptingResult,
  // getPayxpertCustomerToken: state =>state.payment?.paymentConfig?.customerToken,
  getPaymentModalStatus: (state) => state.payment?.paymentModalStatus,
  getRetrievePaymentPayPlugResult: (state) => state.payment?.retrievePaymentPayPlugResult,
};

/* ------------- Reducers ------------- */

export const createPaymentRequest = (state) => state.merge({ paymentFetching: true, paymentConfig: null });
export const createPaymentSuccess = (state, { paymentConfig }) => {
  return state.merge({
    paymentConfig: paymentConfig,
    paymentFetching: false,
    paymentError: null,
    paymentErrors: null,
  });
};
export const createPaymentFailure = (state, { paymentError, paymentErrors }) =>
  state.merge({
    paymentFetching: false,
    paymentError,
    paymentErrors,
  });

// export const getStripeClientSecretRequest = state => state.merge({ paymentFetching: true });

// export const getStripeClientSecretSuccess = (state, {paymentIntent}) => {
//   return state.merge({
//     stripeClientSecret: paymentIntent.client_secret,
//     paymentIntentId: paymentIntent.id,
//     paymentFetching: false,
//     paymentError: null,
//     paymentErrors: null,
//   });
// }

// export const getStripeClientSecretFailure = (state, { paymentError, paymentErrors }) =>
//   state.merge({
//     paymentFetching: false,
//     paymentError,
//     paymentErrors
// })

///////////////////////// STRIPE /////////////////////////

export const paymentWithStripeRequest = (state) => state.merge({ paymentWithStripeFetching: true, paymentIntentSuccess: false });

export const paymentWithStripeSuccess = (state, { paymentIntentStatus }) => {
  return state.merge({
    paymentConfig: null,
    paymentWithStripeFetching: false,
    paymentWithStripeMsgError: null,
    // stripeClientSecret: null,
    paymentIntentStatus,
    paymentIntentSuccess: true,
  });
};

export const paymentWithStripeFailure = (state, { paymentWithStripeMsgError }) =>
  state.merge({
    paymentWithStripeFetching: false,
    paymentWithStripeMsgError,
  });

export const resetStripePaymentData = (state) =>
  state.merge({
    paymentWithStripeMsgError: false,
    paymentIntentStatus: false,
    paymentIntentSuccess: false,
  });

///////////////////////// Paygreen ////////////////////////
export const paymentWithPaygreenRequest = (state) => state.merge({ paymentWithPaygreenFetching: true, urlPaymentWithPaygreen: null });

export const paymentWithPaygreenSuccess = (state, { urlPaymentWithPaygreen }) => {
  return state.merge({
    paymentWithPaygreenFetching: false,
    paymentWithPaygreenError: null,
    paymentWithPaygreenErrors: null,
    urlPaymentWithPaygreen,
  });
};

export const paymentWithPaygreenFailure = (state, { paymentWithPaygreenError, paymentWithPaygreenErrors }) =>
  state.merge({
    paymentWithPaygreenFetching: false,
    paymentWithPaygreenError,
    paymentWithPaygreenErrors,
  });

///////////////////////// Payxpert ////////////////////////
// export const paymentWithPayxpertRequest = state => state.merge({ paymentWithPayxpertFetching: true, customerPayxpertToken: null });
// export const paymentWithPayxpertSuccess = (state, {customerPayxpertToken}) => {

//   return state.merge({
//     paymentWithPayxpertFetching: false,
//     paymentWithPayxpertError: null,
//     paymentWithPayxpertErrors: null,
//     customerPayxpertToken,
//   });
// }

// export const paymentWithPayxpertFailure = (state, { paymentWithPayxpertError, paymentWithPayxpertErrors }) =>
//   state.merge({
//     paymentWithPayxpertFetching: false,
//     paymentWithPayxpertError,
//     paymentWithPayxpertErrors,
// })

export const resetPaymentConfig = (state) => state.merge({ paymentConfig: null });

export const resetAllPaymentErrors = (state) =>
  state.merge({
    paymentError: null,
    paymentErrors: null,
    paymentWithNeptingRetailError: null,
    paymentWithNeptingRetailErrors: null,
    getResultNeptingRetailError: null,
    getResultNeptingRetailErrors: null,
    retrievePaymentPayPlugResultError: null,
    retrievePaymentPayPlugResultErrors: null,
  });

export const paymentSucceeded = (state) => state.merge({ paymentError: null, paymentErrors: null, transactionNeptingResult: null });

export const paymentWithNeptingRetailRequest = (state) => state.merge({ paymentWithNeptingRetailFetching: true });

export const paymentWithNeptingRetailSuccess = (state, {}) => {
  return state.merge({
    paymentWithNeptingRetailFetching: false,
    paymentWithNeptingRetailError: null,
    paymentWithNeptingRetailErrors: null,
  });
};

export const paymentWithNeptingRetailFailure = (state, { paymentWithNeptingRetailError, paymentWithNeptingRetailErrors }) =>
  state.merge({
    paymentWithNeptingRetailFetching: false,
    paymentWithNeptingRetailError,
    paymentWithNeptingRetailErrors,
  });

export const getResultNeptingRetailRequest = (state) =>
  state.merge({ getResultNeptingRetailFetching: true, getResultNeptingRetailError: null, getResultNeptingRetailErrors: null });

export const getResultNeptingRetailSuccess = (state, { transactionNeptingResult }) => {
  return state.merge({
    transactionNeptingResult,
    getResultNeptingRetailFetching: false,
    getResultNeptingRetailError: null,
    getResultNeptingRetailErrors: null,
  });
};

export const getResultNeptingRetailFailure = (state, { getResultNeptingRetailError, getResultNeptingRetailErrors }) =>
  state.merge({
    getResultNeptingRetailFetching: false,
    getResultNeptingRetailError,
    getResultNeptingRetailErrors,
  });

export const onShowHidePayment = (state, { paymentModalStatus }) => state.merge({ paymentModalStatus });

export const retrievePaymentPayPlugResultRequest = (state) =>
  state.merge({
    retrievePaymentPayPlugResult: null,
    retrievePaymentPayPlugResultFetching: true,
    retrievePaymentPayPlugResultError: null,
    retrievePaymentPayPlugResultErrors: null,
  });

export const retrievePaymentPayPlugResultSuccess = (state, { retrievePaymentPayPlugResult }) => {
  const status = retrievePaymentPayPlugResult.state;
  const finalResult = functions.paymentIsPending(status) ? null : status;
  return state.merge({
    retrievePaymentPayPlugResult: finalResult,
    retrievePaymentPayPlugResultFetching: false,
    retrievePaymentPayPlugResultError: null,
    retrievePaymentPayPlugResultErrors: null,
  });
};

export const retrievePaymentPayPlugResultFailure = (state, { retrievePaymentPayPlugResultError, retrievePaymentPayPlugResultErrors }) => {
  return state.merge({
    retrievePaymentPayPlugResultFetching: false,
    retrievePaymentPayPlugResultError,
    retrievePaymentPayPlugResultErrors,
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_PAYMENT_REQUEST]: createPaymentRequest,
  [Types.CREATE_PAYMENT_SUCCESS]: createPaymentSuccess,
  [Types.CREATE_PAYMENT_FAILURE]: createPaymentFailure,

  [Types.PAYMENT_WITH_NEPTING_RETAIL_REQUEST]: paymentWithNeptingRetailRequest,
  [Types.PAYMENT_WITH_NEPTING_RETAIL_SUCCESS]: paymentWithNeptingRetailSuccess,
  [Types.PAYMENT_WITH_NEPTING_RETAIL_FAILURE]: paymentWithNeptingRetailFailure,

  [Types.GET_RESULT_NEPTING_RETAIL_REQUEST]: getResultNeptingRetailRequest,
  [Types.GET_RESULT_NEPTING_RETAIL_SUCCESS]: getResultNeptingRetailSuccess,
  [Types.GET_RESULT_NEPTING_RETAIL_FAILURE]: getResultNeptingRetailFailure,

  // [Types.GET_STRIPE_CLIENT_SECRET_REQUEST]: getStripeClientSecretRequest,
  // [Types.GET_STRIPE_CLIENT_SECRET_SUCCESS]: getStripeClientSecretSuccess,
  // [Types.GET_STRIPE_CLIENT_SECRET_FAILURE]: getStripeClientSecretFailure,

  [Types.PAYMENT_WITH_STRIPE_REQUEST]: paymentWithStripeRequest,
  [Types.PAYMENT_WITH_STRIPE_SUCCESS]: paymentWithStripeSuccess,
  [Types.PAYMENT_WITH_STRIPE_FAILURE]: paymentWithStripeFailure,
  [Types.RESET_STRIPE_PAYMENT_DATA]: resetStripePaymentData,

  [Types.PAYMENT_WITH_PAYGREEN_REQUEST]: paymentWithPaygreenRequest,
  [Types.PAYMENT_WITH_PAYGREEN_SUCCESS]: paymentWithPaygreenSuccess,
  [Types.PAYMENT_WITH_PAYGREEN_FAILURE]: paymentWithPaygreenFailure,

  // [Types.PAYMENT_WITH_PAYXPERT_REQUEST]: paymentWithPayxpertRequest,
  // [Types.PAYMENT_WITH_PAYXPERT_SUCCESS]: paymentWithPayxpertSuccess,
  // [Types.PAYMENT_WITH_PAYXPERT_FAILURE]: paymentWithPayxpertFailure,
  [Types.RESET_PAYMENT_CONFIG]: resetPaymentConfig,

  [Types.RESET_ALL_PAYMENT_ERRORS]: resetAllPaymentErrors,

  [Types.PAYMENT_SUCCEEDED]: paymentSucceeded,

  [Types.ON_SHOW_HIDE_PAYMENT]: onShowHidePayment,

  [Types.RETRIEVE_PAYMENT_PAY_PLUG_RESULT_REQUEST]: retrievePaymentPayPlugResultRequest,
  [Types.RETRIEVE_PAYMENT_PAY_PLUG_RESULT_SUCCESS]: retrievePaymentPayPlugResultSuccess,
  [Types.RETRIEVE_PAYMENT_PAY_PLUG_RESULT_FAILURE]: retrievePaymentPayPlugResultFailure,
});
