import { useEffect, useRef, useState, useCallback } from "react";
import { Platform } from "react-native";
import { Loader } from "@googlemaps/js-api-loader";
import i18n from "i18n-js";
import Secrets from "../../config.env";

export function useRedirectAfterLoadSetting(props) {
  useEffect(() => {
    if (!props.isFontLoaded && props.loadingFont !== true && props.loadFontRequest instanceof Function) {
      props.loadFontRequest(Platform.OS);
    }
    if (__DEV__) console.log("foxOrders useRedirectAfterLoadSetting props", props);
    if (props.customColors) {
      props.navigation?.navigate("Shops");
    }
  }, [props.isFontLoaded, props.loadingFont, props.customColors?.length, props.selectedShop?.id]);
}

export function usePrevious(value) {
  const prevRef = useRef();
  useEffect(() => {
    prevRef.current = value;
  }, [value]);
  return prevRef.current;
}

// exec ==> useBeforeRender(() => console.log("one before render"), false);
export function useBeforeRender(callback, deps) {
  const [isRun, setIsRun] = useState(false);

  if (!isRun) {
    callback();
    setIsRun(true);
  }

  useEffect(() => () => setIsRun(false), deps ? deps : [isRun]);
}

export function useGoogleMapsPlaces(props) {
  const [googleMapsValues, setGoogleMapsValues] = useState(null);
  const [googleMapsApiLoader, setGoogleMapsApiLoader] = useState(null);
  const autoCompleteRef = useRef();
  const googleMapsConfig = {
    apiKey: Secrets.GOOGLE_MAP_API_Key,
    version: "weekly",
    language: i18n.locale,
    fields: ["address_components", "geometry", "icon", "name"],
  };
  useEffect(() => {
    if (i18n.locale) {
      try {
        if (!googleMapsApiLoader) {
          const loader = new Loader(googleMapsConfig);
          setGoogleMapsApiLoader(loader);
        } else {
          // googleMapsApiLoader.reset();
          googleMapsApiLoader.deleteScript();
          googleMapsApiLoader.language = i18n.locale; // cette ligne
          delete googleMapsApiLoader.instance;
          document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach((script) => {
            const scriptName = script.src?.replace?.(/^(.*[\\\/])/, "");
            if (["places.js", "common.js", "util.js", "controls.js", "places_impl.js"].includes(scriptName)) {
              script.remove();
            }
          });
          if (window.google) delete window.google.maps;
          const loader = new Loader(googleMapsConfig);
          loader.setScript();
          setGoogleMapsApiLoader(loader);
        }
      } catch (error) {
        console.log("googleMapsPlaces Loader error ", error);
      }
    }
  }, [i18n.locale]);

  const inputRef = useCallback(
    (node) => {
      if (!!node && googleMapsApiLoader) {
        googleMapsApiLoader.load().then(async () => {
          const { Autocomplete } = await google.maps.importLibrary("places");
          const selectedShopCountryCode = props.selectedShop?.getCountryCode(); // on peut penser a un tableau de country si le shop n'a pas de pays associés
          autoCompleteRef.current = new Autocomplete(node, {
            componentRestrictions: { country: !!selectedShopCountryCode ? selectedShopCountryCode : "fr" },
          });
          autoCompleteRef.current.setFields(["address_components", "formatted_address", "geometry"]);
          autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            const address = place.address_components ?? [];
            let city = "",
              country = "",
              zip = "",
              street = "",
              streetNumber = "",
              countryCode = "";
            address.forEach(function (component) {
              const types = component.types;
              if (types.indexOf("locality") > -1) {
                city = component.long_name;
              }
              if (types.indexOf("country") > -1) {
                country = component.long_name;
                countryCode = component.short_name;
              }
              if (types.indexOf("postal_code") > -1) {
                zip = component.long_name;
              }
              if (types.indexOf("route") > -1) {
                street = component.long_name;
              }
              if (types.indexOf("street_number") > -1) {
                streetNumber = component.long_name;
              }
            });

            const lat = place.geometry?.location?.lat;
            const lng = place.geometry?.location?.lng;
            const postalCode = {
              code: zip,
              city: {
                name: city,
                country: {
                  name: country,
                  // localCode: countryCode,
                  code: countryCode,
                },
              },
              latitude: lat?.() ?? "",
              longitude: lng?.() ?? "",
            };
            // setValues({...values, city, country, localCode: countryCode, zipCode: zip, street: `${street}${!!streetNumber? ` ${streetNumber}`: ''}`, lat: lat?.(), lng: lng?.() });
            setGoogleMapsValues({
              postalCode,
              //city, country, zipCode ===> pour l'utilisation sur le formulaire
              city,
              country,
              zipCode: zip,
              street: `${!!streetNumber ? `${streetNumber} ` : ""}${street}`,
              latitude: lat?.(),
              longitude: lng?.(),
            });
          });
        });
      }
    },
    [googleMapsApiLoader]
  );

  return { googleMapsPlacesInputRef: inputRef, googleMapsValues, googleMapsApiLoader };
}
