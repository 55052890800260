import AsyncStorage from "@react-native-async-storage/async-storage";
import FakeLocalStorage from "./FakeLocalStorage";

function isLocalStorageEnabled() {
  try {
    return !!window.localStorage?.setItem;
  } catch (e) {
    return false;
  }
}
const LocalStorage = isLocalStorageEnabled() ? AsyncStorage: FakeLocalStorage;

export default LocalStorage;