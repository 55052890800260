import Immutable from "seamless-immutable";
import BaseEntity from "./BaseEntity";
import SpecialOffer from "./SpecialOffer";

export default class ShopMode extends BaseEntity {
  constructor(props) {
    super(props);
    const mutableProps = this.getImmutableAsObj(props);
    if (mutableProps?.promotions)
      mutableProps.promotions = Immutable(
        mutableProps?.promotions?.map((offer) => Immutable(new SpecialOffer(this.getImmutableAsObj(offer)), { prototype: SpecialOffer.prototype })),
      );

    mutableProps && Object.assign(this, Immutable(mutableProps));
  }

  getName() {
    return this.mode?.name;
  }

  getType() {
    return this.mode?.type;
  }

  getCode() {
    return this.mode?.code;
  }

  getMode() {
    return this.mode;
  }

  isTakeAway() {
    return this.getCode() === "take-out";
  }

  isDelivery() {
    return this.getCode() === "delivery";
  }

  isOnSite() {
    return this.getCode() === "on-site";
  }

  get = (property, defaultValue = "") => {
    const propertyPath = property?.split(".");
    return Immutable.getIn(this, propertyPath, defaultValue);
  };

  canOrderAnonymously() {
    return this.anonymous;
  }
}
