import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import LaunchScreen, { MyLoader } from "./LaunchScreen";
import { FranchiseSelectors } from "./Redux/FranchiseRedux";

function IndexContainer(props) {
  if (__DEV__) console.log("foxOrders props.customTemplate", props.customTemplate);
  if (props.customTemplate) {
    return <Suspense fallback={<MyLoader />}>{getSuitableContainer(props.customTemplate)}</Suspense>;
  }
  return <LaunchScreen />;

  function getSuitableContainer(templateName) {
    // const RootContainerBorne = lazy(() => import("./TemplateBorne/Containers/RootContainer"));
    // return <RootContainerBorne />;

    if (templateName === "borne") {
      const RootContainerBorne = lazy(() => import("./TemplateBorne/Containers/RootContainer"));
      return <RootContainerBorne />;
    } else if (templateName === "fox") {
      const RootContainerT1 = lazy(() => import("./Template1/Containers/RootContainer"));
      return <RootContainerT1 />;
    } else if (templateName === "template2") {
      const RootContainerT2 = lazy(() => import("./Template2/Containers/RootContainer"));
      return <RootContainerT2 />;
    } else if (templateName) {
      const RootContainerT1 = lazy(() => import("./Template1/Containers/RootContainer"));
      return <RootContainerT1 />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    customTemplate: FranchiseSelectors.getCustomTemplate(state),
  };
};

// wraps dispatch to create nicer functions to call within our component
const mapDispatchToProps = (dispatch) => ({
  // logout: () => dispatch(LoginActions.logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexContainer);
