import Immutable from "seamless-immutable";
import functions from "../../Shared/functions";
import BaseEntity from "./BaseEntity";
import Option from "./Option";
import Aliment from "./Aliment";

export default class Product extends BaseEntity {
  constructor(props) {
    super(props);
    // if(props.options) props.options = Immutable(props.options.map((option)=> Immutable(new Option(this.getImmutableAsObj(option)), {prototype: Option.prototype})));
    if (props.crossSellingProducts)
      props.crossSellingProducts = Immutable(
        props.crossSellingProducts.map((product) => Immutable(new Product(this.getImmutableAsObj(product)), { prototype: Product.prototype })),
      );
    if (props.upSellingProducts)
      props.upSellingProducts = Immutable(
        props.upSellingProducts.map((product) => Immutable(new Product(this.getImmutableAsObj(product)), { prototype: Product.prototype })),
      );
    if (props.aliments)
      props.aliments = Immutable(props.aliments.map((aliment) => Immutable(new Aliment(this.getImmutableAsObj(aliment)), { prototype: Aliment.prototype })));

    props && Object.assign(this, Immutable(props));
  }

  getName(langCode = "fr_FR") {
    if (!this.productTranslations) {
      return this.name;
    }
    let productTranslations = this.productTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    productTranslations = productTranslations?.name
      ? productTranslations
      : this.productTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");
    return productTranslations?.name ?? "";
  }

  getDescription(langCode = "fr_FR") {
    if (!this.productTranslations) {
      return this.description;
    }
    let productTranslations = this.productTranslations?.find?.((translation) => translation?.locale?.code === langCode);
    productTranslations = productTranslations?.description
      ? productTranslations
      : this.productTranslations?.find?.((translation) => translation?.locale?.code === "fr_FR");

    return productTranslations?.description ?? "";
  }

  getOptionsAsArray() {
    if (!this.options) {
      return [];
    }
    const sortedOptions = Immutable(this.getImmutableAsObj(this.options)?.sort((a, b) => a.position - b.position));
    return Object.values(sortedOptions).map((option) => option.id);
  }

  getOptionsIds() {
    if (!this.options) {
      return [];
    }
    const sortedOptions = Immutable(
      this.getImmutableAsObj(this.options)
        ?.filter((option) => !option?.sideComponent)
        ?.sort((a, b) => a.position - b.position),
    );
    return Object.values(sortedOptions).map((option) => option.id);
  }

  getComponentsIds() {
    if (!this.options) {
      return [];
    }
    const sortedOptions = Immutable(
      this.getImmutableAsObj(this.options)
        ?.filter((option) => !!option?.sideComponent)
        ?.sort((a, b) => a.position - b.position),
    );
    return Object.values(sortedOptions).map((option) => option.id);
  }

  getCategoriesAsArray() {
    return this.productCategories
      ? Object.values(this.productCategories).map((productCat) => {
          if (productCat?.category?.id) {
            return productCat.category.id;
          }
          if (productCat.category && typeof productCat.category === "string" && productCat.category?.includes?.("/api/categories/")) {
            const categoryid = parseInt(productCat.category.replace("/api/categories/", ""));
            if (categoryid && categoryid != NaN) {
              return categoryid;
            }
          }
          return undefined;
        })
      : [];
  }

  checkIfInArray(cats) {
    return cats.some((cat) => this.getCategoriesAsArray().includes(cat.id));
  }

  checkIfProductInCat(cat) {
    return this.getCategoriesAsArray().includes(cat.id);
  }

  getDataProductByShopMode(shopMode) {
    return this.productShopModes?.find((mode) => mode?.shopMode?.mode?.code === shopMode);
  }

  // "productShopModes":[{ "shopMode": { "mode": { "name": "take-out" } }, "price": 10, "tax": { "value": 1 }}, { "shopMode": { "mode": { "name": "delivery" } }, "price": 11, "tax": { "value": 1 }}, { "shopMode": { "mode": { "name": "on-site" } }, "price": 10, "tax": { "value": 1 }}]
  getPriceByShopMode(shopMode) {
    const ProductByShopMode = this.getDataProductByShopMode(shopMode);
    const productPrice = ProductByShopMode?.price;
    return isNaN(productPrice) ? undefined : productPrice;
  }

  getFormatedPriceByShopMode(shopMode, selectedShop) {
    const initPrice = this.getPriceByShopMode(shopMode);
    return functions?.formatPrice(initPrice, selectedShop);
  }

  getTaxByShopMode(shopMode, qty = 1) {
    const productByShopMode = this.getDataProductByShopMode(shopMode);
    if (!productByShopMode?.tax?.rate) return 0;
    const price = productByShopMode?.price;
    const tax = productByShopMode?.tax?.rate * price * qty;
    return tax;
  }

  getOptionsVariantsPrice(selectedOptions) {
    if (!selectedOptions || selectedOptions === {}) return 0;
    const optionsPrice = Object.values(selectedOptions).reduce((acc, value) => {
      const optionsVariantPrice = Object.values(value.optionVariants).reduce((acc1, value1) => {
        return acc1 + value1.price * value1.quantity;
      }, 0);
      return acc + optionsVariantPrice;
    }, 0);
    return optionsPrice;
  }

  getFinalPrice(selectedOptions, qty = 1, deliveriesModesByLink) {
    const optionsPrice = this.getOptionsVariantsPrice(selectedOptions);

    return this.getPriceQty(qty, deliveriesModesByLink) + qty * optionsPrice;
  }

  getPriceQty(qty, deliveriesModesByLink) {
    return this.getPriceByShopMode(deliveriesModesByLink) * qty;
  }

  getOptionsVariantsTax(selectedOptions, qty = 1, shopMode) {
    if (!selectedOptions || selectedOptions === {}) return 0;
    const productByShopMode = this.getDataProductByShopMode(shopMode);
    const productRate = productByShopMode?.tax?.rate;
    if (!productRate) return 0;
    const optionsPrice = Object.values(selectedOptions).reduce((acc, value) => {
      const optionsVariantPrice = Object.values(value.optionVariants).reduce((acc1, value1) => {
        // return value1?.tax?.rate ? acc1 + value1.price * value1?.tax?.rate : acc1;
        //on utise productRate car on a plus le rate par option variante.
        return value1?.tax?.rate ? acc1 + value1.price * productRate : acc1;
      }, 0);
      return acc + optionsVariantPrice;
    }, 0);
    return qty * optionsPrice;
  }

  isQuantitySlot() {
    return this.blockedBySlot;
  }
}
