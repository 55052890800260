import { put, call, take, select } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { dateFormatSyncForApi, dateFormatSyncInStore, delaySync, isSynch, serverTimeZone } from "../Shared/Constants";
import delay from "@redux-saga/delay-p";
import functions from "../Shared/functions";
import moment from "moment";
import SynchronisationActions, { SynchronisationSelectors } from "../Redux/SynchronisationRedux";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import { ShopSelectors } from "../Redux/ShopRedux";
import { FranchiseSelectors } from "../Redux/FranchiseRedux";

export function* synchronisation(api, { endPointApi, params, getSinceSelectors, callbackFunc }) {
  let page = 1;
  const enabled = true;
  let resultSync = [];
  let temp = 0;
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const isBorne = franchise?.isTemplateBorneFn();
  while (isSynch) {
    yield delay(delaySync);
    const idleSkipRequest = yield select(SynchronisationSelectors.getIdleSkipRequest);
    if (idleSkipRequest) {
      continue;
    }
    if (isBorne && !["getShopsSync"].includes(endPointApi)) {
      const selectedShop = yield select(ShopSelectors.getShop);
      if (selectedShop && selectedShop.id) {
        const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, selectedShop.id);
        if (!selectedDevicesInfo?.borne?.token || !selectedDevicesInfo?.borne?.enabled) {
          // si pas de token ne pas tentez de synchroniser
          continue;
        }
      } else {
        continue;
      }
    }
    const initSince = yield select(getSinceSelectors); // since with seconds
    const initSinceAsMomoent = moment(initSince);
    const validInitSince = initSinceAsMomoent.isValid() ? initSinceAsMomoent : moment().subtract(delaySync + 1, "seconds");
    const since = validInitSince.format(dateFormatSyncForApi); // since without seconds

    if (__DEV__) console.log("Foxorders synchronisation endPointApi , initSince, since", endPointApi, initSince, since);

    // test si since + delaySync > date now do break // case of twice call
    //dateFormatSyncInStore car il contient la partie seconds
    const nowDate = functions.getNowDate(serverTimeZone, dateFormatSyncInStore, true);
    const secondsBetweenSinceAndNow = functions.getSecondsBetweenTwoTimes(validInitSince?.toDate(), nowDate, serverTimeZone);
    if (secondsBetweenSinceAndNow * 1000 >= delaySync) {
      const apiCall = call(api[endPointApi], { ...params, since, enabled, page });
      const response = yield call(callApi, apiCall);
      if (response.ok) {
        const responseResult = response.data;
        let responsePagination = responseResult["hydra:view"]
          ? {
              first: responseResult["hydra:view"]["hydra:first"],
              last: responseResult["hydra:view"]["hydra:last"],
              next: responseResult["hydra:view"]["hydra:next"],
              current: responseResult["hydra:view"]["@id"],
              totalItems: responseResult["hydra:totalItems"],
            }
          : { first: 0, last: 0, next: 0, current: 0, totalItems: 0 };
        resultSync = responseResult["hydra:member"]?.length > 0 ? responseResult["hydra:member"] : [];
        while (responsePagination?.next) {
          page = page + 1;

          const apiCall2 = call(api[endPointApi], { ...params, since, enabled, page });
          const response2 = yield call(callApi, apiCall2);

          if (response.ok) {
            const responseResult2 = response2.data;
            responsePagination = responseResult2["hydra:view"]
              ? {
                  first: responseResult2["hydra:view"]["hydra:first"],
                  last: responseResult2["hydra:view"]["hydra:last"],
                  next: responseResult2["hydra:view"]["hydra:next"],
                  current: responseResult2["hydra:view"]["@id"],
                  totalItems: responseResult2["hydra:totalItems"],
                }
              : { first: 0, last: 0, next: 0, current: 0, totalItems: 0 };
            resultSync = responseResult2["hydra:member"]?.length > 0 ? resultSync.push(responseResult2["hydra:member"]) : resultSync;
          }
        }
        yield call(callbackFunc, resultSync);
      } else {
        yield put(SynchronisationActions.synchronisationFailure(response?.data?.["hydra:description"], response?.data?.["hydra:description"]));
      }
    }
  }
}
