import { put, call, select, take, all } from "redux-saga/effects";
import * as Font from "expo-font";
import { getFontsToLoad } from "../Themes/Fonts";

import StartupActions, { StartupTypes } from "../Redux/StartupRedux";
import FranchiseActions, { FranchiseSelectors, FranchiseTypes } from "../Redux/FranchiseRedux";
import LoginActions from "../Redux/LoginRedux";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import Secrets from "../../config.env";
import { DoPrintTest } from "../TemplateBorne/PrinterService/DoPrint";
import { ShopSelectors } from "../Redux/ShopRedux";
// process LOAD_FONT_REQUEST actions
export function* loadFont(os) {
  yield call(Font.loadAsync, getFontsToLoad(os));

  yield put(StartupActions.loadFontSuccess());
}

// process STARTUP actions
export function* startup(api, action) {
  const franchise = yield select(FranchiseSelectors.getFranchise);
  const isBorne = franchise?.isTemplateBorneFn();
  if (isBorne) {
    yield put(StartupActions.resetCacheRequest('data'));
  }

  yield put(StartupActions.getGeneratedTokenRequest(Secrets.IS_DEV_OR_STAGING ? "staging" : "prod"));
  yield take([StartupTypes.GET_GENERATED_TOKEN_SUCCESS, StartupTypes.GET_GENERATED_TOKEN_FAILURE]);

  yield put(LoginActions.autoLoginRequest());
  // if we're here it means store has been rehydrated if it needed to
  yield all([
    take([FranchiseTypes.GET_FRANCHISE_SUCCESS, FranchiseTypes.GET_FRANCHISE_FAILURE]),
    put(FranchiseActions.getFranchiseRequest(getOwnerIdFromHost())),
  ]);
  const franchiseData = yield select(FranchiseSelectors.getFranchise);
  const selectedLocaleInStore = yield select((state) => state.franchise.selectedLocale);
  yield put(FranchiseActions.setSelectedLocaleRequest(selectedLocaleInStore ?? franchiseData?.parameter?.locale?.code ?? "fr_FR", false));
  // yield call(DoPrintTest);

  const shop = yield select(ShopSelectors.getShop);
  //ici c'est le cas d'actualisation (réhydratation du store)
  if (shop?.id) {
    const selectedDevicesInfo = yield select(FranchiseSelectors.getSelectedDevicesInfo, shop.id);
    if (selectedDevicesInfo?.borne?.token) {
      yield call(api.setDeviceToken, selectedDevicesInfo.borne);
    }
  }
}

const getOwnerIdFromHost = () => {
  // const ownerId = window.location?.host ? window.location?.host?.split(":")[0] : "";
  const ownerId = window.location?.origin ? window.location?.origin : "";
  return ownerId;
};

export function* changeStartupState(api, action) {}

export function* generateToken(api, { env }) {
  const apiCall = call(api.generateToken, { env });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(StartupActions.generateTokenSuccess(response.data));
  } else {
    yield put(StartupActions.generateTokenFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* getGeneratedToken(api, { env }) {
  const apiCall = call(api.getGeneratedToken, { env });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(StartupActions.getGeneratedTokenSuccess(response.data));
  } else {
    yield put(StartupActions.getGeneratedTokenFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}
