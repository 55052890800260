import { put, call, take, select } from "redux-saga/effects";
import callApi from "./CallApiSaga";
import { getApiResponseError, getApiResponseErrors } from "../Shared/ApiResponse";
import ProductActions, { ProductsSelectors } from "../Redux/ProductRedux";
import { ShopSelectors } from "../Redux/ShopRedux";
import { MenuSelectors } from "../Redux/MenuRedux";
import { synchronisation } from "./SynchronisationSagas";
import functions from "../Shared/functions";
import { serverTimeZone } from "../Shared/Constants";

export function* getProducts(api, { menuId, status, page }) {
  const serverTimeZoneVal = serverTimeZone;
  if (!status && !page) {
    //le cas pour relancer la synchronisation après actualisation de la page et DURATION_TO_RELOAD_PRODUCTS n'est pas achever
    yield put(ProductActions.getProductsFailure(null, null));
    return yield call(getSynchProducts, api, { serverTimeZoneVal, menuId });
  }
  const apiCall = call(api.getProducts, { menuId, status, page });
  const response = yield call(callApi, apiCall);

  if (response.ok) {
    const shop = yield select(ShopSelectors.getShop);
    yield put(ProductActions.getProductsSuccess(response.data, shop, serverTimeZoneVal, page));

    yield call(getSynchProducts, api, { serverTimeZoneVal, menuId });
  } else {
    yield put(ProductActions.getProductsFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

export function* getOptionsByProduct(api, { productId, status }) {
  const apiCall = call(api.getOptionsByProduct, { productId, status });
  const response = yield call(callApi, apiCall);
  if (response.ok) {
    yield put(ProductActions.getOptionsByProductSuccess(response.data));
  } else {
    yield put(ProductActions.getOptionsByProductFailure(getApiResponseError(response), getApiResponseErrors(response)));
  }
}

// ProductsSelectors.getProductsPagination(state)

// sync Products
export function* getSynchProducts(api, { serverTimeZoneVal, menuId }) {
  const menu = yield select(MenuSelectors.getMenu);
  const endPointApi = "getProductsSync";
  if (!menuId && !menu?.id) return;

  const params = { menuId: menu ? menu.id : menuId };

  const getSinceSelectors = ProductsSelectors.getSinceProducts;

  yield call(synchronisation, api, {
    endPointApi,
    params,
    getSinceSelectors,
    callbackFunc: function* (resultSync) {
      const productsPagination = yield select(ProductsSelectors.getProductsPagination);
      if (resultSync.length > 0 && !productsPagination?.next) {
        const productsFresh = yield select(ProductsSelectors.getProducts);

        const finalProducts = resultSync?.map((productRes) => {
          const originalProduct = productsFresh?.find((oProduct) => oProduct.id === productRes.id);
          if (originalProduct?.productCategories) productRes.productCategories = originalProduct.productCategories;
          if (originalProduct?.options) productRes.options = originalProduct.options;
          // if(originalProduct.productShopModes) productRes.productShopModes = originalProduct.productShopModes;

          return productRes;
        });

        const finalArraySync = functions.getFinalArraySync(productsFresh, finalProducts);
        const shop = yield select(ShopSelectors.getShop);

        yield put(ProductActions.getProductsFromSyncSuccess(finalArraySync, shop, serverTimeZoneVal));
      } else if (!productsPagination?.next) {
        yield put(ProductActions.setSinceProducts(serverTimeZoneVal));
      }
    },
  });
}
